import React, { useState, useEffect } from 'react';
import { Card, CardContent,Grid, Typography, TextField, Select, MenuItem, Button, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { COLORS } from '../Component/colors';
import { CorporateFare as CorporateFareIcon, CardMembership as CardMembershipIcon, LocationOn, Email, Phone, AccountCircle, Lock, DateRange, FormatListNumbered, Person , Add, Remove} from '@mui/icons-material';
import axios from 'axios';
import api from '../Services/api';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import { Link ,useNavigate} from 'react-router-dom';

const useStyles = makeStyles({
 

  detail: {
    display: 'flex',
    alignItems: 'center',
   justifyContent:"center",
   marginBottom: 15
  },
  
  textField: {
    width: '80%',
    '& .MuiInputLabel-root': {
      color: 'gray', // Default label color (or set to something else)
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'gray', // Outline color to white when not focused
      },
      '&:hover fieldset': {
        borderColor: 'gray', // Optional: Outline color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black', // Optional: Outline color when focused
      },
    },
    // When the field is focused, change label color to white
    '& .MuiInputLabel-root.Mui-focused': {
      color: 'gray',
    },
    // Text inside input area when focused
    '& .MuiOutlinedInput-input': {
      color: 'black', // Text color inside the input area when focused
    },
    // Ensure the text inside is visible when the field is not focused
    '& .MuiOutlinedInput-input.Mui-focused': {
      color: 'black', // Make text black when focused to keep it visible
    },
  },

});

const statesInIndia = ['Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal', 'Andaman and Nicobar Islands', 'Chandigarh', 'Dadra and Nagar Haveli and Daman and Diu', 'Delhi', 'Ladakh', 'Lakshadweep', 'Puducherry'];
const CompanyCard = () => {
  let data1 = sessionStorage.getItem('user');
  let assessorData = JSON.parse(data1);
  let id1 = assessorData.id;
  let id2 = assessorData.companyId;
  const classes = useStyles();
  const [formData, setFormData] = useState({
    clientName: '',
    assessorId: id1,
    principleEmployer: '',
    companyId: id2,
    companyAddress: '',
    clientContactPersonName: '',
    clientContactPersonPhoneNumber: '',
    clientContactPersonEmailId: '',
    clientContactPersonPhoneNumber1: '',
    clientContactPersonEmailId1: '',
    stateId: '',
    placeName: '',
    pinCode: '',
    natureOfWork: '',
    // noOfWorkers:'',
    // startDate: '',
    // endDate: '',
    vendorCompanyName: '',
    vendorName: '',
    isVendor: true,
    isDeleted: false,
    vendorCompanyAddress: '',
    vendorEmail: '',
    vendorPhoneNumber: '',
    vendorEmail1: '',
    vendorPhoneNumber1: '',
    vendorStateId: '',
    vendorPlaceName: '',
    vendorPinCode: '',
    vendorUsername: '',
    vendorCode: '',
    vendorPassword: '',
    vendorContractStartDate: '',
    vendorContractEndDate: '',
  });
  const navigate=useNavigate();
  const [errors, setErrors] = useState({});
  const [clientOptions, setClientOptions] = useState([]);
  const [showPassword, setShowPassword] = React.useState(false);
  const [extraEmailVendorVisible, setExtraEmailVendorVisible] = useState(false);
  const [extraPhoneNumberVisible, setExtraPhoneNumberVisible] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [snackbarErrorMessage, setSnackbarErrorMessage] = useState('');


  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleAddEmailVendor = () => {
    setExtraEmailVendorVisible(prevState => !prevState);
  }

  const handlePhoneNumberVendor = () => {
    setExtraPhoneNumberVisible(prevState => !prevState);
  }

  //FETCHING LOGGED ASSESSOR CLIENT DETAILS 
  useEffect(() => {
    try{
    api.client.getClient(id2).then((response) => {
      if(response.status === 200){
      const client = response.data.data;
      setFormData(prevState => ({
        ...prevState,
        principleEmployer: client.principleEmployer || '',
        companyAddress: client.companyAddress || '',
        placeName: client.placeName || ' ',
        stateId: client.stateId || '',
        pinCode: client.pinCode || '',
        clientName: client.clientName || '',
        clientContactPersonName: client.clientContactPersonName || '',
        clientContactPersonPhoneNumber: client.clientContactPersonPhoneNumber || '',
        clientContactPersonEmailId: client.clientContactPersonEmailId || '',
        clientContactPersonPhoneNumber1: client.clientContactPersonPhoneNumber1 || '',
        clientContactPersonEmailId1: client.clientContactPersonEmailId1 || '',
        // startDate: client.startDate || '',
        // endDate: client.endDate || ''
      }));
      setClientOptions(response.data.data);
    }
    else if(response.status === 404){
      setErrorSnackbarOpen(true);
      setSnackbarErrorMessage("Company Profile Not Found");
    }
    else{
      setErrorSnackbarOpen(true);
      setSnackbarErrorMessage("Error fetching Client Details");
    }
  })
}
  catch(error) {
        console.error('Error fetching company names:', error);
       
      }
  }, []);



  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));

  };


  //VALIDATION
  const validateForm = () => {
    const newErrors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^[6789]\d{9}$/;
    const pinCodePattern = /^(?!000000$)[0-9]{6}$/;
    // const noOfWorkers =  /^[0-9]+$/;
    if (!formData.clientName) newErrors.clientName = 'Select client';
    if (!formData.natureOfWork) newErrors.natureOfWork = 'Nature of work is required';
    if (!formData.vendorCompanyName) newErrors.vendorCompanyName = 'Vendor Company Name is required';
    if (!formData.vendorName) newErrors.vendorName = 'Vendor Name is required';
    if (!formData.vendorPlaceName) newErrors.vendorPlaceName = 'Place Name is required';
    if (!formData.vendorCompanyAddress) newErrors.vendorCompanyAddress = 'Vendor Company Address is required';
    const stateIdVendor = formData.vendorStateId;
    if(!stateIdVendor){
        newErrors.stateIdVendor = 'Please select State';
      }
      if(!pinCodePattern.test(formData.vendorPinCode)){
        newErrors.vendorPinCode = formData.vendorPinCode ? 'Invalid Pin code format(245678)' : 'Pin code required';
      }
      // if(!noOfWorkers.test(formData.noOfWorkers)){
      //   newErrors.noOfWorkers = formData.noOfWorkers ? 'Enter only numbers' : 'No of Workers is required';
      // }
      
    if (!emailPattern.test(formData.vendorEmail)) {
      newErrors.vendorEmail = formData.vendorEmail ? 'Invalid email address(ex:text@email.com)' : 'Email address required';
    }
  
    if (!phonePattern.test(formData.vendorPhoneNumber)) {
      newErrors.vendorPhoneNumber = formData.vendorPhoneNumber ? 'Invalid phone number(only numbers and length should be 10)' : 'Phone number required';
    }
  

    if (formData.vendorEmail1 && !emailPattern.test(formData.vendorEmail1)) {
      newErrors.vendorEmail1 = 'Invalid email address (e.g., text@email.com)';
    }
    
    if (formData.vendorPhoneNumber1 && !phonePattern.test(formData.vendorPhoneNumber1)) {
      newErrors.vendorPhoneNumber1 = 'Invalid phone number. Number should not start with zero)';
    }
    if (!formData.vendorUsername) newErrors.vendorUsername = 'Username is required';
    if (!formData.vendorCode) newErrors.vendorCode = 'VendorCode is required';
    if (!formData.vendorPassword) newErrors.vendorPassword = 'Password is required';
    if (!formData.vendorContractStartDate) newErrors.vendorContractStartDate = 'Contract Start Date is required';
    if (!formData.vendorContractEndDate) newErrors.vendorContractEndDate = 'Contract End Date is required';
    const selectField = formData.clientName || formData.clientName;
    if (!selectField) {
      newErrors.selectField = 'Please select client';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };



  //CREATING VENDOR PROFILE
  const handleSave = async () => {
    if (validateForm()) {
      let data = sessionStorage.getItem('user');
      let data1 = JSON.parse(data);
      let type = data1.type;
      try {
        if(type === 'assessor'){
     const response = await api.vendor.createVendor(formData)
        
         
           if (response.status === 400) {
            setErrorSnackbarOpen(true);
            setSnackbarErrorMessage('The username you Entered is already in use. Please enter a different one')
          }
          else if (response.status === 201) {
            const vendorData = response.data;
            sessionStorage.setItem('vendor', JSON.stringify(vendorData));
            setSnackbarOpen(true);
            setSnackbarMessage("Vendor Created Succesfully!!")
            handleSubmit();

          }
else{
  setErrorSnackbarOpen(true);
  setSnackbarErrorMessage('Some error occured try Again')
  
}
        }
      
      else {
        setErrorSnackbarOpen(true);
        setSnackbarErrorMessage("Only assessor can create vendors");
        navigate('/');
            }
        
          } 
      catch(error){
        console.error('Some error occured try Again', error);
        
      }
    }
  };


  //VENDOR MASTERDATA CREATION
  const handleSubmit = async () => {
    let data = sessionStorage.getItem('vendor');
    let vendorData = JSON.parse(data);
    const id = vendorData.data.vendorId;

    const masterData = {
      vendorId: id,
      assessorId: id1,
      companyId: id2,

      dateOfCommencement: {
        value: ""
      },
      dateOfCompletion: {
        value: ""
      },
      poCopy: {
        value: ""
      },
      epfoAllotment: {
        value: ""
      },
      esicAllotment: {
        value: ""
      },
      pTaxRc: {
        value: ""
      },
      ptEc: {
        value: ""
      },
      lwf: {
        value: ""
      },
      factoryLicence: {
        value: ""
      },
      buildingPlanLayout: {
        value: ""
      },
      stabilityCertificate: {
        value: ""
      },
      concernToEstablishment: {
        value: ""
      },
      concernToOperations: {
        value: ""
      },
      fireAdvisory: {
        value: ""
      },
      fireNoc: {
        value: ""
      },
      environmentClearance: {
        value: ""
      },
      medicalOrHospitalCertificate: {
        value: ""
      },
      apprenticeshipRegistration: {
        value: ""
      },
      neemOrNapsRegistration: {
        value: ""
      },
      clraRc: {
        value: ""
      },
      sAndCeRc: {
        value: ""
      },
      clraLicence: {
        value: ""
      },
      bocwRc: {
        value: ""
      },
      ismwRc: {
        value: ""
      },
      ismwLicence: {
        value: ""
      },
      passaraLicence: {
        value: ""
      },
      fssaiLicence: {
        value: ""
      },


    }
    try {
   const response = await api.masterData.createMasterData(masterData)
        if (response.status === 201) {
         
          setSnackbarOpen(true);
          setSnackbarMessage('VendorMasterData Created Successfully')
          setFormData({
            natureOfWork: '',
            vendorCompanyName: '',
            vendorName: '',
            vendorCode:'',
            vendorStateId: '',
            vendorPinCode: '',
            vendorPlaceName: '',
            vendorCompanyAddress: '',
            vendorEmail: '',
            // noOfWorkers:'',
            vendorPhoneNumber: '',
            vendorEmail1: '',
            vendorPhoneNumber1: '',
            vendorUsername: '',
            vendorPassword: '',
            vendorContractStartDate: '',
            vendorContractEndDate: '',
          });
      setTimeout(function(){
        window.location.reload()
      },4000);


        }
        else if(response.status === 400){
      
          setErrorSnackbarOpen(true);
          setSnackbarErrorMessage('MasterData Already Exists')
        }
        else{
         
          setErrorSnackbarOpen(true);
          setSnackbarErrorMessage('ERROR!! Some error occured, please try again')
          setFormData({
            natureOfWork: '',
            vendorCompanyName: '',
            vendorName: '',
            vendorCode: '',
            vendorStateId: '',
            vendorPinCode: '',
            vendorPlaceName: '',
            vendorCompanyAddress: '',
            vendorEmail: '',
            // noOfWorkers:'',
            vendorPhoneNumber: '',
            vendorEmail1: '',
            vendorPhoneNumber1: '',
            vendorUsername: '',
            vendorPassword: '',
            vendorContractStartDate: '',
            vendorContractEndDate: '',
          });

        }


    }
      catch(error) {
        console.error('An unexpected error occurred. Please try again', error);
        
        

      }
  };



  return (
    <>
      <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          
          }}
          severity="success"
          open={snackbarOpen}
          autoHideDuration={4000}
          onClose={() => setSnackbarOpen(false)}
          sx={{
            marginTop: '2.9%', 
            marginRight: '7%'
          }}
        >
          <SnackbarContent style={{ backgroundColor: "green" }} message={snackbarMessage} />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          severity="error"
          open={errorSnackbarOpen}
          autoHideDuration={5000}
          sx={{
            marginTop: '2.9%', 
            marginRight: '7%'
          }}
          onClose={() => {setErrorSnackbarOpen(false)}}
        >
          <SnackbarContent style={{ backgroundColor: "red" }} message={snackbarErrorMessage} />
        </Snackbar>
        <div style={{background:'#eeee'}}>
   <h4 className="p-3  text-center " style={{borderRadius:'0px 0px 0px 0px',background: 'linear-gradient(205deg, rgba(28,28,28,1) 68%, rgba(213,214,215,0) 8%)',color:'#eeee'}}>Create Vendor Profile</h4>
<div className="mt-4">
        <Grid container>
      <Grid item xs={12} sm={6}>
      <h6 className="text-secondary pb-2" style={{paddingLeft:'65px'}}>Client Details</h6>
          <div className={classes.detail}>
            {/* <CorporateFareIcon className={classes.icon} /> */}
            <TextField className={classes.textField} label="Principal Employer" InputLabelProps={{ shrink: true }} name="principleEmployer" value={formData.principleEmployer} onChange={handleChange} InputProps={{ readOnly: true }} />

          </div>

          <div className={classes.detail}>
            {/* <LocationOn className={classes.icon} /> */}
            <TextField   InputLabelProps={{ shrink: true }} className={classes.textField} label="Address" name="companyAddress" value={formData.companyAddress} onChange={handleChange} InputProps={{ readOnly: true }} />
          </div>
          <div className={classes.detail}>
            {/* <LocationOn className={classes.icon} /> */}
            <TextField
              InputLabelProps={{ shrink: true }}
              className={classes.textField}
              label="Place"
              name="placeName"
              value={formData.placeName}
              onChange={handleChange}

              InputProps={{ readOnly: true }}

            />
          </div>
          <div className={classes.detail}>
            {/* <LocationOn className={classes.icon} /> */}
            <TextField
              InputLabelProps={{ shrink: true }}
              className={classes.textField}
              label="Pincode"
              name="pinCode"
              value={formData.pinCode}
              onChange={handleChange}

              InputProps={{ readOnly: true }}

            />
          </div>
          <div className={classes.detail}>
            {/* <CorporateFareIcon className={classes.icon} /> */}
            <TextField 
              InputLabelProps={{ shrink: true }} className={classes.textField} label="Contractor" name="clientName " value={formData.clientName} onChange={handleChange} InputProps={{ readOnly: true }} />

          </div>

          <div className={classes.detail}>
            {/* <Person className={classes.icon} /> */}
            <TextField 
              InputLabelProps={{ shrink: true }} className={classes.textField} label="Contact Person Name" name="clientContactPersonName" value={formData.clientContactPersonName} onChange={handleChange} InputProps={{ readOnly: true }} />
          </div>
          <div className={classes.detail}>
            {/* <Email className={classes.icon} /> */}
            <TextField
              InputLabelProps={{ shrink: true }}
               className={classes.textField} label="Email" name="clientContactPersonEmailId" value={formData.clientContactPersonEmailId} onChange={handleChange} InputProps={{ readOnly: true }} />
          </div>
          <div className={classes.detail}>
            {/* <Phone className={classes.icon} /> */}
            <TextField 
              InputLabelProps={{ shrink: true }}
              className={classes.textField} label="Phone Number" name="clientContactPersonPhoneNumber" value={formData.clientContactPersonPhoneNumber} onChange={handleChange} InputProps={{ readOnly: true }} />
          </div>
        </Grid>

         <Grid xs={12} sm={6}>
         <h6 className="text-secondary pb-2" style={{paddingLeft:'60px'}}>Vendor Details</h6>
          <div className={classes.detail}>
            <TextField className={classes.textField} label="Vendor Company Name" name="vendorCompanyName" value={formData.vendorCompanyName} onChange={handleChange} error={!!errors.vendorCompanyName}
                InputLabelProps={{ shrink: true }}
                helperText={errors.vendorCompanyName} />
          </div>
          <div className={classes.detail}>
          
            <Select
            // style={{border:'1px solid black'}}
            InputLabelProps={{ shrink: true }}
              className={classes.textField}
              label="State ID"
              value={formData.vendorStateId}
              onChange={handleChange}
              name="vendorStateId"
              displayEmpty
              error={!!errors.vendorStateId}
            >
              <MenuItem value=""   InputLabelProps={{ shrink: true }} disabled >Select State</MenuItem>
              {statesInIndia.map((state, index) => (

                <MenuItem key={index} value={state}>{state}</MenuItem>
              ))}
            </Select>

          </div>
          {errors.stateIdVendor && (
            <div className="pb-3 text-center" style={{ color: 'red', fontSize: '14px' }}>
              {errors.stateIdVendor}
            </div>
          )}
          <div className={classes.detail}>
            {/* <LocationOn className={classes.icon} /> */}
            <TextField   InputLabelProps={{ shrink: true }} className={classes.textField} label="Vendor Company Address" name="vendorCompanyAddress" value={formData.vendorCompanyAddress} onChange={handleChange} error={!!errors.vendorCompanyAddress}
              helperText={errors.vendorCompanyAddress} />
          </div>
          <div className={classes.detail}>
            {/* <MyLocationIcon className={classes.icon} /> */}
            <TextField
              className={classes.textField}
              label="Place"
              InputLabelProps={{ shrink: true }}
              name="vendorPlaceName"
              value={formData.vendorPlaceName}
              onChange={handleChange}
              
              error={!!errors.vendorPlaceName}
              helperText={errors.vendorPlaceName}
            />
          </div>
          <div className={classes.detail}>
            {/* <LocationOn className={classes.icon} /> */}
            <TextField className={classes.textField}   InputLabelProps={{ shrink: true }} label="Pincode" name="vendorPinCode" value={formData.vendorPinCode} onChange={handleChange} error={!!errors.vendorPinCode}
              helperText={errors.vendorPinCode} />
          </div>
          <div className={classes.detail}>
            {/* <Person className={classes.icon} /> */}
            <TextField 
              InputLabelProps={{ shrink: true }}
              className={classes.textField} label="Vendor Name" name="vendorName" value={formData.vendorName} onChange={handleChange} error={!!errors.vendorName}
              helperText={errors.vendorName} />
          </div>
          <div className={classes.detail}>
            {/* <Person className={classes.icon} /> */}
            <TextField className={classes.textField}   InputLabelProps={{ shrink: true }}
             label="Vendor Code" name="vendorCode" value={formData.vendorCode} onChange={handleChange} error={!!errors.vendorCode}
              helperText={errors.vendorCode} />
          </div>
          <div className={classes.detail}>
            {/* <Phone className={classes.icon} /> */}
            <TextField className={classes.textField}   InputLabelProps={{ shrink: true }} sx={{marginLeft:'42px'}} label="Vendor Phone Number" name="vendorPhoneNumber" type="number" value={formData.vendorPhoneNumber} onChange={handleChange} error={!!errors.vendorPhoneNumber}
              helperText={errors.vendorPhoneNumber} />
                <IconButton 
                  onClick={handlePhoneNumberVendor} 
                  sx={{ marginLeft: "5px" }} 
                  color="primary"
              >
                  {extraPhoneNumberVisible ? <Remove sx={{ color: "gray" }} /> : <Add sx={{ color: "gray" }} />}
              </IconButton>
          </div>
          {extraPhoneNumberVisible && (
            <div className={classes.detail}>
                <TextField 
                    className={classes.textField}
                    label="Alternative Phone Number"
                    InputLabelProps={{ shrink: true }}
                    name="vendorPhoneNumber1"
                    type="number"
                    value={formData.vendorPhoneNumber1}
                    onChange={handleChange}
                    error={!!errors.vendorPhoneNumber1}
                    helperText={errors.vendorPhoneNumber1} 
                />
            </div>
        )}
          <div className={classes.detail}>
            {/* <Email className={classes.icon} /> */}
            <TextField className={classes.textField} 
              InputLabelProps={{ shrink: true }}
               sx={{marginLeft:'42px'}} label="Vendor Email" name="vendorEmail" value={formData.vendorEmail} onChange={handleChange} error={!!errors.vendorEmail}
              helperText={errors.vendorEmail} />
               <IconButton
              onClick={handleAddEmailVendor}
              style={{ marginLeft: '5px' }}
              color="primary"
            >
              {extraEmailVendorVisible ? <Remove sx={{ color: "gray" }} /> : <Add sx={{ color: "gray" }} />}
            </IconButton>
          </div>
          {extraEmailVendorVisible && (
            <div className={classes.detail}>
              {/* <MailOutlineIcon className={classes.icon} /> */}
              <TextField className={classes.textField}
                label="Alternative Email"
                InputLabelProps={{ shrink: true }}
                name="vendorEmail1"
                value={formData.vendorEmail1}
                onChange={handleChange}
                error={!!errors.vendorEmail1}
                helperText={errors.vendorEmail1} />

            </div>
          )}
          <div className={classes.detail}>
            {/* <FormatListNumbered className={classes.icon} /> */}
            <TextField  
             InputLabelProps={{ shrink: true }}
            className={classes.textField} label="Nature Of Work" name="natureOfWork" value={formData.natureOfWork} onChange={handleChange} error={!!errors.natureOfWork}
              helperText={errors.natureOfWork} />
          </div>
         
            {/* <LocationOn className={classes.icon} /> */}
            {/* <TextField
              className={classes.textField}
              InputLabelProps={{ shrink: true }}
              label="No Of Workers"
              name="noOfWorkers"
              value={formData.noOfWorkers}
              onChange={handleChange}
              helperText={errors.noOfWorkers}
              error={!!errors.noOfWorkers}
             

            /> */}
         


          <div className={classes.detail}>
            {/* <DateRange className={classes.icon} /> */}
            <TextField
              className={classes.textField}
              label="Contract Start Date"
              InputLabelProps={{ shrink: true }}
              type="date"
              name="vendorContractStartDate"
              value={formData.vendorContractStartDate}
              onChange={handleChange}
             
              error={!!errors.vendorContractStartDate}
              helperText={errors.vendorContractStartDate}
            />
          </div>
          <div className={classes.detail}>
            {/* <DateRange className={classes.icon} /> */}
            <TextField
              InputLabelProps={{ shrink: true }}
              className={classes.textField}
              label="Contract End Date"
              type="date"
              name="vendorContractEndDate"
              value={formData.vendorContractEndDate}
              onChange={handleChange}
             
              error={!!errors.vendorContractEndDate}
              helperText={errors.vendorContractEndDate}
            />
          </div>
        

          <div className={classes.detail}>
            {/* <AccountCircle className={classes.icon} /> */}
            <TextField
              InputLabelProps={{ shrink: true }}
              className={classes.textField}
              label="Username"
              name="vendorUsername"
              value={formData.vendorUsername}
              onChange={handleChange}
             
              error={!!errors.vendorUsername}
              helperText={errors.vendorUsername}
            />
          </div>

          <div className={classes.detail}>
            {/* <Lock className={classes.icon} /> */}
            <FormControl sx={{ width: '100%' }} variant="outlined">

              <TextField
               sx={{marginLeft:'10%'}}
              className={classes.textField}
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff sx={{color:"black"}}/> : <Visibility sx={{color:'black'}}/>}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}

                label="Password"
                InputLabelProps={{ shrink: true }}
                name="vendorPassword"
                value={formData.vendorPassword}
                onChange={handleChange}
                error={!!errors.vendorPassword}
                helperText={errors.vendorPassword}
              />
            </FormControl>
            </div>
</Grid>
<Grid item xs={12}>
<button type="button pb-3" className="btn  btn-outline-dark float-end   mt-2 mb-2" style={{marginRight:'65px'}} disabled={!validateForm} onClick={handleSave}>
        Create
      </button>
    </Grid>
</Grid>


    </div>
    </div>
    </>
  );
};

export default CompanyCard;
