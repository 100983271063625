import Footer from "../layout/Footer"
import Navbar from "../layout/NavBar"
import { Link } from "react-router-dom"
import imageOne from "../Images/lc-one.png"
import imageTwo from "../Images/lc-two.png"
import imageThree from "../Images/lc-three.png"
import { TiTick } from "react-icons/ti";

const NewLabourCodes = () => {
  return (
    <>
      <Navbar />
      <div className="p-8 pt-5 h-50 bg-primary-color h-[400px] bg-opacity-20 grid grid-cols-2">
        <div className=" pb-8 mt-5  flex flex-col justify-center">
          <h1 className="text-2xl text-primary-color text-center font-bold ">
            New Labour Codes
          </h1>
          <h1 className="leading-6 text-center mt-2">
          The Government of India introduced four new labour codes to consolidate and simplify the country’s complex and fragmented labour laws. These codes aim to promote ease of doing business, enhance employee welfare, and ensure uniformity in the implementation of labour laws across sectors and states.
          </h1>
          <h1 className="leading-6 text-left mt-2">
            The four labour codes are :
          </h1>
          <div className="d-flex flex-col gap-2  mt-2">
            <h1 className="flex items-center"><TiTick fontSize="1rem" /> <span>Code on Wages, 2019</span></h1>
            <h1 className="flex items-center"><TiTick fontSize="1rem" /> <span>Industrial Relations Code, 2020</span></h1>
            <h1 className="flex items-center"><TiTick fontSize="1rem" /> <span>Code on Social Security, 2020</span></h1>
            <h1 className="flex items-center"><TiTick fontSize="1rem" /> <span>Occupational Safety, Health, and Working Conditions Code (OSH Code), 2020</span></h1>
          </div>
          <h1 className="pt-2 leading-6 text-center">
          We evaluate the impact of New Labour Codes on your Compensation and Benefits and IR. We can assist you in making necessary changes in your Policies and Processes to be fully ready for implementation of the New Labour Codes from the day they become effective.
          </h1>
          <div className="mt-lg-3 mt-sm-1 flex justify-center item-center"> 
                <Link to="/contact-us">
                  <button className=" p-2 bg-primary-color text-white rounded-md shadow-lg hover:bg-danger transition duration-300">
                    Contact Us
                  </button>
                </Link>
          </div>             
        </div>
        <div className="flex justify-center items-center">
          <img src={imageOne} alt="" className="w-[350px] h-[350px]" />
        </div>
      </div>
      <div className="mt-4 px-[100px] grid grid-cols-2">
        <div>
          <img src={imageThree} alt="" className="w-[350px] h-[350px]" />
        </div>
        <div>
          <h1 className="font-bold text-primary-color text-xl">
            Benefits of Labor Law Compliance
          </h1>
          <div className="flex gap-2 mt-2 items-center">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Legal Protection:</h1>
              <h1 className="mt-1">Helps avoid legal disputes, fines, and penalties by ensuring compliance with labor laws.</h1>
            </div>
          </div>
          <div className="flex gap-2 mt-4 items-center">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold"> Employee Satisfaction:</h1>
              <h1 className="mt-1">  Promotes fair treatment, leading to higher employee morale and retention. </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-4 items-center">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold"> Workplace Safety: </h1>
              <h1 className="mt-1"> Reduces workplace accidents and ensures a safer working environment. </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-4 items-center">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Enhanced Reputation:  </h1>
              <h1 className="mt-1"> Demonstrates commitment to employee welfare, enhancing the company's reputation.</h1>
            </div>
          </div>
          <div className="flex gap-2 mt-4 items-center">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Risk Mitigation:  </h1>
              <h1 className="mt-1">  Identifies and mitigates potential legal and financial risks associated with non-compliance.</h1>
            </div>
          </div>
          <div className="flex gap-2 mt-4 items-center">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Productivity Boost:  </h1>
              <h1 className="mt-1">  Ensures a motivated and well-treated workforce, leading to increased productivity.</h1>
            </div>
          </div>
          <div className="flex gap-2 mt-4 items-center">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Fair Dispute Resolution:  </h1>
              <h1 className="mt-1"> Provides clear processes for resolving conflicts, reducing workplace tensions.</h1>
            </div>
          </div>
          <div className="flex gap-2 mt-4 items-center">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Equal Opportunity:</h1>
              <h1 className="mt-1">  Promotes diversity and inclusion, fostering a more dynamic and innovative workplace.</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 px-4 mt-4">
        <div>
          <h1 className="font-bold text-primary-color text-xl">
            Key Areas of Labor Law
          </h1>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold"> Employment Contracts  </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold"> Wages and Compensation
              </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">
                Working Hours and Leave
              </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Health and Safety  </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">
                Discrimination and Equal Opportunity
              </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Termination and Severance  </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">
                Employee Benefits
              </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">
                Dispute Resolution
              </h1>
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <img src={imageTwo} alt="" className="w-[450px] h-[450px]" />
        </div>
      </div>
      <Footer />
    </>)
}

export default NewLabourCodes