import Footer from "../layout/Footer"
import Navbar from "../layout/NavBar"
import { Link } from "react-router-dom"
import imageOne from "../Images/pa-one.png"
import imageTwo from "../Images/pa-two.png"
import imageThree from "../Images/pa-three.png"

const ProductivityAudit = () => {
  return (
    <>
      <Navbar />
      <div className="p-5 bg-primary-color h-[450px] bg-opacity-20 grid grid-cols-2">
        <div className="flex flex-col justify-center">
          <h1 className="text-2xl text-primary-color text-center font-bold ">
            Productivity Audit
          </h1>
          <h1 className="leading-6 text-center mt-2">
          Our productivity experts can help in establishing baseline productivity standards, implement lean practices and establish an improvement culture for your organization.
          </h1>
          <div className="mt-lg-3 mt-sm-1 flex justify-center item-center"> 
              <Link to="/contact-us">
                <button className=" p-2 bg-primary-color text-white rounded-md shadow-lg hover:bg-danger transition duration-300">
                 Contact Us
                </button>
              </Link>
          </div>           
        </div>
        <div className="mt-3  flex justify-center items-center">
          <img src={imageOne} alt="" className="w-[350px] h-[350px]" />
        </div>
      </div>
      <div className="mt-4 px-[100px] grid grid-cols-2">
        <div>
          <img src={imageThree} alt="" className="w-[350px] h-[350px]" />
        </div>
        <div>
          <h1 className="font-bold text-primary-color text-xl">
            Benefits of Productivity Audit
          </h1>
          <div className="flex gap-2 mt-2 items-center">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Identifies Inefficiencies:  </h1>
              <h1 className="mt-1"> Highlights areas where resources are being underutilized, enabling process improvements and redundancies.</h1>
            </div>
          </div>
          <div className="flex gap-2 mt-4 items-center">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Enhances Workflow:  </h1>
              <h1 className="mt-1">Streamlines operations to reduce delays and imporove overall efficiency.</h1>
            </div>
          </div>
          <div className="flex gap-2 mt-4 items-center">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold"> Cost Savings:</h1>
              <h1 className="mt-1"> Cuts down on unnecessary expenses by addressing waste and inefficiencies. </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-4 items-center">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Boosts Employee Morale:   </h1>
              <h1 className="mt-1"> Resolves issues that may hinder productivity, leading to a more motivated workforce.</h1>
            </div>
          </div>
          <div className="flex gap-2 mt-4 items-center">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Increases Output:  </h1>
              <h1 className="mt-1"> Optimizes resource usage, resulting in higher productivity and better performance.</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 px-4 mt-4">
        <div>
          <h1 className="font-bold text-primary-color text-xl">
            Key Areas of productivity Audit
          </h1>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Resource Utilization
              </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">
                Employee Performance               </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Technology and Tools </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">
                Time Management
              </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">   Communication and Collaboration </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">
                Goal Alignment
              </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">
                Feedback and Improvement
              </h1>
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <img src={imageTwo} alt="" className="w-[450px] h-[450px]" />
        </div>
      </div>
      <Footer />
    </>)
}

export default ProductivityAudit