import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { Link, useNavigate ,useLocation} from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import grclogo from '../Images/GRC-Pulse-Logo.png';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { COLORS } from '../Component/colors';
import { SideBar } from './SideBar';
import { Email, Phone } from '@mui/icons-material';
import LogoutIcon from '@mui/icons-material/Logout';
import { SlCalender } from 'react-icons/sl';


const pages = ['Client Dashboard','Vendors-Master Data','Vendors-Monthly Data'];

export const ClientAppBar = () => {
  const [showProfileText, setShowProfileText] = React.useState(false);
  const [showLogoutText, setShowLogoutText] = React.useState(false);
  const navigate = useNavigate();
 const location = useLocation(); 


  const handleLogout = () => {
    navigate("/signin");
    // onLogout();
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("token");
    console.log("log out clicked");
  };

  const handleProfile = () => {
    navigate("/client/profile");
  }
  const isPageActive = (page) => {
    return location.pathname.includes(page.toLowerCase().replace(/\s+/g, ''));
  };

  return (
    <AppBar position="sticky" sx={{ backgroundColor: '#1C1C1C' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* First row: Logo on the left, Email and Phone on the right */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/clientdashboard"
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                textDecoration: 'none',
              }}
            >
              <img
                src={grclogo}
                width="130px"
                alt="Company Logo"
                style={{
                  padding: '5px'
                }}
              />
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'center', fontSize: 12, color: COLORS.ivory }}>
            <Typography sx={{ mr: 1 }}>
  <a href="mailto:support@grcpulse.in" style={{ textDecoration: 'none', color: COLORS.ivory }}>
    <Email /> support@grcpulse.in
  </a>
</Typography>
              <Typography><Phone /> 080-31634351<br/><span style={{ paddingLeft: '20px'}}>+91-8310163968 </span></Typography>
            </Box>
          </Box>
        </Toolbar>

        {/* Second row: Pages (links) with black background */}
        <Box sx={{ backgroundColor: '#B22222' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            {/* Pages */}
            <Box sx={{ display: 'flex' }}>
              {pages.map((page) => (
                <Button
                  key={page}
                  sx={{
                    m: 1,


                    display: 'block',
                    textTransform: 'none',
                  }}
                >
                  <Link className="nav-link" to={`/${page.replace(/\s+/g, '').toLowerCase()}`} style={{ color: COLORS.ivory, textDecoration: 'none' }}>
                    <Typography sx={{ fontSize: 15 ,color: isPageActive(page)? 'white': '#C0C0C0'}}>{page}</Typography>
                  </Link>
                </Button>
              ))}
            </Box>

            {/* Profile and Logout Icons */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {/* Profile Icon */}
              <Link to="/complience-calender">
                <SlCalender size="1.6rem" color='#ffffff' />
              </Link>
              <Tooltip title={showProfileText ? 'Profile' : ''}>
                <IconButton
                  onClick={handleProfile}
                  onMouseEnter={() => setShowProfileText(true)}
                  onMouseLeave={() => setShowProfileText(false)}
                  sx={{ p: 1 }}
                >
                  <AccountCircle sx={{ fontSize: '35px', color: COLORS.ivory }} />
                </IconButton>
              </Tooltip>

              {/* Logout Icon */}
              <Tooltip title={showLogoutText ? 'Log Out' : ''}>
                <IconButton 
                  onClick={handleLogout}
                  onMouseEnter={() => setShowLogoutText(true)}
                  onMouseLeave={() => setShowLogoutText(false)}
                  sx={{ p: 1 }}
                >
                  <LogoutIcon sx={{ fontSize: '30px', color: COLORS.ivory }} />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </Container>
    </AppBar>
  )
}
