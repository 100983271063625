import React, { useState, useEffect, useRef } from 'react';
import { COLORS } from '../Component/colors';
import api from '../Services/api';
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import MonthlyReport from './MonthlyReport';
import { BiUpload } from 'react-icons/bi';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Snackbar, Button, MenuItem, Select, FormControl, InputLabel,TextField} from '@mui/material';
import SnackbarContent from '@mui/material/SnackbarContent';
import { IoIosWarning } from "react-icons/io";
import { SideBar } from './SideBar';
import zIndex from '@mui/material/styles/zIndex';
import { useLocation } from 'react-router-dom';
 
 
const months = [
    { name: "JANUARY", value: "01" },
    { name: "FEBRUARY", value: "02" },
    { name: "MARCH", value: "03" },
    { name: "APRIL", value: "04" },
    { name: "MAY", value: "05" },
    { name: "JUNE", value: "06" },
    { name: "JULY", value: "07" },
    { name: "AUGUST", value: "08" },
    { name: "SEPTEMBER", value: "09" },
    { name: "OCTOBER", value: "10" },
    { name: "NOVEMBER", value: "11" },
    { name: "DECEMBER", value: "12" }
];
const currentYear = new Date().getFullYear();

const years = Array.from({ length: currentYear - 2020 + 1 }, (x, i) => 2020 + i);
const titles = [
    "The Contract Labour (Regulation & Abolition) Act, 1970",
    "Generic",
    "Minimum Wages Act, 1936",
    "The Employee's Provident Funds & Miscellaneous Provisions Act and Scheme, 1952",
    "The Employees' State Insurance Act and Regulations, 1948",
    "Professional Tax",
    "The Payment of Bonus Act, 1972",
    "Shops And Commercial Establishments Act",
    "The Maternity Benefit Act, 1961",
    "The Employees Compensation Act, 1923",
    "The Equal Remuneration Act, 1976",
    "The Factories Act, 1948",
    "The Labour Welfare Fund Act, 1965",
 
];
const titleToKeyMap = {
    "Combined Muster Roll-cum-Register of Wages": "combinedMusterRollCumRegisterOfWages",
    "Register of Workmen": "RegisterOfWorkmen",
    "Leave Register/Leave Book": "leaveRegisterLeaveBook",
    "Employment Card": "EmploymentCard",
    "Wage Slip": "WageSlip",
    "Service Certificate for Left Employees": "ServiceCertificateForLeftEmployees",
    "In and out time Register": "inAndOutTimeRegister",
    "Bank Statement for Salary Credit Confirmation": "BankStatementOrSalaryCreditConfirmation",
    "PF Declaration on letterhead": "PFDeclarationOnLetterhead",
    "ESI Declaration on letterhead": "ESIDeclarationOnLetterhead",
    "Overtime Declaration on letterhead": "OvertimeDeclarationOnLetterhead",
    "Payment done as per revised rate of Minimum wages": "PaymentDoneAsPerRevisedRateOfMinimumwages",
    "PF Monthly ECR": "PFMonthlyECR",
    "PF Combined Challan": "PFCombinedChallan",
    "PF TRRN Summary": "PFTRRNSummary",
    "IW-1 International Worker Monthly Returns": "IW1InternationalWorkerMonthlyReturns",
    "ESI-Accident Register": "ESIAccidentRegister",
    "ESIC Contribution Challan": "ESICContributionChallan",
    "ESIC Monthly Statement": "ESICMonthlyStatement",
    "Pehchan Card (Insured Person)": "PehchanCard",
    "Professional Tax Returns": "professionalTaxReturns",
    "PT Challan": "PTChallan",
    "Monthly Bonus Paid or Not": "MonthlyBonusPaidOrNot",
    "Statutory Bonus Return": "statutoryBonusReturn",
    "Registration Of Establishment": "registrationOfEstablishment",
    "Appointment Order In Form Q": "appointmentOrderInFormQ",
    "Muster Roll Of Women Workers": "musterRollOfWomenWorkers",
    "Employees Compensation Policy": "employeesCompensationPolicy",
    "Equal Remuneration And Register To Be Maintained": "EqualRemunerationAndRegisterToBeMaintained",
    "Register of Overtime and Payment": "RegisterofOvertimeandPayment",
    "Register Of Leave And Leave Book": "RegisterOfLeaveAndLeaveBook",
    "Register Of Adult Workers Cum Muster Roll": "RegisterOfAdultWorkersCumMusterRoll",
    "Combined register for Muster Roll and Wages": "CombinedregisterforMusterRollandWages",
    "Register Of Unclaimed Wages And Payment To The Board": "RegisterOfUnclaimedWagesAndPaymentToTheBoard",
    "LWF Challan": "LWFChallan",
 
 
 
 
 
 
};
 
 
 
const preferredSubTitles = {

    "The Contract Labour (Regulation & Abolition) Act, 1970": [

        { subtitle: "Combined Muster Roll-cum-Register of Wages", risk: "High" },

        { subtitle: "Register of Workmen", risk: "Medium" },

        { subtitle: "Leave Register/Leave Book", risk: "High" },

        { subtitle: "Employment Card", risk: "High" },

        { subtitle: "Wage Slip", risk: "High" },

        { subtitle: "Service Certificate for Left Employees", risk: "Medium" },

 

    ],

    "Generic": [

        {subtitle:"In and out time Register", risk: "Low"},

       {subtitle:"Bank Statement for Salary Credit Confirmation", risk:"High"},

        {subtitle:"PF Declaration on letterhead", risk:"Low"},

        {subtitle:"ESI Declaration on letterhead", risk:"Low"},

        {subtitle:"Overtime Declaration on letterhead", risk:"Low"}

    ],

    "Minimum Wages Act, 1936": [

        {subtitle:"Payment done as per revised rate of Minimum wages", risk:"High"},

    ],

    "The Employee's Provident Funds & Miscellaneous Provisions Act and Scheme, 1952": [

        {subtitle:"PF Monthly ECR", risk:"High"},

        {subtitle:"PF Combined Challan", risk:"High"},

        {subtitle:"PF TRRN Summary", risk:"High"},

        {subtitle:"IW-1 International Worker Monthly Returns", risk:"High"}

    ],

    "The Employees' State Insurance Act and Regulations, 1948": [

        {subtitle:"ESI-Accident Register", risk:"High"},

       {subtitle:"ESIC Contribution Challan", risk:"High"},

        {subtitle:"ESIC Monthly Statement", risk:"High"},

        {subtitle:"Pehchan Card (Insured Person)", risk:"Medium"}

    ],

    "Professional Tax": [

       {subtitle:"Professional Tax Returns", risk:"High"},

        {subtitle:"PT Challan", risk:"High"}

    ],

    "The Payment of Bonus Act, 1972": [

        {subtitle:"Monthly Bonus Paid or Not", risk:"High"},

        {subtitle:"Statutory Bonus Return", risk:"High"}

    ],

    "Shops And Commercial Establishments Act": [

        {subtitle:"Registration Of Establishment", risk:"High"},

        {subtitle:"Appointment Order In Form Q", risk:"High"}

    ],

    "The Maternity Benefit Act, 1961": [

         {subtitle:"Muster Roll Of Women Workers", risk:"High"}

 

    ],

    "The Employees Compensation Act, 1923": [

        {subtitle:"Employees Compensation Policy", risk:"High"}

 

    ],

    "The Equal Remuneration Act, 1976": [

        {subtitle:"Equal Remuneration And Register To Be Maintained", risk:"High"}

 

    ],

    "The Factories Act, 1948": [

        {subtitle:"Register of Overtime and Payment", risk:"High"},

        {subtitle:"Register Of Leave And Leave Book", risk:"High"},

        {subtitle:"Register Of Adult Workers Cum Muster Roll", risk:"High"},

        {subtitle:"Combined register for Muster Roll and Wages", risk:"High"}

    ],

    "The Labour Welfare Fund Act, 1965": [

        {subtitle:"Register Of Unclaimed Wages And Payment To The Board", risk:"Low"},

        {subtitle:"LWF Challan", risk:"High"}

    ],

 

 

};
 
const MonthlyData = () => {
    const location = useLocation(); 
    const { filter, month, year } = location.state || {};
     const [selectedDate, setSelectedDate] = useState('');
    const [monthlyData, setMonthlyData] = useState({});
    const [uploadStatuses, setUploadStatuses] = useState({});
    const [uploadSuccess, setUploadSuccess] = useState({});
    const [isSaved, setIsSaved] = useState(false);
    const [savedStatus, setSavedStatus] = useState({});
    const [selectedMonth, setSelectedMonth] = useState(month || '');
    const [saveEnabled, setSaveEnabled] = useState({});
    const [selectedYear, setSelectedYear] = useState(year|| null);
    const [showModal, setShowModal] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [snackbarErrorMessage, setSnackbarErrorMessage] = useState(''); // State to control the modal visibility
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState(filter || 'all')
   // Access the state passed via navigation

  const[employee,setEmployee] = useState('');



    const handleShow = () => setShowModal(true);  // Show modal
    const handleClose = () => setShowModal(false);
 
    const today = new Date();
    const handleMonthChange = (event) => {
        
        setSelectedMonth(event.target.value);

    };
 const handleNumOfWorkersChange = (event) => {
    if (event.target.value >= 0) {
        setEmployee(event.target.value);
      }
      else{
      setErrorSnackbarOpen(true);
      setSnackbarErrorMessage('Please enter valid number')
      }
    
   
 }
    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };
    const handleSnackbarClose = () => {
        setShowSnackbar(false);
      };
   
      const handleFilterChange = (event) => {
        setSelectedFilter(event.target.value);
        setShowSnackbar(false); // Close Snackbar once a filter is selected
      };
    const vendor = JSON.parse(sessionStorage.getItem('user'));
    const id = vendor?.id;


    
    useEffect(() => {
 
        if (id && selectedMonth !== null && selectedYear !== null) {
            fetchData(id, selectedMonth, selectedYear);
        }
    }, [id, selectedMonth, selectedYear,selectedFilter]);
 
  
    
    const fetchData = async (vendorId, month, year) => {
      
       
        try {
            const response = await api.vendor.getMonthlyData(vendorId, month, year);
            if (response.status === 200) {
                const data = response.data.data || {};
                let complianceDate = '';
                let noOfEmployees = '';
 
                const initializedData = {};
                const newSavedStatus = {};
                const newUploadStatuses = {};
                Object.keys(data).forEach((key) => {
                    const entry = data[key];
                    initializedData[key] = {
                        fileUrl: entry.fileUrl || '',
                        validation: entry.validation !== null ? entry.validation : 0,
                        remark: entry.remark || '',
                        vendorRemark: entry.vendorRemark || '',
                        file: entry.fileUrl ? entry.fileUrl.split('/').pop() : '',
                        na: entry.na || false
 
                    };
                    if (entry.fileUrl || entry.na === true) {
                        newSavedStatus[key] = true; 
                    } else {
                        newSavedStatus[key] = false; 
                    }
                   
                
                    if (data.complianceFiledDate) {
                        complianceDate = data.complianceFiledDate;
                    }
                    if (data.noOfEmployees) {
                        noOfEmployees = data.noOfEmployees;
                    }
                });
 
                if (complianceDate) {
                    setSelectedDate(complianceDate.slice(0, 10));
                } else {
                    setSelectedDate('');
                }
                if (noOfEmployees) {
                    setEmployee(noOfEmployees);
                } else {
                    setEmployee('');
                }
 
                setMonthlyData(initializedData);
                setSavedStatus(newSavedStatus);
                setUploadStatuses(newUploadStatuses);
                
            }
            else if (response.status === 404) {
                setErrorSnackbarOpen(true);
                setSnackbarErrorMessage(response.data.message);
                setSelectedYear(null);
                setSelectedMonth('');
                setMonthlyData({});
                 setSelectedDate('');
                 setEmployee('');
 
            }
            else if (response.status === 400) {
                setErrorSnackbarOpen(true);
                setSnackbarErrorMessage(response.data.message);
                setSelectedYear(null);
                setSelectedMonth('');
                setMonthlyData({});
                 setSelectedDate('');
                 setEmployee('');
            }
            else if (response.status === 500) {
                setErrorSnackbarOpen(true);
                setSnackbarErrorMessage(response.data.message);
                setSelectedYear(null);
                setSelectedMonth('');
                setMonthlyData({});
                 setSelectedDate('');
                 setEmployee('');
            }
            else {
                setErrorSnackbarOpen(true);
                setSnackbarErrorMessage("Some error occured, please try again later");
                setSelectedYear(null);
                setSelectedMonth('');
                setMonthlyData({});
                 setSelectedDate('');
                 setEmployee('');
            }
 
        } catch (error) {
            console.error('Error fetching monthly data', error);
        }
    };
 
   
 
    const handleDateChange = (date) => {
      
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Add 1 to get the correct month
        const day = String(date.getDate()).padStart(2, '0'); // Ensure it's two digits

        const formattedDate = `${year}-${month}-${day}`;
        setSelectedDate(formattedDate);
    };
 
    const handleNaChange = (subTitle) => {
        const key = titleToKeyMap[subTitle];
        const updatedData = { ...monthlyData };
       
        updatedData[key] = {
            ...updatedData[key],
            na: !updatedData[key]?.na,  // Toggle the 'NA' status
            vendorRemark: '',   
            file: '',  
         
        };
    
        setMonthlyData(updatedData);
        setSaveEnabled((prev) => ({
            ...prev,
            [titleToKeyMap[subTitle]]: true,  // Enable the save button
        }));
        setSavedStatus((prev) => ({
            ...prev,
            [titleToKeyMap[subTitle]]: false,  // Mark as unsaved
        }));
        setUploadStatuses((prevStatus) => ({
            ...prevStatus,
           [subTitle]: 'Upload',
        }));
    
       
    };
    
    const fileInputRefs = useRef(
        titles.map((title) =>
          preferredSubTitles[title].map(() => React.createRef()) // Create a ref for each subtitle within a title
        )
      );
      
    

    const handleFileChange = (subTitle) => (event) => {
        console.log(subTitle)
        const files = event.target.files;
        const file = files[0];
        const key = titleToKeyMap[subTitle];
        console.log(key,"names")
        const updatedData = { ...monthlyData };
 
        if (file) {
            updatedData[key] = {
                ...updatedData[key],
                file: file.name,
                selectedFile: file,
                vendorRemark: '',
            };
            setUploadStatuses((prevStatus) => ({
                ...prevStatus,
                [subTitle]: 'Upload',
            }));
            setSavedStatus(prev => ({
                ...prev,
                [titleToKeyMap[subTitle]]: false,  // Mark as saved
            }));

            setSaveEnabled(prev => ({
                ...prev,
                [titleToKeyMap[subTitle]]: false,  // Disable save after saving
            }));
         
        } else {
            updatedData[key] = {
                ...updatedData[key],
                file: '',
                selectedFile: '',
            };
           
        }
        setMonthlyData(updatedData);
    };
 
 
 
    const handleFileUpload = async (subTitle, title) => {
        const key = titleToKeyMap[subTitle];
        const selectedFile = monthlyData[key]?.selectedFile;
    
        // Do not upload the file until 'Save' is clicked.
        if (!selectedFile) {
            setErrorSnackbarOpen(true);
            setSnackbarErrorMessage("Please upload a file before saving.");
            return;
        }
        setUploadStatuses((prevStatus) => ({
            ...prevStatus,
           [subTitle]: 'Uploading...',
        }));
    
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('vendorId', id);
        formData.append('fieldName', key);
    
        try {
            // Do not persist immediately, just update the UI with uploaded info
            const response = await axios.put('https://www.grcpulse.in/api/monthlydata/uploadfile', formData);
            if (response.status === 200) {
                const updatedData = { ...monthlyData };
                updatedData[key] = {
                    ...updatedData[key],
                    validation: 0,
                    fileUrl: response.data.data,  // Store the URL temporarily in state
                    remark: '',
                    vendorRemark: '',
                };
    
                setMonthlyData(updatedData);
                setUploadSuccess((prev) => ({
                    ...prev,
                   [subTitle]: true,
                }));
    
                // Update upload status to "Uploaded"
                setUploadStatuses((prevStatus) => ({
                    ...prevStatus,
                   [subTitle]: 'Uploaded',
                }));
    
                setSavedStatus(prev => ({
                    ...prev,
                    [titleToKeyMap[subTitle]]: false,  // Mark as saved
                }));
    
                setSaveEnabled(prev => ({
                    ...prev,
                    [titleToKeyMap[subTitle]]: true,  // Disable save after saving
                }));
    
                setSnackbarOpen(true);
                setSnackbarMessage("File uploaded successfully.");
            } 
            else if(response.status === 413){
                setErrorSnackbarOpen(true);
                setSnackbarErrorMessage("File size should be below 5MB");
                setUploadSuccess((prev) => ({
                    ...prev,
                    [subTitle]: false,
                }));
    
                // Update upload status to "Uploaded"
                setUploadStatuses((prevStatus) => ({
                    ...prevStatus,
                    [subTitle]: 'Upload',
                }));
                setSavedStatus(prev => ({
                    ...prev,
                    [titleToKeyMap[subTitle]]: false,  // Mark as saved
                }));
    
                setSaveEnabled(prev => ({
                    ...prev,
                    [titleToKeyMap[subTitle]]: false,  // Disable save after saving
                }));
    
              }
              else {
                setErrorSnackbarOpen(true);
                setSnackbarErrorMessage("Error occurred while uploading, try again.");
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };
    
 
  
 
    const handleRemarkChange = (subTitle, newRemark) => {
        setMonthlyData(prevData => {
            const entry = prevData[titleToKeyMap[subTitle]] || {};
            return {
                ...prevData,
                [titleToKeyMap[subTitle]]: {
                    ...entry,
                    vendorRemark: newRemark,
                }
            };
        });
    };




    const handleSave = async (subTitle) => {
        if (!selectedDate) {
            setErrorSnackbarOpen(true);
            setSnackbarErrorMessage("Please select a compliance filing date.");
            return;
        }
        if (!employee) {
            setErrorSnackbarOpen(true);
            setSnackbarErrorMessage("Please enter the number of employees.");
            return;
        }
    
        const key = titleToKeyMap[subTitle];
        const updatedEntry = monthlyData[key] || {};
    
        if (!updatedEntry.selectedFile && !updatedEntry.na) {
            setErrorSnackbarOpen(true);
            setSnackbarErrorMessage("Please upload a file or mark as 'Not Applicable'.");
            return;
        }
    
        const fileUrl = updatedEntry.na ? "" : updatedEntry.fileUrl; // Only set fileUrl when isSaved is true

        const Monthlydata = {
            vendorId: id,
            month: selectedMonth,
            year: selectedYear,
             complianceFiledDate: selectedDate || "",
            noOfEmployees: employee || '',
            [key]: {
                fileUrl: fileUrl,
                validation: 1,  // Mark as validated
                remark: updatedEntry.remark,
                vendorRemark: updatedEntry.vendorRemark,
                na: updatedEntry.na,
            }
        };
    
        try {
            const response = await api.vendor.updateMonthlyData(Monthlydata);
            if (response.status === 200) {
                setMonthlyData(prevData => ({
                    ...prevData,
                    [key]: {
                        ...prevData[key],
                        fileUrl: fileUrl,
                        validation: 1,
                        remark: updatedEntry.remark,
                        vendorRemark: updatedEntry.vendorRemark,
                        background: COLORS.bggray,
                        na: updatedEntry.na,
                    }
                }));
    
                setIsSaved(true);
                setSnackbarOpen(true);
                setSnackbarMessage(response.data.message);
    
                setSavedStatus(prev => ({
                    ...prev,
                    [titleToKeyMap[subTitle]]: true,  // Mark as saved
                }));
    
                setSaveEnabled(prev => ({
                    ...prev,
                    [titleToKeyMap[subTitle]]: false,  // Disable save after saving
                }));
                
                api.vendor.updateMonthlyData(Monthlydata);
            } 
            else if(response.status === 400){
                setErrorSnackbarOpen(true);
                setSnackbarErrorMessage(response.data.message);
            }
            else if(response.status === 404){
                setErrorSnackbarOpen(true);
                setSnackbarErrorMessage(response.data.message);
            }
            else if(response.status === 500){
                setErrorSnackbarOpen(true);
                setSnackbarErrorMessage(response.data.message);
                            }else {
                setErrorSnackbarOpen(true);
                setSnackbarErrorMessage("Some error occurred. Please try again.");
            }
        } catch (error) {
            console.error('Error saving monthly data:', error);
        }
    };
    
 
    const renderRows = () => {
        return titles.map((title, titleIndex) => (
          <React.Fragment key={titleIndex}>
            <tr className="bg-light">
              <td colSpan={6}><strong className='text-dark '>{title}</strong></td>
            </tr>
   
            {preferredSubTitles[title]
              .map((item, subTitleIndex) => {
                const { subtitle, risk } = item;
                const titleIndex = titles.indexOf(title);
                const fileIndex = titleIndex * preferredSubTitles[title].length + subTitleIndex;
                const dataEntry = monthlyData[titleToKeyMap[subtitle]] || {};
                const { validation = 0, remark = '', vendorRemark = '', fileUrl = '', na = false, complianceFiledDate = '' } = dataEntry;
                const fileInputRef = fileInputRefs.current[titleIndex][subTitleIndex];
                return {
                    subtitle,
                    risk,  // Add risk here to return it as part of the object
                    validation,
                    subTitleIndex,
                    dataEntry,
                    fileInputRef
                };
              })
              .filter(({ validation }) => {
                // Filter based on selected filter option
                if (selectedFilter === 'all') return true;
                if (selectedFilter === 'rejected' && validation === 3) return true;
                if (selectedFilter === 'accepted' && validation === 2) return true;
                if (selectedFilter === 'underReview' && validation === 1) return true;
                if (selectedFilter === 'notUploaded' && validation === 0) return true;
                return false;
              })
              .map(({ subtitle, validation, subTitleIndex, dataEntry ,risk,fileInputRef}) => {
                const titleIndex = titles.indexOf(title);
                const fileIndex = titleIndex * preferredSubTitles[title].length + subTitleIndex;
                const { remark = '', vendorRemark = '', fileUrl = '', na = false, complianceFiledDate = '' ,file=""} = dataEntry;
   
                const statusText = validation === 0 ? 'Not Uploaded' :
                  validation === 1 ? 'Under Review' :
                    validation === 2 ? 'Accepted' : 'Rejected';
   
                const isSaveEnabled = dataEntry.selectedFile || na;
                let riskColor, riskIcon;
                switch (risk) {
                    case 'High':
                       
                        riskIcon = <IoIosWarning style={{color:'red',fontSize:"20px"}}/>; // Warning icon for High Risk
                        break;
                    case 'Medium':
                        
                        riskIcon =  <IoIosWarning style={{color:'yellow',fontSize:"20px"}}/>; // Caution icon for Medium Risk
                        break;
                    case 'Low':
                        riskIcon =  <IoIosWarning style={{color:'lightgray',fontSize:"20px"}}/> ; // Checkmark icon for Low Risk
                        break;
                    default:
                        riskIcon = ''; // No icon if risk is unspecified
                }
                return (
                  <tr key={subTitleIndex} style={{ backgroundColor: validation === 1 ? COLORS.bggray : validation === 2 ? 'lightgreen' : validation === 3 ? 'lightcoral' : 'transparent' }}>
                    <td>{subtitle}</td>
                    <td style={{  color:'black' ,textAlign:'center'}}>
                                {riskIcon} <br></br>{risk}
                            </td>
                    <td className="d-flex justify-content-between" style={{borderTop:"0px solid lightgray",borderBottom:'0.8px solid lightgray',borderLeft:'none', borderRight:"none" }}>
                      <div className="d-grid" >
                      <TextField
                                    variant="outlined"
                                    label="Uploaded File Name"
                                    value={monthlyData[titleToKeyMap[subtitle]]?.file || ''}
                                    fullWidth
                                    InputProps={{
                                        readOnly: true, // Make it read-only so the user can't edit the file name manually
                                    }}
                                />
                                <Button
                                    variant="outlined"
                                    className="mt-2"
                                    disabled={na || uploadStatuses[subtitle] === 'Uploading'}
                                    onClick={() => fileInputRef.current.click()} // Use the fileInputRef here
                                >
                                    <BiUpload style={{ fontSize: '18px' }} />
                                </Button>
                                <input
                                    type="file"
                                    style={{ display: 'none' }}
                                    ref={fileInputRef} // Reference to the correct file input
                                    onChange={handleFileChange(subtitle)} // Handle file selection
                                    disabled={na} // Disable if 'na' is true
                                />
                                <button className="mt-2 btn btn-sm btn-light" style={{ border: '1px solid gray' }} disabled={na || uploadStatuses[subtitle] === 'Uploading' || uploadStatuses[subtitle] === 'Uploaded'}
                                    onClick={() => handleFileUpload(subtitle, title)}>
                                    {uploadStatuses[subtitle] || 'Upload'}
                                </button>
   
                        {fileUrl && (
                          <div className="mt-2 d-flex justify-content-center">
                            <button className='btn btn-sm btn-primary' onClick={() => window.open(`https://docs.google.com/gview?url=${fileUrl}&embedded=true`, '_blank')}><i className="bi bi-eye"></i> Doc </button>
                            <button className="btn mx-1 btn-sm btn-primary" onClick={() => window.open(fileUrl)}><i className="bi bi-download"></i> Doc </button>
                          </div>
                        )}
                      </div>
   
                      <div className="m-2 d-flex flex-row p-3 gap-3">
                        <input
                          type="checkbox"
                          checked={na}
                          onChange={() => handleNaChange(subtitle)}
                          style={{cursor:'pointer'}}
                        />
                        <p  style={{marginTop:"30px"}}> Not Applicable</p>
                      </div>
                    </td>
                    <td>
                      <textarea
                        value={vendorRemark}
                        placeholder="Enter your remarks here"
                        onChange={(e) => handleRemarkChange(subtitle, e.target.value)}
                        rows="2"
                        style={{ width: '100%' }}
                      />
                    </td>
   
                    <td>
                      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                      <button
    className="btn btn-md btn-success"
    onClick={() => handleSave(subtitle)}
    disabled={!saveEnabled[titleToKeyMap[subtitle]]}  // Button is disabled until changes are made
>
    {savedStatus[titleToKeyMap[subtitle]] ? "Saved" : "Save"}  {/* Show "Save" or "Saved" based on savedStatus */}
</button>
                      </div>
                    </td>
                    <td>
                      <tr>{statusText} </tr>
                      {remark && (
                        <div style={{ overflow: 'auto', width: '200px',height:'100px' }}>Remark: {remark}</div>
                      )}
                    </td>
                  </tr>
                );
              })}
          </React.Fragment>
        ));
      };
   
 
 
 
 
 
 
 
 
 
    return (
 
 
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
 
                }}
                severity="success"
                open={snackbarOpen}
                autoHideDuration={4000}
                onClose={() => setSnackbarOpen(false)}
                sx={{
                    marginTop: '2.9%',
                    marginRight: '7%'
                }}
            >
                <SnackbarContent style={{ backgroundColor: "green" }} message={snackbarMessage} />
            </Snackbar>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                severity="error"
                open={errorSnackbarOpen}
                autoHideDuration={5000}
                sx={{
                    marginTop: '2.9%',
                    marginRight: '7%'
                }}
                onClose={() => { setErrorSnackbarOpen(false) }}
            >
                <SnackbarContent style={{ backgroundColor: "red" }} message={snackbarErrorMessage} />
            </Snackbar>
         
      <Snackbar
        open={showSnackbar}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'top' }}
        sx={{
           marginTop:'8%',
         
          }}
        message={
          <FormControl variant="filled" style={{ minWidth: 150,backgroundColor:'white' }}>
            <InputLabel>Filter by Status</InputLabel>
            <Select
              value={selectedFilter}
              onChange={handleFilterChange}
              label="Filter by Status"
              sx={{
               
                color: 'black',
                '& .MuiSelect-icon': { color: 'white' },
              }}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="rejected">Rejected</MenuItem>
              <MenuItem value="accepted">Accepted</MenuItem>
              <MenuItem value="underReview">Under Review</MenuItem>
              <MenuItem value="notUploaded">Not Uploaded</MenuItem>
            </Select>
          </FormControl>
        }
        action={
          <Button color="secondary" size="small" onClick={handleSnackbarClose} sx={{color:'red'}}>
            Close
          </Button>
        }
      />
            <div className='d-flex pb-3' style={{ justifyContent: 'space-between'}}>
                <div className="mt-3" style={{ letterSpacing: '1px' }}>
                    <div style={{ backgroundColor: COLORS.bggray, width: '15px', height: '15px', display: 'inline-block', marginRight: '4px', marginLeft: '8px', borderRadius: '50%' }}></div>
                    <span style={{ fontSize: '14px' }}>Under review</span>
                    <div className="vr  mx-3"></div>
                    <div style={{ backgroundColor: COLORS.success, width: '15px', height: '15px', display: 'inline-block', marginRight: '4px', borderRadius: '50%' }}></div>
                    <span style={{ fontSize: '14px' }}>Accepted</span>
                    <div className="vr mx-3"></div>
                    <div style={{ backgroundColor: COLORS.danger, width: '15px', height: '15px', display: 'inline-block', marginRight: '4px', borderRadius: '50%' }}></div>
                    <span style={{ fontSize: '14px' }}>Rejected</span>
                </div>
                </div>
               
               
                <div className="d-flex px-3 justify-content-between">
                <div className="mt-2 mb-2">
                    {selectedYear && selectedMonth && vendor && (
                <button onClick={() => setShowSnackbar(true)} className="btn  p-1 btn-sm btn-primary ">
        Filter <i className="bi bi-funnel"></i>
      </button>
                    )}
      </div>
      <div>
    
                    <select className='mx-1 p-1' style={{border:'1px solid lightgray',borderRadius:'4px'}} value={selectedMonth || ''} onChange={handleMonthChange}>
                        <option value="" disabled>Select Month</option>
                        {months.map((month) => (
                            <option key={month.value} value={month.value}>
                                {month.name}
                            </option>
                        ))}
                    </select>
                    <select className='mx-1 p-1' style={{border:'1px solid lightgray',borderRadius:'4px'}} value={selectedYear || ''} onChange={handleYearChange} >
                        <option value="" disabled>Select Year</option>
                        {years.map((year) => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                    </select>
              
               
           
         
               
 
                  
                    <input
                  type="number"
                  id="numOfEmployees"
                  placeholder="No Of Employees"
                className='mx-1 p-1'
                style={{border:'1px solid lightgray',borderRadius:'4px'}}
                 value={employee}
                  onChange = {handleNumOfWorkersChange}
                  min="0"
                
                />
               
               <div className="d-flex float-end" style={{ position: 'relative',padding:'2px',zIndex: 9999,width:'auto',margin:'5px',border:'1px solid lightgray',width:'200px'}}>
                  <DatePicker
                        selected={selectedDate}
                        onChange={handleDateChange} 
                      
                       
                        placeholderText="Compliance filling date"
                        dateFormat="dd/MM/yyyy"
                        filterDate={(date) => date.toDateString() === today.toDateString()}
                        disabled={selectedDate !== ''}
                    />
                    </div>
                </div>
            
           
      </div>
      
      <div className="mt-0" style={{ minHeight:'370px',maxHeight: "380px",overflowY: "auto" }}>
            <table  className="mt-0 pt-0 ">
          
                <thead className='position-sticky top-0' style={{ zIndex: 10 }}>
                    <tr className='text-white bg-dark'>
                        <th>Title</th>
                        <th>Risk</th>
                        <th>Upload File</th>
                        <th>Vendor Remark</th>
                        <th>Action</th>
                        <th>Status</th>
 
                    </tr>
                </thead>
                <tbody>
                
                {selectedDate && (
      
                    <button className="m-2 btn btn-sm btn-danger" onClick={handleShow}>
                        <span className="text-decoration-none text-white">View Report</span>
                    </button>
                  
           
                )}
                    {renderRows()}
                </tbody>
            </table>
            </div>
 
            <MonthlyReport showModal={showModal} onClose={handleClose} selectedMonth={selectedMonth}
                selectedYear={selectedYear} />
        </div>
 
    );
}
 
export default MonthlyData;