import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import "../css/components/complianceCalender.css"
import { CALENDAR_DATA_V2 } from "../Services/constants/calender";
import { MONTHS, STATES } from "../Services/constants/shared";

const ComplianceCalenderContainer = () => {
  const [tableData, setTableData] = useState([])
  const [selectedMonth, setSelectedMonth] = useState(MONTHS.january)
  const [selectedState, setSelectedState] = useState(STATES.delhi)


  const handleOnMonthChange = (e) => {
    const month = e.target.value
    setSelectedMonth(month)
  }

  const handleOnStateChange = (e) => {
    const state = e.target.value
    setSelectedState(state)
  }

  const parseDate = (dateStr) => {
    const months = { Jan: 0, Feb: 1, Mar: 2, Apr: 3, May: 4, Jun: 5, Jul: 6, Aug: 7, Sep: 8, Oct: 9, Nov: 10, Dec: 11 };
    const [day, monthStr] = dateStr.split("-");
    return { month: months[monthStr], day: parseInt(day, 10) };
  };

  useEffect(() => {
    const data = CALENDAR_DATA_V2.map(obj => ({
      ...obj,
      month: obj.month,
      act: obj.act,
      type: obj.type,
      compliance: obj.category,
      state: obj.state || obj.state,
      dueDate: obj.due_date
    }
    ))

    const filteredData = data.filter(val => (val.month === selectedMonth && val.state === selectedState))
    const allStatesFilteredData = data.filter(val => (val.month === selectedMonth && val.state === "All States"))
    setTableData([...filteredData, ...allStatesFilteredData].sort((a, b) => {
      const dateA = parseDate(a.dueDate);
      const dateB = parseDate(b.dueDate);
      return dateA.day - dateB.day;
    }))
  }, [selectedMonth, selectedState])

  return (
    <>
      <div className="compliance-calender-select-month-container">
        <div className="compliance-calender-select-month-container-header">
          <h4 style={{flexGrow:1,textAlign:"center",fontWeight:"bolder",fontSize:"24px"}}>Compliance Calendar</h4>
          <div className="compliance-calender-month-container-header-select-container">
            <div>
              <FormControl fullWidth>
                <InputLabel id="test-select-label">Month</InputLabel>
                <Select
                  labelId="test-select-label"
                  label="Month"
                  value={selectedMonth}
                  size="small"
                  onChange={handleOnMonthChange}
                >
                  {Object.values(MONTHS).map((val, index) => (
                    <MenuItem value={val} key={index}>{val}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl fullWidth>
                <InputLabel id="test-select-label">State</InputLabel>
                <Select
                  labelId="test-select-label"
                  label="State"
                  value={selectedState}
                  placeholder="Select State"
                  size="small"
                  onChange={handleOnStateChange}
                >
                  {Object.values(STATES).map((val, index) => (
                    <MenuItem value={val} key={index}>{val}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
      <div className="compliance-calender-table-container">
        <table className="compliance-calender-table">
          <thead className="compliance-calender-table-thead">
            <tr>
              <th className="compliance-calender-table-th">Due Date</th>
              <th className="compliance-calender-table-th">Act</th>
              <th className="compliance-calender-table-th">Remittance/Return</th>
              <th className="compliance-calender-table-th">Type of Return</th>
              <th className="compliance-calender-table-th">Form</th>
              <th className="compliance-calender-table-th">Remarks</th>
            </tr>
          </thead>
          <tbody className="compliance-calender-table-body">
            {tableData.map((data, index) => (
              <tr key={index}>
                <th className="compliance-calender-table-td">{data.dueDate}</th>
                <td className="compliance-calender-table-td">{data.act}</td>
                <td className="compliance-calender-table-td">{data.type}</td>
                <td className="compliance-calender-table-td">{data.compliance}</td>
                <td className="compliance-calender-table-td">{data.form}</td>
                <td className="compliance-calender-table-td">{data.remarks}</td>
              </tr>))
            }
          </tbody>
        </table>
      </div>
    </>
  )
}

export default ComplianceCalenderContainer
