import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Grid, TextField, Button, Divider,Dialog,
  DialogActions,
  DialogContent,
  DialogTitle } from '@mui/material';
import DateRange from '@mui/icons-material/DateRange';  
import vendorIcon from '../Images/vendor icon.png';
import adminIcon from '../Images/admin icon.png';
import assesorIcon from '../Images/assesor icon.png';
import clientIcon from '../Images/client icon.png';
import { FaMapMarkerAlt, FaEnvelope, FaPhone, FaIndustry, FaMap, FaBuilding, FaUserAlt} from 'react-icons/fa';
// import { MdLocationOn, MdBusiness } from 'react-icons/md';
import axios from 'axios';
import api from '../Services/api';
import {Col} from "react-bootstrap";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import MyLocationIcon from '@mui/icons-material/MyLocation';


const Profile = () => {

  const [originalData, setOriginalData] = useState({});
  const [errors, setErrors] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [contactData, setContactData] = useState({
          vendorId : " ",
          assessorId :" ",
          companyId : " ",
          // companyName: " ",
          // companyAddress : " ",
          // stateId: "",
          // placeName: "",
          // contactPersonName : " ",
          vendorCompanyName : " ",
          vendorCode: "",
          vendorCompanyAddress: "",
          natureOfWork: "",
          vendorName: " ",
          vendorPhoneNumber:" ",
          vendorEmail: " ",
          vendorPhoneNumber1:" ",
          vendorEmail1: " ",
          vendorPinCode:"",
          // noOfWorkers:"",
          vendorPlaceName: "",
          vendorStateId: "",
          vendorContractStartDate: " ",
          vendorContractEndDate: " ",

  });

 

  const [assessorData, setAssessorData] = useState({      
          assessorId: "",
          clientId: "",
          assessorCompanyName: "",
          assessorAddress: "",
          assessorPlaceName: "",
          assessorStateId: "",
          assessorPinCode: "",
          assessorName: "",
          assessorEmail: "",
          assessorPhoneNumber: "",
          assessorEmail1: "",
          assessorPhoneNumber1: ""
       
  });
  const [companyData, setCompanyData] = useState({
    principleEmployer: '',
    companyAddress: '',
   
    // companyEmailId: '',
    // companyPhoneNumber: '',
    clientName:'',
      clientContactPersonName: '',
    clientContactPersonPhoneNumber: '',
    clientContactPersonEmailId: '',
    clientContactPersonPhoneNumber1: '',
    clientContactPersonEmailId1: '',
    natureOfBusiness: '',
    stateId: '',
    placeName: '',
    pinCode: '',
   
  });


  const validateForm = () => {
    const newErrors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^[6789]\d{9}$/;
   
    
    
    if (!contactData.vendorName) newErrors.vendorName = 'Vendor Name is require';
     if (!contactData.vendorCode) newErrors.vendorCode = 'Vendor Code is required';
   
    if (!emailPattern.test(contactData.vendorEmail)) {
      newErrors.vendorEmail = contactData.vendorEmail ? 'Invalid email address(ex:text@email.com)' : 'Email address required';
    }
  
    if (!phonePattern.test(contactData.vendorPhoneNumber)) {
      newErrors.vendorPhoneNumber = contactData.vendorPhoneNumber ? 'Invalid phone number. Number should not start with zero' : 'Phone number required';
    }
 
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  useEffect(() =>{
    api.vendor.findvendor()
    .then(response =>{
      const temp = response.data.filtterData;
      console.log("temp",temp);
      setContactData(temp);
      setOriginalData(temp);
    })
    .catch(error => console.error('error fetching profile data:', error))
  }, []);

  

  useEffect(() => {
    const id = contactData.assessorId;
     console.log("assessorid:",id);
    api.assessor.findOne(id)
    .then(response => {
      const temp1 = response.data;
    console.log("temp1",temp1);
       setAssessorData(temp1)
    
    })
    .catch(error => console.error('error fetching company data', error));
  }, [contactData]);


  useEffect(() => {
    const requestData1 = contactData.companyId;
     console.log("id1:",requestData1);
    api.client.getClient(requestData1)
    .then(response => {
      // console.log("masterdata:", response);
      const temp2 = response.data;
    console.log("temp2",temp2);
       setCompanyData(temp2)
      //  setOriginalData(temp2)
    })
    .catch(error => console.error('error fetching company data', error));
  }, [contactData]);

  const handleEdit = () => {
    setOriginalData({...contactData});
    setOpenDialog(true); 
  };

  const handleCancelEdit = () => {
    setOpenDialog(false); 
    setContactData({...originalData});
   
  };

  
 
  const handleSave = async ()=>{
    let id = contactData.vendorId;
    if(validateForm()){
      try{
    const response = await api.vendor.updateOne(id, contactData)
   
      if(response.status === 200){
      window.alert("Edited Successfully");
      setOriginalData({ ...contactData });
      setOpenDialog(false); 
      }
      else{
        window.alert("error in editing the details");
      }
    
      } catch(error){
        console.error('Error updating',error)
      }
  }
  };
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>

 
        <Card className="d-md-flex mx-4 mt-3">
        <Col xs={12} md={8}>
      <h6 className="text-dark text-center fw-5 p-2 w-auto bg-light" style={{borderBottom:'1px solid lightgray',borderTop:'1px solid lightgray'}}>Client</h6>
  <div className='p-3 d-flex justify-content-between'>
    <div style={{ width: '50%' }}>
    <h6 className='text-secondary'>Client Details</h6>
      <Typography variant="body2" className="pt-1">
        <FaBuilding/> Principal Employer: {companyData?.data?.principleEmployer}
      </Typography>
      <Typography variant="body2" className="pt-1">
        <FaUserAlt /> Client Name: {companyData?.data?.clientName}
      </Typography>
      <Typography variant="body2" className="pt-1">
        <FaMapMarkerAlt /> Address: {companyData?.data?.companyAddress}, {companyData?.data?.placeName} {companyData?.data?.stateId} - {companyData?.data?.pinCode}
      </Typography>
    </div>
    <div className="vr text-secondary"></div>
    <div  className="px-4" style={{ width: '50%' }}>
      <h6 className='text-secondary'>Contact Person Details</h6>
      <Typography variant="body2" className="pt-1">
        <FaUserAlt /> Name: {companyData?.data?.clientContactPersonName}
      </Typography>
      <Typography variant="body2" className="pt-1">
        <FaEnvelope /> Email: {companyData?.data?.clientContactPersonEmailId} {companyData?.data?.clientContactPersonEmailId1 && `, ${companyData?.data?.clientContactPersonEmailId1}`}
      </Typography>
      <Typography variant="body2" className="pt-1">
        <FaPhone /> Phone: {companyData?.data?.clientContactPersonPhoneNumber} {companyData?.data?.clientContactPersonPhoneNumber1 && `, ${companyData?.data?.clientContactPersonPhoneNumber1}`}
      </Typography>
    </div>
  </div>

    {/* Assessor Section */}
    <div className="pt-3">
    <h6 className="text-dark text-center p-2 w-auto bg-light" style={{borderBottom:'1px solid lightgray',borderTop:'1px solid lightgray'}}>Assessor</h6>

  <div className='p-3 d-flex justify-content-between'>
    {/* Assessor Normal Details */}
    <div  style={{ width: '50%' }}>
    <h6 className='text-secondary'>Assessor Details</h6>
      <Typography variant="body2" className="pt-1">
        <FaBuilding /> Company Name: {assessorData?.data?.assessorCompanyName}
      </Typography>
      <Typography variant="body2" className="pt-1">
        <FaMapMarkerAlt /> Address: {assessorData?.data?.assessorAddress}, {assessorData?.data?.assessorPlaceName}, {assessorData?.data?.assessorStateId} - {assessorData?.data?.assessorPinCode}
      </Typography>
    </div>
    <div className="vr text-secondary"></div>
    {/* Assessor Contact Person Details */}
    <div className='px-4'  style={{ width: '50%' }}>
    <h6 className='text-secondary'>Contact Person Details</h6>
      <Typography variant="body2" className="pt-1">
        <FaUserAlt /> Name: {assessorData?.data?.assessorName}
      </Typography>
      <Typography variant="body2" className="pt-1">
        <FaEnvelope /> Email: {assessorData?.data?.assessorEmail} {assessorData?.data?.assessorEmail1 && `, ${assessorData?.data?.assessorEmail1}`}
      </Typography>
      <Typography variant="body2" className="pt-1">
        <FaPhone /> Phone: {assessorData?.data?.assessorPhoneNumber} {assessorData?.data?.assessorPhoneNumber1 && `, ${assessorData?.data?.assessorPhoneNumber1}`}
      </Typography>
    </div>
  </div>
</div>

  </Col>



  {/* Vendor Section */}
  <Col className="p-2" xs={12} md={4} style={{backgroundColor:'rgba(0,0,0,0.9)'}}>
    <button className='btn btn-sm float-end text-danger' onClick={handleEdit}><i class="bi bi-pen text-danger"></i> Edit</button>
    <CardContent>
      <div className='mt-4 d-flex'>
        {/* <img className='bg-light' src={vendorIcon} alt="vendor" height="50px" width="50px" /> */}
        <h6 className="p-3 text-white text-center" style={{borderRadius:'50px',backgroundColor:'#B22222'}}>
          {contactData.vendorCode}
        </h6>
      </div>

      {/* Vendor Details */}
      <Typography className="mt-3 text-white" variant="body2">
Company Name : {contactData.vendorCompanyName}
      </Typography>
      <Typography className="mt-2 text-white" variant="body2">
Address : {contactData.vendorCompanyAddress}, {contactData.vendorPlaceName} {contactData.vendorStateId} - {contactData.vendorPinCode}
      </Typography>
      <Typography className="mt-2 text-white" variant="body2">
 Nature of Work : {contactData.natureOfWork}
      </Typography>
      {/* <Typography className="mt-2 text-white" variant="body2">
No of Workers : {contactData.noOfWorkers}
      </Typography> */}
      <Typography className="mt-2 text-white" variant="body2">
Email : {contactData.vendorEmail} {contactData.vendorEmail1 && `, ${contactData.vendorEmail1}`}
      </Typography>
      <Typography className="mt-2 text-white" variant="body2">
        Phone : {contactData.vendorPhoneNumber} {contactData.vendorPhoneNumber1 && `, ${contactData.vendorPhoneNumber1}`}
      </Typography>
      <Typography className="mt-2 text-white" variant="body2">
         Contract Start Date : {contactData.vendorContractStartDate.slice(0, 10).split('-').reverse().join('-')}
      </Typography>
      <Typography className="mt-2 text-white" variant="body2">
       Contract End Date : {contactData.vendorContractEndDate.slice(0, 10).split('-').reverse().join('-')}
      </Typography>
    </CardContent>
  </Col>

        </Card>
      

        <Dialog open={openDialog} onClose={handleCancelEdit} maxWidth="md" fullWidth>
        <h6 style={{textAlign:'center',color:'white',backgroundColor:"#1C1C1C",padding:'24px',letterSpacing:'0.5px'}}>EDIT VENDOR PROFILE</h6>
        <DialogContent >
          <Grid container spacing={2}>
          
       
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Vendor Name"
                name="vendorName"
                value={contactData.vendorName}
                onChange={handleInputChange}
                margin="normal"
                error={!!errors.vendorName}
              helperText={errors.vendorName}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Vendor Code"
                name="vendorCode"
                value={contactData.vendorCode}
                onChange={handleInputChange}
                margin="normal"
                error={!!errors.vendorCode}
              helperText={errors.vendorCode}
              
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Email"
                name="vendorEmail"
                value={contactData.vendorEmail}
                onChange={handleInputChange}
                margin="normal"
                error={!!errors.vendorEmail}
              helperText={errors.vendorEmail}
              />
            </Grid>
           
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Alternative Email"
                name="vendorEmail1"
                value={contactData.vendorEmail1}
                onChange={handleInputChange}
                margin="normal"
              
              />
            </Grid>
           
          
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Phone"
                name="vendorPhoneNumber"
                value={contactData.vendorPhoneNumber}
                onChange={handleInputChange}
                margin="normal"
                error={!!errors.vendorPhoneNumber}
              helperText={errors.vendorPhoneNumber}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Alternative PhoneNumber"
                name="vendorPhoneNumber1"
                value={contactData.vendorPhoneNumber1}
                onChange={handleInputChange}
                margin="normal"
               
              />
              
            </Grid>
         
          </Grid>
        </DialogContent>
       
          <div className='mx-4 mb-3' style={{display:'flex',justifyContent:'space-between'}}>
          <button className="btn btn-md btn-danger" onClick={handleCancelEdit} >
            Cancel
          </button>
          <button className="btn btn-md btn-success" onClick={handleSave} disabled={!validateForm}>
            Save
          </button>
          </div>
      
      </Dialog>


    </>
  );
};

export default Profile;