import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import { useNavigate, Link } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import api from '../../../Services/api';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';
import MonthlyData from '../../../Component/MonthlyData';
import MonthlyReport from '../../../Component/MonthlyReport';

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend, ArcElement);

const COLORS = {
  success: '#0BDA51',
  overall: '#50c878',
  danger: '#B22222',
  notUploaded: 'white',
  warning: '#EFCC00'
};

const months = [
  { name: "JANUARY", value: "01" },
  { name: "FEBRUARY", value: "02" },
  { name: "MARCH", value: "03" },
  { name: "APRIL", value: "04" },
  { name: "MAY", value: "05" },
  { name: "JUNE", value: "06" },
  { name: "JULY", value: "07" },
  { name: "AUGUST", value: "08" },
  { name: "SEPTEMBER", value: "09" },
  { name: "OCTOBER", value: "10" },
  { name: "NOVEMBER", value: "11" },
  { name: "DECEMBER", value: "12" }
];
const currentYear = new Date().getFullYear();
const years = Array.from({ length: currentYear - 2020 + 1 }, (x, i) => 2020 + i);

const ReportTable = ({ filter,month,year, onPercentagesChange }) => {
  let data = sessionStorage.getItem('user');
  let vendorData = JSON.parse(data);
  let vendorId = vendorData.id;

  const currentDate = new Date();
 
  const navigate = useNavigate();
  const currentMonth = currentDate.getMonth() + 1;
  const currentDay = currentDate.getDate();

  const [vendorChartData1, setVendorChartData1] = useState({});
  const [isDataEmpty, setIsDataEmpty] = useState(false);
  const [report, setReport] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState(null);
  const [monthlyData, setMonthlyData] = useState({});  // Data for current month
  const [selectedMonthData, setSelectedMonthData] = useState({});  // Data for selected month (for report)
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [snackbarErrorMessage, setSnackbarErrorMessage] = useState('');
  const[selectedFilter,setSelectedFilter] = useState('all')
  useEffect(() => {
    // Make sure you pass the correct values when the data is available
    const { rejectedPercent, notUploadedPercent } = getRejectedAndNotUploadedPercentages(monthlyData);
    onPercentagesChange(rejectedPercent, notUploadedPercent);
    console.log("percentage",rejectedPercent, notUploadedPercent);
  }, [monthlyData]);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  // Fetch current month's data when the component mounts
  useEffect(() => {
   
      setErrorSnackbarOpen(true);
      setSnackbarErrorMessage('Compliance start Date is 18th');

      fetchCurrentMonthData(vendorId, currentMonth < 10 ? `0${currentMonth}` : currentMonth, currentYear);
    
  }, [vendorId, currentMonth, currentYear]);

  // Fetch selected month data for the report when selectedMonth or selectedYear changes
  useEffect(() => {
    if (selectedMonth && selectedYear) {
      fetchSelectedMonthData(vendorId, selectedMonth, selectedYear);
    }
  }, [selectedMonth, selectedYear]);
  const handleMonthChange = (event) => setSelectedMonth(event.target.value);
  const handleYearChange = (event) => setSelectedYear(event.target.value);
  // Fetch data for current month (status)
  const fetchCurrentMonthData = async (vendorId, month, year) => {
    try {
      const response = await api.vendor.getMonthlyData(vendorId, month, year);
      if (response.status === 200) {
        const chartData = response.data;
        setVendorChartData1((prevData) => ({
          ...prevData,
          [vendorId]: chartData,
        }));
        setMonthlyData(chartData);
        const { rejectedPercent, acceptedPercentage, underReviewPercent } = getRejectedAndNotUploadedPercentages(chartData);
        if (rejectedPercent === 0 && acceptedPercentage === 0 && underReviewPercent === 0) {
          setIsDataEmpty(true);
        } else {
          setIsDataEmpty(false);
        }
      
      } 
      else if (response.status === 404) {
        setErrorSnackbarOpen(true);
        setSnackbarErrorMessage(response.data.message);
        setMonthlyData(null);
     

    }
    else if (response.status === 400) {
        setErrorSnackbarOpen(true);
        setSnackbarErrorMessage(response.data.message);
      
    }
    else if (response.status === 500) {
        setErrorSnackbarOpen(true);
        setSnackbarErrorMessage(response.data.message);
      
    }
      else {
        setErrorSnackbarOpen(true);
        setSnackbarErrorMessage(response.data.message);
      }
    } catch (error) {
      console.error('Error fetching current month data', error);
    }
  };

  // Fetch data for selected month (for report)
  const fetchSelectedMonthData = async (vendorId, month, year) => {
    try {
      const response = await api.vendor.getMonthlyData(vendorId, month, year);
      if (response.status === 200) {
        const chartData = response.data;
        setSelectedMonthData(chartData);
        setReport(true);
      } else {
        setErrorSnackbarOpen(true);
        setSnackbarErrorMessage(response.data.message);
        setReport(false);
      }
    } catch (error) {
      console.error('Error fetching selected month data', error);
    }
  };
  const handleFilterChange = (filter) => {
    const percentages = getRejectedAndNotUploadedPercentages(monthlyData);
  if (
    (filter === 'rejected' && percentages.rejectedPercent === 0) ||
    (filter === 'accepted' && percentages.acceptedPercentage === 0) ||
    (filter === 'underReview' && percentages.underReviewPercent === 0)
  ) {
  
    navigate('/monthlydata', {
      state: {
        filter: 'all',
        month: currentMonth < 10 ? `0${currentMonth}` : currentMonth,
        year: currentYear,
      },
  }) 
}
else{
    navigate('/monthlydata', {
      state: {
        filter: filter,
        month: currentMonth < 10 ? `0${currentMonth}` : currentMonth,
        year: currentYear,
      },
    });
  }
  };

  const getRejectedAndNotUploadedPercentages = (monthlyData) => {
    if (monthlyData && monthlyData.data) {
      const rejected = monthlyData.data.RejectedPercent || 0;  // Safe access to rejected percentage
      const underReview = monthlyData.data.UnderReviewPercent || 0;
      const accepted = monthlyData.data.AcceptedPercent || 0;  // Safe access to under review percentage
      return { rejectedPercent: rejected, underReviewPercent: underReview, acceptedPercentage: accepted };
  } else {
      // Handle the case where data might not be available
      console.error('Data is missing or incorrect structure');
      return { rejectedPercent: 0, underReviewPercent: 0 };
  }
  };


  // const StackedProgressBar = ({ segments, gap }) => {
  //  If all percentages are zero, return a message instead of a progress bar
  //   const totalPercentage = segments.reduce((total, segment) => total + segment.percentage, 0);
  //   console.log(totalPercentage,"totalPercentage")
  //   if (totalPercentage === 0) {
  //     return <div className="text-light">Not yet started</div>; // Show message when no data is available
  //   }
  
  //   return (
  //     <div style={{ width: '100%', height: '30px', display: 'flex', borderRadius: '0px', overflow: 'hidden', position: 'relative' }}>
  //       {segments.map((segment, index) => {
  //         const { percentage, color, label } = segment;
  //         const marginRight = `${gap || 0}px`;
  
  //         return (
  //           <div key={index} style={{ position: 'relative', width: `${percentage}%` ,marginRight}}>
  //             <div style={{ width: '100%', height: '100%', backgroundColor: color }} />
  //             <div style={{ position: 'absolute', top: '0px',paddingTop:'5px',paddingLeft:'1px',fontSize: '12px', color: 'black' }}>
  //               {percentage ? `${percentage}%` : ''}
  //             </div>
  //           </div>
  //         );
  //       })}
  //     </div>
  //   );
  // };
  

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        severity="success"
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSnackbarOpen(false)}
        sx={{ marginTop: '2.9%', marginRight: '7%' }}
      >
        <SnackbarContent style={{ backgroundColor: "green" }} message={snackbarMessage} />
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        severity="error"
        open={errorSnackbarOpen}
        autoHideDuration={5000}
        sx={{ marginTop: '2.9%', marginRight: '7%' }}
        onClose={() => setErrorSnackbarOpen(false)}
      >
        <SnackbarContent style={{ backgroundColor: "red" }} message={snackbarErrorMessage} />
      </Snackbar>

      <div className="d-grid" >
        <div className=" bg-dark" style={{ border: '1px solid lightgray'}}>
      
          <h6 className="text-center bg-dark text-light p-2 m-0">Current-Month Status</h6>
         
          <section >
          {monthlyData ? (
  <table className="table table-hover table-light m-0 pt-0" style={{ borderCollapse: 'collapse', cursor: 'pointer' }}>
    <thead>
      <tr>
        <th className="text-dark text-center">Actions</th>
        <th className="text-dark text-center">Percentage</th>
      </tr>
    </thead>
    <tbody>
      <tr onClick={() => handleFilterChange('rejected')}>
        <td className="text-center" style={{ backgroundColor: 'lightgray' }}>Not Complied</td>
        <td className="text-danger text-center" style={{ fontWeight: 'bold', backgroundColor: 'lightgray' }}>
          {getRejectedAndNotUploadedPercentages(monthlyData).rejectedPercent}%
        </td>
      </tr>
      <tr onClick={() => handleFilterChange('accepted')}>
        <td className="text-center">Complied</td>
        <td className="text-success text-center">
          {getRejectedAndNotUploadedPercentages(monthlyData).acceptedPercentage}%
        </td>
      </tr>
      <tr onClick={() => handleFilterChange('underReview')}>
        <td className="text-center">Under Review</td>
        <td className="text-secondary text-center">
          {getRejectedAndNotUploadedPercentages(monthlyData).underReviewPercent}%
        </td>
      </tr>
    </tbody>
  </table>
) : (
  <div className="text-center text-light" style={{borderTop:'1px solid lightgray'}}>
    <p className="p-4">Monthly data not created or unavailable.</p>
  </div>
)}

                
                {isDataEmpty && (
                           
                  <p onClick={()=>handleFilterChange('all')} className="p-1 text-center text-warning" style={{fontSize:"12px",cursor:"pointer"}}>Start uploading data to see the progress <i class="bi bi-arrow-right text-danger bg-light"></i></p>
          
               )} 
            
                
            
         
          </section>

          <section className="d-flex justify-content-center align-items-center w-100">
  {/* <div className="d-flex flex-wrap justify-content-center align-items-center w-100">
    {Object.keys(vendorChartData1).map((vendorId) => {
      // const vendorData = vendorChartData1[vendorId];

      const acceptedPercentage = monthlyData.data.AcceptedPercent || 0;
      const rejectedPercentage = monthlyData.data.RejectedPercent || 0;
      const underReviewPercentage = monthlyData.data.UnderReviewPercent || 0;

      const segments = [];

      // Push only non-zero percentages to the segments array
      if (acceptedPercentage > 0) {
        segments.push({ percentage: acceptedPercentage, color:'#50c878', label: 'Accepted' });
      }

      if (rejectedPercentage > 0) {
        segments.push({ percentage: rejectedPercentage, color: COLORS.danger, label: 'Rejected' });
      }

      if (underReviewPercentage > 0) {
        segments.push({ percentage: underReviewPercentage, color: COLORS.warning, label: 'Under Review' });
      }

      // If no valid segments, show "Not yet started" message
      if (segments.length === 0) {
        return <div>
          <p className='text-light'>Not Yet Started</p></div>;
      }

      return (
        <div key={vendorId} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', padding: '10px' }}>
          <StackedProgressBar segments={segments} gap={1} />
        </div>
      );
    })}
  </div> */}
</section>
        </div>

        <section className="mt-2" style={{ border: '1px solid lightgray',backgroundColor:'rgba(0,0,0,0.85)'  }}>
        
          <h6 className="text-light text-center p-2" style={{ backgroundColor: 'rgba(0,0,0,0.1)' ,borderBottom:'0.2px solid gray'}}>Audit Report</h6>
       
          <div className="mx-4 mt-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <select value={selectedMonth || ''} onChange={handleMonthChange} style={{ marginRight: '10px' }}>
              <option value="" disabled>Select Month</option>
              {months.map((month) => (
                <option key={month.value} value={month.value}>{month.name}</option>
              ))}
            </select>
            <select value={selectedYear || ''} onChange={handleYearChange}>
              <option value="" disabled>Select Year</option>
              {years.map((year) => (
                <option key={year} value={year}>{year}</option>
              ))}
            </select>
          </div>
          <div className="mb-4 mt-3 d-flex justify-content-center">
            <button className="btn btn-sm btn-danger mx-2" onClick={handleShow} disabled={!selectedMonth || !selectedYear || !report}>
              View Report
            </button>
          </div>
          <MonthlyReport showModal={showModal} onClose={handleClose} selectedMonth={selectedMonth} selectedYear={selectedYear} />
     
        </section>
      </div>
    </div>
  );
};

export default ReportTable;

