import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import AppBarSelector from './Pages/AppBarSelector';
import { VendorList } from './Pages/VendorList';
import VendorProfile from './Pages/VendorProfile';
import ChangePass from './Pages/ChangePass';
import MasterData from './Component/MasterData';
import Forgot from './Pages/Forgot';
import './App.css';
import Login from './Pages/Login';
import MasterdataTableAssessor from '../src/Component/MasterdataTableAssessor';
import AdminClient from './Pages/AdminClient';
import AsessorVendor from './Pages/AssessorVendor';
import Company from './Pages/Company';
import AdminAssessor from './Pages/AdminAssessor';
import Clientdashboard from './Pages/Dashboard/Client';
import Vendordashboard from './Pages/Dashboard/Vendor';
import Admindashboard from './Pages/Dashboard/Admin';
import AssesorDashboard from './Pages/Dashboard/Assessor';
import { AssessorProfile } from './Pages/AssessorProfile';
import MasterdataTableAdmin from './Component/MasterdataTableAdmin';
import { AssessorList } from './Pages/AssessorList';
import { ClientList } from './Pages/ClientList';
import { ClientProfile } from './Pages/ClientProfile';
import MasterdataTableClient from './Component/MasterdataTableClient';
import MonthlyData from './Component/MonthlyData';
import MonthlyDataTableAssessor from './Component/MonthlyDataTableAssessor';
import MonthlyDataTableClient from './Component/MonthlyDataTableClient';
import MonthlyReport from './Component/MonthlyReport';
import LandingPage from './Pages/LandingPage';
import AboutUs from './Pages/AboutUs';
import PrivacyAndPolicy from './Pages/PrivacyAndPolicy';
import ContactUs from './Pages/ContactUs';
import EstablishmentComplianceService from './Pages/EstablishmentComplianceService';
import VendorCompliance from './Pages/VendorCompliance';
import FactoryComplianceService from './Pages/FactoryCompliance';
import HrServiceProviderAuditService from './Pages/HrServiceProviderAudit';
import PayrollManagement from './Pages/PayrollManagement';
import NewLabourCodes from './Pages/NewLabourCodes';
import ProductivityAudit from './Pages/ProductivityAudit';
 import Registration_LicenseServices from './Pages/Registration_LicenseServices';
import Demo from './Pages/Demo';
import FAQs from './Pages/FAQs';
import PrivateRoute from './Services/PrivateRoute';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Terms from './Pages/TermsAndConditions';
import ComplianceCalendar from './Pages/ComplianceCalender';

const theme = createTheme({
  typography: {
    fontFamily: "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
  },
});

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const location = useLocation();
  const hideAppBarRoutes = ["/", "/login", "/about-us", "/services", "/FAQs","/contact-us", "/services/establishment-compliance","services/factory-compliance",
    "/services/hr-service-provider-audit",
    "/services/payroll-management",
    "/services/new-labour-laws",
    "/services/productivity-audit",
    "/services/vendor-compliance",
    "/services/factory-compliance",
    "/request-a-demo",
    "/terms-and-condition",
    "/Registation_License_Services",
    "/PrivacyPolicy"
    // "/complience-calender",
  ];

  const shouldHideAppBar = hideAppBarRoutes.includes(location.pathname);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Reset scroll position to the top
  }, [pathname]);

  const MainRoutes = () => (
    <Routes>
      <Route path="/signin" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
      
      {/* Other routes for the app */}
      <Route path="/createCompany" element={ <PrivateRoute element={<AdminClient /> } isLoggedIn={isLoggedIn} /> } />
      <Route path="/createVendor" element={ <PrivateRoute element={<AsessorVendor />} isLoggedIn={isLoggedIn} /> } />
      <Route path="/createAssessor" element={ <PrivateRoute element={<AdminAssessor />} isLoggedIn={isLoggedIn} /> } />
      <Route path="/profile/vendor" element={ <PrivateRoute element={ <VendorProfile />} isLoggedIn={isLoggedIn} /> } />
      <Route path="/profile/assessor" element={ <PrivateRoute element={ <AssessorProfile />} isLoggedIn={isLoggedIn } /> } />
      <Route path="/company" element={ <PrivateRoute element={ <Company />} isLoggedIn={isLoggedIn} /> } />
      <Route path="/changePass" element={ <PrivateRoute element={ <ChangePass />} isLoggedIn={isLoggedIn } /> } />
      <Route path="/clientdashboard" element={ <PrivateRoute element={ <Clientdashboard />} isLoggedIn={isLoggedIn}/> } />
      <Route path="/vendordashboard" element={ <PrivateRoute element={ <Vendordashboard />} isLoggedIn={isLoggedIn} /> } />
      <Route path="/admindashboard" element={ <PrivateRoute element={ <Admindashboard />} isLoggedIn={isLoggedIn} /> } />
      <Route path="/assessordashboard" element={ <PrivateRoute element={ <AssesorDashboard />} isLoggedIn={isLoggedIn} /> } />
      <Route path="/vendorsMasterData" element={ <PrivateRoute element={ <MasterdataTableAssessor />} isLoggedIn={isLoggedIn} /> } />
      {/* <Route path="/vendorsMasterData" element={ <PrivateRoute element={ <MasterdataTableAdmin />} isLoggedIn={isLoggedIn} /> } /> */}
      <Route path="/vendors-masterData" element={ <PrivateRoute element={ <MasterdataTableClient />} isLoggedIn={isLoggedIn} /> } />
      <Route path="/forgotPassword" element={ <PrivateRoute element={ <Forgot />} isLoggedIn={isLoggedIn} /> } />
      <Route path="/masterdata" element={ <PrivateRoute element={ <MasterData />} isLoggedIn={isLoggedIn} /> } />
      <Route path="/vendorsList" element={ <PrivateRoute element={ <VendorList />} isLoggedIn={isLoggedIn} /> } />
      <Route path="/clientList" element={ <PrivateRoute element={ <ClientList />} isLoggedIn={isLoggedIn} /> } />
      <Route path="/client/profile" element={ <PrivateRoute element={ <ClientProfile />} isLoggedIn={isLoggedIn} /> } />
      <Route path="/assessorList" element={<PrivateRoute element={ <AssessorList />} isLoggedIn={ isLoggedIn } /> } />
      <Route path="/MonthlyData" element={ <PrivateRoute element={ <MonthlyData />} isLoggedIn={isLoggedIn} /> }/>
      <Route path="/vendorsMonthlyData" element={ <PrivateRoute element={ <MonthlyDataTableAssessor />} isLoggedIn={isLoggedIn} /> } />
      <Route path="/vendors-monthlyData" element={ <PrivateRoute element={ <MonthlyDataTableClient />} isLoggedIn={isLoggedIn} /> } />
      <Route path="/report" element={ <PrivateRoute element={ <MonthlyReport />} isLoggedIn={isLoggedIn }/> } />
      <Route path='/complience-calender' element={ <PrivateRoute element={ <ComplianceCalendar/> } isLoggedIn={isLoggedIn} /> } />
    </Routes>
  );

  return (
    <>
      {!shouldHideAppBar && (
        <AppBarSelector>
          <MainRoutes />
        </AppBarSelector>
      )}
     
      <Routes>
        <Route path="/" element={<LandingPage />} />
        {/* Redirect from LandingPage to Login */}
        {/* <Route path="/landing" element={<Navigate to="/login" />} /> */}
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/FAQs" element={<FAQs />} />
        <Route path="/services/establishment-compliance" element={<EstablishmentComplianceService />} />
        <Route path="/services/vendor-compliance" element={<VendorCompliance />} />
        <Route path="/services/factory-compliance" element={<FactoryComplianceService />} />
        <Route path="/services/hr-service-provider-audit" element={<HrServiceProviderAuditService />} />
        <Route path="/services/payroll-management" element={<PayrollManagement />} />
        <Route path="/services/new-labour-laws" element={<NewLabourCodes />} />
        <Route path="/services/productivity-audit" element={<ProductivityAudit />} />
        <Route path="/Registation_License_Services"element={<Registration_LicenseServices/>}/>
        <Route path="/request-a-demo" element={<Demo />} />
        <Route path="/terms-and-condition" element={<Terms />} />
        {/* <Route path="/complience-calender" element={ <ComplianceCalendar/> } /> */}
        <Route path='/PrivacyPolicy' element={<PrivacyAndPolicy/>}/>
      </Routes>
    </>
  );
};

export default App;
