import Navbar from "../layout/NavBar"
import Footer from "../layout/Footer"
import { Link } from "react-router-dom"
import serviceHeaderImageOne from "../Images/fs-three.png"
import serviceHeaderImageTwo from "../Images/fs-two.png"
import serviceHeaderImageThree from "../Images/fs-one.png"

const FactoryCompliance = () => {
  return (
    <>
      <Navbar />
      <div className="p-4 bg-primary-color h-[400px] bg-opacity-20 grid grid-cols-2">
        <div className="flex flex-col justify-center">
          <h1 className="text-2xl text-primary-color text-center font-bold">
            Factory Compliance
          </h1>
          <h1 className="leading-6 text-center">
          Ensure that your factory remains compliant with industrial laws, safety regulations, labor welfare requirements, statutory approvals, and environmental obligations essential for legally and efficiently operating your business with GRC@Pulse, your trusted partner in Compliance Assurance.     </h1>
          <div className="mt-lg-3 mt-sm-1 flex justify-center item-center"> 
            <Link to="/contact-us">
              <button className=" p-2 bg-primary-color text-white rounded-md shadow-lg hover:bg-danger transition duration-300">
                Contact Us
              </button>
            </Link>
          </div>   
        </div>
        <div className="mt-4 flex justify-center items-center">
          <img src={serviceHeaderImageOne} alt="" className="w-[350px] h-[350px]" />
        </div>
      </div>
      <div className="mt-4 px-[100px] grid grid-cols-2">
        <div>
          <img src={serviceHeaderImageTwo} alt="" className="w-[350px] h-[350px]" />
        </div>
        <div>
          <h1 className="font-bold text-primary-color text-xl">Benefits of Factory Compliance </h1>
          <div className="flex gap-2 mt-2 items-center">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Legal Protection:</h1>
              <h1 className="mt-1"> Avoids fines and ensures smooth audits.</h1>
            </div>
          </div>
          <div className="flex gap-2 mt-4 items-center">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold"> Workplace Safety: </h1>
              <h1 className="mt-1"> Reduces accidents and promotes employee well-being.</h1>
            </div>
          </div>
          <div className="flex gap-2 mt-4 items-center">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold"> Operational Efficiency: </h1>
              <h1 className="mt-1"> Streamlines processes and optimizes resources.</h1>
            </div>
          </div>
          <div className="flex gap-2 mt-4 items-center">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold"> Reputation Boost:  </h1>
              <h1 className="mt-1">Builds trust and attracts talent.</h1>
            </div>
          </div>
          <div className="flex gap-2 mt-4 items-center">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Sustainability: </h1>
              <h1 className="mt-1">Encourages eco-friendly practices and resource conservation.</h1>
            </div>
          </div>
          <div className="flex gap-2 mt-4 items-center">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold"> Customer Satisfaction: </h1>
              <h1 className="mt-1">Ensures product quality and market compliance.</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 px-4 mt-4">
        <div>
          <h1 className="font-bold text-primary-color text-xl">Key Areas of Factory Compliance</h1>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold"> Factory Registration and Licenses</h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold"> Health and Safety Compliance  </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold"> Environmental Compliance </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Worker Welfare Provisions  </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Working Hours and Leave Compliance </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Legal and Statutory Filings</h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Fire and Electrical Safety Compliance</h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">
                Contractor and Subcontractor Compliance
              </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">
                Hazardous Material Handling Compliance
              </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">
                Labour Welfare Fund (LWF)
              </h1>
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <img src={serviceHeaderImageThree} alt="" className="w-[450px] h-[450px]" />
        </div>
      </div>
      <Footer />
    </>)
}

export default FactoryCompliance