import React from 'react'
import Navbar from '../layout/NavBar'
import Footer from '../layout/Footer'
import { Link } from 'react-router-dom'
import registration_license from "../Images/L-R.png"

const Registration_LicenseServices = () => {
  return (
    <>
    <Navbar/>
      <section className='pt-5 d-flex flex-row gap-x-6 align-center justify-content-between'>
        <div className='my-[100px] w-50 '>
        <h1 className="text-2xl text-primary-color text-center font-bold ">Licenses And Registration Service</h1>
          <h1 className="leading-6 text-center pt-4">
          Ensure timely acquisition and renewal of licenses and registrations, keeping your business compliant with regulatory requirements and operationally seamless with GRC@Pulse, your trusted partner in Compliance Assurance.
          </h1>
          <div className="mt-lg-3 mt-sm-1 flex justify-center item-center"> 
            <Link to="/contact-us">
              <button className=" p-2 bg-primary-color text-white rounded-md shadow-lg hover:bg-danger transition duration-300">
                Contact Us
              </button>
            </Link>
          </div>
        </div>
        <div className='w-50 ms-5'>
          <img src={registration_license} alt="" className='h-[400px] w-[400px]'/>
        </div>
      </section>

      <section>
        <div className='mx-5 my-2 '>
          <h1 className='fs-1 text-danger text-center fw-bolder'>Registration Services</h1>
          <div className="mx-5 my-2">
          <div className="relative bg-red-500 text-white px-6 py-2 font-bold rounded-l-lg w-fit">
            <div className="absolute right-0 top-0 w-0 h-0 border-l-8 border-t-8 border-b-8 border-l-transparent border-t-white border-b-white"></div>
            Shop and Establishment Act Registration:
          </div>
          <p className="mt-2">Obtain registration under the Shop and Establishment Act, a mandatory requirement for all shops and establishments in India.</p>
        </div>

        <div className="mx-5 my-2">
          <div className="relative bg-red-500 text-white px-6 py-2 font-bold rounded-l-lg w-fit">
            <div className="absolute right-0 top-0 w-0 h-0 border-l-8 border-t-8 border-b-8 border-l-transparent border-t-white border-b-white"></div>
            Professional Tax Registration:
          </div>
          <p className="mt-2">Register for Professional Tax, a tax levied by state governments on professionals and businesses.</p>
        </div>

        <div className="mx-5 my-2">
          <div className="relative bg-red-500 text-white px-6 py-2 font-bold rounded-l-lg w-fit">
            <div className="absolute right-0 top-0 w-0 h-0 border-l-8 border-t-8 border-b-8 border-l-transparent border-t-white border-b-white"></div>
            EPF (Employees' Provident Fund) Registration:
          </div>
          <p className="mt-2">Register for EPF, a mandatory retirement savings scheme for employees.</p>
        </div>

        <div className="mx-5 my-2">
          <div className="relative bg-red-500 text-white px-6 py-2 font-bold rounded-l-lg w-fit">
            <div className="absolute right-0 top-0 w-0 h-0 border-l-8 border-t-8 border-b-8 border-l-transparent border-t-white border-b-white"></div>
            ESI (Employees' State Insurance) Registration:
          </div>
          <p className="mt-2">Register for ESI, a social security and health insurance scheme for employees.</p>
        </div>

        <div className="mx-5 my-2">
          <div className="relative bg-red-500 text-white px-6 py-2 font-bold rounded-l-lg w-fit">
            <div className="absolute right-0 top-0 w-0 h-0 border-l-8 border-t-8 border-b-8 border-l-transparent border-t-white border-b-white"></div>
            LWF Registration:
          </div>
          <p className="mt-2">The Labour Welfare Fund (LWF) is a statutory fund established to promote the welfare of workers in various industries.</p>
        </div>

        <div className="mx-5 my-2">
          <div className="relative bg-red-500 text-white px-6 py-2 font-bold rounded-l-lg w-fit">
            <div className="absolute right-0 top-0 w-0 h-0 border-l-8 border-t-8 border-b-8 border-l-transparent border-t-white border-b-white"></div>
            CLRA RC:
          </div>
          <p className="mt-2">CLRA stands for Contract Labour (Regulation and Abolition) Act, 1970. The CLRA regulates the employment of contract labour in various establishments. RC stands for Registration Certificate. Under the CLRA, every establishment that employees' contract labour must obtain a Registration Certificate (RC) from the relevant authorities. Establishments that employ 20 or more contract labourers are required to obtain a CLRA RC.</p>
        </div>

        <div className="mx-5 my-2">
          <div className="relative bg-red-500 text-white px-6 py-2 font-bold rounded-l-lg w-fit">
            <div className="absolute right-0 top-0 w-0 h-0 border-l-8 border-t-8 border-b-8 border-l-transparent border-t-white border-b-white"></div>
            BOCW RC:
          </div>
          <p className="mt-2">BOCW stands for Building and Other Construction Workers. The BOCW RC refers to the Registration Certificate issued under the Building and Other Construction Workers (Regulation of Employment and Conditions of Service) Act, 1996. Establishments that employ 10 or more construction workers are required to obtain a BOCW RC.</p>
        </div>

        <div className="mx-5 my-2">
          <div className="relative bg-red-500 text-white px-6 py-2 font-bold rounded-l-lg w-fit">
            <div className="absolute right-0 top-0 w-0 h-0 border-l-8 border-t-8 border-b-8 border-l-transparent border-t-white border-b-white"></div>
            ISMW RC:
          </div>
          <p className="mt-2">ISMW stands for Inter-State Migrant Workmen. The ISMW RC refers to the Registration Certificate issued under the Inter-State Migrant Workmen (Regulation of Employment and Conditions of Service) Act, 1979. Establishments that employ inter-state migrant workmen are required to obtain an ISMW RC.</p>
        </div>

        </div>
      </section>

      <section className='mt-5'>
        <div className='mx-5 my-2 '>
          <h1 className='fs-1 text-danger text-center fw-bolder'>License Services</h1>
          <div className="mx-5 my-2">
          <div className="relative bg-red-500 text-white px-6 py-2 font-bold rounded-l-lg w-fit">
            <div className="absolute right-0 top-0 w-0 h-0 border-l-8 border-t-8 border-b-8 border-l-transparent border-t-white border-b-white"></div>
            Contract Labour License
          </div>
          <p className="mt-2">Obtain a license under the Contract Labour (Regulation and Abolition) Act, 1970, for businesses engaging contract labor.</p>
        </div>

        <div className="mx-5 my-2">
          <div className="relative bg-red-500 text-white px-6 py-2 font-bold rounded-l-lg w-fit">
            <div className="absolute right-0 top-0 w-0 h-0 border-l-8 border-t-8 border-b-8 border-l-transparent border-t-white border-b-white"></div>
            Factory License:
          </div>
          <p className="mt-2">Obtain a license under the Factories Act, 1948, for businesses operating factories.</p>
        </div>

        </div>
      </section>

      <div className='m-5 fw-bold'>
      By outsourcing Registration and Licenses services to GRC@Pulse, businesses can ensure compliance with HR statutory requirements, reduce administrative burdens, and focus on core operations.
      </div>
        
    
        
<Footer/>
    </>
  )
}

export default Registration_LicenseServices