import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import api from '../Services/api';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import axios from 'axios';
import {
    Card,
    CardContent,
    Typography,
    Grid,
    Select,
    Button,
    MenuItem,
    TextField,
    InputLabel,
    FormControl,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from '@mui/material';

const statesInIndia = ['Andhra Pradesh','Arunachal Pradesh','Assam','Bihar','Chhattisgarh','Goa','Gujarat','Haryana','Himachal Pradesh','Jharkhand','Karnataka', 'Kerala','Madhya Pradesh','Maharashtra','Manipur','Meghalaya','Mizoram','Nagaland', 'Odisha','Punjab','Rajasthan','Sikkim','Tamil Nadu','Telangana','Tripura','Uttar Pradesh','Uttarakhand','West Bengal','Andaman and Nicobar Islands','Chandigarh','Dadra and Nagar Haveli and Daman and Diu','Delhi','Ladakh','Lakshadweep','Puducherry'];

export const AssessorList = () => {
    const [assessors, setAssessors] = useState([]);
    const [editingAssessor, setEditingAssessor] = useState(null);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(true);
    const [assessorFormData, setAssessorFormData] = useState({
        assessorId: '',
        companyId: '',
        principleEmployer: '',
        stateId: '',
        placeName:'',
        pinCode:'',
        companyAddress: '',
        authorisedPersonName: '',
        companyEmailId: '',
        companyPhoneNumber: '',
        companyEmailId1: '',
        companyPhoneNumber1: '',
        clientName: '',
        clientContactPersonName: '',
        clientContactPersonPhoneNumber: '',
        clientContactPersonEmailId: '',
        clientContactPersonPhoneNumber1: '',
        clientContactPersonEmailId1: '',
        assessorCompanyName: '',
        assessorStateId: '',
        assessorAddress: '',
        assessorPlaceName: '',
        assessorPinCode: '',
        assessorName: '',
        assessorEmail: '',
        assessorPhoneNumber: '',
        assessorEmail1: '',
        assessorPhoneNumber1: '',
        joiningDate: '',
        exitDate: '',
    });
    const [companyOptions, setCompanyOptions] = useState([]);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [snackbarErrorMessage, setSnackbarErrorMessage] = useState('');



    const fetchClients = async () => {
        try {
            const response = await api.client.getClients();
            setCompanyOptions(response.data);
          
        } catch (error) {
            console.error('Error fetching clients:', error);
        }
    };

    const fetchAssessors = async () => {
        try {
            const response = await api.assessor.getAssessors();
            setAssessors(response.data.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching clients:', error);
        }
    };

    const handleEditClick = (assessor) => {
        setEditingAssessor(assessor.assessorId);
        setAssessorFormData({ ...assessor });
        setOpenEditModal(true);
    };
    const validateForm = () => {
        const newErrors = {};
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phonePattern = /^[6789]\d{9}$/;
    const pinCodePattern = /^(?!000000$)[0-9]{6}$/;
        
        if (!assessorFormData.assessorCompanyName) newErrors.assessorCompanyName = 'Company Name is required';
        if (!assessorFormData.assessorStateId) newErrors.assessorStateId = 'State is required';
        if (!assessorFormData.assessorPlaceName) newErrors.assessorPlaceName = 'Place is required';
        if (!assessorFormData.assessorAddress) newErrors.assessorAddress = 'Address is required';
        if (!assessorFormData.assessorName) newErrors.assessorName = 'Assessor Name is required';
        const stateIdAssessor = assessorFormData.assessorStateId;
        if(!stateIdAssessor){
            newErrors.stateIdAssessor = 'Please select State';
          }
        if (!emailPattern.test(assessorFormData.assessorEmail)) {
          newErrors.assessorEmail = assessorFormData.assessorEmail ? 'Invalid email address(ex:text@email.com)' : 'Email address required';
        }
        // if (!emailPattern.test(assessorFormData.assessorEmail1)) {
        //   newErrors.assessorEmail1 = assessorFormData.assessorEmail1 ? 'Invalid email address(ex:text@email.com)' : 'Email address required';
        // }
        if(!pinCodePattern.test(assessorFormData.assessorPinCode)){
          newErrors.assessorPinCode = assessorFormData.assessorPinCode ? 'Invalid Pin code format(245678)' : 'Pin code required';
        }
        if (!phonePattern.test(assessorFormData.assessorPhoneNumber)) {
          newErrors.assessorPhoneNumber = assessorFormData.assessorPhoneNumber ? 'Invalid phone number(only numbers and length should be 10)' : 'Phone number required';
        }
        if (assessorFormData.assessorEmail1 && !emailPattern.test(assessorFormData.assessorEmail1)) {
          newErrors.assessorEmail1 = 'Invalid email address (e.g., text@email.com)';
        }
        
        if (assessorFormData.assessorPhoneNumber1 && !phonePattern.test(assessorFormData.assessorPhoneNumber1)) {
          newErrors.assessorPhoneNumber1 = 'Invalid phone number. Number should not start with zero)';
        }
        // if (!phonePattern.test(assessorFormData.assessorPhoneNumber1)) {
        //   newErrors.assessorPhoneNumber1 = assessorFormData.assessorPhoneNumber1 ? 'Invalid phone number(only numbers and length should be 10)' : 'Phone number required';
        // }
        if (!assessorFormData.site) newErrors.site = 'Site Name  is required';
        if (!assessorFormData.joiningDate) newErrors.joiningDate = 'Joining Date is required';
        if (!assessorFormData.exitDate) newErrors.exitDate = 'Exit Date is required';

    
        const selectField = assessorFormData.principleEmployer;
        if (!selectField) {
          newErrors.selectField = 'Please select Principal Employer';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
      };

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setAssessorFormData({ ...assessorFormData, [name]: value });

        if (name === 'principleEmployer') {
            api.client.getClients()
                .then(response => {
                    const selectedCompany = response.data.find(company => company.principleEmployer === value);
                    if (selectedCompany) {
                        setAssessorFormData(prevData => ({
                            ...prevData,
                            principleEmployer: value,
                            companyId: selectedCompany.companyId,
                            stateId: selectedCompany.stateId,
                            placeName: selectedCompany.placeName,
                            pinCode: selectedCompany.pinCode,
                            companyAddress: selectedCompany.companyAddress,
                            authorisedPersonName: selectedCompany.authorisedPersonName,
                            companyEmailId: selectedCompany.companyEmailId,
                            companyPhoneNumber: selectedCompany.companyPhoneNumber,
                            companyEmailId1: selectedCompany.companyEmailId,
                            companyPhoneNumber1: selectedCompany.companyPhoneNumber,
                            clientName: selectedCompany.clientName,
                            clientContactPersonName: selectedCompany.clientContactPersonName,
                            clientContactPersonPhoneNumber: selectedCompany.clientContactPersonPhoneNumber,
                            clientContactPersonEmailId: selectedCompany.clientContactPersonEmailId,
                            clientContactPersonPhoneNumber1: selectedCompany.clientContactPersonPhoneNumber1,
                            clientContactPersonEmailId1: selectedCompany.clientContactPersonEmailId1,
                        }));
                    }
                });
        }
    };

    const handleSave = async () => {
        if(validateForm()){
        try {
            const response = await api.assessor.editAssessor(assessorFormData.assessorId, assessorFormData);
            if (response.status === 200) {
              
                setSnackbarOpen(true);
                setSnackbarMessage("Updated successfully!")
                setOpenEditModal(false);
                fetchAssessors();
            } else {
              
                setErrorSnackbarOpen(true);
                setSnackbarErrorMessage("Error in submitting")
            }
        } catch (error) {
            console.error('Error saving data:', error);
            
            setOpenEditModal(false);
        }
    }
    };

    const handleCancel = () => {
        setOpenEditModal(false);
        setEditingAssessor(null);
    };

    useEffect(() => {
        fetchAssessors();
        fetchClients();
    }, []);

    return (
      <>
       <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          
          }}
          severity="success"
          open={snackbarOpen}
          autoHideDuration={4000}
          onClose={() => setSnackbarOpen(false)}
          sx={{
            marginTop: '2.9%', 
            marginRight: '7%'
          }}
        >
          <SnackbarContent style={{ backgroundColor: "green" }} message={snackbarMessage} />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          severity="error"
          open={errorSnackbarOpen}
          autoHideDuration={5000}
          sx={{
            marginTop: '2.9%', 
            marginRight: '7%'
          }}
          onClose={() => {setErrorSnackbarOpen(false)}}
        >
          <SnackbarContent style={{ backgroundColor: "red" }} message={snackbarErrorMessage} />
        </Snackbar>
        {loading ? (
          <div>
            <h4 className=" text-center mt-5">Loading......</h4>
          </div>
        ) : 
      assessors && assessors.length > 0 ? (
//         <div className="m-4">
// <h5>Assessor List <i class="bi bi-arrow-right-square-fill" ></i></h5>
//             <table className="mt-2">
//                 <thead style={{backgroundColor:'black',color:'white'}}>
//                     <tr>
//                         <th>Assessor Details</th>
//                         <th>Contractor Details</th>
//                         <th>Principal Employer</th>
                      
//                         <th>Actions</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {assessors.map((assessor) => (
//                       const company = companyOptions.find((company) => company.companyId === assessor.companyId);
//                         <tr key={assessor.assessorId}>
//                             <td ><span style={{fontSize:"14px",fontWeight:'bold'}}>{assessor.assessorCompanyName}</span> <br></br>
//                               <span style={{color:'gray'}}>
//                                 Address: {assessor.assessorAddress}, {assessor.assessorPlaceName} {assessor.assessorStateId}- {assessor.assessorPinCode} <br></br>
//                               Contact Person: {assessor.assessorName}
//                                <br></br>Site: {assessor.site}<br></br>
//                               Email:{assessor.assessorEmail} {assessor.assessorEmail1 && `, ${assessor.assessorEmail1}`} <br></br>Phone: {assessor.assessorPhoneNumber} {assessor.assessorPhoneNumber1 && `, ${assessor.assessorPhoneNumber1}`}<br></br>
//                                Joining Date: {assessor.joiningDate?.slice(0, 10).split('-').reverse().join('-')}<br></br>
//                                Exit Date: {assessor.exitDate?.slice(0, 10).split('-').reverse().join('-')} 
//                                </span>
//                               </td>
//                               <td><span style={{fontSize:'14px',fontWeight:'bold'}}>{assessor.clientName}</span><br></br>
//                                <span style={{color:'gray'}}>
//                               Contact Person: {assessor.clientContactPersonName}
//                             <br></br>Email: {assessor.clientContactPersonEmailId} {assessor.clientContactPersonEmailId1 && `, ${assessor.clientContactPersonEmailId1}`}
//                             <br></br>Phone: {assessor.clientContactPersonPhoneNumber} {assessor.clientContactPersonPhoneNumber1 && `, ${assessor.clientContactPersonPhoneNumber1}`}
//                             </span>
//                             </td>
//                             <td><span style={{fontSize:'14px',fontWeight:'bold'}}>{company.principleEmployer}</span><br></br>
//                             <span style={{color:'gray'}}>
//                             Place: {company.placeName}<br>
//                             </br>Address: {company.companyAddress}, {company.placeName} {company.stateId}- {company.pinCode}<br>
//                             </br>Authorised Person Name: {company.authorisedPersonName}<br></br> Email: {company.companyEmailId} {company.companyEmailId1 && `, ${assessor.companyEmailId1}`} <br>
//                             </br>Phone Number: {company.companyPhoneNumber} {company.companyPhoneNumber1 && `, ${company.companyPhoneNumber1}`}
//                             </span>
//                             </td>
                            
//                             <td>
//                             <button className="mx-1 btn btn-sm btn-primary" onClick={() => handleEditClick(assessor)}>Edit</button>
//                             </td>
//                         </tr>
//                     ))}
//                 </tbody>
//             </table>
//             </div>
<div className="m-4">
  <h5>Assessor List <i className="bi bi-arrow-right-square-fill"></i></h5>
  <div style={{ minHeight:'420px',maxHeight: "440px", marginTop:"10px", overflowY: "auto",overflowX:"auto" }}>
  <table className="mt-2 border-top-0 ">
    <thead className="position-sticky top-0 " style={{zIndex:1000, backgroundColor: 'black', color: 'white',borderTop:"none" }}>
      <tr>
        <th className='border-top-0'>Assessor Details</th>
        <th className='border-top-0'>Contractor Details</th>
        <th className='border-top-0'>Principal Employer</th>
        <th className='border-top-0'>Actions</th>
      </tr>
    </thead>
    <tbody>
      {assessors.map((assessor) => {
        // Find the company that matches the assessor's companyId
        const company = companyOptions.find(
          (company) => company.companyId === assessor.companyId
        );

        // Only render the row if the corresponding company is found
        return company ? (
          <tr key={assessor.assessorId}>
            <td>
              <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
                {assessor.assessorCompanyName}
              </span>
              <br />
              <span style={{ color: 'gray' }}>
                Address: {assessor.assessorAddress}, {assessor.assessorPlaceName}{' '}
                {assessor.assessorStateId} - {assessor.assessorPinCode}
                <br />
                Contact Person: {assessor.assessorName}
                <br />
                Site: {assessor.site}
                <br />
                Email: {assessor.assessorEmail}{' '}
                {assessor.assessorEmail1 && `, ${assessor.assessorEmail1}`}
                <br />
                Phone: {assessor.assessorPhoneNumber}{' '}
                {assessor.assessorPhoneNumber1 && `, ${assessor.assessorPhoneNumber1}`}
                <br />
                Joining Date: {assessor.joiningDate?.slice(0, 10).split('-').reverse().join('-')}
                <br />
                Exit Date: {assessor.exitDate?.slice(0, 10).split('-').reverse().join('-')}
              </span>
            </td>
            <td>
              <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
                {assessor.clientName}
              </span>
              <br />
              <span style={{ color: 'gray' }}>
                Contact Person: {assessor.clientContactPersonName}
                <br />
                Email: {assessor.clientContactPersonEmailId}{' '}
                {assessor.clientContactPersonEmailId1 && `, ${assessor.clientContactPersonEmailId1}`}
                <br />
                Phone: {assessor.clientContactPersonPhoneNumber}{' '}
                {assessor.clientContactPersonPhoneNumber1 && `, ${assessor.clientContactPersonPhoneNumber1}`}
              </span>
            </td>
            <td>
              <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
                {company.principleEmployer}
              </span>
              <br />
              <span style={{ color: 'gray' }}>
                Place: {company.placeName}
                <br />
                Address: {company.companyAddress}, {company.placeName} {company.stateId} - {company.pinCode}
                <br />
                Authorised Person Name: {company.authorisedPersonName}
                <br />
                Email: {company.companyEmailId}{' '}
                {company.companyEmailId1 && `, ${company.companyEmailId1}`}
                <br />
                Phone Number: {company.companyPhoneNumber}{' '}
                {company.companyPhoneNumber1 && `, ${company.companyPhoneNumber1}`}
              </span>
            </td>
            <td>
              <button
                className="mx-1 btn btn-sm btn-primary"
                onClick={() => handleEditClick(assessor)}
              >
                Edit
              </button>
            </td>
          </tr>
        ) : null; // Skip the row if no matching company is found
      })}
    </tbody>
  </table>
  </div>
</div>

      ) : (
        <div className='mt-4'>
        <p className="text-center">No Assessor Found !! <Link to="/createassessor">Click here</Link> to Create</p>
      </div>
      )}
            <Dialog open={openEditModal} onClose={handleCancel}>
            <h6 style={{textAlign:'center',color:'white',backgroundColor:"#1C1C1C",padding:'24px',letterSpacing:'0.5px'}}>EDIT ASSESSOR DETAILS</h6>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                                fullWidth
                                label="Assessor Company Name"
                                name="assessorCompanyName"
                                value={assessorFormData.assessorCompanyName}
                                onChange={handleFormChange}
                                margin="normal"
                                error={!!errors.assessorCompanyName}
                                helperText={errors.assessorCompanyName}

                            />
                          
          
            <Select
             fullWidth
             InputLabelProps={{ shrink: true }}
              label="State ID"
              value={assessorFormData.assessorStateId}
              onChange={handleFormChange}
              name="assessorStateId"
              displayEmpty
              error={!!errors.assessorStateId}
            >
              <MenuItem value="" disabled >Select State</MenuItem>
              {statesInIndia.map((state, index) => (

                <MenuItem key={index} value={state}>{state}</MenuItem>
              ))}
            </Select>

          {errors.stateIdAssessor && (
            <div className="pb-3 px-5" style={{ color: 'red', fontSize: '14px' }}>
              {errors.stateIdAssessor}
            </div>
          )}
                            <TextField
                              InputLabelProps={{ shrink: true }}
                                fullWidth
                                label="Assessor Address"
                                name="assessorAddress"
                                value={assessorFormData.assessorAddress}
                                onChange={handleFormChange}
                                margin="normal"
                                error={!!errors.assessorAddress}
                                helperText={errors.assessorAddress}
                            />
                            <TextField
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                label="Place Name"
                                name="assessorPlaceName"
                                value={assessorFormData.assessorPlaceName}
                                onChange={handleFormChange}
                                margin="normal"
                                error={!!errors.assessorPlaceName}
                                helperText={errors.assessorPlaceName}
                            />
                            <TextField
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                label="PinCode"
                                name="assessorPinCode"
                                value={assessorFormData.assessorPinCode}
                                onChange={handleFormChange}
                                margin="normal"
                                error={!!errors.assessorPinCode}
                                helperText={errors.assessorPinCode}
                            />
                            <TextField
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                label="Assessor Name"
                                name="assessorName"
                                value={assessorFormData.assessorName}
                                onChange={handleFormChange}
                                margin="normal"
                                error={!!errors.assessorName}
                                helperText={errors.assessorName}
                            />
                            <TextField
                                fullWidth
                                label="Assessor Email"
                                name="assessorEmail"
                                InputLabelProps={{ shrink: true }}
                                value={assessorFormData.assessorEmail}
                                onChange={handleFormChange}
                                margin="normal"
                                error={!!errors.assessorEmail}
                                helperText={errors.assessorEmail}
                            />
                              <TextField
                                fullWidth
                                label="Alternative Email"
                                name="assessorEmail1"
                                type="email"
                                InputLabelProps={{ shrink: true }}
                                value={assessorFormData.assessorEmail1}
                                onChange={handleFormChange}
                                margin="normal"
                                error={!!errors.assessorEmail1}
                                helperText={errors.assessorEmail1}
                            />
                            <TextField
                                fullWidth
                                label="Assessor Phone"
                                name="assessorPhoneNumber"
                                value={assessorFormData.assessorPhoneNumber}
                                onChange={handleFormChange}
                                margin="normal"
                                InputLabelProps={{ shrink: true }}
                                error={!!errors.assessorPhoneNumber}
                                helperText={errors.assessorPhoneNumber}
                            />
                             <TextField
                                fullWidth
                                label="Alternative PhoneNumber"
                                name="assessorPhoneNumber1"
                                type="number"
                                InputLabelProps={{ shrink: true }}
                                value={assessorFormData.assessorPhoneNumber1}
                                onChange={handleFormChange}
                                margin="normal"
                                error={!!errors.assessorPhoneNumber1}
                                helperText={errors.assessorPhoneNumber1}
                            />
                             <TextField
                                fullWidth
                                label="Assessor Site"
                                name="site"
                                value={assessorFormData.site}
                                onChange={handleFormChange}
                                margin="normal"
                                InputLabelProps={{ shrink: true }}
                                error={!!errors.site}
                                helperText={errors.site}
                            />
                            <TextField
                fullWidth
                type="date"
                label="Joining Date"
                name="joiningDate"
                InputLabelProps={{ shrink: true }}
                 value={assessorFormData.joiningDate ? assessorFormData.joiningDate.slice(0, 10): ''}
                onChange={handleFormChange}
                margin="normal"
                error={!!errors.joiningDate}
                                helperText={errors.joiningDate}
              />
                         <TextField
                fullWidth
                type="date"
                label="Exit Date"
                name="exitDate"
                 value={assessorFormData.exitDate ? assessorFormData.exitDate.slice(0, 10) : ''}
                onChange={handleFormChange}
                margin="normal"
                InputLabelProps={{ shrink: true }}
                error={!!errors.exitDate}
                                helperText={errors.exitDate}
              />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Principal Employer</InputLabel>
                                <Select
                                  InputLabelProps={{ shrink: true }}
                                    name="principleEmployer"
                                    value={assessorFormData.principleEmployer}
                                    onChange={handleFormChange}
                                    
                                >
                                    {companyOptions.map((company) => (
                                        <MenuItem key={company.companyId} value={company.principleEmployer}>
                                            {company.principleEmployer}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {errors.selectField && (
            <div className="pb-3 px-5" style={{ color: 'red', fontSize: '14px' }}>
              {errors.selectField}
            </div>
          )}
                          <TextField
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          label="Contractor"
                          name="clientName"
                          value={assessorFormData.clientName}
                          onChange={handleFormChange}
                          margin="normal"
                          InputProps={{ readOnly: true }}
                        />
                             <TextField
                          fullWidth
                          label="State"
                          InputLabelProps={{ shrink: true }}
                          name="stateId"
                          value={assessorFormData.stateId}
                          onChange={handleFormChange}
                          margin="normal"
                          InputProps={{ readOnly: true }}
                        />
                        
                        <TextField
                          fullWidth
                          label="Place"
                          name="placeName"
                          InputLabelProps={{ shrink: true }}
                          value={assessorFormData.placeName}
                          onChange={handleFormChange}
                          margin="normal"
                          InputProps={{ readOnly: true }}
                        />
                         <TextField
                          fullWidth
                          label="Address"
                          name="companyAddress"
                          InputLabelProps={{ shrink: true }}
                          value={assessorFormData.companyAddress}
                          onChange={handleFormChange}
                          margin="normal"
                          InputProps={{ readOnly: true }}
                        />
                         <TextField
                          fullWidth
                          label="Pincode"
                          name="pinCode"
                          InputLabelProps={{ shrink: true }}
                          value={assessorFormData.pinCode}
                          onChange={handleFormChange}
                          margin="normal"
                          InputProps={{ readOnly: true }}
                        />
                        
                        <TextField
                          fullWidth
                          label="Contact Person Name"
                          name="clientContactPersonName"
                          InputLabelProps={{ shrink: true }}
                          value={assessorFormData.clientContactPersonName}
                          onChange={handleFormChange}
                          margin="normal"
                          InputProps={{ readOnly: true }}
                        />
                         <TextField
                          fullWidth
                          label="Email"
                          name="clientContactPersonEmailId"
                          InputLabelProps={{ shrink: true }}
                          value={assessorFormData.clientContactPersonEmailId}
                          onChange={handleFormChange}
                          margin="normal"
                          InputProps={{ readOnly: true }}
                        />
                         <TextField
                          fullWidth
                          label="Alternative Email"
                          name="clientContactPersonEmailId1"
                          InputLabelProps={{ shrink: true }}
                          value={assessorFormData.clientContactPersonEmailId1}
                          onChange={handleFormChange}
                          margin="normal"
                          InputProps={{ readOnly: true }}
                        />
                          <TextField
                          fullWidth
                          label="Phone"
                          name="clientContactPersonPhoneNumber"
                          InputLabelProps={{ shrink: true }}
                          value={assessorFormData.clientContactPersonPhoneNumber}
                          onChange={handleFormChange}
                          margin="normal"
                          InputProps={{ readOnly: true }}
                        />
                         <TextField
                          fullWidth
                          label="Alternative PhoneNumber"
                          name="clientContactPersonPhoneNumber1"
                          InputLabelProps={{ shrink: true }}
                          value={assessorFormData.clientContactPersonPhoneNumber1}
                          onChange={handleFormChange}
                          margin="normal"
                          InputProps={{ readOnly: true }}
                        />

                        </Grid>
                    </Grid>
                </DialogContent>
                <div className='mx-4 mb-3' style={{display:'flex',justifyContent:'space-between'}}>
          <button className="btn btn-md btn-danger" onClick={handleCancel} >
            Cancel
          </button>
          <button className="btn btn-md btn-success" disabled={!validateForm} onClick={handleSave} >
            Save
          </button>
          </div>
            </Dialog>
            </>
       
    );
};
