import serviceOne from "../Images/esta.png"
import serviceTwo from "../Images/vender.png"
import serviceThree from "../Images/factory.png"
import serviceFour from "../Images/hr.png"
import serviceFive from "../Images/payroll.png"
import serviceSix from "../Images/labour.png"
import Navbar from "../layout/NavBar"
import { IoIosArrowForward } from "react-icons/io";
import Footer from "../layout/Footer"
import imageOne from "../Images/section-one-img.png"
import compImage from "../Images/compaliance.png"
import govImage from "../Images/governce.png"
import riskImage from "../Images/risk.png";
import registration_license from "../Images/registration_license.png"
import serivceSeveen from "../Images/audit-illu.png"
import { Link } from "react-router-dom"
import "./landing.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import {  Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import client1 from '../Images/img5.jpg'
import client2 from '../Images/img6.jpg'
import client3 from '../Images/img7.jpg'
import client4 from '../Images/img8.jpg'
import client5 from '../Images/img9.jpg'
import client6 from '../Images/img10.jpg'
import client7 from '../Images/img11.jpg'
import client8 from '../Images/img12.jpg'
import client9 from '../Images/img13.jpg'
import client11 from '../Images/img15.jpg'
import client12 from '../Images/img16.jpg'
import client13 from '../Images/hutch.jpg'
import client14 from '../Images/img19.jpg'
import client15 from '../Images/img21.jpg'
import client16 from '../Images/img22.jpg'
import client17 from '../Images/img24.jpg'
import client18 from '../Images/img25.jpg'

const LandingPage = () => {
  const handleTouchPointsUi = () => {
    return (
      <div className=" lg:absolute xl:absolute grid lg:grid-cols-3 xl:grid-cols-3 gap-4 z-20 w-full lg:transform xl:transform lg:translate-y-[-150px] xl:translate-y-[-150px] pt-5">
        <div className=" lg:h-[300px] bg-white shadow-card-shadow rounded-md text-center p-4  flex flex-col items-center">
          <img src={govImage} alt="" className="w-[100px] h-[100px]" />
          <h1 className="font-bold text-2xl">Governance</h1>
          <p className="mt-2 text text-center">
          GRC@Pulse framework enables strong governance practices that promote clarity in roles, policies, and processes. Enhances trust, sustainability, and long-term success.
          </p>
        </div>
        <div className="lg:h-[300px] bg-white shadow-card-shadow rounded-md text-center p-4 flex flex-col items-center">
          <img src={riskImage} alt="" className="w-[100px] h-[100px]" />
          <h1 className="font-bold text-2xl">Risk</h1>
          <p className="mt-2 text-center">
          GRC@Pulse takes a proactive approach to risk management. This ensures resilience, minimizes disruptions, and helps organisations stay prepared for evolving challenges.
          </p>
        </div>
        <div className="lg:h-[300px] bg-white shadow-card-shadow rounded-md text-center p-4 flex flex-col items-center">
          <img src={compImage} alt="" className="w-[100px] h-[100px] text-center" />
          <h1 className="font-bold text-2xl">Compliance</h1>
          <p className="mt-2 text-center">
          GRC@Pulse helps complete compliance to laws, regulations, standards, and ethical practices. It helps improve decision-making, build a culture of transparency and trust across the organization.
          </p>
        </div>
      </div>)
  }

  return (
    <div className="relative">
      <Navbar />
      <div className="landingscreen  bg-[#B22222] pb-0 pt-[70px] pr-[50px] pl-[50px] pb-[10px] relative">
        <div className="flex flex-col lg:flex-row  justify-between gap-4">
          <img src={imageOne} alt="" className="block lg:hidden w-full h-[250px] lg:w-[450px] lg:h-[450px]" />
          <div className="mt-5 lg:mt-0">
            <h1 className="text-white text-2xl lg:text-6xl font-bold">GRC@Pulse </h1>
            <h1 className="text-white text-2xl lg:text-4xl font-bold">Compliance Assurance Services</h1>
            <h1 className="text-white mt-[20px]">Brought to you by People Process Teck, an HR start-up led by experienced professionals with expertise across various industries. </h1>
            <Link to="/request-a-demo">
              <button className="bg-[#B22222] p-2 border-2 hover:border-white hover:!bg-primary-color text-white font-bold rounded-md mt-4 cursor-pointer">Request for demo</button>
            </Link>
          </div>
          <img src={imageOne} alt="" className="hidden mt-4 lg:block w-[150px] h-[150px] lg:w-[450px] lg:h-[450px]" />
        </div>
      </div>
      {handleTouchPointsUi()}
     <h1 className="text-center text-4xl font-bold mt-10 lg:pt-[100px] xl:mt-[150px] mt-8">What We Offer</h1>
     <div className="grid grid-cols-1 lg:grid-cols-4 mt-2 p-2 gap-3 max-w-[1024px]:grid-cols-1">
        {[ 
          { title: "Establishment Compliance", img: serviceOne, link: "/services/establishment-compliance", desc: "Ensure seamless adherence to legal requirements for your business operations, reducing risks." },
          { title: "Vendor Compliance", img: serviceTwo, link: "/services/vendor-compliance", desc: "Monitor and manage vendor obligations to maintain smooth partnerships and regulatory compliance." },
          { title: "Factory Compliance", img: serviceThree, link: "/services/factory-compliance", desc: "Streamline factory processes to meet safety, labor, and environmental standards efficiently." },
          { title: "Licenses And Registration", img: registration_license, link: "/Registation_License_Services", desc: "GRC@Pulse provides comprehensive Licenses and Registration services. By outsourcing Registrations and License services to GRC@Pulse, businesses can ensure compliance with statutory requirements, reduce administrative burdens, and focus on core operations." }
        ].map((service, index) => (
          <section key={index} className="shadow-card-shadow rounded-lg p-[1rem] flex flex-col items-center min-h-[400px] h-full">
            <div className="flex justify-center">
              <img src={service.img} alt="" className="h-[8rem] w-[8rem] lg:h-[12rem] lg:w-[12rem]" />
            </div>
            <h1 className="lg:text-2xl font-bold text-center mt-[1rem]">{service.title}</h1>
            <div className="flex-grow">
              <p className="mt-[1rem] text-center text-gray-1000 leading-6 text-xs lg:text-base">{service.desc}</p>
            </div>
            <Link to={service.link}>
              <button className="mt-[2rem] px-4 py-2 rounded-md font-bold bg-primary-color text-slate-50 border-2 border-primary-color hover:bg-transparent hover:text-primary-color">
                <h1 className="inline"> View More</h1>
                <IoIosArrowForward className="inline ml-1" color="#ffffff" />
              </button>
            </Link>
          </section>
        ))}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-4 mt-2 p-2 gap-3">
          <section className="shadow-card-shadow rounded-lg p-[1rem] flex flex-col items-center h-full">
            <div className="flex justify-center">
              <img src={serviceFive} alt="" className="h-[8rem] w-[8rem] lg:h-[12rem] lg:w-[12rem]" />
            </div>
            <h1 className="lg:text-2xl font-bold text-center mt-[1rem]">
              Payroll Management
            </h1>
            <p className="mt-[1.5rem] text-center text-gray-1000 leading-6 text-xs lg:text-base flex-grow">
              Simplify payroll with accurate processing, timely disbursals, and statutory compliance.
            </p>
            <Link to="/services/payroll-management" className="mt-auto">
              <button className="px-4 py-2 rounded-md font-bold bg-primary-color text-slate-50 border-2 border-primary-color hover:bg-transparent hover:text-primary-color">
                <h1 className="inline">View More</h1>
                <IoIosArrowForward className="inline ml-1" color="#ffffff" />
              </button>
            </Link>
          </section>

          <section className="shadow-card-shadow rounded-lg p-[1rem] flex flex-col items-center h-full">
            <div className="flex justify-center">
              <img src={serviceSix} alt="" className="h-[8rem] w-[8rem] lg:h-[12rem] lg:w-[12rem]" />
            </div>
            <h1 className="lg:text-2xl font-bold text-center mt-[1rem]">
              New Labour Codes
            </h1>
            <p className="mt-[1.5rem] text-center text-gray-1000 leading-6 text-xs lg:text-base flex-grow">
              Stay updated and compliant with the evolving labor laws for a future-ready workforce.
            </p>
            <Link to="/services/new-labour-laws" className="mt-auto">
              <button className="px-4 py-2 rounded-md font-bold bg-primary-color text-slate-50 border-2 border-primary-color hover:bg-transparent hover:text-primary-color">
                <h1 className="inline">View More</h1>
                <IoIosArrowForward className="inline ml-1" color="#ffffff" />
              </button>
            </Link>
          </section>

          <section className="shadow-card-shadow rounded-lg p-[1rem] flex flex-col items-center h-full">
            <div className="flex justify-center">
              <img src={serivceSeveen} alt="" className="h-[8rem] w-[8rem] lg:h-[12rem] lg:w-[12rem]" />
            </div>
            <h1 className="lg:text-2xl font-bold text-center mt-[1rem]">
              Productivity Audit
            </h1>
            <p className="mt-[1.5rem] text-center text-gray-1000 leading-6 text-xs lg:text-base flex-grow">
              Align workflow with a productivity audit for a smarter tomorrow.
            </p>
            <Link to="/services/productivity-audit" className="mt-auto">
              <button className="px-4 py-2 rounded-md font-bold bg-primary-color text-slate-50 border-2 border-primary-color hover:bg-transparent hover:text-primary-color">
                <h1 className="inline">View More</h1>
                <IoIosArrowForward className="inline ml-1" color="#ffffff" />
              </button>
            </Link>
          </section>

          <section className="shadow-card-shadow rounded-lg p-[1rem] flex flex-col items-center h-full">
            <div className="flex justify-center">
              <img src={serviceFour} alt="" className="h-[8rem] w-[8rem] lg:h-[12rem] lg:w-[12rem]" />
            </div>
            <h1 className="lg:text-2xl font-bold text-center mt-[1rem]">
              HR Service Provider Audit
            </h1>
            <p className="mt-[1rem] text-center text-gray-1000 leading-6 text-xs lg:text-base flex-grow">
            Audit your HR services to ensure accuracy, transparency, and alignment with compliance norms.
            </p>
            <Link to="/services/hr-service-provider-audit" className="mt-auto">
              <button className="px-4 py-2 rounded-md font-bold bg-primary-color text-slate-50 border-2 border-primary-color hover:bg-transparent hover:text-primary-color">
                <h1 className="inline">View More</h1>
                <IoIosArrowForward className="inline ml-1" color="#ffffff" />
              </button>
            </Link>
          </section>
        </div>

        <>
          <h1 className="text-center text-4xl font-bold lg:pt-[50px] my-5">Our Clients</h1>
          <div className="w-full flex items-center justify-center px-[2rem]">
                  <Swiper
                    modules={[Autoplay]}
                    spaceBetween={100}
                    loop={true}
                    slidesPerView={4}
                    autoplay={{
                      delay: 1000,
                      disableOnInteraction: false,
                    }}
                  >
                    <SwiperSlide>
                        <img src={client1} alt="" className="w-[15rem] h-[10rem] border-2 border-gray p-[1rem]" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={client2} alt="" className="w-[15rem] h-[10rem] border-2 border-gray p-[1rem]"/>
                    </SwiperSlide>
                    <SwiperSlide>
                      
                        <img src={client3} alt="" className="w-[15rem] h-[10rem] border-2 border-gray p-[1rem]"/>
                    </SwiperSlide>
                    <SwiperSlide>
                      
                        <img src={client4} alt="" className="w-[15rem] h-[10rem] border-2 border-gray p-[1rem]"/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={client5} alt="" className="w-[15rem] h-[10rem] border-2 border-gray p-[1rem]"/>
                    </SwiperSlide>
                    <SwiperSlide>
                      
                        <img src={client6} alt="" className="w-[15rem] h-[10rem] border-2 border-gray p-[1rem]"/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={client7} alt="" className="w-[15rem] h-[10rem] border-2 border-gray p-[1rem]"/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={client8} alt="" className="w-[15rem] h-[10rem] border-2 border-gray p-[1rem]"/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={client9} alt="" className="w-[15rem] h-[10rem] border-2 border-gray p-[1rem]"/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={client11} alt="" className="w-[15rem] h-[10rem] border-2 border-gray p-[1rem]"/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={client12} alt="" className="w-[15rem] h-[10rem] border-2 border-gray p-[1rem]"/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={client13} alt="" className="w-[15rem] h-[10rem] border-2 border-gray p-[1rem]"/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={client14} alt="" className="w-[15rem] h-[10rem] border-2 border-gray p-[1rem]"/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={client15} alt="" className="w-[15rem] h-[10rem] border-2 border-gray p-[1rem]"/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={client16} alt="" className="w-[15rem] h-[10rem] border-2 border-gray p-[1rem]"/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={client17} alt="" className="w-[15rem] h-[10rem] border-2 border-gray p-[1rem] "/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={client18} alt="" className="w-[15rem] h-[10rem] border-2 border-gray p-[1rem]"/>
                    </SwiperSlide>
                  </Swiper>
          </div>
        </>

      <Footer />
    </div>
  )
}

export default LandingPage