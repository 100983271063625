import logo from "../Images/GRC-Pulse-Logo.png"
import {AboutUs} from "../../src/Pages/AboutUs"
import { Link } from "react-router-dom"
import { FaPhone } from "react-icons/fa";
import { MdAttachEmail } from "react-icons/md";
import { FaInstagram } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaPinterest } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";


const Footer = () => {
  return (
    <>
      <div className="border-1 mt-6 "style={{backgroundColor:"rgba(0,0,0,0.85)"} }/>
      <div className="p-4 lg:px-20 lg:py-16 grid grid-cols-1 lg:grid-cols-4 justify-between gap-6 lg:gap-32 bg-stone-950">
        <div>
          <Link to="/">
            <img src={logo} alt="" className="w-[150px] h-[50px] " />
          </Link>
          <p className="mt-4 text-xl text-left text-white">
            4C-209,2nd Floor, 4th Cross, East of NEGF, Kasturi Nagar, Bengaluru 560043
          </p>
        </div>
        <div className=" flex flex-col ">
          <h1 className="font-bold text-2xl text-light ">Useful Links</h1>
          <ul class="list-disc mt-2">
            <a href="https://www.peopleprocessteck.com/" target="_blank" rel="noreferrer" >
              <li className="mt-2 text-lg cursor-pointer text-light">People Process Teck</li>
            </a>
            <a href="https://www.gigleji.com/" target="_blank" rel="noreferrer">
              <li className="mt-2 text-lg cursor-pointer text-light">Gigleji</li>
            </a>
            <Link to="/FAQs">
              <li className="mt-2 text-lg text-light">FAQS</li>
            </Link>
            <Link to="/terms-and-condition">
              <li className="mt-2 text-lg text-light">Terms and Conditions</li>
            </Link>
            <Link to="/PrivacyPolicy">
              <li className="mt-2 text-lg text-light">Privacy Policy </li>
            </Link>
          </ul>
        </div>
        <div className="text-light">
          <h1 className="text-2xl font-bold">Contact Us</h1>
          <div className="flex gap-2 items-center mt-2">
            <FaPhone />
            <h1 className="text-l">080-31634351 <br/> 91-8310163968</h1>
          </div>
          <div className="flex gap-2 items-center mt-2">
            <a href="mailto:support@grcpulse.in" className="flex items-center">
              <MdAttachEmail className="text-xl" />
              <h1 className="text-l ml-2">support@grcpulse.in</h1>
            </a>
          </div>
        </div>
        <div className="text-center text-white flex flex-col items-center">
          <div className="flex gap-3">
            <a href="https://www.instagram.com/peopleprocessteck/" target="_blank" rel="noreferrer">
              <FaInstagram fontSize="1.6rem" />
            </a>
            <a href="https://www.facebook.com/people/People-Process-Teck/100063922820365/" target="_blank" rel="noreferrer">
              <FaFacebookSquare fontSize="1.6rem"/>
            </a>
            <a href="https://www.linkedin.com/company/people-process-teck-1/" target="_blank" rel="noreferrer">
              <FaLinkedin fontSize="1.6rem" />
            </a>
            <a href="https://in.pinterest.com/peopleprocessteck/" target="_blank" rel="noreferrer">
              <FaPinterest fontSize="1.6rem" />
            </a>
            <a href="https://x.com/PeopleProcessTk?mx=2" target="_blank" rel="noreferrer">
              <FaXTwitter fontSize="1.6rem" />
            </a>
          </div>
          <Link to="/request-a-demo">
            <button className="p-2 mt-2 font-bold bg-primary-color rounded-md text-white mt-4">Request for demo</button>
          </Link>
        </div>
      </div>
      <div className="bg-stone-950 text-center text-white py-4 flex justify-center">
          <p className="m-0 p-0">
            <span>&copy;</span> 2025 People Process Teck. All Rights Reserved.
          </p>
      </div>
    </>
  )
}

export default Footer
