import React, { useState, useRef, useEffect } from 'react';
import { Form, Card, Button, Row, Col } from 'react-bootstrap';
import { BiUpload } from 'react-icons/bi';
import VendorAppBar from './VendorAppBar';
import { COLORS } from '../Component/colors';
import axios from 'axios';
import './EditableCard.css';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import api from '../Services/api';





const titles = [
  "Date of Commencement",
  "Date of Completion",
  "PO Copy Number",
  "EPFO Allotment copy",
  "ESIC allotment copy",
  "P Tax RC Number",
  "PT EC Number",
  "LWF Number",
  "Factory Licence",
  "Building plan layout",
  "Stability Certificate",
  "Consent to Establishment",
  "Consent to Operations",
  "Fire Advisory",
  "Fire NOC",
  "Environment Clearance",
  "Medical/Hospital - Certificate",
  "Apprenticeship Registration",
  "NEEM/NAPS - Registration",
  "CLRA RC",
  "S&CE RC",
  "CLRA Licence",
  "BOCW RC",
  "ISMW RC",
  "ISMW Licence",
  "PSARA Licence",
  "FSSAI Licence",
  "WC Policy Number",
  "HR Policy Undertaking Ack",
  

];





const MasterData = () => {

  const initialState = titles.map(title => ({ value: '', fileUrl: '',validDate:'',noOfWorkers:'', validation: 0, remark: '',vendorRemark: '', na: false, qId: '', editing: false, isFileModified: false }));
  const [cardStates, setCardStates] = useState(initialState);
  const fileInputRefs = useRef(Array(titles.length).fill(null).map(() => React.createRef()));
  const [userId, setUserId] = useState('');
  const [userType, setUserType] = useState('');
 
      
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [countValidation1, setCountValidation1] = useState(0);
  const [countValidation2, setCountValidation2] = useState(0);
  const [countValidation3, setCountValidation3] = useState(0);
  const [snackbarErrorMessage, setSnackbarErrorMessage] = useState('');
  const [uploadStatus, setUploadStatus] = useState(Array(titles.length).fill('upload'));
  const [initialCardStates, setInitialCardStates] = useState(initialState);







  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem('user'));

    if (userData && userData.id) {
      setUserId(userData.id);
      setUserType(userData.type);

    }
  }, []);

  useEffect(() => {
   
    if (userType === 'client') {

      fetchCompanyData(userId);
    }
    else if (userType === 'vendor') {
      fetchData(userId);
    }
  }, [userId]);




  const fetchData = async (userId) => {
    try {
      const response = await api.masterData.getVendorMasterData(userId);
      if (response.status === 200) {
        const responseData = response.data.data;
        let validation1 = 0;
        let validation2 = 0;
        let validation3 = 0;

        const updatedCardStates = Object.keys(responseData).map(key => {
          let background = COLORS.light;
         
          const validation = responseData[key].validation;
         
          if (validation === 1) {
            background = COLORS.bggray;
            validation1 += 1;

          } else if (validation === 2) {
            background = COLORS.success;
            validation2 += 1;

          } else if (validation === 3) {
            background = COLORS.danger;
            validation3 += 1;

          }
          
          const fileUrl = responseData[key].fileUrl;
          const fileName = fileUrl ? fileUrl.substring(fileUrl.lastIndexOf('/') + 1) : '';
          return {
            value: responseData[key].value,
            fileUrl: responseData[key].fileUrl,
            fileName: fileName,
            validation: responseData[key].validation,
            validDate: responseData[key].validDate,
            noOfWorkers: responseData[key].noOfWorkers,
            remark: responseData[key].remark,
            vendorRemark: responseData[key].vendorRemark,
            na: responseData[key].na,
            editing: responseData[key].editing,
            qId: responseData[key].qId,
            background: background,
           
          };
        });
        const updatedUploadStatus = updatedCardStates.map(card => 
          card.fileUrl ? 'uploaded' : 'upload'
        );

        setCardStates(updatedCardStates);
        setUploadStatus(updatedUploadStatus);
        setInitialCardStates(updatedCardStates);
        setCountValidation1(validation1);
        setCountValidation2(validation2);
        setCountValidation3(validation3);
       
      } else if (response.status === 404) {
        setSnackbarOpen(true);
        setSnackbarErrorMessage("MasterData not found for this vendor");
        setCardStates(initialState);
      }
      else if (response.status === 500) {
        setErrorSnackbarOpen(true);
        setSnackbarErrorMessage(response.data.message);
        setCardStates(initialState);
      }
      else if (response.status === 400) {
        setErrorSnackbarOpen(true);
        setSnackbarErrorMessage(response.data.message);
        setCardStates(initialState);
      }
      else {
        setSnackbarOpen(true);
        setSnackbarErrorMessage("Error fetching details");
        setCardStates(initialState);
      }


    } catch (error) {
      console.error('Error fetching vendor data:', error);
    }
  };

  const fetchCompanyData = async (userId) => {
    try {
      const response = await api.masterData.getClientMasterData(userId);
      if (response.status === 200) {
        const responseData = response.data.data;
        let validation1 = 0;
        let validation2 = 0;
        let validation3 = 0;// Access the nested data object
        const updatedCardStates = Object.keys(responseData).map(key => {
          let background = COLORS.light;
          const validation = responseData[key].validation;
          if (validation === 1) {
            background = COLORS.bggray;
            validation1 += 1;

          } else if (validation === 2) {
            background = COLORS.success;
            validation2 += 1;

          } else if (validation === 3) {
            background = COLORS.danger;
            validation3 += 1;

          }

          return {
            value: responseData[key].value,
            fileUrl: responseData[key].fileUrl,
            validation: responseData[key].validation,
            validDate: responseData[key].validDate,
            noOfWorkers: responseData[key].noOfWorkers,
            remark: responseData[key].remark,
            vendorRemark: responseData[key].vendorRemark,
            na: responseData[key].na,
            editing: responseData[key].editing,
            qId: responseData[key].qId,
            background: background
          };
        });
        setCardStates(updatedCardStates);
        setInitialCardStates(updatedCardStates);
        setCountValidation1(validation1);
        setCountValidation2(validation2);
        setCountValidation3(validation3);
      }
      else if (response.status === 404) {
        setErrorSnackbarOpen(true);
        setSnackbarErrorMessage("MasterData not found for this client")
        setCardStates(initialState);
      }
      else if (response.status === 500) {
        setErrorSnackbarOpen(true);
        setSnackbarErrorMessage(response.data.message);
        setCardStates(initialState);
      }
      else if (response.status === 400) {
        setErrorSnackbarOpen(true);
        setSnackbarErrorMessage(response.data.message);
        setCardStates(initialState);
      }
      else {
        setErrorSnackbarOpen(true);
        setSnackbarErrorMessage("Error fetching details");
        setCardStates(initialState);
      }


    } catch (error) {
      console.error('Error fetching vendor data:', error);
    }
  };


  const handleFileChange = (e, index) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const updatedCardStates = [...cardStates];
      updatedCardStates[index].fileUrl = '';
      updatedCardStates[index].fileName = selectedFile.name;
      updatedCardStates[index].vendorRemark = ''
      updatedCardStates[index].isFileModified = false;
      setCardStates(updatedCardStates);
      const updatedUploadStatus = [...uploadStatus];
      updatedUploadStatus[index] = 'upload';
      
      setUploadStatus(updatedUploadStatus);
    }
  };
  const handleFileUpload = async (index, title) => {
    const selectedFile = fileInputRefs.current[index].current.files[0];
    
    if (!selectedFile) {
   
      setErrorSnackbarOpen(true);
      setSnackbarErrorMessage('No file selected!');
      
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('vendorId', userId);
    formData.append('fieldName', title);

    const updatedUploadStatus = [...uploadStatus];
    updatedUploadStatus[index] = 'uploading';
    setUploadStatus(updatedUploadStatus);
    try {
      const response = await axios.put('https://www.grcpulse.in/api/companyMasterData/uploadfile', formData)
     if(response.status === 200){
      const updatedCardStates = [...cardStates];
      updatedCardStates[index].fileUrl = response.data.data;
      updatedCardStates[index].fileName = selectedFile.name;
      setCardStates(updatedCardStates);
      updatedUploadStatus[index] = 'uploaded';
      updatedCardStates[index].isFileModified = true;
      setUploadStatus(updatedUploadStatus);
      setSnackbarOpen(true);
      setSnackbarMessage('File uploaded successfully!');
     
    } 
    else if(response.status === 413){
      setErrorSnackbarOpen(true);
      setSnackbarErrorMessage("File size should be below 5MB");
      updatedUploadStatus[index] = 'upload';
      const updatedCardStates = [...cardStates];
      updatedCardStates[index].fileUrl = '';
      updatedCardStates[index].fileName = '';
      updatedCardStates[index].isFileModified = false;
      setCardStates(updatedCardStates);
      setUploadStatus(updatedUploadStatus);
    }
  }catch (error) {

      console.error('Error uploading file:', error);
      
      updatedUploadStatus[index] = 'upload';
      setUploadStatus(updatedUploadStatus);
    }

  };

  const handleUploadClick = (index) => {
    fileInputRefs.current[index].current.click();
  };

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    const updatedCardStates = [...cardStates];
    updatedCardStates[index].value = value;
    updatedCardStates[index].vendorRemark = ''
   
    setCardStates(updatedCardStates);
  };
  const handleRemarkChange = (e,index) => {
    const {value} = e.target;
    const updatedCardStates = [...cardStates];
    updatedCardStates[index].vendorRemark = value;
    setCardStates(updatedCardStates);

   
};
  const handleEditClick = (index) => {
    const updatedCardStates = [...cardStates];
    setInitialCardStates(prevStates => {
      const newStates = [...prevStates];
      newStates[index] = { ...cardStates[index] };
      return newStates;
    });
    updatedCardStates[index].editing = true;
    setCardStates(updatedCardStates);
  };

  const handleNotApplicableChange = (index) => {
    const updatedCardStates = [...cardStates];
    updatedCardStates[index].na = !updatedCardStates[index].na;
    // updatedCardStates[index].isModified = true;
    if (updatedCardStates[index].na) {
      updatedCardStates[index].value = '';
      updatedCardStates[index].fileUrl = '';
      updatedCardStates[index].fileName = '';
      updatedCardStates[index].noOfWorkers = '';
      updatedCardStates[index].validDate = '';
      updatedCardStates[index].vendorRemark = ''

    }
    
    setCardStates(updatedCardStates);
  };
  const handleValidDateChange = (value, index) => {
    const updatedCardStates = [...cardStates];
    updatedCardStates[index].validDate = value;
    updatedCardStates[index].vendorRemark = ''
    setCardStates(updatedCardStates);
  };
  const handleNumOfWorkersChange = (value, index) => {
    if(value>=0){
    const updatedCardStates = [...cardStates];
    updatedCardStates[index].noOfWorkers = value;
    updatedCardStates[index].vendorRemark = ''
    setCardStates(updatedCardStates);
    }
    else{
      setErrorSnackbarOpen(true);
      setSnackbarErrorMessage('Please enter valid number');
      const updatedCardStates = [...cardStates];
    updatedCardStates[index].noOfWorkers = '';
    
    setCardStates(updatedCardStates);
    }
  };
  const handleDateChange = (value, index) => {
    const updatedCardStates = [...cardStates];
    updatedCardStates[index].value = value;
    updatedCardStates[index].vendorRemark = ''
    setCardStates(updatedCardStates);
  };

  const handlesave = async (index) => {
    const updatedCardStates = [...cardStates];
    const updatedValue = cardStates[index].value;
    const updatedValidation = 1;
    const updatedValidDate = cardStates[index].validDate;
    const updatedRemark = cardStates[index].remark;
    const updatedNoOfWorkers = cardStates[index].noOfWorkers;
    const updatedVendorRemark = cardStates[index].vendorRemark
    const updatena = cardStates[index].na;
    const updatedFileUrl = cardStates[index].fileUrl;
    const updatedFileUpload = cardStates[index].isFileModified;

   
  const currentValues = updatedCardStates[index];
  console.log(currentValues);
  
  const initialValues = initialCardStates[index];
  console.log(initialValues);
  

  const isModified =
  currentValues.value !== initialValues.value ||
  (updatedFileUpload && currentValues.fileUrl !== initialValues.fileUrl) ||
  currentValues.validDate !== initialValues.validDate ||
  currentValues.noOfWorkers !== initialValues.noOfWorkers ||
  currentValues.vendorRemark !== initialValues.vendorRemark ||
  currentValues.na !== initialValues.na;

if (!isModified) {
  updatedCardStates[index] = { ...initialValues, editing: true };

  if (uploadStatus[index] != 'uploaded') {
    updatedCardStates[index].fileName = '';  
    updatedCardStates[index].fileUrl = '';   
  } else {
    updatedCardStates[index].fileName = initialValues.fileName || ''; 
    updatedCardStates[index].fileUrl = initialValues.fileUrl || '';   
  }

  const updatedUploadStatus = [...uploadStatus];
  updatedUploadStatus[index] = initialValues.fileUrl ? 'uploaded' : 'upload'; 

  setCardStates(updatedCardStates);
  setUploadStatus(updatedUploadStatus);
  setErrorSnackbarOpen(true);
  setSnackbarErrorMessage("No changes detected. Please modify the fields before saving.");
  return;
}
if (uploadStatus[index] != 'uploaded') {

  if (initialValues.fileUrl) {
    updatedCardStates[index].fileUrl = initialValues.fileUrl; // Use previous fileUrl if file was uploaded before
    updatedCardStates[index].fileName = initialValues.fileName || '';
   
  } else {
    updatedCardStates[index].fileName = '';  // Clear file name if no previous file exists
    updatedCardStates[index].fileUrl = '';   // Clear file URL if no previous file exists
  }
}

if (uploadStatus[index] === 'uploaded') {
  updatedCardStates[index].fileName = updatedFileUrl ? updatedFileUrl.substring(updatedFileUrl.lastIndexOf('/') + 1) : '';  // Show file name
  updatedCardStates[index].fileUrl = updatedFileUrl; 
}
const updatedUploadStatus = [...uploadStatus];
setCardStates(updatedCardStates);
setUploadStatus(updatedUploadStatus);

 
    const masterdata = {
      [userType === 'client' ? 'companyId' : 'vendorId']: userId
    };


    if (index === 0) {
      masterdata.dateOfCommencement = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        vendorRemark: updatedVendorRemark,
        na: updatena,
      };
    }
    if (index === 1) {
      masterdata.dateOfCompletion = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        vendorRemark: updatedVendorRemark,
        na: updatena,
      };

    }

    if (index === 2) {
      masterdata.poCopy = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        validDate: updatedValidDate,
        remark: updatedRemark,
        vendorRemark: updatedVendorRemark,
        na: updatena,
      };
    }
    if (index === 3) {
      masterdata.epfoAllotment = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        vendorRemark: updatedVendorRemark,
        na: updatena,
      };
    }
    if (index === 4) {
      masterdata.esicAllotment = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        vendorRemark: updatedVendorRemark,
        na: updatena,
      };
    }
    if (index === 5) {
      masterdata.pTaxRc = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        vendorRemark: updatedVendorRemark,
        na: updatena,
      };
    }
    if (index === 6) {
      masterdata.ptEc = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        vendorRemark: updatedVendorRemark,
        na: updatena,
      };
    }
    if (index === 7) {
      masterdata.lwf = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        vendorRemark: updatedVendorRemark,
        na: updatena,
      };
    }
    if (index === 8) {
      masterdata.factoryLicence = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        validDate: updatedValidDate,
        remark: updatedRemark,
        vendorRemark: updatedVendorRemark,
        na: updatena,
      };
    }
    if (index === 9) {
      masterdata.buildingPlanLayout = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        vendorRemark: updatedVendorRemark,
        na: updatena,
      };
    }
    if (index === 10) {
      masterdata.stabilityCertificate = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        validDate: updatedValidDate,
        remark: updatedRemark,
        vendorRemark: updatedVendorRemark,
        na: updatena,
      };
    }
    if (index === 11) {
      masterdata.concernToEstablishment = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        vendorRemark: updatedVendorRemark,
        na: updatena,
      };
    }
    if (index === 12) {
      masterdata.concernToOperations = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        vendorRemark: updatedVendorRemark,
        na: updatena,
      };
    }
    if (index === 13) {
      masterdata.fireAdvisory = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        vendorRemark: updatedVendorRemark,
        na: updatena,
      };
    }
    if (index === 14) {
      masterdata.fireNoc = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        vendorRemark: updatedVendorRemark,
        na: updatena,
      };
    }
    if (index === 15) {
      masterdata.environmentClearance = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        validDate: updatedValidDate,
        remark: updatedRemark,
        vendorRemark: updatedVendorRemark,
        na: updatena,
      };
    }
    if (index === 16) {
      masterdata.medicalOrHospitalCertificate = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        vendorRemark: updatedVendorRemark,
        na: updatena,
      };
    }
    if (index === 17) {
      masterdata.apprenticeshipRegistration = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        vendorRemark: updatedVendorRemark,
        na: updatena,
      };
    }
    if (index === 18) {
      masterdata.neemOrNapsRegistration = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        vendorRemark: updatedVendorRemark,
        na: updatena,
      };
    }
    if (index === 19) {
      masterdata.clraRc = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        vendorRemark: updatedVendorRemark,
        na: updatena,
      };
    }
    if (index === 20) {
      masterdata.sAndCeRc = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        vendorRemark: updatedVendorRemark,
        na: updatena,
      };
    }
    if (index === 21) {
      masterdata.clraLicence = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        validDate: updatedValidDate,
        noOfWorkers: updatedNoOfWorkers,
        remark: updatedRemark,
        vendorRemark: updatedVendorRemark,
        na: updatena,
      };
    }
    if (index === 22) {
      masterdata.bocwRc = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        validDate: updatedValidDate,
        noOfWorkers: updatedNoOfWorkers,
        remark: updatedRemark,
        vendorRemark: updatedVendorRemark,
        na: updatena,
      };
    }
    if (index === 23) {
      masterdata.ismwRc = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        remark: updatedRemark,
        vendorRemark: updatedVendorRemark,
        na: updatena,
      };
    }
    if (index === 24) {
      masterdata.ismwLicence = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        validDate: updatedValidDate,
        remark: updatedRemark,
        vendorRemark: updatedVendorRemark,
        na: updatena,
      };
    }
    if (index === 25) {
      masterdata.passaraLicence = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        validDate: updatedValidDate,
        remark: updatedRemark,
        vendorRemark: updatedVendorRemark,
        na: updatena,
      };
    } if (index === 26) {
      masterdata.fssaiLicence = {
        value: updatedValue,
        fileUrl: updatedFileUrl,
        validation: updatedValidation,
        validDate: updatedValidDate,
        remark: updatedRemark,
        vendorRemark: updatedVendorRemark,
        na: updatena,
      }
    }
      if (index === 27) {
        masterdata.wCPolicyNumber = {
          value: updatedValue,
          fileUrl: updatedFileUrl,
          validation: updatedValidation,
          validDate: updatedValidDate,
          noOfWorkers: updatedNoOfWorkers,
          remark: updatedRemark,
          vendorRemark: updatedVendorRemark,
          na: updatena,
        };
      }
        if (index === 28) {
          masterdata.hrPolicyUndertakingAck= {
            value: updatedValue,
            fileUrl: updatedFileUrl,
            validation: updatedValidation,
          
            remark: updatedRemark,
            vendorRemark: updatedVendorRemark,
            na: updatena,
          };
        }
       
    try {
      const response = await api.masterData.updateMasterData(masterdata)
      if (response.status === 200) {
        for (const key in masterdata) {

          if (masterdata.hasOwnProperty(key)) {

            const updatedCardStates = [...cardStates];
            updatedCardStates[index].editing = false;
            updatedCardStates[index].background = COLORS.bggray;
            updatedCardStates[index].validation = 1;
            setCardStates(updatedCardStates);
            

          }
        }
        setSnackbarOpen(true);
        setSnackbarMessage('Updated Successfully');
      }
     
      else if (response.status === 400) {
        setErrorSnackbarOpen(true);
        setSnackbarErrorMessage('Data not updated, Please try again');

      }
      else if (response.status === 404) {
           setErrorSnackbarOpen(true);
        setSnackbarErrorMessage('There is no masterdata available assosiated with this Id');
      }
      else {
        setErrorSnackbarOpen(true);
        setSnackbarErrorMessage('Error in Updating the data,please try again');
      }
    }
    catch (error) {
      console.error("Error updating masterdata:", error);
    }
  };


  


  const handleCancelButton = (index) => {
    const updatedCardStates = [...cardStates];
    updatedCardStates[index] = initialCardStates[index];
    updatedCardStates[index].editing = false;
    setCardStates(updatedCardStates);
  };



  const chunkArray = (array, chunkSize) => {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunkedArray.push(array.slice(i, i + chunkSize));
    }
    return chunkedArray;
  };

  


  const chunkedTitles = chunkArray(titles, 3);
  const renderInput = (index) => {
    // Handle the case for indices that need a 'validDate' and 'numOfWorkers'
    if (index === 2 || index === 8 || index === 10 || index === 15 || index === 21 || index === 22 || index === 24 || index === 25 || index === 26 || index === 27) {
      return (
        <>
         
          
            <input
              type="text"
              id="value"
              placeholder={`Enter ${titles[index]}`}
              className="p-2 mb-2"
              style={{ width: '100%', border: '1px solid #eee', borderRadius: '5px' }}
              value={cardStates[index].value}
              onChange={(e) => handleInputChange(e, index)}
              disabled={cardStates[index].na}
            />
        
  
          {/* Render 'validDate' and 'numOfWorkers' conditionally based on the index */}
          {index === 21 || index === 22 || index === 27 ? (
            // For indices 21, 22, 27, render both 'validDate' and 'numOfWorkers'
            <section className="d-flex justify-content-between">
             
              
                <input
                  type="date"
                  id="validDate"
                  placeholder='Valid Date'
                  className="p-2 mb-2"
                  style={{ width: '100%', border: '1px solid #eee', borderRadius: '5px' }}
                  value={cardStates[index].validDate}
                  onChange={(e) => handleValidDateChange(e.target.value, index)}
                  disabled={cardStates[index].na}
                />
            
                <input
                  type="number"
                  id="numOfWorkers"
                  placeholder="No Of Workers"
                  className="p-2 mb-2"
                  style={{ width: '100%', border: '1px solid #eee', borderRadius: '5px' }}
                  value={cardStates[index].noOfWorkers}
                  onChange={(e) => handleNumOfWorkersChange(e.target.value, index)}
                  disabled={cardStates[index].na}
                  min="0"
                />
            
            </section>
          ) : (
       
         
              <input
                type="date"
                id="validDate"
                placeholder='Valid Date'
                className="p-2 mb-2"
                style={{ width: '100%', border: '1px solid #eee', borderRadius: '5px' }}
                value={cardStates[index].validDate}
                onChange={(e) => handleValidDateChange(e.target.value, index)}
                disabled={cardStates[index].na}
              />
           
          )}
        </>
      );
    } else {
      // Handle the case for indices 0 and 1 where 'value' should be replaced with 'date'
      if (index === 0 || index === 1) {
        return (
          <div className="mb-1">
        
            <input
              type="date"
              id="value"
              className="p-2 mb-1"
              style={{ width: '100%', border: '1px solid #eee', borderRadius: '5px' }}
              value={cardStates[index].value}
              onChange={(e) => handleDateChange(e.target.value, index)}
              disabled={cardStates[index].na}
            />
          </div>
        );
      } else {
        // For other indices, always display the 'value' field as a text input
        return (
          <div className="mb-1">
          
            <input
              type="text"
              placeholder={`Enter ${titles[index]}`}
              id="value"
              className="p-2"
              style={{ width: '100%', border: '1px solid #eee', borderRadius: '5px' }}
              value={cardStates[index].value}
              onChange={(e) => handleInputChange(e, index)}
              disabled={cardStates[index].na}
            />
          </div>
        );
      }
    }
  };
  


  return (

    <div style={{ overflow: 'hidden' }}>

      <div className="mt-4 mb-3 d-md-flex " style={{ justifyContent: 'space-between' }} >

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          severity="success"
          open={snackbarOpen}
          autoHideDuration={4000}
          onClose={() => setSnackbarOpen(false)}
           sx={{
            marginTop: '2.9%', 
            marginRight: '7%'
          }}
        >
          <SnackbarContent style={{ backgroundColor: "green" }} message={snackbarMessage} />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          severity="success"
          open={errorSnackbarOpen}
          autoHideDuration={4000}
          onClose={() => {setErrorSnackbarOpen(false)}}
          sx={{
            marginTop: '2.9%', 
            marginRight: '7%'
          }}
        >
          <SnackbarContent style={{ backgroundColor: "red" }} message={snackbarErrorMessage} />
        </Snackbar>

        <div style={{ letterSpacing: '1px' }}>
          <div style={{ backgroundColor: COLORS.bggray, width: '15px', height: '15px', display: 'inline-block', marginRight: '4px', marginLeft: '8px', borderRadius: '50%' }}></div>
          <span style={{ fontSize: '14px' }}>Under review : {countValidation1}</span>
          <div className="vr  mx-3"></div>
          <div style={{ backgroundColor: COLORS.success, width: '15px', height: '15px', display: 'inline-block', marginRight: '4px', borderRadius: '50%' }}></div>
          <span style={{ fontSize: '14px' }}>Accepted : {countValidation2}</span>
          <div className="vr mx-3"></div>
          <div style={{ backgroundColor: COLORS.danger, width: '15px', height: '15px', display: 'inline-block', marginRight: '4px', borderRadius: '50%' }}></div>
          <span style={{ fontSize: '14px' }}>Rejected : {countValidation3}</span>
        </div>

      </div>

      <div >
        {chunkedTitles.map((row, rowIndex) => (
          <Row key={rowIndex} >
            {row.map((title, colIndex) => {
              const index = rowIndex * 3 + colIndex;
              const updatedValue = cardStates[index].value;
              const updatedValidDate= cardStates[index].validDate;
              const updatedNoOfWorkers = cardStates[index].noOfWorkers;
              const updatedFileUrl = cardStates[index].fileUrl;
              const updatedValidation = cardStates[index].validation;
              const updatedRemark = cardStates[index].remark;
             
              const updatedVendorRemark = cardStates[index].vendorRemark;
              const filename = updatedFileUrl ? updatedFileUrl.substring(updatedFileUrl.lastIndexOf('/') + 1) : '';
              const updatena = cardStates[index].na;
              // const showRemark = updatedValidation !== 1;
              const validationText = updatedValidation === 1 ? "Under Review" : updatedValidation === 2 ? "Accepted" : updatedValidation === 3 ? "Rejected" : "Not Uploaded";
              return (

                <Col key={colIndex} >
                  <Card className={` ${cardStates[index].editing ? 'editing' : ''}  `} style={{marginTop:'10px', marginLeft:'10px',marginRight:'8px',backgroundColor: cardStates[index].background, height: '300px', width: '380px' }}>
                    <Card.Body>
                      {!cardStates[index].editing ? (
                        <>
                          <div className="p-3 " style={{ cursor: 'pointer', textAlign: 'center', backgroundColor: '#eee', borderRadius: '5px', boxShadow: '0px 0px 2px 0px rgba(0,0,0)' }} onClick={() => { handleEditClick(index) }}>
                            <Card.Title style={{ fontSize: '16px', letterSpacing: "0.6px" }}>{title}</Card.Title>
                          </div>
                          <div className="pt-4 d-flex" style={{ justifyContent: 'space-between' }}>
                            <div style={{ overflowY: 'auto', width: '200px', height: 'auto', maxHeight: '90px' }}>
                               {updatedValue && <h6>{updatedValue}</h6>}
                               {updatedValidDate && <h6>Valid Date: {updatedValidDate.split('-').reverse().join('-')}</h6>}
                              {updatedNoOfWorkers && <h6>No Of Workers: {updatedNoOfWorkers}</h6>}
                              {!updatedValue && !updatedFileUrl && updatena && <p>Not Applicable</p>}</div>
                            {updatedFileUrl && (
                              <div>
                                <button className='btn btn-sm btn-primary' onClick={() => window.open(`https://docs.google.com/gview?url=${updatedFileUrl}&embedded=true`, '_blank')}><i className="bi bi-eye"></i> Doc</button>
                                <button className="btn mx-1 btn-sm btn-primary" onClick={() => window.open(updatedFileUrl)}><i className="bi bi-download"></i> Doc</button>
                              </div>
                            )}

                          </div>
                          {updatedRemark && updatedRemark.trim() !== '' && (
                            <div className="d-flex pt-2">
                              <div>
                                <h6 style={{ backgroundColor: 'red', padding: '3px', borderRadius: '5px', color: 'white' }}>Remark:</h6>
                              </div>
                              <div style={{ marginLeft: '5px', width: '100%', padding: '5px', border: '1px solid gray', overflow: 'auto', height: '60px' }}>
                                {updatedRemark}
                              </div>
                            </div>
                          )}
                          <p className="pt-2 " style={{ letterSpacing: '0.6px' }}>Status : {validationText}</p>
                        </>

                      ) : (
                        <>
                          <Form.Group>
                            <Form.Group className="d-flex ">
                              <Form.Check label="Not Applicable" type="checkbox" style={{ fontSize: '14px', marginLeft: 'auto', marginRight: '5px', marginBottom: '2px',cursor:'pointer' }} checked={cardStates[index].na} onChange={() => handleNotApplicableChange(index)} />
                            </Form.Group>
                            {/* <Form.Label >{title}</Form.Label> */}
                            {renderInput(index)}
                          </Form.Group>
                          <Form.Group>
                            <div className="d-flex align-items-center">
                              <Form.Control
                                type="text"
                                placeholder={`Upload Proof for ${title}`}
                                // const filename={cardStates[index].fileUrl.substring(cardStates[index].fileUrl.lastIndexOf('/') + 1)}
                                value={cardStates[index].fileName || ''}

                                readOnly
                                disabled={cardStates[index].na}
                              />
                              <Button variant="light" className="m-2" disabled={cardStates[index].na}>
                                <BiUpload style={{ fontSize: '18px' }} onClick={() => handleUploadClick(index)} />
                              </Button>
                              <Button
                                variant="light"
                                className="ms-2"
                                id="upload"
                                style={{ border: '1px solid gray' }}
                                disabled={cardStates[index].na || uploadStatus[index] === 'uploading' || uploadStatus[index] === 'uploaded'}
                                onClick={() => handleFileUpload(index, title)}

                              >
                                {uploadStatus[index] === 'uploading' ? 'Uploading...' : uploadStatus[index] === 'uploaded' ? 'Uploaded' : 'Upload'}
                              </Button>
                              <input
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => handleFileChange(e, index)}
                                ref={fileInputRefs.current[index]}
                              />
                               
                            </div>
                            <textarea
                                  value={cardStates[index].vendorRemark}  
                                    placeholder="Enter your remarks here"
                                    className='mt-1'
                                 onChange={(e) => handleRemarkChange(e,index)}
                                    rows="2"
                                    style={{ width: '100%' }}
                                />
                          </Form.Group>

                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                            <button className="btn btn-md btn-light" style={{ border: "1px solid gray" }} onClick={() => handleCancelButton(index)} >Cancel</button>
                            <button 
  className='btn btn-md btn-success' 
  onClick={() => handlesave(index)} 
  disabled={!(cardStates[index].value || cardStates[index].fileUrl || cardStates[index].na || cardStates[index].validDate || cardStates[index].noOfWorkers )}>
Save

</button>


                          </div>
                          {/* {showRemark && (
                            <h6 className="text-danger">
                              {updatedRemark}
                            </h6>
                          )} */}
                        </>
                      )}

                    </Card.Body>
                  </Card>
                </Col>
              );
            })}
          </Row>
        ))}
      </div>
    </div>
  );
};

export default MasterData;
