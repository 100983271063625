import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";
import { IoIosArrowDown } from "react-icons/io";
import mainLogo from "../Images/GRC-Pulse-Logo.png";
import { SlCalender } from 'react-icons/sl';


function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenServices, setIsOpenServices] = useState(false);
  const { pathname } = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  return (
    <nav className="px-4 z-50 py-3 position-fixed w-100" style={{backgroundColor:"rgba(0,0,0,0.85)"}}>
      <div className=" flex items-center justify-between">
        <div>
          <Link to ="/">
            <img src={mainLogo} alt="" className="w-[110px] h-[40px]" />
          </Link>
        </div>
        <div className="hidden md:flex space-x-6 items-center">
          <Link to="/" className={pathname === "/" ? `block text-slate-50	 border-b-2 border-white` : `block text-slate-50	`}>Home</Link>
          <Link to="/about-us" className={pathname === "/about-us" ? `block text-slate-50	 border-b-2 border-white` : `block text-slate-50`}>About</Link>
          <div
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            className={pathname === "/services" ? `block text-slate-50	 border-b-2 border-white flex gap-2 items-center` : `block text-slate-50 flex gap-2 items-center`}>
            <h1>Services</h1>
            <IoIosArrowDown />
          </div>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem>
              <Link to="/services/establishment-compliance">
                Establishment Compliance
              </Link>
            </MenuItem>
            <MenuItem>
              <Link to="/services/vendor-compliance">
                Vendor Compliance
              </Link>
            </MenuItem>
            <MenuItem>
              <Link to="/services/factory-compliance">
                Factory Compliance
              </Link>
            </MenuItem>
           <MenuItem>
              <Link to="/Registation_License_Services">
                Licenses And Registration
              </Link>
            </MenuItem>
            <MenuItem>
              <Link to="/services/payroll-management">
                Payroll Management
              </Link>
            </MenuItem>
            <MenuItem>
              <Link to="/services/new-labour-laws">
                New Labour Codes
              </Link>
            </MenuItem>
            <MenuItem>
              <Link to="/services/productivity-audit">
                Productivity Audit
              </Link>
            </MenuItem>
            <MenuItem>
              <Link to="/services/hr-service-provider-audit">
                HR Service Provider Audit
              </Link>
            </MenuItem>
           
          </Menu>
          <Link to="/contact-us" className={pathname === "/contact" ? `block text-slate-50	 border-b-2 border-white` : `block text-slate-50`}>Contact Us</Link>
          {/* <Link to="/complience-calender" target="blank"> <SlCalender size="1.6rem" color='#ffffff' />  </Link> */}
          <button className="bg-primary-color font-bold p-2 text-white rounded-md border border-white">
           <Link to='/signin'>Login</Link> 
          </button>
        </div>
        <button
          className="text-white md:hidden"
          onClick={() => setIsOpen(!isOpen)}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
      </div>

      {isOpen && (
  <div className="md:hidden bg-blue-700">
    <Link to="/" className={pathname === "/" ? `block px-4 py-2 text-white border-2` : `block px-4 py-2 text-slate-50 hover:bg-blue-500`}>Home</Link>
    <Link to="/about-us" className="block px-4 py-2 text-slate-50 hover:bg-blue-500">About</Link>
    
    {/* Services Dropdown in Mobile View */}
    <div className="relative">
      <button 
        onClick={() => setIsOpenServices(!isOpenServices)} 
        className="block w-full px-4 py-2 text-slate-50 hover:bg-blue-500 flex justify-between"
      >
        Services <IoIosArrowDown />
      </button>
      {isOpenServices && (
        <div className="bg-blue-600">
          <Link to="/services/establishment-compliance" className="block px-4 py-2 text-white hover:bg-blue-500">Establishment Compliance</Link>
          <Link to="/services/vendor-compliance" className="block px-4 py-2 text-white hover:bg-blue-500">Vendor Compliance</Link>
          <Link to="/services/factory-compliance" className="block px-4 py-2 text-white hover:bg-blue-500">Factory Compliance</Link>
          <Link to="/services/hr-service-provider-audit" className="block px-4 py-2 text-white hover:bg-blue-500">HR Service Provider Audit</Link>
          <Link to="/services/payroll-management" className="block px-4 py-2 text-white hover:bg-blue-500">Payroll Management</Link>
          <Link to="/services/new-labour-laws" className="block px-4 py-2 text-white hover:bg-blue-500">New Labour Codes</Link>
          <Link to="/services/productivity-audit" className="block px-4 py-2 text-white hover:bg-blue-500">Productivity Audit</Link>
          {/* <Link to="/services/Registration_License-service" className="block px-4 py-2 text-white hover:bg-blue-500">Registration and License Service</Link> */}
        </div>
      )}
    </div>

    <Link to="/contact-us" className="block px-4 py-2 text-slate-50 hover:bg-blue-500">Contact</Link>
  </div>
)}
    </nav>
  );
}

export default Navbar;
