    import React, { useState, useEffect } from 'react';
    import axios from 'axios';
    import { Bar } from 'react-chartjs-2';
    import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';

    import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
    ArcElement
    } from 'chart.js';

    ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend, ArcElement);

    const Monthlydata = () => {
    let data = sessionStorage.getItem('user');
    let vendorData = JSON.parse(data);
    let vendorId = vendorData.id;
    const currentYear = new Date().getFullYear();
      const [snackbarOpen, setSnackbarOpen] = useState(false);
      const [snackbarMessage, setSnackbarMessage] = useState('');
      const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
      const [snackbarErrorMessage, setSnackbarErrorMessage] = useState('');

    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
        {
            label: 'Accepted',
            data: [],
            backgroundColor: '#50c878',
        },
        {
            label: 'Rejected',
            data: [],
            backgroundColor: '#B22222',
        },
        {
            label: 'UnderReview',
            data: [],
            backgroundColor: '#EFCC00',
        }
        ]
    });

    const [selectedQuarter, setSelectedQuarter] = useState('');
    const [selectedYear, setSelectedYear] = useState(currentYear);

    useEffect(() => {
        if (!selectedQuarter) {
        monthlyData(vendorId, selectedYear);
        } else {
        monthlyData(vendorId, selectedYear, selectedQuarter);
        }
    }, [vendorId, selectedYear, selectedQuarter]);

    const monthlyData = async (vendorId, year, quarter = '') => {
        try {
        const response = await axios.get(`https://www.grcpulse.in/api/monthlydata/yearly/${vendorId}`, {
            params: { year }
        });
       if(response.status === 200){
        const data = response.data.data;
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        console.log("mothlydata",data);
        const quarterMonths = quarter ? getQuarterMonths(quarter) : [...Array(12).keys()];

        const acceptedPercent = [];
        const rejectedPercent = [];
        const underReviewPercent = [];
        const monthLabels = [];
 
        quarterMonths.forEach((monthIndex) => {
            const monthData = data.find(item => item.month === (monthIndex + 1).toString().padStart(2, '0'));
            monthLabels.push(months[monthIndex]);
            if (monthData) {
            acceptedPercent.push(monthData.AcceptedPercent || 0);
            rejectedPercent.push(monthData.RejectedPercent || 0);
            underReviewPercent.push(monthData.UnderReviewPercent || 0);
            } else {
            acceptedPercent.push(0);
            rejectedPercent.push(0);
            underReviewPercent.push(0);
            }
        });

        setChartData({
            labels: monthLabels,
            datasets: [
            {
                label: 'Accepted',
                data: acceptedPercent,
                backgroundColor: '#50c878',
                 barThickness: 16,
            
                 
            },
            {
                label: 'Rejected',
                data: rejectedPercent,
                backgroundColor: '#B22222',
                 barThickness: 16,
            },
            {
                label: 'UnderReview',
                data: underReviewPercent,
                backgroundColor: '#EFCC00',
                 barThickness: 16,
            }
            ]
        });
        } 
    
    else if(response.status === 404)
        {
       setErrorSnackbarOpen(true);
       setSnackbarErrorMessage(response.data.message)
        }
        else if(response.status === 400){
            setErrorSnackbarOpen(true);
       setSnackbarErrorMessage(response.data.message)
        }
        else if(response.status === 500){
            setErrorSnackbarOpen(true);
       setSnackbarErrorMessage(response.data.message)
        }
        else{
            setErrorSnackbarOpen(true);
            setSnackbarErrorMessage('Some error occured, please try again later');
        }
    }catch (error) {
        console.error("Error fetching monthly data:", error);
        }
    };

    const getQuarterMonths = (quarter) => {
        switch (quarter) {
        case 'Q1':
            return [0, 1, 2];
        case 'Q2':
            return [3, 4, 5]; 
        case 'Q3':
            return [6, 7, 8]; 
        case 'Q4':
            return [9, 10, 11]; 
        default:
            return [];
        }
    };

    const handleQuarterChange = (e) => {
        setSelectedQuarter(e.target.value);
    };

    const handleYearChange = (e) => {
        setSelectedYear(e.target.value);
    };

    return (
        <div className='m-0 p-0'>
            <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    severity="success"
                    open={snackbarOpen}
                    autoHideDuration={4000}
                    onClose={() => setSnackbarOpen(false)}
                    sx={{ marginTop: '2.9%', marginRight: '7%' }}
                  >
                    <SnackbarContent style={{ backgroundColor: "green" }} message={snackbarMessage} />
                  </Snackbar>
                  <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    severity="error"
                    open={errorSnackbarOpen}
                    autoHideDuration={5000}
                    sx={{ marginTop: '2.9%', marginRight: '7%' }}
                    onClose={() => setErrorSnackbarOpen(false)}
                  >
                    <SnackbarContent style={{ backgroundColor: "red" }} message={snackbarErrorMessage} />
                  </Snackbar>
             <div style={{ backgroundColor: 'white', border: '1px solid lightgray' }}>
             
          <h6 className="text-dark text-center p-2" style={{backgroundColor:'rgba(0,0,0,0.1)'}}>Monthly Data</h6>
       
        <div className=" d-grid">
         
          
            <div className="m-2 d-flex justify-content-end">
            
                <select  className="mx-2" onChange={handleQuarterChange} value={selectedQuarter}  style={{border:'1px solid lightgray',borderRadius:'4px'}}>
                <option value="">All Months</option> 
                <option value="Q1">Q1</option>
                <option value="Q2">Q2</option>
                <option value="Q3">Q3</option>
                <option value="Q4">Q4</option>
                </select>

                <select  onChange={handleYearChange} value={selectedYear}  style={{border:'1px solid lightgray',borderRadius:'4px'}}>
                {Array.from({ length: currentYear - 2020 + 1 }, (_, i) => 2020 + i).map((year) => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                </select>
            </div>
          

            <div>
            <Bar
  data={chartData}
  options={{
    responsive: true,
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem) {
            const percentageValue = tooltipItem.raw;
            const datasetLabel = tooltipItem.dataset.label; // Get the label of the dataset
            let status = '';

            // Determine the status based on the dataset label
            if (datasetLabel === 'Accepted') {
                status = 'Accepted';
            } else if (datasetLabel === 'Rejected') {
                status = 'Rejected';
            } else if (datasetLabel === 'UnderReview') {
                status = 'Under Review';
            }

            // Return the status with the percentage
            return `${status}: ${percentageValue}%`;
          },
        },
      },
      datalabels: {
        display: false, // Enable the datalabels plugin
        color: 'black', // Set label color
        align: 'center', // Center the label horizontally
        anchor: 'end', // Position the label at the top of the bar
        font: {
          size: 10,
          weight:'bold'
          
        
        
        },
       
        
        formatter: function (value) {
          if (value != 0) {
            return `${value}%`; // Format as percentage
          } else {
            return '';
          }
        },
         
      },
     
    },
    scales: {
      x: {
        title: { display: true },
        ticks: { color: 'gray' },
        grid: { display: false },
      },
      y: {
        title: { display: true },
        min: 0,
        max: 100,
        stepSize: 20,
        ticks: { display: true, color: 'gray' ,callback: value => value + '%' },
        grid: { display: false },
      },
    },
  }}
/>


            </div>
        </div>
        </div>
        </div>
    );
    };

    export default Monthlydata;
