import React, { useEffect, useState } from 'react';
import api from '../../../Services/api';
import { Bar } from 'react-chartjs-2';
import { Doughnut } from 'react-chartjs-2';
import ProgressBar from 'react-bootstrap/ProgressBar'; // You can use this for the progress bar
import './index.css';
// import ChartDataLabels from 'chartjs-plugin-datalabels';  // Import chartjs-plugin-datalabels

// Register Chart.js components
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
//   ChartDataLabels
);
const YearlyMonthlydata = () => {
    let data1 = sessionStorage.getItem('user');
    let clientData = JSON.parse(data1);
    let id = clientData.id;

    const currentYear = new Date().getFullYear();
    const [yearlyData, setYearlyData] = useState(null);
    const [vendorList, setVendorList] = useState([]);
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [selectedQuarter, setSelectedQuarter] = useState('Yearly'); // New state for selected quarter/half

    useEffect(() => {
        const getData = async () => {
            const response = await api.client.getAvgDataVendors(id, selectedYear);
            setYearlyData(response.data.data);
        };
        getData();
    }, [id, selectedYear]);

    useEffect(() => {
        const fetchVendors = async () => {
            const response = await api.client.getAllVendors(id);
            setVendorList(response.data.data.vendorProfile);
        };
        fetchVendors();
    }, []);

    const handleYearChange = (e) => {
        setSelectedYear(e.target.value);
    };

    const handleQuarterChange = (e) => {
        setSelectedQuarter(e.target.value);
    };

    // Data for Doughnut Chart
    
    const overallAccepted = yearlyData ? parseFloat(yearlyData.overallYearlyAcceptedAvg) : 0;
    const overallRejected = yearlyData ? parseFloat(yearlyData.overallYearlyRejectedAvg) : 0;
    const overallUnderReview = yearlyData ? parseFloat(yearlyData.overallyearlyUnderReviewAvg) : 0;
    const total = overallAccepted + overallRejected + overallUnderReview;
   

const remainingPercentage = 100 - (parseFloat(overallAccepted) + parseFloat(overallRejected) + parseFloat(overallUnderReview));

    const doughnutData = {
        labels: ['Accepted', 'Rejected','UnderReview','Action needed'],
        datasets: [
            {
                data: [overallAccepted, overallRejected,overallUnderReview,remainingPercentage],
                backgroundColor: ['#50c878', '#B22222','#EFCC00','lightgray'],  // Green for accepted, red for rejected
                borderWidth: 0,
                
            },
        ],
    };
    const chunkData = (data, chunkSize) => {
        let result = [];
        for (let i = 0; i < data.length; i += chunkSize) {
            result.push(data.slice(i, i + chunkSize));
        }
        return result;
    };

    // Data for Bar Chart
    let vendorLabels = [];
    let combinedData = [];
  
    if (yearlyData && yearlyData.vendorAverages) {
        for (let i = 0; i < yearlyData.vendorAverages.length; i++) {
            const vendor = yearlyData.vendorAverages[i];
            const vendorName = vendorList.find(v => v.vendorId === vendor.vendorId)?.vendorCompanyName || '';
            
            vendorLabels.push(vendorName.slice(0, 5)); // Vendor names for X-axis
            // Combine the accepted and rejected data into one (you can sum or average them)
            const combinedValue = parseFloat(vendor.yearlyAcceptedAverage) + parseFloat(vendor.yearlyRejectedAverage);
            combinedData.push(combinedValue);
        }
    }
    const chunkedVendorLabels = chunkData(vendorLabels, 22); // Chunk the labels
    const chunkedCombinedData = chunkData(combinedData, 22);

  
    
    const getQuarterOrHalfData = () => {
        if (yearlyData && yearlyData.vendorAverages) {
            return yearlyData.vendorAverages.map(vendor => {
                switch (selectedQuarter) {
                    case 'Q1':
                        return vendor.Q1AcceptedAvg;
                    case 'Q2':
                        return vendor.Q2AcceptedAvg;
                    case 'Q3':
                        return vendor.Q3AcceptedAvg;
                    case 'Q4':
                        return vendor.Q4AcceptedAvg;
                    // case 'H1':
                    //     return vendor.H1AcceptedAvg;
                    // case 'H2':
                    //     return vendor.H2AcceptedAvg;
                        case 'Yearly':
                        return vendor.yearlyAcceptedAverage;
                    default:
                        return 0;
                }
            });
        }
        return [];
    };

    const getQuarterOrHalfDataRejected = () => {
        if (yearlyData && yearlyData.vendorAverages) {
            return yearlyData.vendorAverages.map(vendor => {
                switch (selectedQuarter) {
                    case 'Q1':
                        return vendor.Q1RejectedAvg;
                    case 'Q2':
                        return vendor.Q2RejectedAvg;
                    case 'Q3':
                        return vendor.Q3RejectedAvg;
                    case 'Q4':
                        return vendor.Q4RejectedAvg;
                    // case 'H1':
                    //     return vendor.H1RejectedAvg;
                    // case 'H2':
                    //     return vendor.H2RejectedAvg;
                        case 'Yearly':
                        return vendor.yearlyRejectedAverage;
                    default:
                        return 0;
                }
            });
        }
        return [];
    };

    const acceptedQuarterData = getQuarterOrHalfData();
const rejectedQuarterData = getQuarterOrHalfDataRejected();

    return (
        <div className="bg-light" style={{borderRight:'1px solid lightgray',borderBottom:'1px solid lightgray'}}>
           
                <div className="p-3 bg-dark d-flex justify-content-between">
                    <h6 className="mx-2 text-light" >Vendor's Average Yearly Compliance</h6>
                    <select value={selectedYear} onChange={handleYearChange} className=" mx-2 year-dropdown text-dark bg-light">
                    {Array.from({ length: currentYear - 2020 + 1 }, (_, i) => 2020 + i).map((year) => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                    </select>
                   
                </div>
         

                {yearlyData && (
    <section className="d-flex flex-column align-items-center">
     
        <div style={{ height: '200px', width: '200px' }}>
           <h1 className="text-dark" style={{position:'relative',top:'110px',left:'78px',fontSize:'18px'}}>{(overallAccepted / 100 * 100).toFixed(2)+'%'}</h1>
            <Doughnut
                data={doughnutData}
                options={{
                    responsive: true,
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            callbacks: {
                                label: function (context) {
                                    let percentage;
                                    if (context.label === 'Accepted') {
                                        percentage = overallAccepted;
                                        return `Accepted: ${percentage}%`;
                                    } else if (context.label === 'Rejected') {
                                        percentage = overallRejected;
                                        return `Rejected: ${percentage}%`;
                                    } else if (context.label === 'UnderReview') {
                                        percentage = overallUnderReview;
                                        return `UnderReview: ${percentage}%`;
                                    }
                                    else if (context.label === 'Action needed') {
                                        percentage = remainingPercentage;
                                        return `Action needed: ${percentage.toFixed(2)}%`;
                                    }
                                },
                            },
                        },
                        datalabels: {
                            display: false, // Enable data labels (percentages inside bars)
                            color: 'gray', // White color for the percentage labels
                            align: 'center', // Align the label inside the bar
                            anchor: 'center', // Anchor the label inside the bar
                            font: {
                                size: 12, // Font size for the label
                               // Make the text bold
                            },
                        },
                    },
                }}
            />
        </div>
      
        

        <div className="mt-3 d-flex justify-content-between w-100">
            {/* The Doughnut Chart will be centered, and the Quarter select will be aligned to the right */}
            <div> </div> {/* Empty div to push the select dropdown to the right */}
            
            <select 
                value={selectedQuarter} 
                onChange={handleQuarterChange} 
                className="mx-2 quarter-dropdown" 
                style={{color:"black",border: '1px solid lightgray' ,borderRadius:'4px'}}
            >
                {['Yearly', 'Q1', 'Q2', 'Q3', 'Q4'].map((quarter) => (
                    <option key={quarter} value={quarter}>
                        {quarter}
                    </option>
                ))}
            </select>
        </div>

       {chunkedVendorLabels.map((chunk, index) => {
            return (
        <Bar
    className="w-100"
    key={index}
    
    data={{
        labels: chunk, // Vendor names for the X-axis
        datasets: [
            {
                label: 'Accepted',
                data: acceptedQuarterData,  // Data for "Accepted"
                backgroundColor: '#3CB371', // Green color for "Accepted"
                borderColor: '#50c878',
                borderRadius: 0, // No border radius
                borderWidth: 0,
                barThickness: 23, // Set bar thickness for accepted bars
                stack: 'stack1', // Stack this dataset with the next dataset (Rejected)
            },
            {
                label: 'Rejected',
                data: rejectedQuarterData,  // Data for "Rejected"
                backgroundColor: '#B22222', // Red color for "Rejected"
                borderColor: '#B22222',
                borderRadius: 0, // No border radius
                borderWidth: 0,
                barThickness: 23, // Set bar thickness for rejected bars
                stack: 'stack1', // Stack this dataset with the previous dataset (Accepted)
            },
        ],
    }}
    options={{
        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false, // Hide grid lines for X-axis
                },
                ticks: {
                    display: true,
                    color: 'gray',
                    font: {
                        size: 10,
                    },
                    maxRotation: 45,
                    minRotation: 30,
                },
            },
            y: {
                min: 0, // Start the Y-axis from 0%
                grid: {
                    display: false, // Show grid lines for Y-axis
                },
                ticks: {
                    display: false, // Show ticks on Y-axis
                    stepSize: 10, // Control the step size for ticks
                    callback: function(value) {
                        return value + '%'; // Format Y-axis ticks as percentage
                    },
                },
            },
        },
        plugins: {
            legend: {
                display: false, // Disable the legend
            },
            title: {
                display: true,
            },
            tooltip: {
                callbacks: {
                    label: function(tooltipItem) {
                        const vendor = yearlyData.vendorAverages[tooltipItem.dataIndex];
                        const fullVendorName = vendorList.find(v => v.vendorId === vendor.vendorId)?.vendorCompanyName || '';
                        const acceptedValue = acceptedQuarterData[tooltipItem.dataIndex]; // Correct data access for Accepted
                        const rejectedValue = rejectedQuarterData[tooltipItem.dataIndex]; // Correct data access for Rejected

                        if (tooltipItem.datasetIndex === 0) {
                            // Dataset 0 represents Accepted
                            return `${fullVendorName}: Accepted ${acceptedValue}%`;
                        } else {
                            // Dataset 1 represents Rejected
                            return `${fullVendorName}: Rejected ${rejectedValue}%`;
                        }
                    }
                },
            },
            datalabels: {
                display: true, // Enable data labels
                color: 'white', // Text color for the percentage labels
                align: 'center', // Align the label at the center of the bar
                font: {
                    size: 10, // Font size for the percentage labels
                },
                rotation: -50,
                formatter: function (value, context) {
                    let roundedValue;
                    if (context.datasetIndex === 0) {
                        // For Accepted bars, show the accepted percentage
                        const acceptedValue = acceptedQuarterData[context.dataIndex];
                        roundedValue = Math.round(acceptedValue);
                        return `${roundedValue}%`; // Show percentage for the "Accepted" bar
                    } else {
                        // For Rejected bars, show the rejected percentage
                        const rejectedValue = rejectedQuarterData[context.dataIndex];
                        roundedValue = Math.round(rejectedValue);
                        if(roundedValue!=0) {// Show percentage for the "Rejected" bar
                        return `${roundedValue}%`
                    }
                        return ''; // Show percentage for the "Rejected" bar
                    }
                },
            },
        },
    }}
/>
            )})}




    </section>
)}


        </div>
    );
};

export default YearlyMonthlydata;
