import React, { useState, useEffect } from 'react';
import './EditableCard.css';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { COLORS } from './colors';
import api from '../Services/api';
import { Card, CardContent, Typography, TextField, Select, MenuItem, Button, Divider } from '@mui/material';

import 'font-awesome/css/font-awesome.min.css';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';



const titles = [
  "Date of Commencement",
  "Date of Completion",
  "PO Copy Number",
  "EPFO Allotment copy",
  "ESIC allotment copy",
  "P Tax RC Number",
  "PT EC Number",
  "LWF Number",
  "Factory Licence",
  "Building plan layout",
  "Stability Certificate",
  "Consent to Establishment",
  "Consent to Operations",
  "Fire Advisory",
  "Fire NOC",
  "Environment Clearance",
  "Medical/Hospital - Certificate",
  "Apprenticeship Registration",
  "NEEM/NAPS - Registration",
  "CLRA RC",
  "S&CE RC",
  "CLRA Licence",
  "BOCW RC",
  "ISMW RC",
  "ISMW Licence",
  "PSARA Licence",
  "FSSAI Licence",
  "WC Policy Number",
  "HR Policy Undertaking Ack",
];

const Table = () => {
  let data = sessionStorage.getItem('user');
  let assessorData = JSON.parse(data);
  let id = assessorData.id;
  const [vendorData, setVendorData] = useState(null);
  const[loading,setLoading] = useState(true);
  const [vendors, setVendors] = useState(false);
  const [userType, setUserType] = useState('');
  const [clientOptions, setClientOptions] = useState([]);
  const [clients, setClients] = useState(false);
  const[vendorsDetails,setVendorsDetails] = useState([]);
  const [completedCheckboxes, setCompletedCheckboxes] = useState(new Array(titles.length).fill(false));
  const [rejectedCheckboxes, setRejectedCheckboxes] = useState(new Array(titles.length).fill(false));
  const [remarks, setRemarks] = useState(new Array(titles.length).fill(''));
  const [vendorRemark, setVendorRemark] = useState(new Array(titles.length).fill(''));
  const [savedStatus, setSavedStatus] = useState(new Array(titles.length).fill(false));
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [snackbarErrorMessage, setSnackbarErrorMessage] = useState('');

  const [assessorOptions, setAssessorOptions] = useState([]);
  const [vendorOptions, setVendorOptions] = useState([]);
  const[selectedVendorDetails,setSelectedVendorDetails] = useState('');
  const [clientFormData, setClientFormData] = useState({
    clientName: '',
    companyId: '',
    principleEmployer: '',
    companyAddress: '',
    placeName: '',
    clientContactPersonName: '',
    clientContactPersonEmailId: '',
    clientContactPersonPhoneNumber: '',
    stateId: ''
  });
  const [assessorFormData, setAssessorFormData] = useState({
    assessorCompanyName: '',
    assessorId: '',
  });
  const [vendorFormData, setVendorFormData] = useState({
    vendorCompanyName: '',
    vendorId: '',

  });


  useEffect(() => {
    const data = sessionStorage.getItem('user');
    let name = JSON.parse(data);
    let type = name.type;
    setUserType(type);
    let id = name.id;
    if (type === 'assessor') {
        fetchVendor(id);
    }
    else if (type === 'admin') {
        fetchClient();
    }
}, []);

useEffect(() => {
  if (userType === 'admin') {
      fetchClient();  // This will run every time userType is 'admin'
  }
}, [userType]);


  useEffect(() => {
    setRemarks(new Array(titles.length).fill(''));
  }, [titles.length]);
  

  const fetchVendor = async (id) => {
    try {
      const response = await api.vendor.getVendors(id);
      const vendorData = response.data.data;
      setVendorsDetails(vendorData);
      setLoading(false);
    

      if (vendorData.length > 0) {
        setVendors(true);
      } else {
        setVendors(false);
      }
    } catch (error) {
      console.error("Vendor ID could not be fetched:", error);
     
    }
  };
  const fetchClient = async () => {

 api.client.getClients().then(response => {
     const clientData = response.data;
     setClientOptions(clientData);
      setLoading(false);
      if (clientData.length > 0) {
        setClients(true);
      } else {
        setClients(false);
      }
    })
      .catch(error => {
        console.error('Error fetching company names:', error);
      });
  };

  const handleVendorChange = async (event) => {
    const vendorId = event.target.value;
    
    fetchVendorData(vendorId);
    fetchVendorDetails(vendorId);
    const selectedVendorId = sessionStorage.setItem('selectedVendorId',vendorId);
}

  const fetchVendorData = async (vendorId) => {
   
    try {
      const response = await api.masterData.getVendorMasterData(vendorId);
      if (response.status === 200) {
        setVendorData(response.data.data);

        const newCompletedCheckboxes = [];
        const newRejectedCheckboxes = [];
        const newRemarks = [];
        const newVendorRemarks = [];
        const newSavedStatus = [...savedStatus];
        for (let i = 0; i < titles.length; i++) {

          const rowData = response.data.data[Object.keys(response.data.data)[i]];

          if (rowData) {
            if (rowData.validation === 2) {
              newCompletedCheckboxes[i] = true;
              newRejectedCheckboxes[i] = false;
              newRemarks[i] = rowData.remark;
              newVendorRemarks[i] = rowData.vendorRemark;
              newSavedStatus[i] = true;
              setSavedStatus(newSavedStatus);

            } else if (rowData.validation === 3) {
              newCompletedCheckboxes[i] = false;
              newRejectedCheckboxes[i] = true;
              newRemarks[i] = rowData.remark;
              newVendorRemarks[i] = rowData.vendorRemark;
              newSavedStatus[i] = true;
              setSavedStatus(newSavedStatus);

            } else if (rowData.validation === 1) {
              newCompletedCheckboxes[i] = false;
              newRejectedCheckboxes[i] = false;
              newVendorRemarks[i] = rowData.vendorRemark;
              newRemarks[i] = " ";

            }
            else {
              newCompletedCheckboxes[i] = false;
              newRejectedCheckboxes[i] = false;
              newRemarks[i] = " ";
              newVendorRemarks[i] = "";
            }

          }

        }

        setCompletedCheckboxes(newCompletedCheckboxes);
        setRejectedCheckboxes(newRejectedCheckboxes);
        setRemarks(newRemarks);
        setVendorRemark(newVendorRemarks);
      }
      else if (response.status === 404) {
      
        setErrorSnackbarOpen(true);
        setSnackbarErrorMessage("MasterData not found for this vendor");
        setVendorData(null);
        setSavedStatus(false);
        setCompletedCheckboxes([]);
        setRejectedCheckboxes([]);
        setRemarks([]);
        setVendorRemark([]);
      }
      else {
        setErrorSnackbarOpen(true);
        setSnackbarErrorMessage("Error fetching details");
        setVendorData(null);
        setSavedStatus(false);
        setCompletedCheckboxes([]);
        setRejectedCheckboxes([]);
        setRemarks([]);
        setVendorRemark([]);
      }

    } catch (error) {
      console.error("Error!,please try again", error);
   
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'clientName') {

      api.client.getClients().then(response => {
        const selectedClient = response.data.find(client => client.companyId === value);
        if (selectedClient) {
          const { companyId ,clientName } = selectedClient;
          setClientFormData(prevState => ({
            ...prevState,
            clientName: clientName,
            companyId,
          }));

          setAssessorFormData({ assessorCompanyName: "", assessorId: "" });
          setVendorFormData({ vendorCompanyName: "", vendorId: "" });
          setVendorData(null)
          setVendorsDetails([]);
          setSelectedVendorDetails('');
          setCompletedCheckboxes('');
          setRejectedCheckboxes('');
          setRemarks('');
          setVendorRemark('');

          //API TO FETCH ASSESSOR DETAILS OF SELECTED CLIENT
          api.assessor.getassessor(companyId)
            .then((response) => {
              if (response.status === 200) {
                setAssessorOptions(response.data.data);
               
              }
              else if (response.status === 404) {
                setErrorSnackbarOpen(true);
                setSnackbarErrorMessage("No Assessor found under this client");
                setAssessorOptions([]);
                setVendorOptions([]);
                setVendorData(null)
          setSelectedVendorDetails('')
          setCompletedCheckboxes('');
          setRejectedCheckboxes('');
          setRemarks('');
          setVendorRemark('');
              }
              else {
                
                setErrorSnackbarOpen(true);
                setSnackbarErrorMessage("Error fetching assessor details,Please try to select other client");
              }
            })
            .catch(error => {
              console.error('Error fetching assessor details:', error);
              
              
            });
        }
      })
        .catch(error => {
          console.error('Error fetching vendor details:', error);
          
        });
    }

    if (name === 'assessorName') {
      const selectedAssessor = assessorOptions.find(assessor => assessor.assessorCompanyName === value);
      if (selectedAssessor) {
        setAssessorFormData(prevState => ({
          ...prevState,
          assessorCompanyName: value,
          assessorId: selectedAssessor.assessorId,
        }));

        fetchVendor(selectedAssessor.assessorId);
        setVendorData(null)
          setSelectedVendorDetails('')
          setCompletedCheckboxes('');
          setRejectedCheckboxes('');
          setRemarks('');
          setVendorRemark('');
      }
    }

    if (name === 'vendorName') {
      const selectedVendor = vendorOptions.find(vendor => vendor.vendorCompanyName === value);
      if (selectedVendor) {
        setVendorFormData(prevState => ({
          ...prevState,
          vendorCompanyName: value,
          vendorId: selectedVendor.vendorId,
        }));

        fetchVendorData(selectedVendor.vendorId);
        fetchVendorDetails(selectedVendor.vendorId);
        sessionStorage.setItem('selectedVendorId', selectedVendor.vendorId);
      }
    }


    if (name !== 'clientName' && name !== 'assessorName') {
      setClientFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };


const fetchVendorDetails = async (vendorId) => {
 
  try {

    const response = await api.vendor.fetchVendorDetailsById(vendorId);
    setSelectedVendorDetails(response.data.data);
    console.log(response.data.data);
   
  } catch (error) {
    console.error('Error fetching vendor data:', error);
  }
}

  const handleCheckboxChange = (index, column) => {
    setSavedStatus(prevStatus => {
      const newStatus = [...prevStatus];
      newStatus[index] = false;
      return newStatus;
      
      
    });
 

    if (column === 'accepted') {
      const newCompletedCheckboxes = [...completedCheckboxes];
      newCompletedCheckboxes[index] = !newCompletedCheckboxes[index];
      // If completed checkbox is clicked, disable the rejected checkbox
      if (newCompletedCheckboxes[index]) {
        const newRejectedCheckboxes = [...rejectedCheckboxes];
        newRejectedCheckboxes[index] = false;
        setRejectedCheckboxes(newRejectedCheckboxes);
           const newRemarks = [...remarks];
  newRemarks[index] = ''; 
  setRemarks(newRemarks);
      
      }
      setCompletedCheckboxes(newCompletedCheckboxes);
      

    } else if (column === 'rejected') {
      const newRejectedCheckboxes = [...rejectedCheckboxes];
      newRejectedCheckboxes[index] = !newRejectedCheckboxes[index];
      // If rejected checkbox is clicked, disable the completed checkbox
      if (newRejectedCheckboxes[index]) {
        const newCompletedCheckboxes = [...completedCheckboxes];
        newCompletedCheckboxes[index] = false;
        setCompletedCheckboxes(newCompletedCheckboxes);
           const newRemarks = [...remarks];
  newRemarks[index] = ''; 
  setRemarks(newRemarks);
      }
      setRejectedCheckboxes(newRejectedCheckboxes);
     
    }
  };
  const handleRemarkChange = (index, value) => {
    const newRemarks = [...remarks];
    newRemarks[index] = value;
    setRemarks(newRemarks);
    setSavedStatus(prevStatus => {
      const newStatus = [...prevStatus];
      newStatus[index] = false;
      return newStatus;
    });

  };


 
  


  const handleViewDocument = (fileUrl) => {
    window.open(`https://docs.google.com/gview?url=${fileUrl}&embedded=true`, '_blank')
  };

  const handleSave = async (index, title) => {
    const selectedVendorId = sessionStorage.getItem('selectedVendorId');
    if (!vendorData || Object.keys(vendorData).length === 0) {
      // window.alert("Please select a vendor");
      setErrorSnackbarOpen(true);
      setSnackbarErrorMessage('Please select vendor!');
      

      return;
    }

  
    const newSavedStatus = [...savedStatus];
    const masterdata = {
      vendorId: selectedVendorId,
    };

    if (index === 0) {
      masterdata.dateOfCommencement = {
        value: vendorData[Object.keys(vendorData)[index]].value,

        fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
        validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
        remark: remarks[index],
        vendorRemark: vendorRemark[index],
        na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
      };

    }
    if (index === 1) {
      masterdata.dateOfCompletion = {
        value: vendorData[Object.keys(vendorData)[index]].value,

        fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
        validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
        remark: remarks[index],
        vendorRemark: vendorRemark[index],
        na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
      };

    }

    if (index === 2) {
      masterdata.poCopy = {
        value: vendorData[Object.keys(vendorData)[index]].value,

        fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
        validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1),
        validDate: vendorData[Object.keys(vendorData)[index]].validDate, // Assuming 1 for pending
        remark: remarks[index],
        vendorRemark: vendorRemark[index],
        na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
      };
    }
    if (index === 3) {
      masterdata.epfoAllotment = {
        value: vendorData[Object.keys(vendorData)[index]].value,

        fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
        validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
        remark: remarks[index],
        vendorRemark: vendorRemark[index],
        na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
      };
    }
    if (index === 4) {
      masterdata.esicAllotment = {
        value: vendorData[Object.keys(vendorData)[index]].value,

        fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
        validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
        remark: remarks[index],
        vendorRemark: vendorRemark[index],
        na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
      };
    }
    if (index === 5) {
      masterdata.pTaxRc = {
        value: vendorData[Object.keys(vendorData)[index]].value,

        fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
        validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
        remark: remarks[index],
        vendorRemark: vendorRemark[index],
        na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
      };
    }
    if (index === 6) {
      masterdata.ptEc = {
        value: vendorData[Object.keys(vendorData)[index]].value,

        fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
        validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
        remark: remarks[index],
        vendorRemark: vendorRemark[index],
        na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
      };
    }
    if (index === 7) {
      masterdata.lwf = {
        value: vendorData[Object.keys(vendorData)[index]].value,

        fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
        validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
        remark: remarks[index],
        vendorRemark: vendorRemark[index],
        na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
      };
    }
    if (index === 8) {
      masterdata.factoryLicence = {
        value: vendorData[Object.keys(vendorData)[index]].value,
        validDate: vendorData[Object.keys(vendorData)[index]].validDate, 
        fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
        validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
        remark: remarks[index],
        vendorRemark: vendorRemark[index],
        na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
      };
    }
    if (index === 9) {
      masterdata.buildingPlanLayout = {
        value: vendorData[Object.keys(vendorData)[index]].value,

        fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
        validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
        remark: remarks[index],
        vendorRemark: vendorRemark[index],
        na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
      };
    }
    if (index === 10) {
      masterdata.stabilityCertificate = {
        value: vendorData[Object.keys(vendorData)[index]].value,
        validDate: vendorData[Object.keys(vendorData)[index]].validDate, 
        fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
        validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
        remark: remarks[index],
        vendorRemark: vendorRemark[index],
        na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
      };
    }
    if (index === 11) {
      masterdata.concernToEstablishment = {
        value: vendorData[Object.keys(vendorData)[index]].value,

        fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
        validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
        remark: remarks[index],
        vendorRemark: vendorRemark[index],
        na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
      };
    }
    if (index === 12) {
      masterdata.concernToOperations = {
        value: vendorData[Object.keys(vendorData)[index]].value,

        fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
        validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
        remark: remarks[index],
        vendorRemark: vendorRemark[index],
        na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
      };
    }
    if (index === 13) {
      masterdata.fireAdvisory = {
        value: vendorData[Object.keys(vendorData)[index]].value,

        fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
        validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
        remark: remarks[index],
        vendorRemark: vendorRemark[index],
        na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
      };
    }
    if (index === 14) {
      masterdata.fireNoc = {
        value: vendorData[Object.keys(vendorData)[index]].value,

        fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
        validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
        remark: remarks[index],
        vendorRemark: vendorRemark[index],
        na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
      };
    }
    if (index === 15) {
      masterdata.environmentClearance = {
        value: vendorData[Object.keys(vendorData)[index]].value,
        validDate: vendorData[Object.keys(vendorData)[index]].validDate, 
        fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
        validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
        remark: remarks[index],
        vendorRemark: vendorRemark[index],
        na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
      };
    }
    if (index === 16) {
      masterdata.medicalOrHospitalCertificate = {
        value: vendorData[Object.keys(vendorData)[index]].value,

        fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
        validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
        remark: remarks[index],
        vendorRemark: vendorRemark[index],
        na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
      };
    }
    if (index === 17) {
      masterdata.apprenticeshipRegistration = {
        value: vendorData[Object.keys(vendorData)[index]].value,

        fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
        validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
        remark: remarks[index],
        vendorRemark: vendorRemark[index],
        na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
      };
    }
    if (index === 18) {
      masterdata.neemOrNapsRegistration = {
        value: vendorData[Object.keys(vendorData)[index]].value,

        fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
        validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
        remark: remarks[index],
        vendorRemark: vendorRemark[index],
        na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
      };
    }
    if (index === 19) {
      masterdata.clraRc = {
        value: vendorData[Object.keys(vendorData)[index]].value,

        fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
        validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
        remark: remarks[index],
        vendorRemark: vendorRemark[index],
        na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
      };
    }
    if (index === 20) {
      masterdata.sAndCeRc = {
        value: vendorData[Object.keys(vendorData)[index]].value,

        fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
        validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
        remark: remarks[index],
        vendorRemark: vendorRemark[index],
        na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
      };
    }
    if (index === 21) {
      masterdata.clraLicence = {
        value: vendorData[Object.keys(vendorData)[index]].value,
        validDate: vendorData[Object.keys(vendorData)[index]].validDate, 
        noOfWorkers: vendorData[Object.keys(vendorData)[index]].noOfWorkers, 
        fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
        validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
        remark: remarks[index],
        vendorRemark: vendorRemark[index],
        na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
      };
    }
    if (index === 22) {
      masterdata.bocwRc = {
        value: vendorData[Object.keys(vendorData)[index]].value,
        validDate: vendorData[Object.keys(vendorData)[index]].validDate, 
        noOfWorkers: vendorData[Object.keys(vendorData)[index]].noOfWorkers, 
        fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
        validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
        remark: remarks[index],
        vendorRemark: vendorRemark[index],
        na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
      };
    }
    if (index === 23) {
      masterdata.ismwRc = {
        value: vendorData[Object.keys(vendorData)[index]].value,

        fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
        validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
        remark: remarks[index],
        vendorRemark: vendorRemark[index],
        na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
      };
    }
    if (index === 24) {
      masterdata.ismwLicence = {
        value: vendorData[Object.keys(vendorData)[index]].value,
        validDate: vendorData[Object.keys(vendorData)[index]].validDate, 
        fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
        validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
        remark: remarks[index],
        vendorRemark: vendorRemark[index],
        na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
      };
    }
    if (index === 25) {
      masterdata.passaraLicence = {
        value: vendorData[Object.keys(vendorData)[index]].value,
        validDate: vendorData[Object.keys(vendorData)[index]].validDate, 

        fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
        validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
        remark: remarks[index],
        vendorRemark: vendorRemark[index],
        na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
      };
    }

    if (index === 26) {
      masterdata.fssaiLicence = {
        value: vendorData[Object.keys(vendorData)[index]].value,
        validDate: vendorData[Object.keys(vendorData)[index]].validDate, 
        fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,

        validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
        remark: remarks[index],
        vendorRemark: vendorRemark[index],
        na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
      };
    }
    if (index === 27) {
      masterdata.wCPolicyNumber = {
        value: vendorData[Object.keys(vendorData)[index]].value,
        validDate: vendorData[Object.keys(vendorData)[index]].validDate, 
        noOfWorkers: vendorData[Object.keys(vendorData)[index]].noOfWorkers, 
        fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
        validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
        remark: remarks[index],
        vendorRemark: vendorRemark[index],
        na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
      };
    }
    if (index === 28) {
      masterdata.hrPolicyUndertakingAck = {
        value: vendorData[Object.keys(vendorData)[index]].value,
        validDate: vendorData[Object.keys(vendorData)[index]].validDate, 

        fileUrl: vendorData[Object.keys(vendorData)[index]].fileUrl,
        validation: completedCheckboxes[index] ? 2 : (rejectedCheckboxes[index] ? 3 : 1), // Assuming 1 for pending
        remark: remarks[index],
        vendorRemark: vendorRemark[index],
        na: (vendorData[Object.keys(vendorData)[index]].na === false ? false : true)
      };
    }

    try {
      const response = await api.masterData.updateMasterData(masterdata);
      if (response.status === 200) {
    
        newSavedStatus[index] = true;
        setSavedStatus(newSavedStatus);
        setSnackbarOpen(true);
        setSnackbarMessage('updated successfully!');
        
      }
      else if (response.status === 404) {

        setErrorSnackbarOpen(true);
        setSnackbarErrorMessage('There is no masterdata available assosiated with this Id');
      

      }
      else if (response.status === 400) {

        setErrorSnackbarOpen(true);
        setSnackbarErrorMessage('Data not updated, Please try again');
      
      }


    } catch (error) {
      console.error("Error updating status", error);
   
      
    };

  };

  const rows = titles.map((title, index) => {
    let rowClassName = '';
    if (vendorData && vendorData[Object.keys(vendorData)[index]]) {
      const validationStatus = vendorData[Object.keys(vendorData)[index]].validation;
      if (validationStatus === 1) {
        rowClassName = 'pending';
      } else if (validationStatus === 2) {
        rowClassName = 'accepted';
      } else if (validationStatus === 3) {
        rowClassName = 'rejected';
      }

    }
    if (completedCheckboxes[index]) {
      rowClassName = 'accepted';
    } else if (rejectedCheckboxes[index]) {
      rowClassName = 'rejected';
    }
    let filename = '';
    if (
      vendorData &&
      vendorData[Object.keys(vendorData)[index]] &&
      vendorData[Object.keys(vendorData)[index]].fileUrl
    ) {
      const fullUrl = vendorData[Object.keys(vendorData)[index]].fileUrl;
      filename = fullUrl.substring(fullUrl.lastIndexOf('/') + 1);
    }
    return (
      <>

        <tr className={rowClassName} >
          <td>{title}</td>
          <td>
            {vendorData && (
            <div
            style={{
              overflowX: 'auto',
              overflow: 'auto',
              width: '200px',
              height: vendorData[Object.keys(vendorData)[index]]?.validDate && vendorData[Object.keys(vendorData)[index]]?.noOfWorkers ? '100px' : 'auto',
            }}
          >
            <p>
             {vendorData[Object.keys(vendorData)[index]].value}
              <br />
              {vendorData[Object.keys(vendorData)[index]]?.validDate ? `Valid Date: ${vendorData[Object.keys(vendorData)[index]].validDate}`: null}
              <br />
              {vendorData[Object.keys(vendorData)[index]]?.noOfWorkers ? `No. of Workers: ${vendorData[Object.keys(vendorData)[index]].noOfWorkers}` : null}
            </p>
          </div>
          
            )}
          </td>
       
          <td>   {vendorData && (
            <>

              {filename && (
                <div className="d-flex" >
                  <button className='btn btn-sm btn-primary' onClick={() => handleViewDocument(vendorData[Object.keys(vendorData)[index]].fileUrl)}><i class="bi bi-eye"></i> Doc</button>
                  <button className="btn mx-1 btn-sm  btn-primary" onClick={() => window.open(vendorData[Object.keys(vendorData)[index]].fileUrl)}><i class="bi bi-download"></i> Doc</button>
                </div>

              )}
            </>
          )}</td>
          <td>   {vendorData && (
            <>

              <p>{vendorData[Object.keys(vendorData)[index]].na === false ? "Applicable" : "Not Applicable"}</p>
            </>
          )}</td>

          <td>
            <input
              type="checkbox"
              checked={completedCheckboxes[index]}
              onChange={() => handleCheckboxChange(index, 'accepted')}
            />
          </td>
          <td>
            <input
              type="checkbox"
              checked={rejectedCheckboxes[index]}
              onChange={() => handleCheckboxChange(index, 'rejected')}
            />
          </td>
          <td className="remark">
            <textarea
             
              value={remarks[index] || ''}
              placeholder={!remarks[index] ? 'Enter Your Remarks here':''}
              onChange={e => handleRemarkChange(index, e.target.value)}
              rows="2"
            />
              {vendorData && (
            <>
              {vendorData && vendorData[Object.keys(vendorData)[index]].vendorRemark && (
  <p className="p-2" style={{width:'200px',overflow:'auto',height:'100px',border:'1px solid lightgray',color:'red'}}>Vendor Remark: {vendorData[Object.keys(vendorData)[index]].vendorRemark}</p>
)}
            </>
          )}
          </td>

          <td>
            <button type="submit" className="btn btn-md btn-outline-dark" onClick={() => handleSave(index, title)}   disabled={savedStatus[index] === true || (!completedCheckboxes[index] && !rejectedCheckboxes[index]) || (rejectedCheckboxes[index] && !remarks[index]?.trim())}> {savedStatus[index] ? "Saved" : "Save"}</button>
          </td>
        </tr>

      </>
    );
  });

  const renderDropdowns = () => {
    if (userType === 'admin') {
        return (



          <div className="d-flex mt-2 flex-wrap justify-content-end gap-2 w-100">

          <Select value={clientFormData.companyId} onChange={handleChange} name="clientName" displayEmpty 
              style={{ height: "35px", minWidth:'250px',maxWidth:'300px'}}>
              <MenuItem value="" disabled>Select Client</MenuItem>
              {Array.isArray(clientOptions) && clientOptions.map((client, index) => (
                  <MenuItem key={index} value={client.companyId}>
                      {client.clientName}, {client.stateId}
                  </MenuItem>
              ))}
          </Select>

          <Select value={assessorFormData.assessorCompanyName} onChange={handleChange} name="assessorName" displayEmpty 
              style={{ height: "35px",minWidth:'250px',maxWidth:'300px', }}>
              <MenuItem value="" disabled>Select Assessor</MenuItem>
              {Array.isArray(assessorOptions) && assessorOptions.map((assessor) => (
                  <MenuItem key={assessor.assessorId} value={assessor.assessorCompanyName}>
                      {assessor.assessorCompanyName}, {assessor.site}
                  </MenuItem>
              ))}
          </Select>

          <select className="p-1 w-auto mx-3" style={{ borderRadius: "4px", border: "1px solid lightgray",height: "35px",minWidth:'250px',maxWidth:'300px' }} onChange={handleVendorChange}>
            <option value="select">Select Vendor</option>
            {vendorsDetails &&
              vendorsDetails.map((vendor) => (
                <option key={vendor.vendorId} value={vendor.vendorId}>
                  {vendor.vendorCompanyName}, {vendor.vendorStateId}
                </option>
              ))}
          </select>

      </div>

        );
    } else {
        return (

          <div className="d-flex mx-3 flex-wrap justify-content-end">
          <select className="p-1 w-auto" style={{ borderRadius: "4px", border: "1px solid lightgray",height: "35px",minWidth:'250px',maxWidth:'300px' }} onChange={handleVendorChange}>
            <option value="select">Select Vendor</option>
            {vendorsDetails &&
              vendorsDetails.map((vendor) => (
                <option key={vendor.vendorId} value={vendor.vendorId}>
                  {vendor.vendorCompanyName}, {vendor.vendorStateId}
                </option>
              ))}
          </select>
        </div>


        );
    }
};



  return (
    <>
<Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          
          }}
          severity="success"
          open={snackbarOpen}
          autoHideDuration={4000}
          onClose={() => setSnackbarOpen(false)}
          sx={{
            marginTop: '2.9%', 
            marginRight: '7%'
          }}
        >
          <SnackbarContent style={{ backgroundColor: "green" }} message={snackbarMessage} />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          severity="error"
          open={errorSnackbarOpen}
          autoHideDuration={5000}
          sx={{
            marginTop: '2.9%', 
            marginRight: '7%'
          }}
          onClose={() => {setErrorSnackbarOpen(false)}}
        >
          <SnackbarContent style={{ backgroundColor: "red" }} message={snackbarErrorMessage} />
        </Snackbar>
        {loading ? (
  <div>
    <h4 className="text-center mt-5">Loading......</h4>
  </div>
) : (
  (vendors || clients) ? (
    <div className="mt-3">
      <div style={{ letterSpacing: '1px' }}>
        <div
          style={{
            backgroundColor: COLORS.bggray,
            width: '15px',
            height: '15px',
            display: 'inline-block',
            marginRight: '4px',
            marginLeft: '8px',
            borderRadius: '50%',
          }}
        ></div>
        <span style={{ fontSize: '14px' }}>Under review</span>
        <div className="vr mx-3"></div>
        <div
          style={{
            backgroundColor: COLORS.success,
            width: '15px',
            height: '15px',
            display: 'inline-block',
            marginRight: '4px',
            borderRadius: '50%',
          }}
        ></div>
        <span style={{ fontSize: '14px' }}>Accepted</span>
        <div className="vr mx-3"></div>
        <div
          style={{
            backgroundColor: COLORS.danger,
            width: '15px',
            height: '15px',
            display: 'inline-block',
            marginRight: '4px',
            borderRadius: '50%',
          }}
        ></div>
        <span style={{ fontSize: '14px' }}>Rejected</span>
      </div>
      <div>{renderDropdowns()}</div>

      <div style={{ maxHeight: '400px', marginTop: '10px', overflowY: 'auto' }}>
        <table className="mt-0 pt-0" style={{ width: '100%' }}>
          <thead className="position-sticky top-0" style={{ zIndex: 10, backgroundColor: 'inherit' }}>
            <tr className="text-white bg-dark">
              <th>Name of DOC</th>
              <th>Date/Doc No</th>
              <th>Uploaded File</th>
              <th>Not Applicable/Applicable</th>
              <th>Accepted</th>
              <th>Rejected</th>
              <th>Remark</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {selectedVendorDetails && (
              <div>
                <h6 className="px-3 pt-2 pb-2">
                  Nature of Work: <span className="fw-bold">{selectedVendorDetails.natureOfWork}</span>
                </h6>
              </div>
            )}
            {rows}
          </tbody>
        </table>
      </div>
    </div>
  ) : (
    <div className="mt-4">
      {vendors ? (
        <p className="text-center">No Vendors Found !! <Link to="/createVendor">Click here</Link> to Create</p>
      ) : clients ? (
        <p className="text-center">No Clients Found !! <Link to="/createCompany">Click here</Link> to Create</p>
      ) : null  }
    </div>
  )
)}

    </>
  );
};

export default Table;
