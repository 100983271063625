import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { Link, useNavigate,useLocation} from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import { Email, Phone } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import grclogo from '../Images/GRC-Pulse-Logo.png';
import ListIcon from '@mui/icons-material/List';
import { COLORS } from '../Component/colors';
import { SideBar } from './SideBar';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { SlCalender } from 'react-icons/sl';




const pages = ['Admin Dashboard','Vendors Master Data','Vendors Monthly Data','Create'];
const settings = ['Client List','Assessor List'];

export default function AdminAppBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElCreate, setAnchorElCreate] = React.useState(null);
  const [showListText, setShowListText] = React.useState(false);
  const [showLogoutText, setShowLogoutText] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();
  const location = useLocation(); 
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
 
  const handleOpenCreateMenu = (event) => {
    setAnchorElCreate(event.currentTarget); 
  };

  const handleCloseCreateMenu = () => {
    setAnchorElCreate(null); 
  };
  const handleListOfClients = () => {
  navigate("/clientList");
  setAnchorEl(null);
  }

  const handleListOfAssessors = () => {
    navigate("/assessorList");
    setAnchorEl(null);

    }
    
    const handleCreateCompany = () => {
      navigate("/createcompany"); 
      setAnchorElCreate(null); 
    };
  
    const handleCreateAssessor = () => {
      navigate("/createassessor");
      setAnchorElCreate(null);  
    };
  const handleLogout = () => {
    navigate("/signin");
    
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("token");
    console.log("log out clicked");
  };
  const isPageActive = (page) => {
    return location.pathname.includes(page.toLowerCase().replace(/\s+/g, ''));
  };



  return (
    <AppBar  position="sticky" sx={{backgroundColor:'#1C1C1C	'}}>
    <Container maxWidth="xxl">
    
        <Toolbar disableGutters>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/admindashboard"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              textDecoration: 'none',
            }}
          >
            <img
              src={grclogo}
              width="130px"
              alt="Company Logo"
              style={{
                padding: '5px'
              }}
            />
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', fontSize: 12, color: COLORS.ivory }}>
          <Typography sx={{ mr: 1 }}>
<a href="mailto:support@grcpulse.in" style={{ textDecoration: 'none', color: COLORS.ivory }}>
  <Email /> support@grcpulse.in
</a>
</Typography>
            <Typography><Phone /> 080-31634351 <br/> <span style={{ paddingLeft: '20px'}}>+91-8310163968 </span></Typography>
          </Box>
        </Box>
      </Toolbar>
      <Box sx={{ backgroundColor: '#B22222' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}>
          {pages.map((page) => (
              page === "Create" ? (
                <Button
                key={page}
                sx={{
                  m: 1,
                  display: 'flex',
                  justifyContent: 'space-between', 
                  alignItems: 'center',
                  textTransform: 'none',
                  width: 'auto', 
                }}
                onClick={handleOpenCreateMenu}
              >
                <Link className="nav-link" style={{textDecoration: 'none'}}>
                  <Typography sx={{ fontSize: 15, color: isPageActive(page)? 'white': '#C0C0C0' }}>{page}</Typography>
                </Link>
                <Button
                  sx={{
                    minWidth: 'auto',
                    padding: 0, 
                    color: '#C0C0C0',
                  }}
                  onClick={handleOpenCreateMenu}
                >
                  <ArrowDropDownIcon />
                </Button>
              </Button>
              ) : (
                <Button
                  key={page}
                  sx={{
                    m: 1,
                    display: 'block',
                    textTransform: 'none',
                  }}
                >
              <Link className="nav-link" to={`/${page.replace(/\s+/g,'').toLowerCase()}`} style={{ color: COLORS.ivory, textDecoration: 'none' }}>
             <Typography sx={{ fontSize:15,color: isPageActive(page)? 'white': '#C0C0C0'}}>{page}</Typography>
              </Link>
            </Button>
          )
          ))}
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          
        <Link to="/complience-calender">
             <SlCalender size="1.6rem" color='#ffffff' />
        </Link>
       < Tooltip title={showListText ? 'List' : ''}>
      <IconButton
        onClick={handleOpenMenu} 
        onMouseEnter={() => setShowListText(true)}
        onMouseLeave={() => setShowListText(false)}
        sx={{ p: 1 }}
      >
        <ListIcon sx={{ fontSize: '35px', color: COLORS.ivory }} />
      </IconButton>
      </Tooltip>
    
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleCloseMenu}
      
    >
      {settings.map((setting) => (
        <MenuItem
          key={setting}
          onClick={() => {
            if (setting === 'Client List') {
              handleListOfClients(); 
            } else if (setting === 'Assessor List') {
              handleListOfAssessors(); 
            }
          }}
        >
          <Typography textAlign="center">{setting}</Typography>
        </MenuItem>
      ))}
    </Menu>
    <Menu
                anchorEl={anchorElCreate}
                open={Boolean(anchorElCreate)}
                onClose={handleCloseCreateMenu}
              >
                <MenuItem onClick={handleCreateCompany}>
                  <Typography textAlign="center">Create Company</Typography>
                </MenuItem>
                <MenuItem onClick={handleCreateAssessor}>
                  <Typography textAlign="center">Create Assessor</Typography>
                </MenuItem>
              </Menu>


              <Tooltip title={showLogoutText ? 'Log Out' : ''}>
                <IconButton
                  onClick={handleLogout}
                  onMouseEnter={() => setShowLogoutText(true)}
                  onMouseLeave={() => setShowLogoutText(false)}
                  sx={{ p: 1 }}
                >
                  <LogoutIcon sx={{ fontSize: '30px', color: COLORS.ivory }} />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </Container>
    </AppBar>

  );
}
