export const CALENDAR_DATA = [
  {
    "id": 1,
    "Month": "January",
    "State": "Kerala",
    "Act": "Kerala Shops and Commercial Establishments Workers Welfare Fund Act, 2006",
    "Type": "Remittance",
    "Compliance": "Monthly Contribution",
    "dueDate": "5-Jan",
    "form": "Form 6",
    "remarks": null
  },
  {
    "id": 2,
    "Month": "January",
    "State": "Andhra Pradesh",
    "Act": "Andhra Pradesh Tax On Professions, Trades, Callings And Employments Act, 1987",
    "Type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "10-Jan",
    "form": "Form V",
    "remarks": null
  },
  {
    "id": 3,
    "Month": "January",
    "State": "Telangana",
    "Act": "Telangana Tax On Professions, Trades, Callings And Employments Act, 1987",
    "Type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "10-Jan",
    "form": "Form V",
    "remarks": null
  },
  {
    "id": 4,
    "Month": "January",
    "State": "Madhya Pradesh",
    "Act": "Madhya Pradesh Vritti Kar Adhiniyam, 1995 (PT)",
    "Type": "Remittance",
    "Compliance": "Monthly Remittance",
    "dueDate": "10-Jan",
    "form": null,
    "remarks": null
  },
  {
    "id": 5,
    "Month": "January",
    "State": "Kerala",
    "Act": "Kerala Shops and Commercial Establishments Act, 1960",
    "Type": "Return",
    "Compliance": "Quarterly Returns",
    "dueDate": "10-Jan",
    "form": "Form -H",
    "remarks": "10 or More Employees"
  },
  {
    "id": 6,
    "Month": "January",
    "State": "Goa",
    "Act": "Goa Shops And Establishments Act, 1973",
    "Type": "Return",
    "Compliance": "Quarterly Returns",
    "dueDate": "10-Jan",
    "form": "Form XXIX",
    "remarks": "5 or more employees"
  },
  {
    "id": 7,
    "Month": "January",
    "State": "Tamil Nadu",
    "Act": "Tamil Nadu Industrial Establishments (Conferment of Permanent Status to Workmen) Act, 1981",
    "Type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "14-Jan",
    "form": "Form 2 along with Form 1",
    "remarks": "Not required if Combined Annual Return Form 22 submitted in Factories Act; Applicable to S&E."
  },
  {
    "id": 8,
    "Month": "January",
    "State": "Bihar",
    "Act": "Factories Act, 1948",
    "Type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "15-Jan",
    "form": "Form No. 20",
    "remarks": null
  },
  {
    "id": 9,
    "Month": "January",
    "State": "All States",
    "Act": "Employee State Insurance Act, 1948",
    "Type": "Remittance",
    "Compliance": "Monthly Contribution Challan",
    "dueDate": "15-Jan",
    "form": "Challan",
    "remarks": null
  },
  {
    "id": 10,
    "Month": "January",
    "State": "All States",
    "Act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "Type": "Remittance",
    "Compliance": "Monthly Contribution Challan",
    "dueDate": "15-Jan",
    "form": "Challan",
    "remarks": null
  },
  {
    "id": 11,
    "Month": "January",
    "State": "All States",
    "Act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "Type": "Return",
    "Compliance": "International Workers Return",
    "dueDate": "15-Jan",
    "form": "IW-1",
    "remarks": null
  },
  {
    "id": 12,
    "Month": "January",
    "State": "Central",
    "Act": "Apprentices Act, 1961",
    "Type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "15-Jan",
    "form": null,
    "remarks": null
  },
  {
    "id": 13,
    "Month": "January",
    "State": "Chandigarh",
    "Act": "Factories Act, 1948",
    "Type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "15-Jan",
    "form": null,
    "remarks": null
  },
  {
    "id": 14,
    "Month": "January",
    "State": "Chhattisgarh",
    "Act": "Factories Act, 1948",
    "Type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "15-Jan",
    "form": "Form No. 27",
    "remarks": null
  },
  {
    "id": 15,
    "Month": "January",
    "State": "Delhi",
    "Act": "Factories Act, 1948",
    "Type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "15-Jan",
    "form": "Form 21",
    "remarks": null
  },
  {
    "id": 16,
    "Month": "January",
    "State": "Delhi",
    "Act": "Factories Act, 1948",
    "Type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "15-Jan",
    "form": "Form 22",
    "remarks": null
  },
  {
    "id": 17,
    "Month": "January",
    "State": "Delhi",
    "Act": "Bombay Labour Welfare Fund Act, 1953 (Delhi)",
    "Type": "Remittance",
    "Compliance": "Half Yearly Remittance & Return",
    "dueDate": "15-Jan",
    "form": "Form A",
    "remarks": "31st Jan"
  },
  {
    "id": 18,
    "Month": "January",
    "State": "Gujarat",
    "Act": "Gujarat Panchayats, Municipal Corporations And State Tax on Professions, Traders, Callings and Employments Act, 1976",
    "Type": "Return",
    "Compliance": "Monthly Returns",
    "dueDate": "15-Jan",
    "form": "Form 5",
    "remarks": null
  },
  {
    "id": 19,
    "Month": "January",
    "State": "Gujarat",
    "Act": "Factories Act, 1948",
    "Type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "15-Jan",
    "form": "Form No. 25",
    "remarks": null
  },
  {
    "id": 20,
    "Month": "January",
    "State": "Jharkhand",
    "Act": "Factories Act, 1948",
    "Type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "15-Jan",
    "form": "Form No. 20",
    "remarks": null
  },
  {
    "id": 21,
    "Month": "January",
    "State": "Karnataka",
    "Act": "Karnataka Labour Welfare Fund Act, 1965",
    "Type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "15-Jan",
    "form": "Form D",
    "remarks": null
  },
  {
    "id": 22,
    "Month": "January",
    "State": "Kerala",
    "Act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "Type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "15-Jan",
    "form": "Form XXV",
    "remarks": null
  },
  {
    "id": 23,
    "Month": "January",
    "State": "Maharashtra",
    "Act": "Maternity Benefit Act, 1961",
    "Type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "15-Jan",
    "form": "Form 11",
    "remarks": "Not required if submission of combined return under Various Acts"
  },
  {
    "id": 24,
    "Month": "January",
    "State": "Maharashtra",
    "Act": "Maharashtra Labour Welfare Fund Act, 1953",
    "Type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "15-Jan",
    "form": "Form A-1",
    "remarks": null
  },
  {
    "id": 25,
    "Month": "January",
    "State": "Tamil Nadu",
    "Act": "Tamil Nadu Payment of Subsistence Allowance Act, 1981",
    "Type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "15-Jan",
    "form": "Form 2",
    "remarks": null
  },
  {
    "id": 26,
    "Month": "January",
    "State": "Tamil Nadu",
    "Act": "Minimum Wages Act, 1948",
    "Type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "15-Jan",
    "form": "Form III",
    "remarks": "Not required if Combined Annual Return Form 22 submitted in Factories Act or Combined annual return Form XIII submitted in TN Catering Establishments or Combined annual return Form XXV under BOCW"
  },
  {
    "id": 27,
    "Month": "January",
    "State": "Uttar Pradesh",
    "Act": "Factories Act, 1948",
    "Type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "15-Jan",
    "form": "Form No. 21",
    "remarks": null
  },
  {
    "id": 28,
    "Month": "January",
    "State": "Uttar Pradesh",
    "Act": "Minimum Wages Act, 1948",
    "Type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "15-Jan",
    "form": "Form III",
    "remarks": "Due date not clarified under the state Rules - Considered common date"
  },
  {
    "id": 29,
    "Month": "January",
    "State": "West Bengal",
    "Act": "Factories Act, 1948",
    "Type": "Return",
    "Compliance": "Quarterly Return",
    "dueDate": "15-Jan",
    "form": "Form 23A",
    "remarks": null
  },
  {
    "id": 30,
    "Month": "January",
    "State": "Assam",
    "Act": "Factories Act, 1948",
    "Type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "15-Jan",
    "form": "Form 21",
    "remarks": null
  },
  {
    "id": 31,
    "Month": "January",
    "State": "Assam",
    "Act": "Factories Act, 1948",
    "Type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "15-Jan",
    "form": "Form 22",
    "remarks": null
  },
  {
    "id": 32,
    "Month": "January",
    "State": "Madhya Pradesh",
    "Act": "Madhya Pradesh Vritti Kar Adhiniyam, 1995 (PT)",
    "Type": "Return",
    "Compliance": "Quarterly Return",
    "dueDate": "15-Jan",
    "form": "Form 7",
    "remarks": null
  },
  {
    "id": 33,
    "Month": "January",
    "State": "Kerala",
    "Act": "Kerala Labour Welfare Fund Act, 1975",
    "Type": "Remittance and Return",
    "Compliance": "Half Yearly",
    "dueDate": "15-Jan",
    "form": "Form A",
    "remarks": null
  },
  {
    "id": 34,
    "Month": "January",
    "State": "West Bengal",
    "Act": "West Bengal Labour Welfare Fund Act, 1974",
    "Type": "Remittance and Return",
    "Compliance": "Half Yearly",
    "dueDate": "15-Jan",
    "form": "Form D",
    "remarks": null
  },
  {
    "id": 35,
    "Month": "January",
    "State": "Chhattisgarh",
    "Act": "Factories Act, 1948",
    "Type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "15-Jan",
    "form": "Form No. 28",
    "remarks": null
  },
  {
    "id": 36,
    "Month": "January",
    "State": "Madhya Pradesh",
    "Act": "Factories Act, 1948",
    "Type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "15-Jan",
    "form": "Form No. 27",
    "remarks": null
  },
  {
    "id": 37,
    "Month": "January",
    "State": "Madhya Pradesh",
    "Act": "Factories Act, 1948",
    "Type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "15-Jan",
    "form": "Form No. 28",
    "remarks": null
  },
  {
    "id": 38,
    "Month": "January",
    "State": "Uttarakhand",
    "Act": "Factories Act, 1948",
    "Type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "15-Jan",
    "form": "Form No. 21",
    "remarks": null
  },
  {
    "id": 39,
    "Month": "January",
    "State": "Himachal Pradesh",
    "Act": "Factories Act, 1948",
    "Type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "15-Jan",
    "form": "Form No. 21",
    "remarks": null
  },
  {
    "id": 40,
    "Month": "January",
    "State": "Himachal Pradesh",
    "Act": "Factories Act, 1948",
    "Type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "15-Jan",
    "form": "Form No. 22",
    "remarks": null
  },
  {
    "id": 41,
    "Month": "January",
    "State": "Mizoram",
    "Act": "Factories Act, 1948",
    "Type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "15-Jan",
    "form": "Form No. 30",
    "remarks": null
  },
  {
    "id": 42,
    "Month": "January",
    "State": "Mizoram",
    "Act": "Factories Act, 1948",
    "Type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "15-Jan",
    "form": "Form No. 31",
    "remarks": null
  },
  {
    "id": 43,
    "Month": "January",
    "State": "Tamil Nadu",
    "Act": "Tamil Nadu Labour Welfare Fund Act, 1972",
    "Type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Jan",
    "form": "Statement",
    "remarks": "Unpaid Accumulation - After 3 years from become payable"
  },
  {
    "id": 44,
    "Month": "January",
    "State": "Kerala",
    "Act": "Kerala Labour Welfare Fund Act, 1975",
    "Type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Jan",
    "form": "Statement",
    "remarks": "Unpaid Accumulation - After 3 years from become payable"
  },
  {
    "id": 45,
    "Month": "January",
    "State": "Karnataka",
    "Act": "Karnataka Labour Welfare Fund Act, 1965",
    "Type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Jan",
    "form": "Statement",
    "remarks": "Unpaid Accumulation - After 3 years from become payable"
  },
  {
    "id": 46,
    "Month": "January",
    "State": "Telangana",
    "Act": "Telangana Labour Welfare Fund Act, 1987",
    "Type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Jan",
    "form": "Statement",
    "remarks": "Unpaid Accumulation - After 3 years from become payable"
  },
  {
    "id": 47,
    "Month": "January",
    "State": "Gujarat",
    "Act": "Gujarat Labour Welfare Fund Act, 1953",
    "Type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Jan",
    "form": "Form B & Form C",
    "remarks": "Unpaid Accumulation - After 1 years from become payable"
  },
  {
    "id": 48,
    "Month": "January",
    "State": "West Bengal",
    "Act": "West Bengal Labour Welfare Fund Act, 1974",
    "Type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Jan",
    "form": "Statement",
    "remarks": "Unpaid Accumulation - After 3 years from become payable"
  },
  {
    "id": 49,
    "Month": "January",
    "State": "Maharashtra",
    "Act": "Maharashtra Labour Welfare Fund Act, 1953",
    "Type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Jan",
    "form": "Statement",
    "remarks": "Unpaid Accumulation - After 3 years from become payable"
  },
  {
    "id": 50,
    "Month": "January",
    "State": "Andhra Pradesh",
    "Act": "Andhra Pradesh Labour Welfare Fund Act, 1987",
    "Type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Jan",
    "form": "Statement",
    "remarks": "Unpaid Accumulation - After 3 years from become payable"
  },
  {
    "id": 51,
    "month": "January",
    "state": "Madhya Pradesh",
    "act": "The Madhya Pradesh Shram Kalyan Nidhi Adhiniyam, 1982 (LWF)",
    "type": "Remittance and Return",
    "Compliance": "Half Yearly Remittance & Return",
    "dueDate": "15-Jan",
    "form": "Form A"
  },
  {
    "id": 52,
    "month": "January",
    "state": "Goa",
    "act": "Goa Labour Welfare Fund Act, 1986",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Jan",
    "form": "Statement",
    "remarks": "Unpaid Accumulation - After 3 years from become payable"
  },
  {
    "id": 53,
    "month": "January",
    "state": "Chhattisgarh",
    "act": "Chhattisgarh Shram Kalyan Nidhi Adhiniyam, 1982 (LWF)",
    "type": "Remittance and Return",
    "Compliance": "Half Yearly Remittance & Return",
    "dueDate": "15-Jan",
    "form": "Form A"
  },
  {
    "id": 54,
    "month": "January",
    "state": "Chhattisgarh",
    "act": "Chhattisgarh Shram Kalyan Nidhi Adhiniyam, 1982 (LWF)",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Jan",
    "form": "Statement",
    "remarks": "Unpaid Accumulation - After 3 years from become payable"
  },
  {
    "id": 55,
    "month": "January",
    "state": "Madhya Pradesh",
    "act": "The Madhya Pradesh Shram Kalyan Nidhi Adhiniyam, 1982 (LWF)",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Jan",
    "form": "Statement",
    "remarks": "Unpaid Accumulation - After 3 years from become payable"
  },
  {
    "id": 56,
    "month": "January",
    "state": "Delhi",
    "act": "Bombay Labour Welfare Fund Act, 1953 (Delhi)",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Jan",
    "form": "Statement",
    "remarks": "Unpaid Accumulation - After 3 years from become payable"
  },
  {
    "id": 57,
    "month": "January",
    "state": "Odisha",
    "act": "Odisha Labour Welfare Fund Act, 1996",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Jan",
    "form": "Statement",
    "remarks": "Unpaid Accumulation - After 5 years from become payable"
  },
  {
    "id": 58,
    "month": "January",
    "state": "Odisha",
    "act": "Odisha Labour Welfare Fund Act, 1996",
    "type": "Remittance & Returns",
    "Compliance": "Half Yearly Remittance & Return",
    "dueDate": "15-Jan",
    "form": "Form F"
  },
  {
    "id": 59,
    "month": "January",
    "state": "Sikkim",
    "act": "Sikkim Tax On Professions, Trades, Callings And Employments, Act 2006",
    "type": "Remittance",
    "Compliance": "Monthly",
    "dueDate": "15-Jan"
  },
  {
    "id": 60,
    "month": "January",
    "state": "Karnataka",
    "act": "Karnataka Tax on Professions, Trades, Callings and Employments Act, 1976",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "20-Jan",
    "form": "Form 9-A or Form 5-A"
  },
  {
    "id": 61,
    "month": "January",
    "state": "Arunachal Pradesh",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "21-Jan",
    "form": "Form L, M, N and O"
  },
  {
    "id": 62,
    "month": "January",
    "state": "Assam",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "21-Jan",
    "form": "Form H"
  },
  {
    "id": 63,
    "month": "January",
    "state": "Bihar",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "21-Jan",
    "form": "Form L, M, N and O"
  },
  {
    "id": 64,
    "month": "January",
    "state": "Chhattisgarh",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "21-Jan",
    "form": "Form K, L, M and N"
  },
  {
    "id": 65,
    "month": "January",
    "state": "Delhi",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "21-Jan",
    "form": "Form L, M, N and O"
  },
  {
    "id": 66,
    "month": "January",
    "state": "Goa",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": "Not required - If submission of combined return under Various Acts"
  },
  {
    "id": 67,
    "month": "January",
    "state": "Haryana",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "21-Jan",
    "form": "Form K, L, M and N",
    "remarks": "Not required - If submission of Unified Single Annual Return."
  },
  {
    "id": 68,
    "month": "January",
    "state": "Himachal Pradesh",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": null
  },
  {
    "id": 69,
    "month": "January",
    "state": "Jammu & Kashmir",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "21-Jan",
    "form": "Form L, M, N, O",
    "remarks": null
  },
  {
    "id": 70,
    "month": "January",
    "state": "Jharkhand",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": null
  },
  {
    "id": 71,
    "month": "January",
    "state": "Punjab",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "21-Jan",
    "form": "Form L, M, N, O",
    "remarks": "Not Required - If submitting the Integrated Annual Return"
  },
  {
    "id": 72,
    "month": "January",
    "state": "West Bengal",
    "act": "West Bengal State Tax on Professions, Trades, Callings and Employments Act, 1979",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "21-Jan",
    "form": "Online",
    "remarks": null
  },
  {
    "id": 73,
    "month": "January",
    "state": "West Bengal",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "21-Jan",
    "form": "Form L, M, N",
    "remarks": null
  },
  {
    "id": 74,
    "month": "January",
    "state": "Rajasthan",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": null
  },
  {
    "id": 75,
    "month": "January",
    "state": "Madhya Pradesh",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "21-Jan",
    "form": "Form K, L and M",
    "remarks": null
  },
  {
    "id": 76,
    "month": "January",
    "state": "Tripura",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": null
  },
  {
    "id": 77,
    "month": "January",
    "state": "Andhra Pradesh",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": null
  },
  {
    "id": 78,
    "month": "January",
    "state": "Central",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": null
  },
  {
    "id": 79,
    "month": "January",
    "state": "Chandigarh",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": null
  },
  {
    "id": 80,
    "month": "January",
    "state": "Daman",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": null
  },
  {
    "id": 81,
    "month": "January",
    "state": "Manipur",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": null
  },
  {
    "id": 82,
    "month": "January",
    "state": "Mizoram",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": null
  },
  {
    "id": 83,
    "month": "January",
    "state": "Meghalaya",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": null
  },
  {
    "id": 84,
    "month": "January",
    "state": "Nagaland",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": null
  },
  {
    "id": 85,
    "month": "January",
    "state": "Odisha",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": "Not required - If submission of combined return under Various Acts"
  },
  {
    "id": 86,
    "month": "January",
    "state": "Telangana",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": "Not required - If submission of combined return under Various Acts"
  },
  {
    "id": 87,
    "month": "January",
    "state": "Uttar Pradesh",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": null
  },
  {
    "id": 88,
    "month": "January",
    "state": "Uttarakhand",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": null
  },
  {
    "id": 89,
    "month": "January",
    "state": "Sikkim",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": null
  },
  {
    "id": 90,
    "month": "January",
    "state": "Andaman Nicobar",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": null
  },
  {
    "id": 91,
    "month": "January",
    "state": "Dadra and Nagar Haveli",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": null
  },
  {
    "id": 92,
    "month": "January",
    "state": "Ladakh",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": null
  },
  {
    "id": 93,
    "month": "January",
    "state": "Lakshadweep",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": null
  },
  {
    "id": 94,
    "month": "January",
    "state": "Puducherry",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": null
  },
  {
    "id": 95,
    "month": "January",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "Compliance": "Monthly Return - EDLI Exempted Employer",
    "dueDate": "25-Jan",
    "form": "Form - 7(IF)",
    "remarks": null
  },
  {
    "id": 96,
    "month": "January",
    "state": "All States",
    "act": "Employment Exchange (Compulsory Notification of Vacancies) Act, 1959",
    "type": "Return",
    "Compliance": "Quarterly Return",
    "dueDate": "30-Jan",
    "form": "ER-1",
    "remarks": null
  },
  {
    "id": 97,
    "month": "January",
    "state": "Arunachal Pradesh",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jan",
    "form": "Form XXIV",
    "remarks": null
  },
  {
    "id": 98,
    "month": "January",
    "state": "Assam",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jan",
    "form": "Form XXIV",
    "remarks": null
  },
  {
    "id": 99,
    "month": "January",
    "state": "Bihar",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jan",
    "form": "Form XXIV",
    "remarks": null
  },
  {
    "id": 100,
    "month": "January",
    "state": "Bihar",
    "act": "Bihar Shops and Establishments Act, 1953",
    "type": "Return",
    "Compliance": "Quarterly Return",
    "dueDate": "30-Jan",
    "form": "Form XIX",
    "remarks": "10 or More Employees"
  },
  {
    "id": 101,
    "month": "January",
    "state": "Central",
    "act": "Payment of Wages Act, 1936",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "30-Jan",
    "form": "Form 4",
    "remarks": null
  },
  {
    "id": 102,
    "month": "January",
    "state": "Chandigarh",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jan",
    "form": "Form XXIV",
    "remarks": null
  },
  {
    "id": 103,
    "month": "January",
    "state": "Chhattisgarh",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jan",
    "form": "Form XXIV",
    "remarks": null
  },
  {
    "id": 104,
    "month": "January",
    "state": "Delhi",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Contractor - Half Yearly Returns",
    "dueDate": "30-Jan",
    "form": "Form XXIV",
    "remarks": null
  },
  {
    "id": 105,
    "month": "January",
    "state": "Goa",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jan",
    "form": "Form XX",
    "remarks": null
  },
  {
    "id": 106,
    "month": "January",
    "state": "Gujarat",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Contractor - Half Yearly Returns",
    "dueDate": "30-Jan",
    "form": "Form XXIV",
    "remarks": null
  },
  {
    "id": 107,
    "month": "January",
    "state": "Haryana",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jan",
    "form": "Form 20",
    "remarks": null
  },
  {
    "id": 108,
    "month": "January",
    "state": "Himachal Pradesh",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jan",
    "form": "Form XXIV",
    "remarks": null
  },
  {
    "id": 109,
    "month": "January",
    "state": "Jammu & Kashmir",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jan",
    "form": "Form XXIV",
    "remarks": null
  },
  {
    "id": 110,
    "month": "January",
    "state": "Jharkhand",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jan",
    "form": "Form XXIV",
    "remarks": "Contractor"
  },
  {
    "id": 111,
    "month": "January",
    "state": "Jharkhand",
    "act": "Bihar Shops and Establishments Act, 1953",
    "type": "Return",
    "Compliance": "Quarterly Return",
    "dueDate": "30-Jan",
    "form": "Form XIX",
    "remarks": "10 or More Employees"
  },
  {
    "id": 112,
    "month": "January",
    "state": "Kerala",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jan",
    "form": "Form XXIV",
    "remarks": null
  },
  {
    "id": 113,
    "month": "January",
    "state": "Kerala",
    "act": "Payment of Wages Act, 1936",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "30-Jan",
    "form": "Form IV",
    "remarks": null
  },
  {
    "id": 114,
    "month": "January",
    "state": "Punjab",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jan",
    "form": "Form XXIV",
    "remarks": null
  },
  {
    "id": 115,
    "month": "January",
    "state": "Rajasthan",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jan",
    "form": "Form XXIV",
    "remarks": null
  },
  {
    "id": 116,
    "month": "January",
    "state": "Tamil Nadu",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jan",
    "form": "Form XXIV",
    "remarks": null
  },
  {
    "id": 117,
    "month": "January",
    "state": "Uttar Pradesh",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jan",
    "form": "Form XXIV",
    "remarks": null
  },
  {
    "id": 118,
    "month": "January",
    "state": "Uttarakhand",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jan",
    "form": "Form XXIV",
    "remarks": null
  },
  {
    "id": 119,
    "month": "January",
    "state": "West Bengal",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jan",
    "form": "Form XXIV",
    "remarks": null
  },
  {
    "id": 120,
    "month": "January",
    "state": "Haryana",
    "act": "Unified single Annual Return",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "30-Jan",
    "form": "Online",
    "remarks": "Notification - S&E, Factories, CLRA, BOCW, ISMW, POW, ID, MW, ESI, MB, POB & POG"
  },
  {
    "id": 121,
    "month": "January",
    "state": "Odisha",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jan",
    "form": "Form XX",
    "remarks": "Contractor"
  },
  {
    "id": 122,
    "month": "January",
    "state": "Puducherry",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jan",
    "form": "Form XXIV",
    "remarks": null
  },
  {
    "id": 123,
    "month": "January",
    "state": "Andhra Pradesh",
    "act": "Payment of Wages Act, 1936",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form IV",
    "remarks": null
  },
  {
    "id": 124,
    "month": "January",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Labour Welfare Fund Act, 1987",
    "type": "Remittance",
    "Compliance": "Annual LWF Payment and Returns",
    "dueDate": "31-Jan",
    "form": "Form F",
    "remarks": null
  },
  {
    "id": 125,
    "month": "January",
    "state": "All States",
    "act": "Sexual Harassment of Workmen at Workplace (Prevention, Prohibition and Redressal) Act, 2013",
    "type": "Return",
    "Compliance": "Annual Report",
    "dueDate": "31-Jan",
    "form": null,
    "remarks": null
  },
  {
    "id": 151,
    "month": "January",
    "state": "Tamil Nadu",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Combined Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form 22",
    "remarks": "Online Return - Notification"
  },
  {
    "id": 152,
    "month": "January",
    "state": "Maharashtra",
    "act": "Maharashtra Shops and Establishments Act, 2017",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form R",
    "remarks": "Not Required - If submission of combined return under Various Acts"
  },
  {
    "id": 153,
    "month": "January",
    "state": "Tamil Nadu",
    "act": "Tamil Nadu Shops And Establishments Act, 1947",
    "type": "Return",
    "Compliance": "Certificate - Half Yearly",
    "dueDate": "31-Jan",
    "form": "Form O",
    "remarks": "Only IT / ITES"
  },
  {
    "id": 154,
    "month": "January",
    "state": "Tamil Nadu",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "Compliance": "Certificate - Half Yearly",
    "dueDate": "31-Jan",
    "form": "Form L",
    "remarks": "Only IT / ITES"
  },
  {
    "id": 155,
    "month": "January",
    "state": "Tamil Nadu",
    "act": "Payment of Gratuity Act, 1972",
    "type": "Return",
    "Compliance": "Certificate - Half Yearly",
    "dueDate": "31-Jan",
    "form": "Form 'V'",
    "remarks": "Only IT / ITES"
  },
  {
    "id": 156,
    "month": "January",
    "state": "Tamil Nadu",
    "act": "Payment of Wages Act, 1936",
    "type": "Return",
    "Compliance": "Certificate - Half Yearly",
    "dueDate": "31-Jan",
    "form": "Form VIII",
    "remarks": "Only IT / ITES"
  },
  {
    "id": 157,
    "month": "January",
    "state": "Karnataka",
    "act": "Payment of Wages Act, 1936",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form U",
    "remarks": null
  },
  {
    "id": 158,
    "month": "January",
    "state": "Goa",
    "act": "Various Acts",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Annexure A",
    "remarks": "Online Filing - Combined - S&E, MW, POW, POB, Cont Lab., ISMW, BOCW, Motor Trans, MB & ID."
  },
  {
    "id": 159,
    "month": "January",
    "state": "Tamil Nadu",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Certificate - Half Yearly",
    "dueDate": "31-Jan",
    "form": "Form XXX",
    "remarks": "Only IT / ITES"
  },
  {
    "id": 160,
    "month": "January",
    "state": "Karnataka",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "License Half Year Return",
    "dueDate": "31-Jan",
    "form": null,
    "remarks": "CLRA - License (Form VI) Half yearly returns period of January to June submitted on or before July'31. Half yearly returns period of July to December submitted on or before January'31."
  },
  {
    "id": 161,
    "month": "January",
    "state": "Karnataka",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Certificate - Annual Return",
    "dueDate": "31-Jan",
    "form": "CLRA - RC (Form II)",
    "remarks": "Annual return on or before January 31st."
  },
  {
    "id": 162,
    "month": "January",
    "state": "Central",
    "act": "Combined Annual Returns - Various Acts",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Sharm Suvida Portal",
    "remarks": "BOCW, CLRA, ISMW, Bonus, ESIC, EPFO"
  },
  {
    "id": 163,
    "month": "January",
    "state": "Andhra Pradesh",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form AR",
    "remarks": null
  },
  {
    "id": 151,
    "month": "January",
    "state": "Tamil Nadu",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Combined Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form 22",
    "remarks": "Online Return - Notification"
  },
  {
    "id": 152,
    "month": "January",
    "state": "Maharashtra",
    "act": "Maharashtra Shops and Establishments Act, 2017",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form R",
    "remarks": "Not Required - If submission of combined return under Various Acts"
  },
  {
    "id": 153,
    "month": "January",
    "state": "Tamil Nadu",
    "act": "Tamil Nadu Shops And Establishments Act, 1947",
    "type": "Return",
    "Compliance": "Certificate - Half Yearly",
    "dueDate": "31-Jan",
    "form": "Form O",
    "remarks": "Only IT / ITES"
  },
  {
    "id": 154,
    "month": "January",
    "state": "Tamil Nadu",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "Compliance": "Certificate - Half Yearly",
    "dueDate": "31-Jan",
    "form": "Form L",
    "remarks": "Only IT / ITES"
  },
  {
    "id": 155,
    "month": "January",
    "state": "Tamil Nadu",
    "act": "Payment of Gratuity Act, 1972",
    "type": "Return",
    "Compliance": "Certificate - Half Yearly",
    "dueDate": "31-Jan",
    "form": "Form 'V'",
    "remarks": "Only IT / ITES"
  },
  {
    "id": 156,
    "month": "January",
    "state": "Tamil Nadu",
    "act": "Payment of Wages Act, 1936",
    "type": "Return",
    "Compliance": "Certificate - Half Yearly",
    "dueDate": "31-Jan",
    "form": "Form VIII",
    "remarks": "Only IT / ITES"
  },
  {
    "id": 157,
    "month": "January",
    "state": "Karnataka",
    "act": "Payment of Wages Act, 1936",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form U",
    "remarks": null
  },
  {
    "id": 158,
    "month": "January",
    "state": "Goa",
    "act": "Various Acts",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Annexure A",
    "remarks": "Online Filing - Combined - S&E, MW, POW, POB, Cont Lab., ISMW, BOCW, Motor Trans, MB & ID."
  },
  {
    "id": 159,
    "month": "January",
    "state": "Tamil Nadu",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Certificate - Half Yearly",
    "dueDate": "31-Jan",
    "form": "Form XXX",
    "remarks": "Only IT / ITES"
  },
  {
    "id": 160,
    "month": "January",
    "state": "Karnataka",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "License Half Year Return",
    "dueDate": "31-Jan",
    "form": null,
    "remarks": "CLRA - License (Form VI) Half yearly returns period of January to June submitted on or before July'31. Half yearly returns period of July to December submitted on or before January'31."
  },
  {
    "id": 161,
    "month": "January",
    "state": "Karnataka",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Certificate - Annual Return",
    "dueDate": "31-Jan",
    "form": "CLRA - RC (Form II)",
    "remarks": "Annual return on or before January 31st."
  },
  {
    "id": 162,
    "month": "January",
    "state": "Central",
    "act": "Combined Annual Returns - Various Acts",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Sharm Suvida Portal",
    "remarks": "BOCW, CLRA, ISMW, Bonus, ESIC, EPFO"
  },
  {
    "id": 163,
    "month": "January",
    "state": "Andhra Pradesh",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form AR",
    "remarks": null
  },
  {
    "id": 164,
    "month": "January",
    "state": "Goa",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Accidents - Annual Return",
    "dueDate": "31-Jan",
    "form": "Form No. 37",
    "remarks": null
  },
  {
    "id": 165,
    "month": "January",
    "state": "Rajasthan",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form No. 23",
    "remarks": "Not Required - If submitting the Integrated Annual Return"
  },
  {
    "id": 166,
    "month": "January",
    "state": "Manipur",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form 29",
    "remarks": null
  },
  {
    "id": 167,
    "month": "January",
    "state": "Meghalaya",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form 34",
    "remarks": null
  },
  {
    "id": 168,
    "month": "January",
    "state": "Tripura",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form 35",
    "remarks": null
  },
  {
    "id": 169,
    "month": "January",
    "state": "Dadra and Nagar Haveli",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form No. 34",
    "remarks": null
  },
  {
    "id": 170,
    "month": "January",
    "state": "Dadra and Nagar Haveli",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Accidents - Annual Return",
    "dueDate": "31-Jan",
    "form": "Form No. 37",
    "remarks": null
  },
  {
    "id": 171,
    "month": "January",
    "state": "Daman",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form No. 34",
    "remarks": null
  },
  {
    "id": 172,
    "month": "January",
    "state": "Daman",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Accidents - Annual Return",
    "dueDate": "31-Jan",
    "form": "Form No. 37",
    "remarks": null
  },
  {
    "id": 173,
    "month": "January",
    "state": "Puducherry",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form No. 19",
    "remarks": null
  },
  {
    "id": 174,
    "month": "January",
    "state": "Puducherry",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "31-Jan",
    "form": "Form No. 20",
    "remarks": null
  },
  {
    "id": 175,
    "month": "January",
    "state": "Gujarat",
    "act": "Gujarat Shops And Establishments Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form Q",
    "remarks": "Online"
  },
  {
    "id": 176,
    "month": "January",
    "state": "Andhra Pradesh",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form III",
    "remarks": "Replaced with Form B - Consolidated return."
  },
  {
    "id": 177,
    "month": "January",
    "state": "Arunachal Pradesh",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 178,
    "month": "January",
    "state": "Chandigarh",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 179,
    "month": "January",
    "state": "Manipur",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 180,
    "month": "January",
    "state": "Nagaland",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 181,
    "month": "January",
    "state": "Daman",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 182,
    "month": "January",
    "state": "Dadra and Nagar Haveli",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 183,
    "month": "January",
    "state": "Lakshadweep",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 184,
    "month": "January",
    "state": "Puducherry",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 185,
    "month": "January",
    "state": "Kerala",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 186,
    "month": "January",
    "state": "Madhya Pradesh",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 187,
    "month": "January",
    "state": "Uttarakhand",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 188,
    "month": "January",
    "state": "Nagaland",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 189,
    "month": "January",
    "state": "Sikkim",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 190,
    "month": "January",
    "state": "Assam",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 191,
    "month": "January",
    "state": "West Bengal",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 192,
    "month": "January",
    "state": "Maharashtra",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 193,
    "month": "January",
    "state": "Bihar",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 194,
    "month": "January",
    "state": "Haryana",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 195,
    "month": "January",
    "state": "Uttarakhand",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 196,
    "month": "January",
    "state": "Odisha",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 197,
    "month": "January",
    "state": "Chhattisgarh",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 198,
    "month": "January",
    "state": "Uttarakhand",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 199,
    "month": "January",
    "state": "Madhya Pradesh",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 200,
    "month": "January",
    "state": "Goa",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 200,
    "month": "January",
    "state": "Goa",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Jan",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 201,
    "month": "January",
    "state": "Goa",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Dec",
    "form": "Form D",
    "remarks": "Not required - If submission of combined return under Various Acts"
  },
  {
    "id": 202,
    "month": "February",
    "state": "Goa",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "1-Feb",
    "form": "Form III",
    "remarks": "Not required - If submission of combined return under Various Acts"
  },
  {
    "id": 203,
    "month": "February",
    "state": "Gujarat",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "1-Feb",
    "form": "Form No. 24",
    "remarks": null
  },
  {
    "id": 204,
    "month": "February",
    "state": "Gujarat",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Dec",
    "form": "Form D",
    "remarks": null
  },
  {
    "id": 205,
    "month": "February",
    "state": "Haryana",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Dec",
    "form": "Form D",
    "remarks": "Not required - If submission of Unified Single Annual Return"
  },
  {
    "id": 206,
    "month": "February",
    "state": "Himachal Pradesh",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Dec",
    "form": "Form D",
    "remarks": null
  },
  {
    "id": 207,
    "month": "February",
    "state": "Jammu & Kashmir",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "1-Feb",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 208,
    "month": "February",
    "state": "Jammu & Kashmir",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Dec",
    "form": "Form D",
    "remarks": null
  },
  {
    "id": 209,
    "month": "February",
    "state": "Jharkhand",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Dec",
    "form": "Form D",
    "remarks": null
  },
  {
    "id": 210,
    "month": "February",
    "state": "Kerala",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Dec",
    "form": "Form D",
    "remarks": null
  },
  {
    "id": 211,
    "month": "February",
    "state": "Madhya Pradesh",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Dec",
    "form": "Form D",
    "remarks": null
  },
  {
    "id": 212,
    "month": "February",
    "state": "Maharashtra",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Dec",
    "form": "Form D",
    "remarks": null
  },
  {
    "id": 213,
    "month": "February",
    "state": "Manipur",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Dec",
    "form": "Form D",
    "remarks": null
  },
  {
    "id": 214,
    "month": "February",
    "state": "Meghalaya",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Dec",
    "form": "Form D",
    "remarks": null
  },
  {
    "id": 215,
    "month": "February",
    "state": "Mizoram",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Dec",
    "form": "Form D",
    "remarks": null
  },
  {
    "id": 216,
    "month": "February",
    "state": "Nagaland",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Dec",
    "form": "Form D",
    "remarks": null
  },
  {
    "id": 217,
    "month": "February",
    "state": "Odisha",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Dec",
    "form": "Form D",
    "remarks": null
  },
  {
    "id": 218,
    "month": "February",
    "state": "Puducherry",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Dec",
    "form": "Form D",
    "remarks": null
  },
  {
    "id": 219,
    "month": "February",
    "state": "Rajasthan",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Dec",
    "form": "Form D",
    "remarks": null
  },
  {
    "id": 220,
    "month": "February",
    "state": "Sikkim",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Dec",
    "form": "Form D",
    "remarks": null
  },
  {
    "id": 221,
    "month": "February",
    "state": "Tamil Nadu",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Dec",
    "form": "Form D",
    "remarks": null
  },
  {
    "id": 222,
    "month": "February",
    "state": "Uttarakhand",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Dec",
    "form": "Form D",
    "remarks": null
  },
  {
    "id": 223,
    "month": "February",
    "state": "Uttar Pradesh",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Dec",
    "form": "Form D",
    "remarks": null
  },
  {
    "id": 224,
    "month": "February",
    "state": "West Bengal",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Dec",
    "form": "Form D",
    "remarks": null
  },
  {
    "id": 225,
    "month": "February",
    "state": "Central",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Dec",
    "form": "Form D",
    "remarks": null
  },
  {
    "id": 226,
    "month": "February",
    "state": "Kerala",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "1-Feb",
    "form": "Form III A",
    "remarks": null
  },
  {
    "id": 227,
    "month": "February",
    "state": "Maharashtra",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Dec",
    "form": "Form D",
    "remarks": "Not Required - If submission of combined return under Various Acts"
  },
  {
    "id": 228,
    "month": "February",
    "state": "Punjab",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Dec",
    "form": "Form D",
    "remarks": "Not Required - If submitting the Integrated Annual Return"
  },
  {
    "id": 229,
    "month": "February",
    "state": "Rajasthan",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Dec",
    "form": "Form D",
    "remarks": "Not required - If submission of Unified Single Annual Return."
  },
  {
    "id": 230,
    "month": "February",
    "state": "Tamil Nadu",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Dec",
    "form": "Form D",
    "remarks": null
  },
  {
    "id": 231,
    "month": "February",
    "state": "Uttar Pradesh",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Dec",
    "form": "Form D",
    "remarks": null
  },
  {
    "id": 232,
    "month": "February",
    "state": "West Bengal",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Dec",
    "form": "Form D",
    "remarks": null
  },
  {
    "id": 233,
    "month": "February",
    "state": "Maharashtra",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "1-Feb",
    "form": "Form 27",
    "remarks": "Not Required - If submission of combined return under Various Acts"
  },
  {
    "id": 234,
    "month": "February",
    "state": "Karnataka",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Combined Annual Returns",
    "dueDate": "1-Feb",
    "form": "Form 20",
    "remarks": "Online - Covering (CLRA, MB, MW, POW, POB)"
  },
  {
    "id": 235,
    "month": "February",
    "state": "Gujarat",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "1-Feb",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 236,
    "month": "February",
    "state": "Himachal Pradesh",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "1-Feb",
    "form": "Form No. 23",
    "remarks": "Leave with Wages"
  },
  {
    "id": 237,
    "month": "February",
    "state": "Himachal Pradesh",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "1-Feb",
    "form": "Form No. 24",
    "remarks": "Compensatory Holiday"
  },
  {
    "id": 238,
    "month": "February",
    "state": "Central",
    "act": "Gujarat Shops And Establishments Act, 1948",
    "type": "Return",
    "Compliance": "Unified annual Return",
    "dueDate": "1-Feb",
    "form": "Form III",
    "remarks": "Unified annual Returns under Various Acts."
  },
  {
    "id": 239,
    "month": "February",
    "state": "Assam",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "1-Feb",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 240,
    "month": "February",
    "state": "Central",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Unified annual Return",
    "dueDate": "1-Feb",
    "form": "Form III",
    "remarks": "Combined Return - Online"
  },
  {
    "id": 241,
    "month": "February",
    "state": "Punjab",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "1-Feb",
    "form": "Form III",
    "remarks": "Not Required - If submitting the Integrated Annual Return"
  },
  {
    "id": 242,
    "month": "February",
    "state": "Haryana",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "1-Feb",
    "form": "Form III",
    "remarks": "Not required - If submission of Unified Single Annual Return."
  },
  {
    "id": 243,
    "month": "February",
    "state": "Himachal Pradesh",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "1-Feb",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 244,
    "month": "February",
    "state": "Meghalaya",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "1-Feb",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 245,
    "month": "February",
    "state": "Mizoram",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "1-Feb",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 246,
    "month": "February",
    "state": "Odisha",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "1-Feb",
    "form": "Form III",
    "remarks": "Not required - Covered under Combined Annual Returns"
  },
  {
    "id": 247,
    "month": "February",
    "state": "Sikkim",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "1-Feb",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 248,
    "month": "February",
    "state": "Tripura",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "1-Feb",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 249,
    "month": "February",
    "state": "West Bengal",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "1-Feb",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 250,
    "month": "February",
    "state": "Haryana",
    "act": "Punjab Labour Welfare Fund Act, 1965",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "1-Feb",
    "form": "Statement",
    "remarks": "Unpaid Accumulation - After 2 years from become payable"
  },
  {
    "id": 251,
    "month": "February",
    "state": "Punjab",
    "act": "Punjab Labour Welfare Fund Act, 1965",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "1-Feb",
    "form": "Statement",
    "remarks": "Unpaid Accumulation - After 3 years from become payable"
  },
  {
    "id": 252,
    "month": "February",
    "state": "Chandigarh",
    "act": "Punjab Labour Welfare Fund Act, 1965",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "1-Feb",
    "form": "Statement",
    "remarks": "Unpaid Accumulation - After 3 years from become payable"
  },
  {
    "id": 253,
    "month": "February",
    "state": "Kerala",
    "act": "Kerala Shops and Commercial Establishments Workers Welfare Fund Act, 2006",
    "type": "Remittance",
    "Compliance": "Monthly Contribution",
    "dueDate": "5-Feb",
    "form": "Form 6",
    "remarks": null
  },
  {
    "id": 254,
    "month": "February",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "10-Feb",
    "form": "Form V",
    "remarks": null
  },
  {
    "id": 255,
    "month": "February",
    "state": "Telangana",
    "act": "Telangana Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "10-Feb",
    "form": "Form V",
    "remarks": null
  },
  {
    "id": 256,
    "month": "February",
    "state": "Madhya Pradesh",
    "act": "Madhya Pradesh Vritti Kar Adhiniyam, 1995 (PT)",
    "type": "Remittance",
    "Compliance": "Monthly Remittance",
    "dueDate": "10-Feb",
    "form": null,
    "remarks": null
  },
  {
    "id": 257,
    "month": "February",
    "state": "All States",
    "act": "Employee State Insurance Act, 1948",
    "type": "Remittance",
    "Compliance": "Monthly Contribution Challan",
    "dueDate": "15-Feb",
    "form": "Challan",
    "remarks": null
  },
  {
    "id": 258,
    "month": "February",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Remittance",
    "Compliance": "Monthly Contribution Challan",
    "dueDate": "15-Feb",
    "form": "Challan",
    "remarks": null
  },
  {
    "id": 259,
    "month": "February",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "Compliance": "International Workers Return",
    "dueDate": "15-Feb",
    "form": "IW-1",
    "remarks": null
  },
  {
    "id": 260,
    "month": "February",
    "state": "Goa",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "15-Feb",
    "form": "Form XXI",
    "remarks": "Not required - If submission of combined return under Various Acts"
  },
  {
    "id": 261,
    "month": "February",
    "state": "Gujarat",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "PE Annual Returns",
    "dueDate": "15-Feb",
    "form": "Form XXV",
    "remarks": null
  },
  {
    "id": 262,
    "month": "February",
    "state": "Gujarat",
    "act": "Gujarat Panchayats, Municipal Corporations And State Tax on Professions, Traders, Callings and Employments Act, 1976",
    "type": "Return",
    "Compliance": "Monthly Returns",
    "dueDate": "15-Feb",
    "form": "Form 5",
    "remarks": null
  },
  {
    "id": 263,
    "month": "February",
    "state": "Haryana",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "PE Annual Returns",
    "dueDate": "15-Feb",
    "form": "Form 21",
    "remarks": "Not required - If submission of Unified Single Annual Return."
  },
  {
    "id": 264,
    "month": "February",
    "state": "Jharkhand",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "15-Feb",
    "form": "Form XXV",
    "remarks": null
  },
  {
    "id": 265,
    "month": "February",
    "state": "Tamil Nadu",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "15-Feb",
    "form": "Form XXV",
    "remarks": "Not required if (Combined Annual Return Form 22 submitted in Factories Act) or (Combined annual return Form XIII submitted in TN Catering Establishments)"
  },
  {
    "id": 266,
    "month": "February",
    "state": "Maharashtra",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "PE Annual Returns",
    "dueDate": "15-Feb",
    "form": "Form XXI",
    "remarks": "Not Required - If submission of combined return under Various Acts"
  },
  {
    "id": 267,
    "month": "February",
    "state": "Delhi",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "PE Annual Returns",
    "dueDate": "15-Feb",
    "form": "Form XXV",
    "remarks": null
  },
  {
    "id": 268,
    "month": "February",
    "state": "Gujarat",
    "act": "Payment of Wages Act, 1936",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "15-Feb",
    "form": "Form IV",
    "remarks": null
  },
  {
    "id": 269,
    "month": "February",
    "state": "Puducherry",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "15-Feb",
    "form": "Form XXV",
    "remarks": null
  },
  {
    "id": 270,
    "month": "February",
    "state": "Maharashtra",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "15-Feb",
    "form": "Form 30",
    "remarks": "Accident Details"
  },
  {
    "id": 271,
    "month": "February",
    "state": "Rajasthan",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "15-Feb",
    "form": "Form III",
    "remarks": "Not required - If submission of Unified Single Annual Return."
  },
  {
    "id": 272,
    "month": "February",
    "state": "Uttar Pradesh",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "15-Feb",
    "form": "Form XXV",
    "remarks": null
  },
  {
    "id": 273,
    "month": "February",
    "state": "Uttar Pradesh",
    "act": "Payment of Wages Act, 1936",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "15-Feb",
    "form": "Form IV",
    "remarks": "Applicable to Factories and Industrial Establishments"
  },
  {
    "id": 274,
    "month": "February",
    "state": "West Bengal",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "15-Feb",
    "form": "Form XXV",
    "remarks": null
  },
  {
    "id": 275,
    "month": "February",
    "state": "Jharkhand",
    "act": "The Jharkhand Tax On Profession, Trades, Callings And Employments Act, 2011",
    "type": "Remittance",
    "Compliance": "Quarterly Return",
    "dueDate": "15-Feb",
    "form": "Form - JPT- 03",
    "remarks": "Employee & Employer Tax online (Employer to deduct monthly and remit quarterly)"
  },
  {
    "id": 276,
    "month": "February",
    "state": "Sikkim",
    "act": "Sikkim Tax On Professions, Trades, Callings And Employments, Act 2006",
    "type": "Remittance",
    "Compliance": "Monthly",
    "dueDate": "15-Feb",
    "form": null,
    "remarks": null
  },
  {
    "id": 277,
    "month": "February",
    "state": "Karnataka",
    "act": "Karnataka Tax on Professions, Trades, Callings and Employments Act, 1976",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "20-Feb",
    "form": "Form 9-A or Form 5-A",
    "remarks": null
  },
  {
    "id": 278,
    "month": "February",
    "state": "West Bengal",
    "act": "West Bengal State Tax on Professions, Trades, Callings and Employments Act, 1979",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "21-Feb",
    "form": "Online",
    "remarks": null
  },
  {
    "id": 279,
    "month": "February",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "Compliance": "Monthly Return - EDLI Exempted Employer",
    "dueDate": "25-Feb",
    "form": "Form - 7(IF)",
    "remarks": null
  },
  {
    "id": 280,
    "month": "February",
    "state": "Maharashtra",
    "act": "Maharashtra State Tax on Professions, Trade, Callings And Employments Act, 1975",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "28-Feb",
    "form": "Online",
    "remarks": null
  },
  {
    "id": 281,
    "month": "February",
    "state": "Tripura",
    "act": "Tripura Professions Trades Callings And Employments Taxation Act, 1997",
    "type": "Remittance and Return",
    "Compliance": "Monthly",
    "dueDate": "28-Feb",
    "form": "Form VII",
    "remarks": null
  },
  {
    "id": 282,
    "month": "February",
    "state": "Meghalaya",
    "act": "Meghalaya Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance and Return",
    "Compliance": "Monthly",
    "dueDate": "28-Feb",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 283,
    "month": "February",
    "state": "Assam",
    "act": "Assam Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "28-Feb",
    "form": "Form III, Form III A, Form VII C",
    "remarks": null
  },
  {
    "id": 284,
    "month": "February",
    "state": "Odisha",
    "act": "Orissa State Tax On Professions, Trades, Callings And Employments Act, 2000",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "28-Feb",
    "form": "Form 5",
    "remarks": null
  },
  {
    "id": 285,
    "month": "February",
    "state": "Kerala",
    "act": "Kerala Panchayat Raj Act, 1994 (PT)",
    "type": "Remittance",
    "Compliance": "Half Yearly Remittance",
    "dueDate": "28-Feb",
    "form": "Offline",
    "remarks": "Period (Oct to Mar) No Registration Required"
  },
  {
    "id": 286,
    "month": "March",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Remittance",
    "Compliance": "Monthly Contribution Challan",
    "dueDate": "15-Mar",
    "form": "Challan",
    "remarks": null
  },
  {
    "id": 287,
    "month": "March",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "Compliance": "International Workers Return",
    "dueDate": "15-Mar",
    "form": "Challan",
    "remarks": null
  },
  {
    "id": 288,
    "month": "March",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "Compliance": "Monthly Return - EDLI Exempted Employer",
    "dueDate": "15-Mar",
    "form": "IW-1",
    "remarks": null
  },
  {
    "id": 289,
    "month": "March",
    "state": "All States",
    "act": "Employee State Insurance Act, 1948",
    "type": "Remittance",
    "Compliance": "Monthly Contribution Challan",
    "dueDate": "15-Apr",
    "form": "Challan",
    "remarks": null
  },
  {
    "id": 290,
    "month": "March",
    "state": "Gujarat",
    "act": "Gujarat Panchayats, Municipal Corporations And State Tax on Professions, Traders, Callings and Employments Act, 1976",
    "type": "Return",
    "Compliance": "Monthly Returns",
    "dueDate": "25-Mar",
    "form": "Form - 7(IF)",
    "remarks": null
  },
  {
    "id": 291,
    "month": "March",
    "state": "Karnataka",
    "act": "Karnataka Tax on Professions, Trades, Callings and Employments Act, 1976",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "15-Mar",
    "form": "Form 5",
    "remarks": null
  },
  {
    "id": 292,
    "month": "March",
    "state": "Kerala",
    "act": "Kerala Shops and Commercial Establishments Workers Welfare Fund Act,2006",
    "type": "Remittance",
    "Compliance": "Monthly Contribution",
    "dueDate": "20-Mar",
    "form": "Form 9-A or Form 5-A",
    "remarks": null
  },
  {
    "id": 293,
    "month": "March",
    "state": "Maharashtra",
    "act": "Maharashtra State Tax on Professions, Trade, Callings And Employments Act, 1975",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "5-Mar",
    "form": "Form 6",
    "remarks": null
  },
  {
    "id": 294,
    "month": "March",
    "state": "Maharashtra",
    "act": "Maharashtra State Tax on Professions, Trade, Callings And Employments Act, 1975",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Mar",
    "form": "Online",
    "remarks": null
  },
  {
    "id": 295,
    "month": "March",
    "state": "West Bengal",
    "act": "West Bengal State Tax on Professions, Trades, Callings and Employments Act, 1979",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "31-Mar",
    "form": "Online",
    "remarks": null
  },
  {
    "id": 296,
    "month": "March",
    "state": "Odisha",
    "act": "Combined Annual Returns - Various Acts",
    "type": "Return",
    "Compliance": "Combined Annual Returns",
    "dueDate": "21-Mar",
    "form": "Online",
    "remarks": null
  },
  {
    "id": 297,
    "month": "March",
    "state": "Telangana",
    "act": "Telangana Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "31-Mar",
    "form": "Form 13",
    "remarks": null
  },
  {
    "id": 298,
    "month": "March",
    "state": "Assam",
    "act": "Assam Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "10-Mar",
    "form": "Form V",
    "remarks": null
  },
  {
    "id": 299,
    "month": "March",
    "state": "Odisha",
    "act": "Orissa State Tax On Professions, Trades, Callings And Employments Act, 2000",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "31-Mar",
    "form": "Form III, Form III A, Form VII C",
    "remarks": null
  },
  {
    "id": 300,
    "month": "March",
    "state": "Madhya Pradesh",
    "act": "Madhya Pradesh Vritti Kar Adhiniyam, 1995 (PT)",
    "type": "Remittance",
    "Compliance": "Monthly Remittance",
    "dueDate": "31-Mar",
    "form": "Form 5",
    "remarks": null
  },
  {
    "id": 301,
    "month": "March",
    "state": "Tamil Nadu",
    "act": "Tamil Nadu Municipal Laws (Second Amendment) Act, 1998 & Tamil Nadu Professional Tax Rules, 1999",
    "type": "Remittance",
    "Compliance": "Remittance and Return",
    "dueDate": "10-Mar",
    "form": null,
    "remarks": null
  },
  {
    "id": 302,
    "month": "March",
    "state": "Tripura",
    "act": "Tripura Professions Trades Callings And Employments Taxation Act, 1997",
    "type": "Remittance and Return",
    "Compliance": "Monthly",
    "dueDate": "31-Mar",
    "form": "Form 2",
    "remarks": null
  },
  {
    "id": 303,
    "month": "March",
    "state": "Meghalaya",
    "act": "Meghalaya Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance and Return",
    "Compliance": "Monthly",
    "dueDate": "31-Mar",
    "form": "Form VII",
    "remarks": null
  },
  {
    "id": 304,
    "month": "March",
    "state": "Sikkim",
    "act": "Sikkim Tax On Professions, Trades, Callings And Employments, Act 2006",
    "type": "Remittance",
    "Compliance": "Monthly",
    "dueDate": "31-Mar",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 305,
    "month": "April",
    "state": "All States",
    "act": "Employment Exchange (Compulsory Notification of Vacancies) Act, 1959",
    "type": "Return",
    "Compliance": "Quarterly Return",
    "dueDate": "30-Apr",
    "form": "ER-1",
    "remarks": null
  },
  {
    "id": 306,
    "month": "April",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "10-Apr",
    "form": "Form V",
    "remarks": null
  },
  {
    "id": 307,
    "month": "April",
    "state": "All States",
    "act": "Employee State Insurance Act, 1948",
    "type": "Remittance",
    "Compliance": "Monthly Contribution Challan",
    "dueDate": "15-Apr",
    "form": "Challan",
    "remarks": null
  },
  {
    "id": 308,
    "month": "April",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Remittance",
    "Compliance": "Monthly Contribution Challan",
    "dueDate": "15-Apr",
    "form": "Challan",
    "remarks": null
  },
  {
    "id": 309,
    "month": "April",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "Compliance": "International Workers Return",
    "dueDate": "15-Apr",
    "form": "IW-1",
    "remarks": null
  },
  {
    "id": 310,
    "month": "April",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "Compliance": "Monthly Return - EDLI Exempted Employer",
    "dueDate": "25-Apr",
    "form": "Form - 7(IF)",
    "remarks": null
  },
  {
    "id": 311,
    "month": "April",
    "state": "Goa",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Apr",
    "form": "Form XX",
    "remarks": null
  },
  {
    "id": 312,
    "month": "April",
    "state": "Gujarat",
    "act": "Gujarat Panchayats, Municipal Corporations And State Tax on Professions, Traders, Callings and Employments Act, 1976",
    "type": "Return",
    "Compliance": "Monthly Returns",
    "dueDate": "15-Apr",
    "form": "Form 5",
    "remarks": null
  },
  {
    "id": 313,
    "month": "April",
    "state": "Karnataka",
    "act": "Karnataka Tax on Professions, Trades, Callings and Employments Act, 1976",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "20-Apr",
    "form": "Form 9-A or Form 5-A",
    "remarks": null
  },
  {
    "id": 314,
    "month": "April",
    "state": "Karnataka",
    "act": "Karnataka Tax on Professions, Trades, Callings and Employments Act, 1976",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "30-Apr",
    "form": "Form 5",
    "remarks": null
  },
  {
    "id": 315,
    "month": "April",
    "state": "Kerala",
    "act": "Kerala Shops and Commercial Establishments Workers Welfare Fund Act,2006",
    "type": "Remittance",
    "Compliance": "Monthly Contribution",
    "dueDate": "5-Apr",
    "form": "Form 6",
    "remarks": null
  },
  {
    "id": 316,
    "month": "April",
    "state": "Maharashtra",
    "act": "Maharashtra State Tax on Professions, Trade, Callings And Employments Act, 1975",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "30-Apr",
    "form": "Online",
    "remarks": null
  },
  {
    "id": 317,
    "month": "April",
    "state": "Telangana",
    "act": "Various Acts",
    "type": "Return",
    "Compliance": "Integrated Return",
    "dueDate": "30-Apr",
    "form": "Form - I",
    "remarks": null
  },
  {
    "id": 318,
    "month": "April",
    "state": "West Bengal",
    "act": "West Bengal State Tax on Professions, Trades, Callings and Employments Act, 1979",
    "type": "Remittance",
    "Compliance": "Monthly Remittance",
    "dueDate": "21-Apr",
    "form": "Online",
    "remarks": null
  },
  {
    "id": 319,
    "month": "April",
    "state": "Telangana",
    "act": "Telangana Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "10-Apr",
    "form": "Form V",
    "remarks": null
  },
  {
    "id": 320,
    "month": "April",
    "state": "Assam",
    "act": "Assam Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "30-Apr",
    "form": "Form III, Form III A, Form VII C",
    "remarks": null
  },
  {
    "id": 321,
    "month": "April",
    "state": "Odisha",
    "act": "Orissa State Tax On Professions, Trades, Callings And Employments Act, 2000",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "30-Apr",
    "form": "Form 5",
    "remarks": null
  },
  {
    "id": 322,
    "month": "April",
    "state": "Madhya Pradesh",
    "act": "Madhya Pradesh Vritti Kar Adhiniyam, 1995 (PT)",
    "type": "Remittance",
    "Compliance": "Monthly Remittance",
    "dueDate": "10-Apr",
    "form": null,
    "remarks": null
  },
  {
    "id": 323,
    "month": "April",
    "state": "Chandigarh",
    "act": "Punjab Labour Welfare Fund Act, 1965",
    "type": "Remittance",
    "Compliance": "Half Yearly Remittance & Return",
    "dueDate": "15-Apr",
    "form": null,
    "remarks": null
  },
  {
    "id": 324,
    "month": "April",
    "state": "Madhya Pradesh",
    "act": "Madhya Pradesh Vritti Kar Adhiniyam, 1995 (PT)",
    "type": "Return",
    "Compliance": "Quarterly Return",
    "dueDate": "15-Apr",
    "form": "Form 7",
    "remarks": null
  },
  {
    "id": 325,
    "month": "April",
    "state": "Maharashtra",
    "act": "Various Acts",
    "type": "Return",
    "Compliance": "Consolidated annual Returns",
    "dueDate": "30-Apr",
    "form": "Annexure IV",
    "remarks": null
  },
  {
    "id": 326,
    "month": "April",
    "state": "Kerala",
    "act": "Kerala Shops and Commercial Establishments Act, 1960",
    "type": "Return",
    "Compliance": "Quarterly Returns",
    "dueDate": "10-Apr",
    "form": "Form - H",
    "remarks": null
  },
  {
    "id": 327,
    "month": "April",
    "state": "Goa",
    "act": "Goa Shops And Establishments Act, 1973",
    "type": "Return",
    "Compliance": "Quarterly Returns",
    "dueDate": "10-Apr",
    "form": "Form XXIX",
    "remarks": null
  },
  {
    "id": 328,
    "month": "April",
    "state": "Bihar",
    "act": "Bihar Shops and Establishments Act, 1953",
    "type": "Return",
    "Compliance": "Quarterly Return",
    "dueDate": "30-Apr",
    "form": "Form XIX",
    "remarks": null
  },
  {
    "id": 329,
    "month": "April",
    "state": "Karnataka",
    "act": "Karnataka Tax on Professions, Trades, Callings and Employments Act, 1976",
    "type": "Return",
    "Compliance": "Employer Enrolment Tax",
    "dueDate": "30-Apr",
    "form": null,
    "remarks": null
  },
  {
    "id": 330,
    "month": "April",
    "state": "Punjab",
    "act": "Punjab Labour Welfare Fund Act,1965",
    "type": "Remittance",
    "Compliance": "Half Yearly Remittance & Return",
    "dueDate": "15-Apr",
    "form": null,
    "remarks": null
  },
  {
    "id": 331,
    "month": "April",
    "state": "West Bengal",
    "act": "West Bengal State Tax on Professions, Trades, Callings and Employments Act, 1979",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "30-Apr",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 332,
    "month": "April",
    "state": "Punjab",
    "act": "Punjab State Development Tax 2018",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "30-Apr",
    "form": "Form PSDT-6",
    "remarks": null
  },
  {
    "id": 333,
    "month": "April",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Shops And Establishments Act, 1988",
    "type": "Return",
    "Compliance": "Quarterly Return",
    "dueDate": "10-Apr",
    "form": "Form XXVII",
    "remarks": null
  },
  {
    "id": 334,
    "month": "April",
    "state": "West Bengal",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Quarterly Return",
    "dueDate": "15-Apr",
    "form": "Form No. 23-A",
    "remarks": null
  },
  {
    "id": 335,
    "month": "April",
    "state": "Telangana",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Integrated Return",
    "dueDate": "30-Apr",
    "form": "Form I",
    "remarks": null
  },
  {
    "id": 336,
    "month": "April",
    "state": "Tamil Nadu",
    "act": "Tamil Nadu Labour Welfare Fund Act, 1972",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Apr",
    "form": "Statement even \"NIL\" Along with Form C",
    "remarks": null
  },
  {
    "id": 337,
    "month": "April",
    "state": "Karnataka",
    "act": "Karnataka Labour Welfare Fund Act, 1965",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Apr",
    "form": "Statement",
    "remarks": null
  },
  {
    "id": 338,
    "month": "April",
    "state": "Telangana",
    "act": "Telangana Labour Welfare Fund Act, 1987",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Apr",
    "form": "Statement",
    "remarks": null
  },
  {
    "id": 339,
    "month": "April",
    "state": "Gujarat",
    "act": "Gujarat Labour Welfare Fund Act, 1953",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Apr",
    "form": "Form B & Form C",
    "remarks": null
  },
  {
    "id": 340,
    "month": "April",
    "state": "West Bengal",
    "act": "West Bengal Labour Welfare Fund Act, 1974",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Apr",
    "form": "Statement",
    "remarks": null
  },
  {
    "id": 341,
    "month": "April",
    "state": "Maharashtra",
    "act": "Maharashtra Labour Welfare Fund Act, 1953",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Apr",
    "form": "Statement",
    "remarks": null
  },
  {
    "id": 342,
    "month": "April",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Labour Welfare Fund Act, 1987",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Apr",
    "form": "Statement",
    "remarks": null
  },
  {
    "id": 343,
    "month": "April",
    "state": "Manipur",
    "act": "Manipur Professions, Trades, Callings And Employments Taxation Act, 1981",
    "type": "Remittance & Returns",
    "Compliance": "Annual Returns",
    "dueDate": "30-Apr",
    "form": "Form V",
    "remarks": null
  },
  {
    "id": 344,
    "month": "April",
    "state": "Tripura",
    "act": "Tripura Professions Trades Callings And Employments Taxation Act, 1997",
    "type": "Remittance and Return",
    "Compliance": "Monthly",
    "dueDate": "30-Apr",
    "form": "Form VII",
    "remarks": null
  },
  {
    "id": 345,
    "month": "April",
    "state": "Goa",
    "act": "Goa Labour Welfare Fund Act, 1986",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Apr",
    "form": "Statement",
    "remarks": null
  },
  {
    "id": 346,
    "month": "April",
    "state": "Chhattisgarh",
    "act": "Chhattisgarh Shram Kalyan Nidhi Adhiniyam, 1982 (LWF)",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Apr",
    "form": "Statement",
    "remarks": null
  },
  {
    "id": 347,
    "month": "April",
    "state": "Madhya Pradesh",
    "act": "The Madhya Pradesh Shram Kalyan Nidhi  Adhiniyam, 1982 (LWF)",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Apr",
    "form": "Statement",
    "remarks": null
  },
  {
    "id": 348,
    "month": "April",
    "state": "Delhi",
    "act": "Bombay Labour Welfare Fund Act, 1953 (Delhi)",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Apr",
    "form": "Statement",
    "remarks": null
  },
  {
    "id": 349,
    "month": "April",
    "state": "Odisha",
    "act": "Odisha Labour Welfare Fund Act, 1996",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Apr",
    "form": "Statement",
    "remarks": null
  },
  {
    "id": 350,
    "month": "April",
    "state": "Jharkhand",
    "act": "The Jharkhand Tax On Profession, Trades, Callings And Employments Act, 2011",
    "type": "Remittance",
    "Compliance": "Quarterly Return",
    "dueDate": "15-Apr",
    "form": "Form - JPT- 03",
    "remarks": null
  },
  {
    "id": 351,
    "month": "April",
    "state": "Meghalaya",
    "act": "Meghalaya Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance and Return",
    "Compliance": "Monthly",
    "dueDate": "30-Apr",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 352,
    "month": "April",
    "state": "Nagaland",
    "act": "Nagaland Professionals, Trades, Calling and Employment Taxation (4th Amendment) Act, 2012",
    "type": "Remittance",
    "Compliance": "Annual Remittance",
    "dueDate": "30-Apr",
    "form": "Statement",
    "remarks": null
  },
  {
    "id": 353,
    "month": "April",
    "state": "Sikkim",
    "act": "Sikkim Tax On Professions, Trades, Callings And Employments, Act 2006",
    "type": "Remittance",
    "Compliance": "Monthly",
    "dueDate": "15-Apr",
    "form": null,
    "remarks": null
  },
  {
    "id": 354,
    "month": "April",
    "state": "Sikkim",
    "act": "Sikkim Tax On Professions, Trades, Callings And Employments, Act 2006",
    "type": "Return",
    "Compliance": "Quarterly Return",
    "dueDate": "30-Apr",
    "form": "Form 5",
    "remarks": null
  },
  {
    "id": 355,
    "month": "April",
    "state": "Jharkhand",
    "act": "Bihar Shops and Establishments Act, 1953",
    "type": "Return",
    "Compliance": "Quarterly Return",
    "dueDate": "30-Apr",
    "form": "Form XIX",
    "remarks": null
  },
  {
    "id": 356,
    "month": "May",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "10-May",
    "form": "Form V",
    "remarks": null
  },
  {
    "id": 357,
    "month": "May",
    "state": "All States",
    "act": "Employee State Insurance Act, 1948",
    "type": "Remittance",
    "Compliance": "Monthly Contribution Challan",
    "dueDate": "15-May",
    "form": "Challan",
    "remarks": null
  },
  {
    "id": 358,
    "month": "May",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Remittance",
    "Compliance": "Monthly Contribution Challan",
    "dueDate": "15-May",
    "form": "Challan",
    "remarks": null
  },
  {
    "id": 359,
    "month": "May",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "Compliance": "International Workers Return",
    "dueDate": "15-May",
    "form": "IW-1",
    "remarks": null
  },
  {
    "id": 360,
    "month": "May",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "Compliance": "Monthly Return - EDLI Exempted Employer",
    "dueDate": "25-May",
    "form": "Form - 7(IF)",
    "remarks": null
  },
  {
    "id": 361,
    "month": "May",
    "state": "Gujarat",
    "act": "Gujarat Panchayats, Municipal Corporations And State Tax on Professions, Traders, Callings and Employments Act, 1976",
    "type": "Return",
    "Compliance": "Monthly Returns",
    "dueDate": "15-May",
    "form": "Form 5",
    "remarks": null
  },
  {
    "id": 362,
    "month": "May",
    "state": "Karnataka",
    "act": "Karnataka Tax on Professions, Trades, Callings and Employments Act, 1976",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "20-May",
    "form": "Form 9-A or Form 5-A",
    "remarks": null
  },
  {
    "id": 363,
    "month": "May",
    "state": "Kerala",
    "act": "Kerala Shops and Commercial Establishments Workers Welfare Fund Act,2006",
    "type": "Remittance",
    "Compliance": "Monthly Contribution",
    "dueDate": "5-May",
    "form": "Form 6",
    "remarks": null
  },
  {
    "id": 364,
    "month": "May",
    "state": "Maharashtra",
    "act": "Maharashtra State Tax on Professions, Trade, Callings And Employments Act, 1975",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "31-May",
    "form": "Online",
    "remarks": null
  },
  {
    "id": 365,
    "month": "May",
    "state": "West Bengal",
    "act": "West Bengal State Tax on Professions, Trades, Callings and Employments Act, 1979",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "21-May",
    "form": "Online",
    "remarks": null
  },
  {
    "id": 366,
    "month": "May",
    "state": "Telangana",
    "act": "Telangana Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "10-May",
    "form": "Form V",
    "remarks": null
  },
  {
    "id": 367,
    "month": "May",
    "state": "Assam",
    "act": "Assam Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "31-May",
    "form": "Form III, Form III A, Form VII C",
    "remarks": null
  },
  {
    "id": 368,
    "month": "May",
    "state": "Odisha",
    "act": "Orissa State Tax On Professions, Trades, Callings And Employments Act, 2000",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "31-May",
    "form": "Form 5",
    "remarks": null
  },
  {
    "id": 369,
    "month": "May",
    "state": "Madhya Pradesh",
    "act": "Madhya Pradesh Vritti Kar Adhiniyam, 1995 (PT)",
    "type": "Remittance",
    "Compliance": "Monthly Remittance",
    "dueDate": "10-May",
    "form": null,
    "remarks": null
  },
  {
    "id": 370,
    "month": "May",
    "state": "All States",
    "act": "Employee State Insurance Act, 1948",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "12-May",
    "form": "Form 5",
    "remarks": "For period Oct to March (after completing the period 42 day time is there to file the return)"
  },
  {
    "id": 371,
    "month": "May",
    "state": "Haryana",
    "act": "Punjab Labour Welfare Fund Act, 1965",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "1-May",
    "form": "Statement",
    "remarks": null
  },
  {
    "id": 372,
    "month": "May",
    "state": "Tripura",
    "act": "Tripura Professions Trades Callings And Employments Taxation Act, 1997",
    "type": "Remittance and Return",
    "Compliance": "Monthly",
    "dueDate": "31-May",
    "form": "Form VII",
    "remarks": null
  },
  {
    "id": 373,
    "month": "May",
    "state": "Punjab",
    "act": "Punjab Labour Welfare Fund Act, 1965",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "1-May",
    "form": "Statement",
    "remarks": null
  },
  {
    "id": 374,
    "month": "May",
    "state": "Chandigarh",
    "act": "Punjab Labour Welfare Fund Act, 1965",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "1-May",
    "form": "Statement",
    "remarks": null
  },
  {
    "id": 375,
    "month": "May",
    "state": "Meghalaya",
    "act": "Meghalaya Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance and Return",
    "Compliance": "Monthly",
    "dueDate": "31-May",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 376,
    "month": "May",
    "state": "Sikkim",
    "act": "Sikkim Tax On Professions, Trades, Callings And Employments, Act 2006",
    "type": "Remittance",
    "Compliance": "Monthly",
    "dueDate": "15-May",
    "form": null,
    "remarks": null
  },
  {
    "id": 377,
    "month": "June",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "10-Jun",
    "form": "Form V",
    "remarks": null
  },
  {
    "id": 378,
    "month": "June",
    "state": "Andhra Pradesh",
    "act": "Various Acts (CLRA, S&E, Factories, MW, POB, ISMW, BOCW, POG)",
    "type": "Return",
    "Compliance": "Combined Annual Returns",
    "dueDate": "30-Jun",
    "form": "Form B",
    "remarks": null
  },
  {
    "id": 379,
    "month": "June",
    "state": "All States",
    "act": "Employee State Insurance Act, 1948",
    "type": "Remittance",
    "Compliance": "Monthly Contribution Challan",
    "dueDate": "15-Jun",
    "form": "Challan",
    "remarks": null
  },
  {
    "id": 380,
    "month": "June",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Remittance",
    "Compliance": "Monthly Contribution Challan",
    "dueDate": "15-Jun",
    "form": "Challan",
    "remarks": null
  },
  {
    "id": 381,
    "month": "June",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "Compliance": "International Workers Return",
    "dueDate": "15-Jun",
    "form": "IW-1",
    "remarks": null
  },
  {
    "id": 382,
    "month": "June",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "Compliance": "Monthly Return - EDLI Exempted Employer",
    "dueDate": "25-Jun",
    "form": "Form - 7(IF)",
    "remarks": null
  },
  {
    "id": 383,
    "month": "June",
    "state": "Gujarat",
    "act": "Gujarat Panchayats, Municipal Corporations And State Tax on Professions, Traders, Callings and Employments Act, 1976",
    "type": "Return",
    "Compliance": "Monthly Returns",
    "dueDate": "15-Jun",
    "form": "Form 5",
    "remarks": null
  },
  {
    "id": 384,
    "month": "June",
    "state": "Karnataka",
    "act": "Karnataka Tax on Professions, Trades, Callings and Employments Act, 1976",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "20-Jun",
    "form": "Form 9-A or Form 5-A",
    "remarks": null
  },
  {
    "id": 385,
    "month": "June",
    "state": "Kerala",
    "act": "Kerala Shops and Commercial Establishments Workers Welfare Fund Act, 2006",
    "type": "Remittance",
    "Compliance": "Monthly Contribution",
    "dueDate": "5-Jun",
    "form": "Form 6",
    "remarks": null
  },
  {
    "id": 386,
    "month": "June",
    "state": "Maharashtra",
    "act": "Maharashtra State Tax on Professions, Trade, Callings And Employments Act, 1975",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "30-Jun",
    "form": "Online",
    "remarks": null
  },
  {
    "id": 387,
    "month": "June",
    "state": "West Bengal",
    "act": "West Bengal State Tax on Professions, Trades, Callings and Employments Act, 1979",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "21-Jun",
    "form": "Online",
    "remarks": null
  },
  {
    "id": 388,
    "month": "June",
    "state": "Telangana",
    "act": "Telangana Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "10-Jun",
    "form": "Form V",
    "remarks": null
  },
  {
    "id": 389,
    "month": "June",
    "state": "Assam",
    "act": "Assam Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "30-Jun",
    "form": "Form III, Form III A, Form VII C",
    "remarks": null
  },
  {
    "id": 390,
    "month": "June",
    "state": "Odisha",
    "act": "Orissa State Tax On Professions, Trades, Callings And Employments Act, 2000",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "30-Jun",
    "form": "Form 5",
    "remarks": null
  },
  {
    "id": 391,
    "month": "June",
    "state": "Madhya Pradesh",
    "act": "Madhya Pradesh Vritti Kar Adhiniyam, 1995 (PT)",
    "type": "Remittance",
    "Compliance": "Monthly Remittance",
    "dueDate": "10-Jun",
    "form": null,
    "remarks": null
  },
  {
    "id": 392,
    "month": "June",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "Compliance": "Enrolment Fees",
    "dueDate": "30-Jun",
    "form": null,
    "remarks": null
  },
  {
    "id": 393,
    "month": "June",
    "state": "Telangana",
    "act": "Telangana Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "Compliance": "Enrolment Fees",
    "dueDate": "30-Jun",
    "form": null,
    "remarks": null
  },
  {
    "id": 394,
    "month": "June",
    "state": "Odisha",
    "act": "Orissa State Tax On Professions, Trades, Callings And Employments Act, 2000",
    "type": "Remittance",
    "Compliance": "Enrolment Fees",
    "dueDate": "30-Jun",
    "form": null,
    "remarks": null
  },
  {
    "id": 395,
    "month": "June",
    "state": "Madhya Pradesh",
    "act": "Madhya Pradesh Vritti Kar Adhiniyam, 1995 (PT)",
    "type": "Remittance",
    "Compliance": "Enrolment Fees",
    "dueDate": "30-Jun",
    "form": null,
    "remarks": null
  },
  {
    "id": 396,
    "month": "June",
    "state": "Bihar",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "30-Jun",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 397,
    "month": "June",
    "state": "Tripura",
    "act": "Tripura Professions Trades Callings And Employments Taxation Act, 1997",
    "type": "Remittance and Return",
    "Compliance": "Monthly",
    "dueDate": "30-Jun",
    "form": "Form VII",
    "remarks": null
  },
  {
    "id": 398,
    "month": "June",
    "state": "Meghalaya",
    "act": "Meghalaya Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance and Return",
    "Compliance": "Monthly",
    "dueDate": "30-Jun",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 399,
    "month": "June",
    "state": "Mizoram",
    "act": "Mizoram Professions, Trades, Callings and Employments Taxation Act, 1995",
    "type": "Remittance and Return",
    "Compliance": "Annual Returns",
    "dueDate": "30-Jun",
    "form": "Form I & Form IV",
    "remarks": null
  },
  {
    "id": 400,
    "month": "June",
    "state": "Sikkim",
    "act": "Sikkim Tax On Professions, Trades, Callings And Employments, Act 2006",
    "type": "Remittance",
    "Compliance": "Monthly",
    "dueDate": "15-Jun",
    "form": null,
    "remarks": null
  },
  {
    "id": 401,
    "month": "July",
    "state": "All States",
    "act": "Employment Exchange (Compulsory Notification of Vacancies) Act, 1959",
    "type": "Return",
    "Compliance": "Quarterly Return",
    "dueDate": "30-Jul",
    "form": "ER-1",
    "remarks": null
  },
  {
    "id": 402,
    "month": "July",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "10-Jul",
    "form": "Form V",
    "remarks": null
  },
  {
    "id": 403,
    "month": "July",
    "state": "Arunachal Pradesh",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jul",
    "form": "Form XXIV",
    "remarks": null
  },
  {
    "id": 404,
    "month": "July",
    "state": "Assam",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jul",
    "form": "Form XXIV",
    "remarks": null
  },
  {
    "id": 405,
    "month": "July",
    "state": "Bihar",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jul",
    "form": "Form XXIV",
    "remarks": null
  },
  {
    "id": 406,
    "month": "July",
    "state": "All States",
    "act": "Employee State Insurance Act, 1948",
    "type": "Remittance",
    "Compliance": "Monthly Contribution Challan",
    "dueDate": "15-Jul",
    "form": "Challan",
    "remarks": null
  },
  {
    "id": 407,
    "month": "July",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Remittance",
    "Compliance": "Monthly Contribution Challan",
    "dueDate": "15-Jul",
    "form": "Challan",
    "remarks": null
  },
  {
    "id": 408,
    "month": "July",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "Compliance": "International Workers Return",
    "dueDate": "15-Jul",
    "form": "IW-1",
    "remarks": null
  },
  {
    "id": 409,
    "month": "July",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "Compliance": "Monthly Return - EDLI Exempted Employer",
    "dueDate": "25-Jul",
    "form": "Form - 7(IF)",
    "remarks": null
  },
  {
    "id": 410,
    "month": "July",
    "state": "Chandigarh",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jul",
    "form": "Form XXIV",
    "remarks": null
  },
  {
    "id": 411,
    "month": "July",
    "state": "Chhattisgarh",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jul",
    "form": "Form XXIV",
    "remarks": null
  },
  {
    "id": 412,
    "month": "July",
    "state": "Delhi",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Contractor - Half Yearly Returns",
    "dueDate": "30-Jul",
    "form": "Form XXIV",
    "remarks": null
  },
  {
    "id": 413,
    "month": "July",
    "state": "Delhi",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "15-Jul",
    "form": "Form 22",
    "remarks": null
  },
  {
    "id": 414,
    "month": "July",
    "state": "Delhi",
    "act": "Bombay Labour Welfare Fund Act, 1953 (Delhi)",
    "type": "Remittance",
    "Compliance": "Half Yearly Remittance & Return",
    "dueDate": "15-Jul",
    "form": "Form A",
    "remarks": null
  },
  {
    "id": 415,
    "month": "July",
    "state": "Goa",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jul",
    "form": "Form XX",
    "remarks": null
  },
  {
    "id": 416,
    "month": "July",
    "state": "Gujarat",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Contractor - Half Yearly Returns",
    "dueDate": "30-Jul",
    "form": "Form XXIV",
    "remarks": null
  },
  {
    "id": 417,
    "month": "July",
    "state": "Gujarat",
    "act": "Gujarat Panchayats, Municipal Corporations And State Tax on Professions, Traders, Callings and Employments Act, 1976",
    "type": "Return",
    "Compliance": "Monthly Returns",
    "dueDate": "15-Jul",
    "form": "Form 5",
    "remarks": null
  },
  {
    "id": 418,
    "month": "July",
    "state": "Gujarat",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "15-Jul",
    "form": "Form No. 25",
    "remarks": null
  },
  {
    "id": 419,
    "month": "July",
    "state": "Gujarat",
    "act": "Gujarat Labour Welfare Fund Act, 1953",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "31-Jul",
    "form": "Form A-1",
    "remarks": null
  },
  {
    "id": 420,
    "month": "July",
    "state": "Haryana",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jul",
    "form": "Form 20",
    "remarks": null
  },
  {
    "id": 421,
    "month": "July",
    "state": "Himachal Pradesh",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jul",
    "form": "Form XXIV",
    "remarks": null
  },
  {
    "id": 422,
    "month": "July",
    "state": "Jammu & Kashmir",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jul",
    "form": "Form XXIV",
    "remarks": null
  },
  {
    "id": 423,
    "month": "July",
    "state": "Jharkhand",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jul",
    "form": "Form XXIV",
    "remarks": null
  },
  {
    "id": 424,
    "month": "July",
    "state": "Karnataka",
    "act": "Karnataka Tax on Professions, Trades, Callings and Employments Act, 1976",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "20-Jul",
    "form": "Form 9-A or Form 5-A",
    "remarks": null
  },
  {
    "id": 425,
    "month": "July",
    "state": "Kerala",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jul",
    "form": "Form XXIV",
    "remarks": null
  },
  {
    "id": 426,
    "month": "July",
    "state": "Kerala",
    "act": "Kerala Shops and Commercial Establishments Workers Welfare Fund Act, 2006",
    "type": "Remittance",
    "Compliance": "Monthly Contribution",
    "dueDate": "5-Jul",
    "form": "Form 6",
    "remarks": null
  },
  {
    "id": 427,
    "month": "July",
    "state": "Maharashtra",
    "act": "Maharashtra State Tax on Professions, Trade, Callings And Employments Act, 1975",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "31-Jul",
    "form": "Online",
    "remarks": null
  },
  {
    "id": 428,
    "month": "July",
    "state": "Punjab",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jul",
    "form": "Form XXIV",
    "remarks": null
  },
  {
    "id": 429,
    "month": "July",
    "state": "Punjab",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "15-Jul",
    "form": "Form 22",
    "remarks": null
  },
  {
    "id": 430,
    "month": "July",
    "state": "Rajasthan",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jul",
    "form": "Form XX",
    "remarks": null
  },
  {
    "id": 431,
    "month": "July",
    "state": "Tamil Nadu",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jul",
    "form": "Form XXIV",
    "remarks": null
  },
  {
    "id": 432,
    "month": "July",
    "state": "Tamil Nadu",
    "act": "Tamil Nadu Payment of Subsistence Allowance Act, 1981",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "15-Jul",
    "form": "Form 2",
    "remarks": null
  },
  {
    "id": 433,
    "month": "July",
    "state": "Uttar Pradesh",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jul",
    "form": "Form XXIV",
    "remarks": null
  },
  {
    "id": 434,
    "month": "July",
    "state": "Uttarakhand",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jul",
    "form": "Form XXIV",
    "remarks": null
  },
  {
    "id": 435,
    "month": "July",
    "state": "West Bengal",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jul",
    "form": "Form XXIV",
    "remarks": null
  },
  {
    "id": 436,
    "month": "July",
    "state": "West Bengal",
    "act": "West Bengal State Tax on Professions, Trades, Callings and Employments Act, 1979",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "21-Jul",
    "form": "Online",
    "remarks": null
  },
  {
    "id": 437,
    "month": "July",
    "state": "Telangana",
    "act": "Telangana Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "10-Jul",
    "form": "Form V",
    "remarks": null
  },
  {
    "id": 438,
    "month": "July",
    "state": "Assam",
    "act": "Assam Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "31-Jul",
    "form": "Form III, Form III A, Form VII C",
    "remarks": null
  },
  {
    "id": 439,
    "month": "July",
    "state": "Odisha",
    "act": "Orissa State Tax On Professions, Trades, Callings And Employments Act, 2000",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "31-Jul",
    "form": "Form 5",
    "remarks": null
  },
  {
    "id": 440,
    "month": "July",
    "state": "Madhya Pradesh",
    "act": "Madhya Pradesh Vritti Kar Adhiniyam, 1995 (PT)",
    "type": "Remittance",
    "Compliance": "Monthly Remittance",
    "dueDate": "10-Jul",
    "form": null,
    "remarks": null
  },
  {
    "id": 441,
    "month": "July",
    "state": "Assam",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "15-Jul",
    "form": "Form 22",
    "remarks": null
  },
  {
    "id": 442,
    "month": "July",
    "state": "Maharashtra",
    "act": "Maharashtra Labour Welfare Fund Act, 1953",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "15-Jul",
    "form": "Form A-1",
    "remarks": null
  },
  {
    "id": 443,
    "month": "July",
    "state": "Tamil Nadu",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "31-Jul",
    "form": "Form 21",
    "remarks": null
  },
  {
    "id": 444,
    "month": "July",
    "state": "Tamil Nadu",
    "act": "Tamil Nadu Shops And Establishments Act, 1947",
    "type": "Return",
    "Compliance": "Certificate - Half Yearly",
    "dueDate": "31-Jul",
    "form": "Form O",
    "remarks": null
  },
  {
    "id": 445,
    "month": "July",
    "state": "Tamil Nadu",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "Compliance": "Certificate - Half Yearly",
    "dueDate": "31-Jul",
    "form": "Form L",
    "remarks": null
  },
  {
    "id": 446,
    "month": "July",
    "state": "Tamil Nadu",
    "act": "Payment of Gratuity Act, 1972",
    "type": "Return",
    "Compliance": "Certificate - Half Yearly",
    "dueDate": "31-Jul",
    "form": "Form 'V'",
    "remarks": null
  },
  {
    "id": 447,
    "month": "July",
    "state": "Tamil Nadu",
    "act": "Payment of Wages Act, 1936",
    "type": "Return",
    "Compliance": "Certificate - Half Yearly",
    "dueDate": "31-Jul",
    "form": "Form VIII",
    "remarks": null
  },
  {
    "id": 448,
    "month": "July",
    "state": "Madhya Pradesh",
    "act": "Madhya Pradesh Vritti Kar Adhiniyam, 1995 (PT)",
    "type": "Return",
    "Compliance": "Quarterly Return",
    "dueDate": "15-Jul",
    "form": "Form 7",
    "remarks": null
  },
  {
    "id": 449,
    "month": "July",
    "state": "Kerala",
    "act": "Kerala Shops and Commercial Establishments Act, 1960",
    "type": "Return",
    "Compliance": "Quarterly Return",
    "dueDate": "10-Jul",
    "form": "Form - H",
    "remarks": null
  },
  {
    "id": 450,
    "month": "July",
    "state": "Goa",
    "act": "Goa Shops And Establishments Act, 1973",
    "type": "Return",
    "Compliance": "Quarterly Return",
    "dueDate": "10-Jul",
    "form": "Form XXIX",
    "remarks": null
  },
  {
    "id": 451,
    "month": "July",
    "state": "Bihar",
    "act": "Bihar Shops and Establishments Act, 1953",
    "type": "Return",
    "Compliance": "Quarterly Return",
    "dueDate": "30-Jul",
    "form": "Form XIX",
    "remarks": null
  },
  {
    "id": 452,
    "month": "July",
    "state": "Jharkhand",
    "act": "Bihar Shops and Establishments Act, 1953",
    "type": "Return",
    "Compliance": "Quarterly Return",
    "dueDate": "30-Jul",
    "form": "Form XIX",
    "remarks": null
  },
  {
    "id": 453,
    "month": "July",
    "state": "Karnataka",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jul",
    "form": "Form XXIV",
    "remarks": null
  },
  {
    "id": 454,
    "month": "July",
    "state": "Madhya Pradesh",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jul",
    "form": "Form XXIV",
    "remarks": null
  },
  {
    "id": 455,
    "month": "July",
    "state": "Meghalaya",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jul",
    "form": "Form XXIV",
    "remarks": null
  },
  {
    "id": 456,
    "month": "July",
    "state": "Mizoram",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jul",
    "form": "Form XXIV",
    "remarks": null
  },
  {
    "id": 457,
    "month": "July",
    "state": "Nagaland",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jul",
    "form": "Form XXIV",
    "remarks": null
  },
  {
    "id": 458,
    "month": "July",
    "state": "Puducherry",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jul",
    "form": "Form XXIV",
    "remarks": null
  },
  {
    "id": 459,
    "month": "July",
    "state": "Tamil Nadu",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "Compliance": "Certificate - Half Yearly",
    "dueDate": "30-Jul",
    "form": "Form XXX",
    "remarks": null
  },
  {
    "id": 460,
    "month": "July",
    "state": "Odisha",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Jul",
    "form": "Form XX",
    "remarks": null
  },
  {
    "id": 461,
    "month": "July",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Shops And Establishments Act, 1988",
    "type": "Return",
    "Compliance": "Quarterly Return",
    "dueDate": "10-Jul",
    "form": "Form XXVII",
    "remarks": null
  },
  {
    "id": 462,
    "month": "July",
    "state": "Haryana",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "15-Jul",
    "form": "Form No.22",
    "remarks": null
  },
  {
    "id": 463,
    "month": "July",
    "state": "Karnataka",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "15-Jul",
    "form": "Form No. 21",
    "remarks": null
  },
  {
    "id": 464,
    "month": "July",
    "state": "Kerala",
    "act": "Kerala Labour Welfare Fund Act, 1975",
    "type": "Remittance and Return",
    "Compliance": "Half Yearly",
    "dueDate": "15-Jul",
    "form": "Form A",
    "remarks": null
  },
  {
    "id": 465,
    "month": "July",
    "state": "Kerala",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "31-Jul",
    "form": "Form No.22",
    "remarks": null
  },
  {
    "id": 466,
    "month": "July",
    "state": "Chandigarh",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "15-Jul",
    "form": "Form No. 22",
    "remarks": null
  },
  {
    "id": 467,
    "month": "July",
    "state": "Tamil Nadu",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "Compliance": "Certificate - Half Yearly",
    "dueDate": "31-Jul",
    "form": "Form XII",
    "remarks": null
  },
  {
    "id": 468,
    "month": "July",
    "state": "Uttar Pradesh",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "15-Jul",
    "form": "Form No. 22",
    "remarks": null
  },
  {
    "id": 469,
    "month": "July",
    "state": "West Bengal",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "15-Jul",
    "form": "Form No. 23",
    "remarks": null
  },
  {
    "id": 470,
    "month": "July",
    "state": "West Bengal",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Quarterly Return",
    "dueDate": "15-Jul",
    "form": "Form No. 23-A",
    "remarks": null
  },
  {
    "id": 471,
    "month": "July",
    "state": "West Bengal",
    "act": "West Bengal Labour Welfare Fund Act, 1974",
    "type": "Remittance and Return",
    "Compliance": "Half Yearly",
    "dueDate": "15-Jul",
    "form": "Form D",
    "remarks": null
  },
  {
    "id": 472,
    "month": "July",
    "state": "Chhattisgarh",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "15-Jul",
    "form": "Form No. 28",
    "remarks": null
  },
  {
    "id": 473,
    "month": "July",
    "state": "Goa",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "15-Jul",
    "form": "Form No. 35",
    "remarks": null
  },
  {
    "id": 474,
    "month": "July",
    "state": "Madhya Pradesh",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "15-Jul",
    "form": "Form No. 28",
    "remarks": null
  },
  {
    "id": 475,
    "month": "July",
    "state": "Rajasthan",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "15-Jul",
    "form": "Form No. 24",
    "remarks": null
  },
  {
    "id": 476,
    "month": "July",
    "state": "Uttarakhand",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "15-Jul",
    "form": "Form No. 22",
    "remarks": null
  },
  {
    "id": 477,
    "month": "July",
    "state": "Himachal Pradesh",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "15-Jul",
    "form": "Form No. 22",
    "remarks": null
  },
  {
    "id": 478,
    "month": "July",
    "state": "Manipur",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "15-Jul",
    "form": "Form 30",
    "remarks": null
  },
  {
    "id": 479,
    "month": "July",
    "state": "Meghalaya",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "15-Jul",
    "form": "Form 35",
    "remarks": null
  },
  {
    "id": 480,
    "month": "July",
    "state": "Mizoram",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "15-Jul",
    "form": "Form No. 31",
    "remarks": null
  },
  {
    "id": 481,
    "month": "July",
    "state": "Tripura",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "15-Jul",
    "form": "Form 36",
    "remarks": null
  },
  {
    "id": 482,
    "month": "July",
    "state": "Dadra and Nagar Haveli",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "15-Jul",
    "form": "Form No. 35",
    "remarks": null
  },
  {
    "id": 483,
    "month": "July",
    "state": "Daman",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "15-Jul",
    "form": "Form No. 35",
    "remarks": null
  },
  {
    "id": 484,
    "month": "July",
    "state": "Puducherry",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "31-Jul",
    "form": "Form No. 20",
    "remarks": null
  },
  {
    "id": 485,
    "month": "July",
    "state": "Tamil Nadu",
    "act": "Tamil Nadu Labour Welfare Fund Act, 1972",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Jul",
    "form": "Statement even \"NIL\" Along with Form C",
    "remarks": null
  },
  {
    "id": 486,
    "month": "July",
    "state": "Kerala",
    "act": "Kerala Labour Welfare Fund Act, 1975",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Jul",
    "form": "Statement",
    "remarks": null
  },
  {
    "id": 487,
    "month": "July",
    "state": "Karnataka",
    "act": "Karnataka Labour Welfare Fund Act, 1965",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Jul",
    "form": "Statement",
    "remarks": null
  },
  {
    "id": 488,
    "month": "July",
    "state": "Telangana",
    "act": "Telangana Labour Welfare Fund Act, 1987",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Jul",
    "form": "Statement",
    "remarks": null
  },
  {
    "id": 489,
    "month": "July",
    "state": "Gujarat",
    "act": "Gujarat Labour Welfare Fund Act, 1953",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Jul",
    "form": "Form B & Form C",
    "remarks": null
  },
  {
    "id": 490,
    "month": "July",
    "state": "West Bengal",
    "act": "West Bengal Labour Welfare Fund Act, 1974",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Jul",
    "form": "Statement",
    "remarks": null
  },
  {
    "id": 491,
    "month": "July",
    "state": "Maharashtra",
    "act": "Maharashtra Labour Welfare Fund Act, 1953",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Jul",
    "form": "Statement",
    "remarks": null
  },
  {
    "id": 492,
    "month": "July",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Labour Welfare Fund Act, 1987",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Jul",
    "form": "Statement",
    "remarks": null
  },
  {
    "id": 493,
    "month": "July",
    "state": "Madhya Pradesh",
    "act": "The Madhya Pradesh Shram Kalyan Nidhi Adhiniyam, 1982 (LWF)",
    "type": "Remittance and Return",
    "Compliance": "Half Yearly Remittance & Return",
    "dueDate": "15-Jul",
    "form": "Form A",
    "remarks": null
  },
  {
    "id": 494,
    "month": "July",
    "state": "Tripura",
    "act": "Tripura Professions Trades Callings And Employments Taxation Act, 1997",
    "type": "Remittance and Return",
    "Compliance": "Monthly",
    "dueDate": "31-Jul",
    "form": "Form VII",
    "remarks": null
  },
  {
    "id": 495,
    "month": "July",
    "state": "Goa",
    "act": "Goa Labour Welfare Fund Act, 1986",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Jul",
    "form": "Statement",
    "remarks": null
  },
  {
    "id": 496,
    "month": "July",
    "state": "Goa",
    "act": "Goa Labour Welfare Fund Act, 1986",
    "type": "Remittance & Returns",
    "Compliance": "Half Yearly Returns",
    "dueDate": "31-Jul",
    "form": "Form A",
    "remarks": null
  },
  {
    "id": 497,
    "month": "July",
    "state": "Chhattisgarh",
    "act": "Chhattisgarh Shram Kalyan Nidhi Adhiniyam, 1982 (LWF)",
    "type": "Remittance and Return",
    "Compliance": "Half Yearly Remittance & Return",
    "dueDate": "15-Jul",
    "form": "Form A",
    "remarks": null
  },
  {
    "id": 498,
    "month": "July",
    "state": "Chhattisgarh",
    "act": "Chhattisgarh Shram Kalyan Nidhi Adhiniyam, 1982 (LWF)",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Jul",
    "form": "Statement",
    "remarks": null
  },
  {
    "id": 499,
    "month": "July",
    "state": "Madhya Pradesh",
    "act": "The Madhya Pradesh Shram Kalyan Nidhi Adhiniyam, 1982 (LWF)",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Jul",
    "form": "Statement",
    "remarks": null
  },
  {
    "id": 500,
    "month": "July",
    "state": "Delhi",
    "act": "Bombay Labour Welfare Fund Act, 1953 (Delhi)",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Jul",
    "form": "Statement",
    "remarks": null
  },
  {
    "id": 501,
    "month": "July",
    "state": "Odisha",
    "act": "Odisha Labour Welfare Fund Act, 1996",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Jul",
    "form": "Statement",
    "remarks": null
  },
  {
    "id": 502,
    "month": "July",
    "state": "Odisha",
    "act": "Odisha Labour Welfare Fund Act, 1996",
    "type": "Remittance & Returns",
    "Compliance": "Half Yearly Remittance & Return",
    "dueDate": "15-Jul",
    "form": "Form F",
    "remarks": null
  },
  {
    "id": 503,
    "month": "July",
    "state": "Jharkhand",
    "act": "The Jharkhand Tax On Profession, Trades, Callings And Employments Act, 2011",
    "type": "Remittance",
    "Compliance": "Quarterly Return",
    "dueDate": "15-Jul",
    "form": "Form - JPT- 03",
    "remarks": null
  },
  {
    "id": 504,
    "month": "July",
    "state": "Meghalaya",
    "act": "Meghalaya Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance and Return",
    "Compliance": "Monthly",
    "dueDate": "31-Jul",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 505,
    "month": "July",
    "state": "Puducherry",
    "act": "Puducherry Municipalities Act, 1973",
    "type": "Remittance and Return",
    "Compliance": "Half Yearly Remittance & Return",
    "dueDate": "15-Jul",
    "form": null,
    "remarks": null
  },
  {
    "id": 506,
    "month": "July",
    "state": "Sikkim",
    "act": "Sikkim Tax On Professions, Trades, Callings And Employments, Act 2006",
    "type": "Remittance",
    "Compliance": "Monthly",
    "dueDate": "15-Jul",
    "form": null,
    "remarks": null
  },
  {
    "id": 507,
    "month": "July",
    "state": "Sikkim",
    "act": "Sikkim Tax On Professions, Trades, Callings And Employments, Act 2006",
    "type": "Return",
    "Compliance": "Quarterly Return",
    "dueDate": "31-Jul",
    "form": "Form 5",
    "remarks": null
  },
  {
    "id": 508,
    "month": "August",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "10-Aug",
    "form": "Form V",
    "remarks": null
  },
  {
    "id": 509,
    "month": "August",
    "state": "All States",
    "act": "Employee State Insurance Act, 1948",
    "type": "Remittance",
    "Compliance": "Monthly Contribution Challan",
    "dueDate": "15-Aug",
    "form": "Challan",
    "remarks": null
  },
  {
    "id": 510,
    "month": "August",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Remittance",
    "Compliance": "Monthly Contribution Challan",
    "dueDate": "15-Aug",
    "form": "Challan",
    "remarks": null
  },
  {
    "id": 511,
    "month": "August",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "Compliance": "International Workers Return",
    "dueDate": "15-Aug",
    "form": "IW-1",
    "remarks": null
  },
  {
    "id": 512,
    "month": "August",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "Compliance": "Monthly Return - EDLI Exempted Employer",
    "dueDate": "25-Aug",
    "form": "Form - 7(IF)",
    "remarks": null
  },
  {
    "id": 513,
    "month": "August",
    "state": "Gujarat",
    "act": "Gujarat Panchayats, Municipal Corporations And State Tax on Professions, Traders, Callings and Employments Act, 1976",
    "type": "Return",
    "Compliance": "Monthly Returns",
    "dueDate": "15-Aug",
    "form": "Form 5",
    "remarks": null
  },
  {
    "id": 514,
    "month": "August",
    "state": "Karnataka",
    "act": "Karnataka Tax on Professions, Trades, Callings and Employments Act, 1976",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "20-Aug",
    "form": "Form 9-A or Form 5-A",
    "remarks": null
  },
  {
    "id": 515,
    "month": "August",
    "state": "Kerala",
    "act": "Kerala Shops and Commercial Establishments Workers Welfare Fund Act,2006",
    "type": "Remittance",
    "Compliance": "Monthly Contribution",
    "dueDate": "5-Aug",
    "form": "Form 6",
    "remarks": null
  },
  {
    "id": 516,
    "month": "August",
    "state": "Maharashtra",
    "act": "Maharashtra State Tax on Professions, Trade, Callings And Employments Act, 1975",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "31-Aug",
    "form": "Online",
    "remarks": null
  },
  {
    "id": 517,
    "month": "August",
    "state": "West Bengal",
    "act": "West Bengal State Tax on Professions, Trades, Callings and Employments Act, 1979",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "21-Aug",
    "form": "Online",
    "remarks": null
  },
  {
    "id": 518,
    "month": "August",
    "state": "Telangana",
    "act": "Telangana Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "10-Aug",
    "form": "Form V",
    "remarks": null
  },
  {
    "id": 519,
    "month": "August",
    "state": "Assam",
    "act": "Assam Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "31-Aug",
    "form": "Form III, Form III A, Form VII C",
    "remarks": null
  },
  {
    "id": 520,
    "month": "August",
    "state": "Odisha",
    "act": "Orissa State Tax On Professions, Trades, Callings And Employments Act, 2000",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "31-Aug",
    "form": "Form 5",
    "remarks": null
  },
  {
    "id": 521,
    "month": "August",
    "state": "Madhya Pradesh",
    "act": "Madhya Pradesh Vritti Kar Adhiniyam, 1995 (PT)",
    "type": "Remittance",
    "Compliance": "Monthly Remittance",
    "dueDate": "10-Aug",
    "form": null,
    "remarks": null
  },
  {
    "id": 522,
    "month": "August",
    "state": "Kerala",
    "act": "Kerala Panchayat Raj Act, 1994 (PT)",
    "type": "Remittance",
    "Compliance": "Half Yearly Remittance",
    "dueDate": "31-Aug",
    "form": "Offline",
    "remarks": null
  },
  {
    "id": 523,
    "month": "August",
    "state": "Haryana",
    "act": "Punjab Labour Welfare Fund Act,1965",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "1-Aug",
    "form": "Statement",
    "remarks": null
  },
  {
    "id": 524,
    "month": "August",
    "state": "Tripura",
    "act": "Tripura Professions Trades Callings And Employments Taxation Act, 1997",
    "type": "Remittance and Return",
    "Compliance": "Monthly",
    "dueDate": "31-Aug",
    "form": "Form VII",
    "remarks": null
  },
  {
    "id": 525,
    "month": "August",
    "state": "Punjab",
    "act": "Punjab Labour Welfare Fund Act,1965",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "1-Aug",
    "form": "Statement",
    "remarks": null
  },
  {
    "id": 526,
    "month": "August",
    "state": "Chandigarh",
    "act": "Punjab Labour Welfare Fund Act, 1965",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "1-Aug",
    "form": "Statement",
    "remarks": null
  },
  {
    "id": 527,
    "month": "August",
    "state": "Meghalaya",
    "act": "Meghalaya Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance and Return",
    "Compliance": "Monthly",
    "dueDate": "31-Aug",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 528,
    "month": "August",
    "state": "Sikkim",
    "act": "Sikkim Tax On Professions, Trades, Callings And Employments, Act 2006",
    "type": "Remittance",
    "Compliance": "Monthly",
    "dueDate": "15-Aug",
    "form": null,
    "remarks": null
  },
  {
    "id": 529,
    "month": "September",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "10-Sep",
    "form": "Form V",
    "remarks": null
  },
  {
    "id": 530,
    "month": "September",
    "state": "All States",
    "act": "Employee State Insurance Act, 1948",
    "type": "Remittance",
    "Compliance": "Monthly Contribution Challan",
    "dueDate": "15-Sep",
    "form": "Challan",
    "remarks": null
  },
  {
    "id": 531,
    "month": "September",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Remittance",
    "Compliance": "Monthly Contribution Challan",
    "dueDate": "15-Sep",
    "form": "Challan",
    "remarks": null
  },
  {
    "id": 532,
    "month": "September",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "Compliance": "International Workers Return",
    "dueDate": "15-Sep",
    "form": "IW-1",
    "remarks": null
  },
  {
    "id": 533,
    "month": "September",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "Compliance": "Monthly Return - EDLI Exempted Employer",
    "dueDate": "25-Sep",
    "form": "Form - 7(IF)",
    "remarks": null
  },
  {
    "id": 534,
    "month": "September",
    "state": "Gujarat",
    "act": "Gujarat Panchayats, Municipal Corporations And State Tax on Professions, Traders, Callings and Employments Act, 1976",
    "type": "Return",
    "Compliance": "Monthly Returns",
    "dueDate": "15-Sep",
    "form": "Form 5",
    "remarks": null
  },
  {
    "id": 535,
    "month": "September",
    "state": "Karnataka",
    "act": "Karnataka Tax on Professions, Trades, Callings and Employments Act, 1976",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "20-Sep",
    "form": "Form 9-A or Form 5-A",
    "remarks": null
  },
  {
    "id": 536,
    "month": "September",
    "state": "Kerala",
    "act": "Kerala Shops and Commercial Establishments Workers Welfare Fund Act, 2006",
    "type": "Remittance",
    "Compliance": "Monthly Contribution",
    "dueDate": "5-Sep",
    "form": "Form 6",
    "remarks": null
  },
  {
    "id": 537,
    "month": "September",
    "state": "Maharashtra",
    "act": "Maharashtra State Tax on Professions, Trade, Callings And Employments Act, 1975",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "30-Sep",
    "form": "Online",
    "remarks": null
  },
  {
    "id": 538,
    "month": "September",
    "state": "West Bengal",
    "act": "West Bengal State Tax on Professions, Trades, Callings and Employments Act, 1979",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "21-Sep",
    "form": "Online",
    "remarks": null
  },
  {
    "id": 539,
    "month": "September",
    "state": "Telangana",
    "act": "Telangana Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "10-Sep",
    "form": "Form V",
    "remarks": null
  },
  {
    "id": 540,
    "month": "September",
    "state": "Assam",
    "act": "Assam Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "30-Sep",
    "form": "Form III, Form III A, Form VII C",
    "remarks": null
  },
  {
    "id": 541,
    "month": "September",
    "state": "Odisha",
    "act": "Orissa State Tax On Professions, Trades, Callings And Employments Act, 2000",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "30-Sep",
    "form": "Form 5",
    "remarks": null
  },
  {
    "id": 542,
    "month": "September",
    "state": "Madhya Pradesh",
    "act": "Madhya Pradesh Vritti Kar Adhiniyam, 1995 (PT)",
    "type": "Remittance",
    "Compliance": "Monthly Remittance",
    "dueDate": "10-Sep",
    "form": null,
    "remarks": null
  },
  {
    "id": 543,
    "month": "September",
    "state": "Tamil Nadu",
    "act": "Tamil Nadu Municipal Laws (Second Amendment) Act, 1998 & Tamil Nadu Professional Tax Rules, 1999",
    "type": "Remittance",
    "Compliance": "Remittance and Return",
    "dueDate": "30-Sep",
    "form": "Form 2",
    "remarks": null
  },
  {
    "id": 544,
    "month": "September",
    "state": "Tripura",
    "act": "Tripura Professions Trades Callings And Employments Taxation Act, 1997",
    "type": "Remittance and Return",
    "Compliance": "Monthly",
    "dueDate": "30-Sep",
    "form": "Form VII",
    "remarks": null
  },
  {
    "id": 545,
    "month": "September",
    "state": "Meghalaya",
    "act": "Meghalaya Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance and Return",
    "Compliance": "Monthly",
    "dueDate": "30-Sep",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 546,
    "month": "September",
    "state": "Nagaland",
    "act": "Nagaland Professionals,Trades,Calling and Employment Taxation (4th Amendment) Act, 2012",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "30-Sep",
    "form": "Form V & Form VA",
    "remarks": null
  },
  {
    "id": 547,
    "month": "September",
    "state": "Sikkim",
    "act": "Sikkim Tax On Professions, Trades, Callings And Employments, Act 2006",
    "type": "Remittance",
    "Compliance": "Monthly",
    "dueDate": "15-Sep",
    "form": null,
    "remarks": null
  },
  {
    "id": 548,
    "month": "October",
    "state": "All States",
    "act": "Employment Exchange (Compulsory Notification of Vacancies) Act, 1959",
    "type": "Return",
    "Compliance": "Quarterly Return",
    "dueDate": "30-Oct",
    "form": "ER-1",
    "remarks": null
  },
  {
    "id": 549,
    "month": "October",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "10-Oct",
    "form": "Form V",
    "remarks": null
  },
  {
    "id": 550,
    "month": "October",
    "state": "All States",
    "act": "Employee State Insurance Act, 1948",
    "type": "Remittance",
    "Compliance": "Monthly Contribution Challan",
    "dueDate": "15-Oct",
    "form": "Challan",
    "remarks": null
  },
  {
    "id": 551,
    "month": "October",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Remittance",
    "Compliance": "Monthly Contribution Challan",
    "dueDate": "15-Oct",
    "form": "Challan",
    "remarks": null
  },
  {
    "id": 552,
    "month": "October",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "Compliance": "International Workers Return",
    "dueDate": "15-Oct",
    "form": "IW-1",
    "remarks": null
  },
  {
    "id": 553,
    "month": "October",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "Compliance": "Monthly Return - EDLI Exempted Employer",
    "dueDate": "25-Oct",
    "form": "Form - 7(IF)",
    "remarks": null
  },
  {
    "id": 554,
    "month": "October",
    "state": "Goa",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "30-Oct",
    "form": "Form XX",
    "remarks": null
  },
  {
    "id": 555,
    "month": "October",
    "state": "Gujarat",
    "act": "Gujarat Panchayats, Municipal Corporations And State Tax on Professions, Traders, Callings and Employments Act, 1976",
    "type": "Return",
    "Compliance": "Monthly Returns",
    "dueDate": "15-Oct",
    "form": "Form 5",
    "remarks": null
  },
  {
    "id": 556,
    "month": "October",
    "state": "Karnataka",
    "act": "Karnataka Tax on Professions, Trades, Callings and Employments Act, 1976",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "20-Oct",
    "form": "Form 9-A or Form 5-A",
    "remarks": null
  },
  {
    "id": 557,
    "month": "October",
    "state": "Kerala",
    "act": "Kerala Shops and Commercial Establishments Workers Welfare Fund Act, 2006",
    "type": "Remittance",
    "Compliance": "Monthly Contribution",
    "dueDate": "5-Oct",
    "form": "Form 6",
    "remarks": null
  },
  {
    "id": 558,
    "month": "October",
    "state": "Maharashtra",
    "act": "Maharashtra State Tax on Professions, Trade, Callings And Employments Act, 1975",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "31-Oct",
    "form": "Online",
    "remarks": null
  },
  {
    "id": 559,
    "month": "October",
    "state": "West Bengal",
    "act": "West Bengal State Tax on Professions, Trades, Callings and Employments Act, 1979",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "21-Oct",
    "form": "Online",
    "remarks": null
  },
  {
    "id": 560,
    "month": "October",
    "state": "Telangana",
    "act": "Telangana Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "10-Oct",
    "form": "Form V",
    "remarks": null
  },
  {
    "id": 561,
    "month": "October",
    "state": "Assam",
    "act": "Assam Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "31-Oct",
    "form": "Form III, Form III A, Form VII C",
    "remarks": null
  },
  {
    "id": 562,
    "month": "October",
    "state": "Odisha",
    "act": "Orissa State Tax On Professions, Trades, Callings And Employments Act, 2000",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "31-Oct",
    "form": "Form 5",
    "remarks": null
  },
  {
    "id": 563,
    "month": "October",
    "state": "Madhya Pradesh",
    "act": "Madhya Pradesh Vritti Kar Adhiniyam, 1995 (PT)",
    "type": "Remittance",
    "Compliance": "Monthly Remittance",
    "dueDate": "10-Oct",
    "form": null,
    "remarks": null
  },
  {
    "id": 564,
    "month": "October",
    "state": "Chandigarh",
    "act": "Punjab Labour Welfare Fund Act, 1965",
    "type": "Remittance",
    "Compliance": "Remittance and Return",
    "dueDate": "15-Oct",
    "form": null,
    "remarks": null
  },
  {
    "id": 565,
    "month": "October",
    "state": "Jharkhand",
    "act": "The Jharkhand Tax On Profession, Trades, Callings And Employments Act, 2011",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Oct",
    "form": "Form JPT 203",
    "remarks": null
  },
  {
    "id": 566,
    "month": "October",
    "state": "Madhya Pradesh",
    "act": "Madhya Pradesh Vritti Kar Adhiniyam, 1995 (PT)",
    "type": "Return",
    "Compliance": "Quarterly Return",
    "dueDate": "15-Oct",
    "form": "Form 7",
    "remarks": null
  },
  {
    "id": 567,
    "month": "October",
    "state": "Kerala",
    "act": "Kerala Shops and Commercial Establishments Act, 1960",
    "type": "Return",
    "Compliance": "Quarterly Returns",
    "dueDate": "10-Oct",
    "form": "Form -H",
    "remarks": null
  },
  {
    "id": 568,
    "month": "October",
    "state": "Goa",
    "act": "Goa Shops And Establishments Act, 1973",
    "type": "Return",
    "Compliance": "Quarterly Returns",
    "dueDate": "10-Oct",
    "form": "Form XXIX",
    "remarks": null
  },
  {
    "id": 569,
    "month": "October",
    "state": "Bihar",
    "act": "Bihar Shops and Establishments Act, 1953",
    "type": "Return",
    "Compliance": "Quarterly Return",
    "dueDate": "30-Oct",
    "form": "Form XIX",
    "remarks": null
  },
  {
    "id": 570,
    "month": "October",
    "state": "Jharkhand",
    "act": "Bihar Shops and Establishments Act, 1953",
    "type": "Return",
    "Compliance": "Quarterly Return",
    "dueDate": "30-Oct",
    "form": "Form XIX",
    "remarks": null
  },
  {
    "id": 571,
    "month": "October",
    "state": "Punjab",
    "act": "Punjab Labour Welfare Fund Act, 1965",
    "type": "Remittance",
    "Compliance": "Half Yearly Remittance & Return",
    "dueDate": "15-Oct",
    "form": null,
    "remarks": null
  },
  {
    "id": 572,
    "month": "October",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Shops And Establishments Act, 1988",
    "type": "Return",
    "Compliance": "Quarterly Return",
    "dueDate": "10-Oct",
    "form": "Form XXVII",
    "remarks": null
  },
  {
    "id": 573,
    "month": "October",
    "state": "West Bengal",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Quarterly Return",
    "dueDate": "15-Oct",
    "form": "Form No. 23-A",
    "remarks": null
  },
  {
    "id": 574,
    "month": "October",
    "state": "Tamil Nadu",
    "act": "Tamil Nadu Labour Welfare Fund Act, 1972",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Oct",
    "form": "Statement even 'NIL' Along with Form C",
    "remarks": null
  },
  {
    "id": 575,
    "month": "October",
    "state": "Karnataka",
    "act": "Karnataka Labour Welfare Fund Act, 1965",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Oct",
    "form": "Statement",
    "remarks": null
  },
  {
    "id": 576,
    "month": "October",
    "state": "Telangana",
    "act": "Telangana Labour Welfare Fund Act, 1987",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Oct",
    "form": "Statement",
    "remarks": null
  },
  {
    "id": 577,
    "month": "October",
    "state": "Gujarat",
    "act": "Gujarat Labour Welfare Fund Act, 1953",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Oct",
    "form": "Form B & Form C",
    "remarks": null
  },
  {
    "id": 578,
    "month": "October",
    "state": "West Bengal",
    "act": "West Bengal Labour Welfare Fund Act, 1974",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Oct",
    "form": "Statement",
    "remarks": null
  },
  {
    "id": 579,
    "month": "October",
    "state": "Maharashtra",
    "act": "Maharashtra Labour Welfare Fund Act, 1953",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Oct",
    "form": "Statement",
    "remarks": null
  },
  {
    "id": 580,
    "month": "October",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Labour Welfare Fund Act, 1987",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Oct",
    "form": "Statement",
    "remarks": null
  },
  {
    "id": 581,
    "month": "October",
    "state": "Tripura",
    "act": "Tripura Professions Trades Callings And Employments Taxation Act, 1997",
    "type": "Remittance and Return",
    "Compliance": "Monthly",
    "dueDate": "31-Oct",
    "form": "Form VII",
    "remarks": null
  },
  {
    "id": 582,
    "month": "October",
    "state": "Goa",
    "act": "Goa Labour Welfare Fund Act, 1986",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Oct",
    "form": "Statement",
    "remarks": null
  },
  {
    "id": 583,
    "month": "October",
    "state": "Chhattisgarh",
    "act": "Chhattisgarh Shram Kalyan Nidhi Adhiniyam, 1982 (LWF)",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Oct",
    "form": "Statement",
    "remarks": null
  },
  {
    "id": 584,
    "month": "October",
    "state": "Madhya Pradesh",
    "act": "The Madhya Pradesh Shram Kalyan Nidhi  Adhiniyam, 1982 (LWF)",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Oct",
    "form": "Statement",
    "remarks": null
  },
  {
    "id": 585,
    "month": "October",
    "state": "Delhi",
    "act": "Bombay Labour Welfare Fund Act, 1953 (Delhi)",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Oct",
    "form": "Statement",
    "remarks": null
  },
  {
    "id": 586,
    "month": "October",
    "state": "Odisha",
    "act": "Odisha Labour Welfare Fund Act, 1996",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation & Fine",
    "dueDate": "15-Oct",
    "form": "Statement",
    "remarks": null
  },
  {
    "id": 587,
    "month": "October",
    "state": "Jharkhand",
    "act": "The Jharkhand Tax On Profession, Trades, Callings And Employments Act, 2011",
    "type": "Remittance",
    "Compliance": "Quarterly Return",
    "dueDate": "15-Oct",
    "form": "Form - JPT- 03",
    "remarks": null
  },
  {
    "id": 588,
    "month": "October",
    "state": "Meghalaya",
    "act": "Meghalaya Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance and Return",
    "Compliance": "Monthly",
    "dueDate": "31-Oct",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 589,
    "month": "October",
    "state": "Sikkim",
    "act": "Sikkim Tax On Professions, Trades, Callings And Employments,  Act 2006",
    "type": "Remittance",
    "Compliance": "Monthly",
    "dueDate": "15-Oct",
    "form": null,
    "remarks": null
  },
  {
    "id": 590,
    "month": "October",
    "state": "Sikkim",
    "act": "Sikkim Tax On Professions, Trades, Callings And Employments,  Act 2006",
    "type": "Return",
    "Compliance": "Quarterly Return",
    "dueDate": "31-Oct",
    "form": "Form 5",
    "remarks": null
  },
  {
    "id": 591,
    "month": "November",
    "state": "Haryana",
    "act": "Punjab Labour Welfare Fund Act,1965",
    "type": "Remittance",
    "Compliance": "Unpaid Accumulation",
    "dueDate": "1-Nov",
    "form": null,
    "remarks": "Unpaid Accumulation - After 2 years from become payable"
  },
  {
    "id": 592,
    "month": "November",
    "state": "Kerala",
    "act": "Kerala Shops and Commercial Establishments Workers Welfare Fund Act,2006",
    "type": null,
    "Compliance": null,
    "dueDate": null,
    "form": null,
    "remarks": null
  },
  {
    "id": 593,
    "month": "November",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "Compliance": "Monthly Contribution",
    "dueDate": "5-Nov",
    "form": "Form 6",
    "remarks": null
  },
  {
    "id": 594,
    "month": "November",
    "state": "Telangana",
    "act": "Telangana Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "10-Nov",
    "form": "Form V",
    "remarks": null
  },
  {
    "id": 595,
    "month": "November",
    "state": "Madhya Pradesh",
    "act": "Madhya Pradesh Vritti Kar Adhiniyam, 1995 (PT)",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "10-Nov",
    "form": "Form V",
    "remarks": null
  },
  {
    "id": 596,
    "month": "November",
    "state": "All States",
    "act": "Employee State Insurance Act, 1948",
    "type": "Remittance",
    "Compliance": "Monthly Remittance",
    "dueDate": "10-Nov",
    "form": null,
    "remarks": null
  },
  {
    "id": 597,
    "month": "November",
    "state": "All States",
    "act": "Employee State Insurance Act, 1948",
    "type": "Return",
    "Compliance": "Half Yearly Returns",
    "dueDate": "11-Nov",
    "form": "Form 5",
    "remarks": "For period Apr to Sep (after completing the period 42 day time is there to file the return)"
  },
  {
    "id": 598,
    "month": "November",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Remittance",
    "Compliance": "Monthly Contribution Challan",
    "dueDate": "15-Nov",
    "form": "Challan",
    "remarks": null
  },
  {
    "id": 599,
    "month": "November",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Remittance",
    "Compliance": "Monthly Contribution Challan",
    "dueDate": "15-Nov",
    "form": "Challan",
    "remarks": null
  },
  {
    "id": 600,
    "month": "November",
    "state": "Gujarat",
    "act": "Gujarat Panchayats, Municipal Corporations And State Tax on Professions, Traders, Callings and Employments Act, 1976",
    "type": "Return",
    "Compliance": "International Workers Return",
    "dueDate": "15-Nov",
    "form": "IW-1",
    "remarks": null
  },
  {
    "id": 601,
    "month": "November",
    "state": "Karnataka",
    "act": "Karnataka Tax on Professions, Trades, Callings and Employments Act, 1976",
    "type": "Return",
    "Compliance": "Monthly Returns",
    "dueDate": "15-Nov",
    "form": "Form 5",
    "remarks": null
  },
  {
    "id": 602,
    "month": "November",
    "state": "Karnataka",
    "act": "Karnataka Tax on Professions, Trades, Callings and Employments Act, 1976",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "20-Nov",
    "form": "Form 9-A or Form 5-A",
    "remarks": null
  },
  {
    "id": 603,
    "month": "November",
    "state": "West Bengal",
    "act": "West Bengal State Tax on Professions, Trades, Callings and Employments Act, 1979",
    "type": null,
    "Compliance": null,
    "dueDate": null,
    "form": null,
    "remarks": null
  },
  {
    "id": 604,
    "month": "November",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "21-Nov",
    "form": "Online",
    "remarks": null
  },
  {
    "id": 605,
    "month": "November",
    "state": "Kerala",
    "act": "Kerala Industrial Establishments (National and Festival Holidays) Act, 1958",
    "type": "Return",
    "Compliance": "Monthly Return - EDLI Exempted Employer",
    "dueDate": "25-Nov",
    "form": "Form - 7(IF)",
    "remarks": null
  },
  {
    "id": 606,
    "month": "November",
    "state": "Maharashtra",
    "act": "Maharashtra State Tax on Professions, Trade, Callings And Employments Act, 1975",
    "type": "Return",
    "Compliance": "Holiday Intimation",
    "dueDate": "30-Nov",
    "form": "Form I",
    "remarks": null
  },
  {
    "id": 607,
    "month": "November",
    "state": "Assam",
    "act": "Assam Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "30-Nov",
    "form": "Online",
    "remarks": null
  },
  {
    "id": 608,
    "month": "November",
    "state": "Odisha",
    "act": "Orissa State Tax On Professions, Trades, Callings And Employments Act, 2000",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "30-Nov",
    "form": "Form III, Form III A, Form VII C",
    "remarks": null
  },
  {
    "id": 609,
    "month": "November",
    "state": "Bihar",
    "act": "Bihar Tax On Professions, Trades, Callings And Employments Act, 2011",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "30-Nov",
    "form": "Form 5",
    "remarks": null
  },
  {
    "id": 610,
    "month": "November",
    "state": null,
    "act": null,
    "type": "Annual Returns",
    "Compliance": null,
    "dueDate": "30-Nov",
    "form": "Form PT - VIII & Form PT - IX",
    "remarks": null
  },
  {
    "id": 611,
    "month": "December",
    "state": "Kerala",
    "act": "Kerala Shops and Commercial Establishments Workers Welfare Fund Act,2006",
    "type": "Remittance",
    "Compliance": "Monthly Contribution",
    "dueDate": "5-Dec",
    "form": "Form 6",
    "remarks": null
  },
  {
    "id": 612,
    "month": "December",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "10-Dec",
    "form": "Form V",
    "remarks": null
  },
  {
    "id": 613,
    "month": "December",
    "state": "Telangana",
    "act": "Telangana Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "10-Dec",
    "form": "Form V",
    "remarks": null
  },
  {
    "id": 614,
    "month": "December",
    "state": "Madhya Pradesh",
    "act": "Madhya Pradesh Vritti Kar Adhiniyam, 1995 (PT)",
    "type": "Remittance",
    "Compliance": "Monthly Remittance",
    "dueDate": "10-Dec",
    "form": null,
    "remarks": null
  },
  {
    "id": 615,
    "month": "December",
    "state": "All States",
    "act": "Employee State Insurance Act, 1948",
    "type": "Remittance",
    "Compliance": "Monthly Contribution Challan",
    "dueDate": "15-Dec",
    "form": "Challan",
    "remarks": null
  },
  {
    "id": 616,
    "month": "December",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Remittance",
    "Compliance": "Monthly Contribution Challan",
    "dueDate": "15-Dec",
    "form": "Challan",
    "remarks": null
  },
  {
    "id": 617,
    "month": "December",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "Compliance": "International Workers Return",
    "dueDate": "15-Dec",
    "form": "IW-1",
    "remarks": null
  },
  {
    "id": 618,
    "month": "December",
    "state": "Gujarat",
    "act": "Gujarat Panchayats, Municipal Corporations And State Tax on Professions, Traders, Callings and Employments Act, 1976",
    "type": "Return",
    "Compliance": "Monthly Returns",
    "dueDate": "15-Dec",
    "form": "Form 5",
    "remarks": null
  },
  {
    "id": 619,
    "month": "December",
    "state": "Sikkim",
    "act": "Sikkim Tax On Professions, Trades, Callings And Employments, Act 2006",
    "type": "Remittance",
    "Compliance": "Monthly",
    "dueDate": "15-Dec",
    "form": null,
    "remarks": null
  },
  {
    "id": 620,
    "month": "December",
    "state": "Gujarat",
    "act": "Gujarat Panchayats, Municipal Corporations And State Tax on Professions, Traders, Callings and Employments Act, 1976",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "20-Dec",
    "form": "Form 5A, Form 5AA, Form 5C",
    "remarks": null
  },
  {
    "id": 621,
    "month": "December",
    "state": "Karnataka",
    "act": "Karnataka Tax on Professions, Trades, Callings and Employments Act, 1976",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "20-Dec",
    "form": "Form 9-A or Form 5-A",
    "remarks": null
  },
  {
    "id": 622,
    "month": "December",
    "state": "West Bengal",
    "act": "West Bengal State Tax on Professions, Trades, Callings and Employments Act, 1979",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "21-Dec",
    "form": "Online",
    "remarks": null
  },
  {
    "id": 623,
    "month": "December",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "Compliance": "Monthly Return - EDLI Exempted Employer",
    "dueDate": "25-Dec",
    "form": "Form - 7(IF)",
    "remarks": null
  },
  {
    "id": 624,
    "month": "December",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Factories And Establishments (National, Festival And Other Holidays) Act, 1974",
    "type": "Return",
    "Compliance": "Holiday Notification",
    "dueDate": "31-Dec",
    "form": "Form I",
    "remarks": "List of Holidays"
  },
  {
    "id": 625,
    "month": "December",
    "state": "Maharashtra",
    "act": "Maharashtra State Tax on Professions, Trade, Callings And Employments Act, 1975",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "31-Dec",
    "form": "Online",
    "remarks": null
  },
  {
    "id": 626,
    "month": "December",
    "state": "Delhi",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Holiday Intimation",
    "dueDate": "31-Dec",
    "form": "List of Holidays",
    "remarks": null
  },
  {
    "id": 627,
    "month": "December",
    "state": "Tamil Nadu",
    "act": "Tamil Nadu Industrial Establishments (National, festival and special holidays) Act, 1958",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Dec",
    "form": "Form V",
    "remarks": null
  },
  {
    "id": 628,
    "month": "December",
    "state": "Tamil Nadu",
    "act": "Tamil Nadu Industrial Establishments (National, festival and special holidays) Act, 1958",
    "type": "Return",
    "Compliance": "Application on Change of Holidays",
    "dueDate": "31-Dec",
    "form": "Form IV",
    "remarks": "If any changes in approved holidays."
  },
  {
    "id": 629,
    "month": "December",
    "state": "Odisha",
    "act": "Self Certificate - UNDER ORISSA SHOPS AND COMMERCIAL ESTABLISHMENTS ACT, 1956",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Dec",
    "form": "Form 15",
    "remarks": null
  },
  {
    "id": 630,
    "month": "December",
    "state": "Assam",
    "act": "Assam Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "31-Dec",
    "form": "Form III, Form III A, Form VII C",
    "remarks": null
  },
  {
    "id": 631,
    "month": "December",
    "state": "Odisha",
    "act": "Orissa State Tax On Professions, Trades, Callings And Employments Act, 2000",
    "type": "Remittance",
    "Compliance": "Monthly Remittance & Filing of Return",
    "dueDate": "31-Dec",
    "form": "Form 5",
    "remarks": null
  },
  {
    "id": 632,
    "month": "December",
    "state": "Maharashtra",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Holiday Intimation",
    "dueDate": "31-Dec",
    "form": "List of Holidays",
    "remarks": null
  },
  {
    "id": 633,
    "month": "December",
    "state": "Delhi",
    "act": "Punjab Industrial Establishments (National and Festival Holidays and Casual and Sick Leave) Rules, 1966",
    "type": "Return",
    "Compliance": "Holiday Intimation",
    "dueDate": "31-Dec",
    "form": "Form A",
    "remarks": "List of Holidays"
  },
  {
    "id": 634,
    "month": "December",
    "state": "Gujarat",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Holiday Intimation",
    "dueDate": "31-Dec",
    "form": "List of Holidays",
    "remarks": null
  },
  {
    "id": 635,
    "month": "December",
    "state": "Assam",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "NFH Intimation",
    "dueDate": "31-Dec",
    "form": "List of Holidays",
    "remarks": null
  },
  {
    "id": 636,
    "month": "December",
    "state": "Bihar",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Holiday Intimation",
    "dueDate": "31-Dec",
    "form": "List of Holidays",
    "remarks": null
  },
  {
    "id": 637,
    "month": "December",
    "state": "Chhattisgarh",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Holiday Intimation",
    "dueDate": "31-Dec",
    "form": "List of Holidays",
    "remarks": null
  },
  {
    "id": 638,
    "month": "December",
    "state": "Goa",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Holiday Intimation",
    "dueDate": "31-Dec",
    "form": "List of Holidays",
    "remarks": null
  },
  {
    "id": 639,
    "month": "December",
    "state": "Karnataka",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Holiday Intimation",
    "dueDate": "31-Dec",
    "form": "List of Holidays",
    "remarks": null
  },
  {
    "id": 640,
    "month": "December",
    "state": "Madhya Pradesh",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Holiday Intimation",
    "dueDate": "31-Dec",
    "form": "List of Holidays",
    "remarks": null
  },
  {
    "id": 641,
    "month": "December",
    "state": "Jharkhand",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Holiday Intimation",
    "dueDate": "31-Dec",
    "form": "List of Holidays",
    "remarks": null
  },
  {
    "id": 642,
    "month": "December",
    "state": "Himachal Pradesh",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Holiday Intimation",
    "dueDate": "31-Dec",
    "form": "List of Holidays",
    "remarks": null
  },
  {
    "id": 643,
    "month": "December",
    "state": "Mizoram",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Holiday Intimation",
    "dueDate": "31-Dec",
    "form": "List of Holidays",
    "remarks": null
  },
  {
    "id": 644,
    "month": "December",
    "state": "Dadra and Nagar Haveli",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Holiday Intimation",
    "dueDate": "31-Dec",
    "form": "List of Holidays",
    "remarks": null
  },
  {
    "id": 645,
    "month": "December",
    "state": "Daman",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Holiday Intimation",
    "dueDate": "31-Dec",
    "form": "List of Holidays",
    "remarks": null
  },
  {
    "id": 646,
    "month": "December",
    "state": "Odisha",
    "act": "Orissa Industrial Establishments (National And Festival) Holidays Act, 1969",
    "type": "Return",
    "Compliance": "Combined Annual Returns",
    "dueDate": "31-Dec",
    "form": "Form V",
    "remarks": null
  },
  {
    "id": 647,
    "month": "December",
    "state": "Odisha",
    "act": "Orissa Industrial Establishments (National And Festival) Holidays Act, 1969",
    "type": "Return",
    "Compliance": "Self Certification undertaking",
    "dueDate": "31-Dec",
    "form": "Form VII",
    "remarks": null
  },
  {
    "id": 648,
    "month": "December",
    "state": "Telangana",
    "act": "Telangana Factories And Establishments (National, Festival And Other Holidays) Act, 1974",
    "type": "Return",
    "Compliance": "Holiday Intimation",
    "dueDate": "31-Dec",
    "form": "Form I",
    "remarks": "List of Holidays"
  },
  {
    "id": 649,
    "month": "December",
    "state": "Haryana",
    "act": "Punjab Industrial Establishment (National And Festival Holidays And Casual And Sick Leave) Act 1965",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Dec",
    "form": "Form A",
    "remarks": "List of Holidays"
  },
  {
    "id": 650,
    "month": "December",
    "state": "Tripura",
    "act": "Tripura Professions Trades Callings And Employments Taxation Act, 1997",
    "type": "Remittance and Return",
    "Compliance": "Monthly",
    "dueDate": "31-Dec",
    "form": "Form VII",
    "remarks": null
  },
  {
    "id": 651,
    "month": "December",
    "state": "Meghalaya",
    "act": "Meghalaya Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance and Return",
    "Compliance": "Monthly",
    "dueDate": "31-Dec",
    "form": "Form III",
    "remarks": null
  },
  {
    "id": 652,
    "month": "December",
    "state": "Himachal Pradesh",
    "act": "Himachal Pradesh Industrial Establishments (National And Festival Holidays And Casual And Sick Leave) Act, 1969",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Dec",
    "form": "Form A",
    "remarks": "List of Holidays"
  },
  {
    "id": 653,
    "month": "December",
    "state": "Punjab",
    "act": "Punjab Industrial Establishment (National And Festival Holidays And Casual And Sick Leave) Act 1965",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Dec",
    "form": "Form A",
    "remarks": "List of Holidays"
  },
  {
    "id": 654,
    "month": "December",
    "state": "Puducherry",
    "act": "Puducherry Industrial Establishments (National And Festival Holidays) Act, 1964",
    "type": "Return",
    "Compliance": "Holiday Intimation",
    "dueDate": "31-Dec",
    "form": "Form V",
    "remarks": "List of Holidays"
  },
  {
    "id": 655,
    "month": "December",
    "state": "All States",
    "act": "Factories Act, 1948",
    "type": "Return",
    "Compliance": "Holiday Intimation",
    "dueDate": "31-Dec",
    "form": "List of Holidays",
    "remarks": null
  },
  {
    "id": 656,
    "month": "December",
    "state": "Karnataka",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "Compliance": "Annual Returns",
    "dueDate": "31-Dec",
    "form": "Form D",
    "remarks": "1. Bonus amount has to pay within a period of eight months from the close of the Accounting year\n2. Filing Form D on or before 31st of December otherwise after paid the Bonus on or before one month."
  }
]

export const CALENDAR_DATA_V2 = [
  {
    "id": 1,
    "month": "January",
    "state": "Kerala",
    "act": "Kerala Shops and Commercial Establishments Workers Welfare Fund Act, 2006",
    "type": "Remittance",
    "category": "Monthly Contribution",
    "due_date": "5-Jan",
    "form": "Form 6",
    "remarks": ""
  },
  {
    "id": 2,
    "month": "January",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "10-Jan",
    "form": "Form V",
    "remarks": ""
  },
  {
    "id": 3,
    "month": "January",
    "state": "Telangana",
    "act": "Telangana Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "10-Jan",
    "form": "Form V",
    "remarks": ""
  },
  {
    "id": 4,
    "month": "January",
    "state": "Madhya Pradesh",
    "act": "Madhya Pradesh Vritti Kar Adhiniyam, 1995 (PT)",
    "type": "Remittance",
    "category": "Monthly Remittance",
    "due_date": "10-Jan",
    "form": "",
    "remarks": ""
  },
  {
    "id": 5,
    "month": "January",
    "state": "Kerala",
    "act": "Kerala Shops and Commercial Establishments Act, 1960",
    "type": "Return",
    "category": "Quarterly Returns",
    "due_date": "10-Jan",
    "form": "Form -H",
    "remarks": "10 or More Employees"
  },
  {
    "id": 6,
    "month": "January",
    "state": "Goa",
    "act": "Goa Shops And Establishments Act, 1973",
    "type": "Return",
    "category": "Quarterly Returns",
    "due_date": "10-Jan",
    "form": "Form XXIX",
    "remarks": "5 or more employees"
  },
  {
    "id": 7,
    "month": "January",
    "state": "Tamil Nadu",
    "act": "Tamil Nadu Industrial Establishments (conferment of Permanent Status to Workmen) Act, 1981",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "14-Jan",
    "form": "Form 2 along with Form 1",
    "remarks": "Not required if (Combined Annual Return Form 22 submitted in Factories Act) & Applicable to S&E."
  },
  {
    "id": 8,
    "month": "January",
    "state": "Bihar",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "15-Jan",
    "form": "Form No. 20",
    "remarks": ""
  },
  {
    "id": 9,
    "month": "January",
    "state": "All States",
    "act": "Employee State Insurance Act, 1948",
    "type": "Remittance",
    "category": "Monthly Contribution Challan",
    "due_date": "15-Jan",
    "form": "Challan",
    "remarks": ""
  },
  {
    "id": 10,
    "month": "January",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Remittance",
    "category": "Monthly Contribution Challan",
    "due_date": "15-Jan",
    "form": "Challan",
    "remarks": ""
  },
  {
    "id": 11,
    "month": "January",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "category": "International Workers Return",
    "due_date": "15-Jan",
    "form": "IW-1",
    "remarks": ""
  },
  {
    "id": 12,
    "month": "January",
    "state": "Central",
    "act": "Apprentices Act, 1961",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "15-Jan",
    "form": "",
    "remarks": ""
  },
  {
    "id": 13,
    "month": "January",
    "state": "Chandigarh",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "15-Jan",
    "form": "",
    "remarks": ""
  },
  {
    "id": 14,
    "month": "January",
    "state": "Chhattisgarh",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "15-Jan",
    "form": "Form No. 27",
    "remarks": ""
  },
  {
    "id": 15,
    "month": "January",
    "state": "Delhi",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "15-Jan",
    "form": "Form 21",
    "remarks": ""
  },
  {
    "id": 16,
    "month": "January",
    "state": "Delhi",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "15-Jan",
    "form": "Form 22",
    "remarks": ""
  },
  {
    "id": 17,
    "month": "January",
    "state": "Delhi",
    "act": "Bombay Labour Welfare Fund Act, 1953 (Delhi)",
    "type": "Remittance",
    "category": "Half Yearly Remittance & Return",
    "due_date": "15-Jan",
    "form": "Form A",
    "remarks": "31st Jan"
  },
  {
    "id": 18,
    "month": "January",
    "state": "Gujarat",
    "act": "Gujarat Panchayats, Municipal Corporations And State Tax on Professions, Traders, Callings and Employments Act, 1976",
    "type": "Return",
    "category": "Monthly Returns",
    "due_date": "15-Jan",
    "form": "Form 5",
    "remarks": ""
  },
  {
    "id": 19,
    "month": "January",
    "state": "Gujarat",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "15-Jan",
    "form": "Form No. 25",
    "remarks": ""
  },
  {
    "id": 20,
    "month": "January",
    "state": "Jharkhand",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "15-Jan",
    "form": "Form No. 20",
    "remarks": ""
  },
  {
    "id": 21,
    "month": "January",
    "state": "Karnataka",
    "act": "Karnataka Labour Welfare Fund Act, 1965",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "15-Jan",
    "form": "Form D",
    "remarks": ""
  },
  {
    "id": 22,
    "month": "January",
    "state": "Kerala",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "15-Jan",
    "form": "Form XXV",
    "remarks": ""
  },
  {
    "id": 23,
    "month": "January",
    "state": "Maharashtra",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "15-Jan",
    "form": "Form 11",
    "remarks": "Not Required - If submission of combined return under Various Acts"
  },
  {
    "id": 24,
    "month": "January",
    "state": "Maharashtra",
    "act": "Maharashtra Labour Welfare Fund Act, 1953",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "15-Jan",
    "form": "Form A-1",
    "remarks": ""
  },
  {
    "id": 25,
    "month": "January",
    "state": "Tamil Nadu",
    "act": "Tamil Nadu Payment of Subsistence Allowance Act, 1981",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "15-Jan",
    "form": "Form 2",
    "remarks": ""
  },
  {
    "id": 26,
    "month": "January",
    "state": "Tamil Nadu",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "15-Jan",
    "form": "Form III",
    "remarks": "Not required if (Combined Annual Return Form 22 submitted in Factories Act) or (Combined annual return Form XIII submitted in TN Catering Establishments) or (Combined annual return Form XXV under BOCW)"
  },
  {
    "id": 27,
    "month": "January",
    "state": "Uttar Pradesh",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "15-Jan",
    "form": "Form No. 21",
    "remarks": ""
  },
  {
    "id": 28,
    "month": "January",
    "state": "Uttar Pradesh",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "15-Jan",
    "form": "Form III",
    "remarks": "Due date not clarified under the state Rules - Considered common date"
  },
  {
    "id": 29,
    "month": "January",
    "state": "West Bengal",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Quarterly Return",
    "due_date": "15-Jan",
    "form": "Form 23A",
    "remarks": ""
  },
  {
    "id": 30,
    "month": "January",
    "state": "Assam",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "15-Jan",
    "form": "Form 21",
    "remarks": ""
  },
  {
    "id": 31,
    "month": "January",
    "state": "Assam",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "15-Jan",
    "form": "Form 22",
    "remarks": ""
  },
  {
    "id": 32,
    "month": "January",
    "state": "Madhya Pradesh",
    "act": "Madhya Pradesh Vritti Kar Adhiniyam, 1995 (PT)",
    "type": "Return",
    "category": "Quarterly Return",
    "due_date": "15-Jan",
    "form": "Form 7",
    "remarks": ""
  },
  {
    "id": 33,
    "month": "January",
    "state": "Kerala",
    "act": "Kerala Labour Welfare Fund Act, 1975",
    "type": "Remittance and Return",
    "category": "Half Yearly",
    "due_date": "15-Jan",
    "form": "Form A",
    "remarks": ""
  },
  {
    "id": 34,
    "month": "January",
    "state": "West Bengal",
    "act": "West Bengal Labour Welfare Fund Act, 1974",
    "type": "Remittance and Return",
    "category": "Half Yearly",
    "due_date": "15-Jan",
    "form": "Form D",
    "remarks": ""
  },
  {
    "id": 35,
    "month": "January",
    "state": "Chhattisgarh",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "15-Jan",
    "form": "Form No. 28",
    "remarks": ""
  },
  {
    "id": 36,
    "month": "January",
    "state": "Madhya Pradesh",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "15-Jan",
    "form": "Form No. 27",
    "remarks": ""
  },
  {
    "id": 37,
    "month": "January",
    "state": "Madhya Pradesh",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "15-Jan",
    "form": "Form No. 28",
    "remarks": ""
  },
  {
    "id": 38,
    "month": "January",
    "state": "Uttarakhand",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "15-Jan",
    "form": "Form No. 21",
    "remarks": ""
  },
  {
    "id": 39,
    "month": "January",
    "state": "Himachal Pradesh",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "15-Jan",
    "form": "Form No. 21",
    "remarks": ""
  },
  {
    "id": 40,
    "month": "January",
    "state": "Himachal Pradesh",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "15-Jan",
    "form": "Form No. 22",
    "remarks": ""
  },
  {
    "id": 41,
    "month": "January",
    "state": "Mizoram",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "15-Jan",
    "form": "Form No. 30",
    "remarks": ""
  },
  {
    "id": 42,
    "month": "January",
    "state": "Mizoram",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "15-Jan",
    "form": "Form No. 31",
    "remarks": ""
  },
  {
    "id": 43,
    "month": "January",
    "state": "Tamil Nadu",
    "act": "Tamil Nadu Labour Welfare Fund Act, 1972",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Jan",
    "form": "Statement",
    "remarks": "Unpaid Accumulation - After 3 years from become payable"
  },
  {
    "id": 44,
    "month": "January",
    "state": "Kerala",
    "act": "Kerala Labour Welfare Fund Act, 1975",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Jan",
    "form": "Statement",
    "remarks": "Unpaid Accumulation - After 3 years from become payable"
  },
  {
    "id": 45,
    "month": "January",
    "state": "Karnataka",
    "act": "Karnataka Labour Welfare Fund Act, 1965",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Jan",
    "form": "Statement",
    "remarks": "Unpaid Accumulation - After 3 years from become payable"
  },
  {
    "id": 46,
    "month": "January",
    "state": "Telangana",
    "act": "Telangana Labour Welfare Fund Act, 1987",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Jan",
    "form": "Statement",
    "remarks": "Unpaid Accumulation - After 3 years from become payable"
  },
  {
    "id": 47,
    "month": "January",
    "state": "Gujarat",
    "act": "Gujarat Labour Welfare Fund Act, 1953",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Jan",
    "form": "Form B & Form C",
    "remarks": "Unpaid Accumulation - After 1 year from become payable"
  },
  {
    "id": 48,
    "month": "January",
    "state": "West Bengal",
    "act": "West Bengal Labour Welfare Fund Act, 1974",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Jan",
    "form": "Statement",
    "remarks": "Unpaid Accumulation - After 3 years from become payable"
  },
  {
    "id": 49,
    "month": "January",
    "state": "Maharashtra",
    "act": "Maharashtra Labour Welfare Fund Act, 1953",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Jan",
    "form": "Statement",
    "remarks": "Unpaid Accumulation - After 3 years from become payable"
  },
  {
    "id": 50,
    "month": "January",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Labour Welfare Fund Act, 1987",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Jan",
    "form": "Statement",
    "remarks": "Unpaid Accumulation - After 3 years from become payable"
  },
  {
    "id": 51,
    "month": "January",
    "state": "Madhya Pradesh",
    "act": "The Madhya Pradesh Shram Kalyan Nidhi Adhiniyam, 1982 (LWF)",
    "type": "Remittance and Return",
    "category": "Half Yearly Remittance & Return",
    "due_date": "15-Jan",
    "form": "Form A",
    "remarks": ""
  },
  {
    "id": 52,
    "month": "January",
    "state": "Goa",
    "act": "Goa Labour Welfare Fund Act, 1986",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Jan",
    "form": "Statement",
    "remarks": "Unpaid Accumulation - After 3 years from become payable"
  },
  {
    "id": 53,
    "month": "January",
    "state": "Chhattisgarh",
    "act": "Chhattisgarh Shram Kalyan Nidhi Adhiniyam, 1982 (LWF)",
    "type": "Remittance and Return",
    "category": "Half Yearly Remittance & Return",
    "due_date": "15-Jan",
    "form": "Form A",
    "remarks": ""
  },
  {
    "id": 54,
    "month": "January",
    "state": "Chhattisgarh",
    "act": "Chhattisgarh Shram Kalyan Nidhi Adhiniyam, 1982 (LWF)",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Jan",
    "form": "Statement",
    "remarks": "Unpaid Accumulation - After 3 years from become payable"
  },
  {
    "id": 55,
    "month": "January",
    "state": "Madhya Pradesh",
    "act": "The Madhya Pradesh Shram Kalyan Nidhi Adhiniyam, 1982 (LWF)",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Jan",
    "form": "Statement",
    "remarks": "Unpaid Accumulation - After 3 years from become payable"
  },
  {
    "id": 56,
    "month": "January",
    "state": "Delhi",
    "act": "Bombay Labour Welfare Fund Act, 1953 (Delhi)",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Jan",
    "form": "Statement",
    "remarks": "Unpaid Accumulation - After 3 years from become payable"
  },
  {
    "id": 57,
    "month": "January",
    "state": "Odisha",
    "act": "Odisha Labour Welfare Fund Act, 1996",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Jan",
    "form": "Statement",
    "remarks": "Unpaid Accumulation - After 5 years from become payable"
  },
  {
    "id": 58,
    "month": "January",
    "state": "Odisha",
    "act": "Odisha Labour Welfare Fund Act, 1996",
    "type": "Remittance & Returns",
    "category": "Half Yearly Remittance & Return",
    "due_date": "15-Jan",
    "form": "Form F",
    "remarks": ""
  },
  {
    "id": 59,
    "month": "January",
    "state": "Sikkim",
    "act": "Sikkim Tax On Professions, Trades, Callings And Employments, Act 2006",
    "type": "Remittance",
    "category": "Monthly",
    "due_date": "15-Jan",
    "form": "",
    "remarks": ""
  },
  {
    "id": 60,
    "month": "January",
    "state": "Karnataka",
    "act": "Karnataka Tax on Professions, Trades, Callings and Employments Act, 1976",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "20-Jan",
    "form": "Form 9-A or Form 5-A",
    "remarks": ""
  },
  {
    "id": 61,
    "month": "January",
    "state": "Arunachal Pradesh",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": ""
  },
  {
    "id": 62,
    "month": "January",
    "state": "Assam",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "21-Jan",
    "form": "Form H",
    "remarks": ""
  },
  {
    "id": 63,
    "month": "January",
    "state": "Bihar",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": ""
  },
  {
    "id": 64,
    "month": "January",
    "state": "Chhattisgarh",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "21-Jan",
    "form": "Form K, L, M and N",
    "remarks": ""
  },
  {
    "id": 65,
    "month": "January",
    "state": "Delhi",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": ""
  },
  {
    "id": 66,
    "month": "January",
    "state": "Goa",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": "Not required - If submission of combined return under Various Acts"
  },
  {
    "id": 67,
    "month": "January",
    "state": "Haryana",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "21-Jan",
    "form": "Form K, L, M and N",
    "remarks": "Not required - If submission of Unified Single Annual Return"
  },
  {
    "id": 68,
    "month": "January",
    "state": "Himachal Pradesh",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": ""
  },
  {
    "id": 69,
    "month": "January",
    "state": "Jammu & Kashmir",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "21-Jan",
    "form": "Form L, M, N, O",
    "remarks": ""
  },
  {
    "id": 70,
    "month": "January",
    "state": "Jharkhand",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": ""
  },
  {
    "id": 71,
    "month": "January",
    "state": "Punjab",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "21-Jan",
    "form": "Form L, M, N, O",
    "remarks": "Not Required - If submitting the Integrated Annual Return"
  },
  {
    "id": 72,
    "month": "January",
    "state": "West Bengal",
    "act": "West Bengal State Tax on Professions, Trades, Callings and Employments Act, 1979",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "21-Jan",
    "form": "Online",
    "remarks": ""
  },
  {
    "id": 73,
    "month": "January",
    "state": "West Bengal",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "21-Jan",
    "form": "Form L, M, N",
    "remarks": ""
  },
  {
    "id": 74,
    "month": "January",
    "state": "Rajasthan",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": ""
  },
  {
    "id": 75,
    "month": "January",
    "state": "Madhya Pradesh",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "21-Jan",
    "form": "Form K, L and M",
    "remarks": ""
  },
  {
    "id": 76,
    "month": "January",
    "state": "Tripura",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": "Rule - 16"
  },
  {
    "id": 77,
    "month": "January",
    "state": "Andhra Pradesh",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": ""
  },
  {
    "id": 78,
    "month": "January",
    "state": "Central",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": ""
  },
  {
    "id": 79,
    "month": "January",
    "state": "Chandigarh",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": ""
  },
  {
    "id": 80,
    "month": "January",
    "state": "Daman",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": ""
  },
  {
    "id": 81,
    "month": "January",
    "state": "Manipur",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": ""
  },
  {
    "id": 82,
    "month": "January",
    "state": "Mizoram",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": ""
  },
  {
    "id": 83,
    "month": "January",
    "state": "Meghalaya",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": ""
  },
  {
    "id": 84,
    "month": "January",
    "state": "Nagaland",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": ""
  },
  {
    "id": 85,
    "month": "January",
    "state": "Odisha",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": "Not required - If submission of combined return under Various Acts"
  },
  {
    "id": 86,
    "month": "January",
    "state": "Telangana",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": "Not required - If submission of combined return under Various Acts"
  },
  {
    "id": 87,
    "month": "January",
    "state": "Uttar Pradesh",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": ""
  },
  {
    "id": 88,
    "month": "January",
    "state": "Uttarakhand",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": ""
  },
  {
    "id": 89,
    "month": "January",
    "state": "Sikkim",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": ""
  },
  {
    "id": 90,
    "month": "January",
    "state": "Andaman Nicobar",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": ""
  },
  {
    "id": 91,
    "month": "January",
    "state": "Dadra and Nagar Haveli",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": ""
  },
  {
    "id": 92,
    "month": "January",
    "state": "Ladakh",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": ""
  },
  {
    "id": 93,
    "month": "January",
    "state": "Lakshadweep",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": ""
  },
  {
    "id": 94,
    "month": "January",
    "state": "Puducherry",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "21-Jan",
    "form": "Form L, M, N and O",
    "remarks": ""
  },
  {
    "id": 95,
    "month": "January",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "category": "Monthly Return - EDLI Exempted Employer",
    "due_date": "25-Jan",
    "form": "Form - 7(IF)",
    "remarks": ""
  },
  {
    "id": 96,
    "month": "January",
    "state": "All States",
    "act": "Employment Exchange (Compulsory Notification of Vacancies) Act, 1959",
    "type": "Return",
    "category": "Quarterly Return",
    "due_date": "30-Jan",
    "form": "ER-1",
    "remarks": ""
  },
  {
    "id": 97,
    "month": "January",
    "state": "Arunachal Pradesh",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jan",
    "form": "Form XXIV",
    "remarks": ""
  },
  {
    "id": 98,
    "month": "January",
    "state": "Assam",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jan",
    "form": "Form XXIV",
    "remarks": ""
  },
  {
    "id": 99,
    "month": "January",
    "state": "Bihar",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jan",
    "form": "Form XXIV",
    "remarks": ""
  },
  {
    "id": 100,
    "month": "January",
    "state": "Bihar",
    "act": "Bihar Shops and Establishments Act, 1953",
    "type": "Return",
    "category": "Quarterly Return",
    "due_date": "30-Jan",
    "form": "Form XIX",
    "remarks": "10 or More Employees"
  },
  {
    "id": 101,
    "month": "January",
    "state": "Central",
    "act": "Payment of Wages Act, 1936",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "30-Jan",
    "form": "Form 4",
    "remarks": ""
  },
  {
    "id": 102,
    "month": "January",
    "state": "Chandigarh",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jan",
    "form": "Form XXIV",
    "remarks": ""
  },
  {
    "id": 103,
    "month": "January",
    "state": "Chhattisgarh",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jan",
    "form": "Form XXIV",
    "remarks": ""
  },
  {
    "id": 104,
    "month": "January",
    "state": "Delhi",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "category": "Contractor - Half Yearly Returns",
    "due_date": "30-Jan",
    "form": "Form XXIV",
    "remarks": ""
  },
  {
    "id": 105,
    "month": "January",
    "state": "Goa",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jan",
    "form": "Form XX",
    "remarks": ""
  },
  {
    "id": 106,
    "month": "January",
    "state": "Gujarat",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "category": "Contractor - Half Yearly Returns",
    "due_date": "30-Jan",
    "form": "Form XXIV",
    "remarks": ""
  },
  {
    "id": 107,
    "month": "January",
    "state": "Haryana",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jan",
    "form": "Form 20",
    "remarks": ""
  },
  {
    "id": 108,
    "month": "January",
    "state": "Himachal Pradesh",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jan",
    "form": "Form XXIV",
    "remarks": ""
  },
  {
    "id": 109,
    "month": "January",
    "state": "Jammu & Kashmir",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jan",
    "form": "Form XXIV",
    "remarks": ""
  },
  {
    "id": 110,
    "month": "January",
    "state": "Jharkhand",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jan",
    "form": "Form XXIV",
    "remarks": "Contractor"
  },
  {
    "id": 111,
    "month": "January",
    "state": "Jharkhand",
    "act": "Bihar Shops and Establishments Act, 1953",
    "type": "Return",
    "category": "Quarterly Return",
    "due_date": "30-Jan",
    "form": "Form XIX",
    "remarks": "10 or More Employees"
  },
  {
    "id": 112,
    "month": "January",
    "state": "Kerala",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jan",
    "form": "Form XXIV",
    "remarks": ""
  },
  {
    "id": 113,
    "month": "January",
    "state": "Kerala",
    "act": "Payment of Wages Act, 1936",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "30-Jan",
    "form": "Form IV",
    "remarks": ""
  },
  {
    "id": 114,
    "month": "January",
    "state": "Punjab",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jan",
    "form": "Form XXIV",
    "remarks": ""
  },
  {
    "id": 115,
    "month": "January",
    "state": "Rajasthan",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jan",
    "form": "Form XXIV",
    "remarks": ""
  },
  {
    "id": 116,
    "month": "January",
    "state": "Tamil Nadu",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jan",
    "form": "Form XXIV",
    "remarks": ""
  },
  {
    "id": 117,
    "month": "January",
    "state": "Uttar Pradesh",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jan",
    "form": "Form XXIV",
    "remarks": ""
  },
  {
    "id": 118,
    "month": "January",
    "state": "Uttarakhand",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jan",
    "form": "Form XXIV",
    "remarks": ""
  },
  {
    "id": 119,
    "month": "January",
    "state": "West Bengal",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jan",
    "form": "Form XXIV",
    "remarks": ""
  },
  {
    "id": 120,
    "month": "January",
    "state": "Haryana",
    "act": "Unified single Annual Return",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "30-Jan",
    "form": "Online",
    "remarks": "Notification - S&E, Factories, CLRA, BOCW, ISMW, POW, ID, MW, ESI, MB, POB & POG"
  },
  {
    "id": 121,
    "month": "January",
    "state": "Odisha",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jan",
    "form": "Form XX",
    "remarks": "Contractor"
  },
  {
    "id": 122,
    "month": "January",
    "state": "Puducherry",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jan",
    "form": "Form XXIV",
    "remarks": ""
  },
  {
    "id": 123,
    "month": "January",
    "state": "Andhra Pradesh",
    "act": "Payment of Wages Act, 1936",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form IV",
    "remarks": ""
  },
  {
    "id": 124,
    "month": "January",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Labour Welfare Fund Act, 1987",
    "type": "Remittance",
    "category": "Annual LWF Payment and Returns",
    "due_date": "31-Jan",
    "form": "Form F",
    "remarks": ""
  },
  {
    "id": 125,
    "month": "January",
    "state": "All States",
    "act": "Sexual Harassment of Workmen at Workplace (Prevention, Prohibition and Redressal) Act, 2013",
    "type": "Return",
    "category": "Annual Report",
    "due_date": "31-Jan",
    "form": "",
    "remarks": ""
  },
  {
    "id": 126,
    "month": "January",
    "state": "Goa",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form No. 34",
    "remarks": ""
  },
  {
    "id": 127,
    "month": "January",
    "state": "Goa",
    "act": "Payment of Wages Act, 1936",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "",
    "remarks": "Not required - If submission of combined return under Various Acts"
  },
  {
    "id": 128,
    "month": "January",
    "state": "Gujarat",
    "act": "Gujarat Labour Welfare Fund Act, 1953",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "31-Jan",
    "form": "Form A-1",
    "remarks": ""
  },
  {
    "id": 129,
    "month": "January",
    "state": "Haryana",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form 21",
    "remarks": "Not required - If submission of Unified Single Annual Return."
  },
  {
    "id": 130,
    "month": "January",
    "state": "Jammu & Kashmir",
    "act": "Payment of Wages Act, 1936",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "",
    "remarks": ""
  },
  {
    "id": 131,
    "month": "January",
    "state": "Jharkhand",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form III",
    "remarks": "(30th June)"
  },
  {
    "id": 132,
    "month": "January",
    "state": "Jharkhand",
    "act": "Payment of Wages Act, 1936",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "",
    "remarks": ""
  },
  {
    "id": 133,
    "month": "January",
    "state": "Karnataka",
    "act": "Karnataka Shops and Commercial Establishments Act, 1961",
    "type": "Return",
    "category": "Combined Annual Returns",
    "due_date": "31-Jan",
    "form": "Form U",
    "remarks": "Online - Covering (S&E, CLRA, MB, MW, POW)"
  },
  {
    "id": 134,
    "month": "January",
    "state": "Karnataka",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form K,M and L",
    "remarks": "S&E - Form U & Factories - Form 20"
  },
  {
    "id": 135,
    "month": "January",
    "state": "Karnataka",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form III",
    "remarks": "(S&E - Form U & Factories - Form 20)"
  },
  {
    "id": 136,
    "month": "January",
    "state": "Kerala",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form 21",
    "remarks": ""
  },
  {
    "id": 137,
    "month": "January",
    "state": "Kerala",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form L, M",
    "remarks": ""
  },
  {
    "id": 138,
    "month": "January",
    "state": "Kerala",
    "act": "Kerala Industrial Establishments (National and Festival Holidays) Act, 1958",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form V",
    "remarks": ""
  },
  {
    "id": 139,
    "month": "January",
    "state": "Maharashtra",
    "act": "Maharashtra State Tax on Professions, Trade, Callings And Employments Act, 1975",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "31-Jan",
    "form": "Online",
    "remarks": ""
  },
  {
    "id": 140,
    "month": "January",
    "state": "Maharashtra",
    "act": "Sexual Harassment of Workmen at Workplace (Prevention, Prohibition and Redressal) Act, 2013",
    "type": "Return",
    "category": "Annual Report",
    "due_date": "31-Jan",
    "form": "",
    "remarks": "Not Required - If submission of combined return under Various Acts"
  },
  {
    "id": 141,
    "month": "January",
    "state": "Punjab",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form 21",
    "remarks": "Not Required - If submitting the Integrated Annual Return"
  },
  {
    "id": 142,
    "month": "January",
    "state": "Tamil Nadu",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form K or (Form 22 -Factories Rule) or (Form XIII - Catering Establishment)",
    "remarks": ""
  },
  {
    "id": 143,
    "month": "January",
    "state": "Tamil Nadu",
    "act": "Payment of Wages Act, 1936",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form IV or Form 22 (Factories Rule)",
    "remarks": ""
  },
  {
    "id": 144,
    "month": "January",
    "state": "Tamil Nadu",
    "act": "Tamil Nadu Labour Welfare Fund Act, 1972",
    "type": "Remittance",
    "category": "Annual Payment and Return",
    "due_date": "31-Jan",
    "form": "Form A",
    "remarks": ""
  },
  {
    "id": 145,
    "month": "January",
    "state": "Telangana",
    "act": "Telangana Labour Welfare Fund Act, 1987",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form F",
    "remarks": ""
  },
  {
    "id": 146,
    "month": "January",
    "state": "West Bengal",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form 22",
    "remarks": ""
  },
  {
    "id": 147,
    "month": "January",
    "state": "West Bengal",
    "act": "Payment of Wages Act, 1936",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form IV",
    "remarks": ""
  },
  {
    "id": 148,
    "month": "January",
    "state": "Rajasthan",
    "act": "Single Integrated Online Return",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Online",
    "remarks": "Notification - Various Acts. - POW, MW, Factories Act, POB, CLRA, ISMW, BOCW, S&E, POG"
  },
  {
    "id": 149,
    "month": "January",
    "state": "Assam",
    "act": "Assam Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "31-Jan",
    "form": "Form III, Form III A, Form VII C",
    "remarks": ""
  },
  {
    "id": 150,
    "month": "January",
    "state": "Odisha",
    "act": "Orissa State Tax On Professions, Trades, Callings And Employments Act, 2000",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "31-Jan",
    "form": "Form 5",
    "remarks": ""
  },
  {
    "id": 151,
    "month": "January",
    "state": "Tamil Nadu",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Combined Annual Returns",
    "due_date": "31-Jan",
    "form": "Form 22",
    "remarks": "Online Return - Notification"
  },
  {
    "id": 152,
    "month": "January",
    "state": "Maharashtra",
    "act": "Maharashtra Shops and Establishments Act, 2017",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form R",
    "remarks": "Not Required - If submission of combined return under Various Acts"
  },
  {
    "id": 153,
    "month": "January",
    "state": "Tamil Nadu",
    "act": "Tamil Nadu Shops And Establishments Act,1947",
    "type": "Return",
    "category": "Certificate - Half Yearly",
    "due_date": "31-Jan",
    "form": "Form O",
    "remarks": "Only IT / ITES"
  },
  {
    "id": 154,
    "month": "January",
    "state": "Tamil Nadu",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "category": "Certificate - Half Yearly",
    "due_date": "31-Jan",
    "form": "Form L",
    "remarks": "Only IT / ITES"
  },
  {
    "id": 155,
    "month": "January",
    "state": "Tamil Nadu",
    "act": "Payment of Gratuity Act, 1972",
    "type": "Return",
    "category": "Certificate - Half Yearly",
    "due_date": "31-Jan",
    "form": "Form 'V'",
    "remarks": "Only IT / ITES"
  },
  {
    "id": 156,
    "month": "January",
    "state": "Tamil Nadu",
    "act": "Payment of Wages Act, 1936",
    "type": "Return",
    "category": "Certificate - Half Yearly",
    "due_date": "31-Jan",
    "form": "Form VIII",
    "remarks": "Only IT / ITES"
  },
  {
    "id": 157,
    "month": "January",
    "state": "Karnataka",
    "act": "Payment of Wages Act, 1936",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form U",
    "remarks": ""
  },
  {
    "id": 158,
    "month": "January",
    "state": "Goa",
    "act": "Various Acts",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Annexure A",
    "remarks": "Online Filing - Combined - S&E, MW, POW, POB, Cont Lab., ISMW, BOCW, Motor Trans, MB & ID."
  },
  {
    "id": 159,
    "month": "January",
    "state": "Tamil Nadu",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "category": "Certificate - Half Yearly",
    "due_date": "31-Jan",
    "form": "Form XXX",
    "remarks": "Only IT / ITES"
  },
  {
    "id": 160,
    "month": "January",
    "state": "Karnataka",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "category": "Certificate - Annual Return",
    "due_date": "31-Jan",
    "form": "CLRA - RC (Form II)",
    "remarks": "Annual return on or before January 31st."
  },
  {
    "id": 161,
    "month": "January",
    "state": "Karnataka",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "category": "License Half Year Return",
    "due_date": "31-Jan",
    "form": "CLRA - License (Form VI)",
    "remarks": "Half-yearly returns for January to June submitted before July 31. Half-yearly returns for July to December submitted before January 31."
  },
  {
    "id": 162,
    "month": "January",
    "state": "Central",
    "act": "Combined Annual Returns - Various Acts",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Sharm Suvida Portal",
    "remarks": "BOCW, CLRA, ISMW, Bonus, ESIC, EPFO"
  },
  {
    "id": 163,
    "month": "January",
    "state": "Andhra Pradesh",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form AR",
    "remarks": ""
  },
  {
    "id": 164,
    "month": "January",
    "state": "Goa",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Accidents - Annual Return",
    "due_date": "31-Jan",
    "form": "Form No. 37",
    "remarks": ""
  },
  {
    "id": 165,
    "month": "January",
    "state": "Rajasthan",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form No. 23",
    "remarks": "Not Required - If submitting the Integrated Annual Return"
  },
  {
    "id": 166,
    "month": "January",
    "state": "Manipur",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form 29",
    "remarks": ""
  },
  {
    "id": 167,
    "month": "January",
    "state": "Meghalaya",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form 34",
    "remarks": ""
  },
  {
    "id": 168,
    "month": "January",
    "state": "Tripura",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form 35",
    "remarks": ""
  },
  {
    "id": 169,
    "month": "January",
    "state": "Dadra and Nagar Haveli",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form No. 34",
    "remarks": ""
  },
  {
    "id": 170,
    "month": "January",
    "state": "Dadra and Nagar Haveli",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Accidents - Annual Return",
    "due_date": "31-Jan",
    "form": "Form No. 37",
    "remarks": ""
  },
  {
    "id": 171,
    "month": "January",
    "state": "Daman",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form No. 34",
    "remarks": ""
  },
  {
    "id": 172,
    "month": "January",
    "state": "Daman",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Accidents - Annual Return",
    "due_date": "31-Jan",
    "form": "Form No. 37",
    "remarks": ""
  },
  {
    "id": 173,
    "month": "January",
    "state": "Puducherry",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form No. 19",
    "remarks": ""
  },
  {
    "id": 174,
    "month": "January",
    "state": "Puducherry",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "31-Jan",
    "form": "Form No. 20",
    "remarks": ""
  },
  {
    "id": 175,
    "month": "January",
    "state": "Gujarat",
    "act": "Gujarat Shops And Establishments Act,1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form Q",
    "remarks": "Online"
  },
  {
    "id": 176,
    "month": "January",
    "state": "Andhra Pradesh",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form III",
    "remarks": "Replaced with Form B - Consolidated return."
  },
  {
    "id": 177,
    "month": "January",
    "state": "Arunachal Pradesh",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form III",
    "remarks": ""
  },
  {
    "id": 178,
    "month": "January",
    "state": "Chandigarh",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form III",
    "remarks": ""
  },
  {
    "id": 179,
    "month": "January",
    "state": "Manipur",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form III",
    "remarks": ""
  },
  {
    "id": 180,
    "month": "January",
    "state": "Nagaland",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form III",
    "remarks": ""
  },
  {
    "id": 181,
    "month": "January",
    "state": "Daman",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form III",
    "remarks": ""
  },
  {
    "id": 182,
    "month": "January",
    "state": "Dadra and Nagar Haveli",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form III",
    "remarks": ""
  },
  {
    "id": 183,
    "month": "January",
    "state": "Lakshadweep",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form III",
    "remarks": ""
  },
  {
    "id": 184,
    "month": "January",
    "state": "Chhattisgarh",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form III",
    "remarks": ""
  },
  {
    "id": 185,
    "month": "January",
    "state": "Delhi",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form III",
    "remarks": ""
  },
  {
    "id": 186,
    "month": "January",
    "state": "Madhya Pradesh",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form III",
    "remarks": ""
  },
  {
    "id": 187,
    "month": "January",
    "state": "Tamil Nadu",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "category": "Certificate - Half Yearly",
    "due_date": "31-Jan",
    "form": "Form XII",
    "remarks": "Only IT / ITES"
  },
  {
    "id": 188,
    "month": "January",
    "state": "Uttarakhand",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form III",
    "remarks": ""
  },
  {
    "id": 189,
    "month": "January",
    "state": "Puducherry",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form III",
    "remarks": ""
  },
  {
    "id": 190,
    "month": "January",
    "state": "Karnataka",
    "act": "Karnataka Labour Welfare Fund Act, 1965",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form B",
    "remarks": "Extract of the register"
  },
  {
    "id": 191,
    "month": "January",
    "state": "Telangana",
    "act": "Telangana Labour Welfare Fund Act, 1987",
    "type": "Return",
    "category": "Extract of unclaimed wage register",
    "due_date": "31-Jan",
    "form": "Form E",
    "remarks": ""
  },
  {
    "id": 192,
    "month": "January",
    "state": "Gujarat",
    "act": "Gujarat Labour Welfare Fund Act, 1953",
    "type": "Return",
    "category": "Extract of unclaimed wage register",
    "due_date": "31-Jan",
    "form": "Form B",
    "remarks": ""
  },
  {
    "id": 193,
    "month": "January",
    "state": "Haryana",
    "act": "Punjab Labour Welfare Fund Act,1965",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form B",
    "remarks": ""
  },
  {
    "id": 194,
    "month": "January",
    "state": "West Bengal",
    "act": "West Bengal Labour Welfare Fund Act, 1974",
    "type": "Return",
    "category": "Extract of unclaimed wage register",
    "due_date": "31-Jan",
    "form": "Form G",
    "remarks": ""
  },
  {
    "id": 195,
    "month": "January",
    "state": "Maharashtra",
    "act": "Maharashtra Labour Welfare Fund Act, 1953",
    "type": "Return",
    "category": "Extract of Register",
    "due_date": "31-Jan",
    "form": "Form B",
    "remarks": ""
  },
  {
    "id": 196,
    "month": "January",
    "state": "Manipur",
    "act": "Manipur Shops And Establishments Act 1972",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form III",
    "remarks": ""
  },
  {
    "id": 197,
    "month": "January",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Labour Welfare Fund Act, 1987",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form E",
    "remarks": "Extract of the Register to be submitted"
  },
  {
    "id": 198,
    "month": "January",
    "state": "Tripura",
    "act": "Tripura Professions Trades Callings And Employments Taxation Act, 1997",
    "type": "Remittance and Return",
    "category": "Monthly",
    "due_date": "31-Jan",
    "form": "Form VII",
    "remarks": ""
  },
  {
    "id": 199,
    "month": "January",
    "state": "Goa",
    "act": "Goa Labour Welfare Fund Act, 1986",
    "type": "Remittance & Returns",
    "category": "Half Yearly Returns",
    "due_date": "31-Jan",
    "form": "Form A",
    "remarks": ""
  },
  {
    "id": 200,
    "month": "January",
    "state": "Goa",
    "act": "Goa Labour Welfare Fund Act, 1986",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Form B",
    "remarks": "Statement to be submitted about the previous Year"
  },
  {
    "id": 201,
    "month": "January",
    "state": "Punjab",
    "act": "Punjab Labour Welfare Fund Act, 1965",
    "type": "Annual Return",
    "category": "Extract of unclaimed wage register",
    "due_date": "31-Jan",
    "form": "Form B",
    "remarks": ""
  },
  {
    "id": 202,
    "month": "January",
    "state": "Chandigarh",
    "act": "Punjab Labour Welfare Fund Act, 1965",
    "type": "Annual Return",
    "category": "Extract of unclaimed wage register",
    "due_date": "31-Jan",
    "form": "Form B",
    "remarks": ""
  },
  {
    "id": 203,
    "month": "January",
    "state": "Delhi",
    "act": "Bombay Labour Welfare Fund Act, 1953 (Delhi)",
    "type": "Annual Return",
    "category": "Extract of the Register",
    "due_date": "31-Jan",
    "form": "Form D",
    "remarks": ""
  },
  {
    "id": 204,
    "month": "January",
    "state": "Odisha",
    "act": "Odisha Labour Welfare Fund Act, 1996",
    "type": "Annual Return",
    "category": "Extract of unclaimed wage register",
    "due_date": "31-Jan",
    "form": "Form E",
    "remarks": ""
  },
  {
    "id": 205,
    "month": "January",
    "state": "Meghalaya",
    "act": "Meghalaya Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance and Return",
    "category": "Monthly",
    "due_date": "31-Jan",
    "form": "Form III",
    "remarks": ""
  },
  {
    "id": 206,
    "month": "January",
    "state": "Sikkim",
    "act": "Sikkim Tax On Professions, Trades, Callings And Employments, Act 2006",
    "type": "Return",
    "category": "Quarterly Return",
    "due_date": "31-Jan",
    "form": "Form 5",
    "remarks": ""
  },
  {
    "id": 207,
    "month": "January",
    "state": "Punjab",
    "act": "Single Integrated Annual Return",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Jan",
    "form": "Online",
    "remarks": "Covered - Factories Act, Punjab S&E, MB, Employee Compensation, MW, POW, POB, CLRA, ISMW, BOCW, M.Transport, POG"
  },
  {
    "id": 208,
    "month": "January",
    "state": "Tamil Nadu",
    "act": "Tamil Nadu Shops And Establishments Act, 1947",
    "type": "Return",
    "category": "Intimation on Employees - Period of Work, Holiday, rest interval",
    "due_date": "On Occurrence",
    "form": "Form S",
    "remarks": ""
  },
  {
    "id": 209,
    "month": "February",
    "state": "Arunachal Pradesh",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Dec",
    "form": "Form D",
    "remarks": ""
  },
  {
    "id": 210,
    "month": "February",
    "state": "Assam",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Dec",
    "form": "Form D",
    "remarks": ""
  },
  {
    "id": 211,
    "month": "February",
    "state": "Bihar",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Dec",
    "form": "Form D",
    "remarks": ""
  },
  {
    "id": 212,
    "month": "February",
    "state": "Central",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Dec",
    "form": "Form D",
    "remarks": ""
  },
  {
    "id": 213,
    "month": "February",
    "state": "Chandigarh",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Dec",
    "form": "Form D",
    "remarks": ""
  },
  {
    "id": 214,
    "month": "February",
    "state": "Chhattisgarh",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Dec",
    "form": "Form D",
    "remarks": ""
  },
  {
    "id": 215,
    "month": "February",
    "state": "Delhi",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Dec",
    "form": "Form D",
    "remarks": ""
  },
  {
    "id": 216,
    "month": "February",
    "state": "Goa",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "1-Feb",
    "form": "Form III",
    "remarks": "Not required - If submission of combined return under Various Acts"
  },
  {
    "id": 217,
    "month": "February",
    "state": "Goa",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Dec",
    "form": "Form D",
    "remarks": "Not required - If submission of combined return under Various Acts"
  },
  {
    "id": 218,
    "month": "February",
    "state": "Gujarat",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "1-Feb",
    "form": "Form No. 24",
    "remarks": ""
  },
  {
    "id": 219,
    "month": "February",
    "state": "Gujarat",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Dec",
    "form": "Form D",
    "remarks": ""
  },
  {
    "id": 220,
    "month": "February",
    "state": "Haryana",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Dec",
    "form": "Form D",
    "remarks": "Not required - If submission of Unified Single Annual Return."
  },
  {
    "id": 221,
    "month": "February",
    "state": "Himachal Pradesh",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Dec",
    "form": "Form D",
    "remarks": ""
  },
  {
    "id": 222,
    "month": "February",
    "state": "Jammu & Kashmir",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "1-Feb",
    "form": "Form III",
    "remarks": ""
  },
  {
    "id": 223,
    "month": "February",
    "state": "Jammu & Kashmir",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Dec",
    "form": "Form D",
    "remarks": ""
  },
  {
    "id": 224,
    "month": "February",
    "state": "Jharkhand",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Dec",
    "form": "Form D",
    "remarks": ""
  },
  {
    "id": 225,
    "month": "February",
    "state": "Kerala",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Dec",
    "form": "Form D",
    "remarks": ""
  },
  {
    "id": 226,
    "month": "February",
    "state": "Kerala",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "1-Feb",
    "form": "Form III A",
    "remarks": ""
  },
  {
    "id": 227,
    "month": "February",
    "state": "Maharashtra",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Dec",
    "form": "Form D",
    "remarks": "Not Required - If submission of combined return under Various Acts"
  },
  {
    "id": 228,
    "month": "February",
    "state": "Punjab",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Dec",
    "form": "Form D",
    "remarks": "Not Required - If submitting the Integrated Annual Return"
  },
  {
    "id": 229,
    "month": "February",
    "state": "Rajasthan",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Dec",
    "form": "Form D",
    "remarks": "Not required - If submission of Unified Single Annual Return."
  },
  {
    "id": 230,
    "month": "February",
    "state": "Tamil Nadu",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Dec",
    "form": "Form D",
    "remarks": ""
  },
  {
    "id": 231,
    "month": "February",
    "state": "Uttar Pradesh",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Dec",
    "form": "Form D",
    "remarks": ""
  },
  {
    "id": 232,
    "month": "February",
    "state": "West Bengal",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Dec",
    "form": "Form D",
    "remarks": ""
  },
  {
    "id": 233,
    "month": "February",
    "state": "Maharashtra",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "1-Feb",
    "form": "Form 27",
    "remarks": "Not Required - If submission of combined return under Various Acts"
  },
  {
    "id": 234,
    "month": "February",
    "state": "Karnataka",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Combined Annual Returns",
    "due_date": "1-Feb",
    "form": "Form 20",
    "remarks": "Online - Covering (CLRA, MB, MW, POW, POB)"
  },
  {
    "id": 235,
    "month": "February",
    "state": "Gujarat",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "1-Feb",
    "form": "Form III",
    "remarks": ""
  },
  {
    "id": 236,
    "month": "February",
    "state": "Himachal Pradesh",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "1-Feb",
    "form": "Form No. 23",
    "remarks": "Leave with Wages"
  },
  {
    "id": 237,
    "month": "February",
    "state": "Himachal Pradesh",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "1-Feb",
    "form": "Form No. 24",
    "remarks": "Compensatory Holiday"
  },
  {
    "id": 238,
    "month": "February",
    "state": "Central",
    "act": "Gujarat Shops And Establishments Act, 1948",
    "type": "Return",
    "category": "Unified annual Return",
    "due_date": "1-Feb",
    "form": "Form III",
    "remarks": "Unified annual Returns under Various Acts."
  },
  {
    "id": 239,
    "month": "February",
    "state": "Assam",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "1-Feb",
    "form": "Form III",
    "remarks": ""
  },
  {
    "id": 240,
    "month": "February",
    "state": "Central",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "category": "Unified annual Return",
    "due_date": "1-Feb",
    "form": "Form III",
    "remarks": "Combined Return - Online"
  },
  {
    "id": 241,
    "month": "February",
    "state": "Punjab",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "1-Feb",
    "form": "Form III",
    "remarks": "Not Required - If submitting the Integrated Annual Return"
  },
  {
    "id": 242,
    "month": "February",
    "state": "Haryana",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "1-Feb",
    "form": "Form III",
    "remarks": "Not required - If submission of Unified Single Annual Return."
  },
  {
    "id": 243,
    "month": "February",
    "state": "Himachal Pradesh",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "1-Feb",
    "form": "Form III",
    "remarks": ""
  },
  {
    "id": 244,
    "month": "February",
    "state": "Meghalaya",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "1-Feb",
    "form": "Form III",
    "remarks": ""
  },
  {
    "id": 245,
    "month": "February",
    "state": "Mizoram",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "1-Feb",
    "form": "Form III",
    "remarks": ""
  },
  {
    "id": 246,
    "month": "February",
    "state": "Odisha",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "1-Feb",
    "form": "Form III",
    "remarks": "Not required - Covered under Combined Annual Returns"
  },
  {
    "id": 247,
    "month": "February",
    "state": "Sikkim",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "1-Feb",
    "form": "Form III",
    "remarks": ""
  },
  {
    "id": 248,
    "month": "February",
    "state": "Tripura",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "1-Feb",
    "form": "Form III",
    "remarks": ""
  },
  {
    "id": 249,
    "month": "February",
    "state": "West Bengal",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "1-Feb",
    "form": "Form III",
    "remarks": ""
  },
  {
    "id": 250,
    "month": "February",
    "state": "Haryana",
    "act": "Punjab Labour Welfare Fund Act,1965",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "1-Feb",
    "form": "Statement",
    "remarks": "Unpaid Accumulation - After 2 years from become payable"
  },
  {
    "id": 251,
    "month": "February",
    "state": "Punjab",
    "act": "Punjab Labour Welfare Fund Act,1965",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "1-Feb",
    "form": "Statement",
    "remarks": "Unpaid Accumulation - After 3 years from become payable"
  },
  {
    "id": 252,
    "month": "February",
    "state": "Chandigarh",
    "act": "Punjab Labour Welfare Fund Act,1965",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "1-Feb",
    "form": "Statement",
    "remarks": "Unpaid Accumulation - After 3 years from become payable"
  },
  {
    "id": 253,
    "month": "February",
    "state": "Kerala",
    "act": "Kerala Shops and Commercial Establishments Workers Welfare Fund Act,2006",
    "type": "Remittance",
    "category": "Monthly Contribution",
    "due_date": "5-Feb",
    "form": "Form 6",
    "remarks": ""
  },
  {
    "id": 254,
    "month": "February",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "10-Feb",
    "form": "Form V",
    "remarks": ""
  },
  {
    "id": 255,
    "month": "February",
    "state": "Telangana",
    "act": "Telangana Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "10-Feb",
    "form": "Form V",
    "remarks": ""
  },
  {
    "id": 256,
    "month": "February",
    "state": "Madhya Pradesh",
    "act": "Madhya Pradesh Vritti Kar Adhiniyam, 1995 (PT)",
    "type": "Remittance",
    "category": "Monthly Remittance",
    "due_date": "10-Feb",
    "form": "",
    "remarks": ""
  },
  {
    "id": 257,
    "month": "February",
    "state": "All States",
    "act": "Employee State Insurance Act, 1948",
    "type": "Remittance",
    "category": "Monthly Contribution Challan",
    "due_date": "15-Feb",
    "form": "Challan",
    "remarks": ""
  },
  {
    "id": 258,
    "month": "February",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Remittance",
    "category": "Monthly Contribution Challan",
    "due_date": "15-Feb",
    "form": "Challan",
    "remarks": ""
  },
  {
    "id": 259,
    "month": "February",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "category": "International Workers Return",
    "due_date": "15-Feb",
    "form": "IW-1",
    "remarks": ""
  },
  {
    "id": 260,
    "month": "February",
    "state": "Goa",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "15-Feb",
    "form": "Form XXI",
    "remarks": "Not required - If submission of combined return under Various Acts"
  },
  {
    "id": 261,
    "month": "February",
    "state": "Gujarat",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "category": "PE Annual Returns",
    "due_date": "15-Feb",
    "form": "Form XXV",
    "remarks": ""
  },
  {
    "id": 262,
    "month": "February",
    "state": "Gujarat",
    "act": "Gujarat Panchayats, Municipal Corporations And State Tax on Professions, Traders, Callings and Employments Act, 1976",
    "type": "Return",
    "category": "Monthly Returns",
    "due_date": "15-Feb",
    "form": "Form 5",
    "remarks": ""
  },
  {
    "id": 263,
    "month": "February",
    "state": "Haryana",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "category": "PE Annual Returns",
    "due_date": "15-Feb",
    "form": "Form 21",
    "remarks": "Not required - If submission of Unified Single Annual Return."
  },
  {
    "id": 264,
    "month": "February",
    "state": "Jharkhand",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "15-Feb",
    "form": "Form XXV",
    "remarks": ""
  },
  {
    "id": 265,
    "month": "February",
    "state": "Tamil Nadu",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "15-Feb",
    "form": "Form XXV",
    "remarks": "Not required if (Combined Annual Return Form 22 submitted in Factories Act) or (Combined annual return Form XIII submitted in TN Catering Establishments)"
  },
  {
    "id": 266,
    "month": "February",
    "state": "Maharashtra",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "category": "PE Annual Returns",
    "due_date": "15-Feb",
    "form": "Form XXI",
    "remarks": "Not Required - If submission of combined return under Various Acts"
  },
  {
    "id": 267,
    "month": "February",
    "state": "Delhi",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "category": "PE Annual Returns",
    "due_date": "15-Feb",
    "form": "Form XXV",
    "remarks": ""
  },
  {
    "id": 268,
    "month": "February",
    "state": "Gujarat",
    "act": "Payment of Wages Act, 1936",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "15-Feb",
    "form": "Form IV",
    "remarks": ""
  },
  {
    "id": 269,
    "month": "February",
    "state": "Puducherry",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "15-Feb",
    "form": "Form XXV",
    "remarks": ""
  },
  {
    "id": 270,
    "month": "February",
    "state": "Maharashtra",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "15-Feb",
    "form": "Form 30",
    "remarks": "Accident Details"
  },
  {
    "id": 271,
    "month": "February",
    "state": "Rajasthan",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "15-Feb",
    "form": "Form III",
    "remarks": "Not required - If submission of Unified Single Annual Return."
  },
  {
    "id": 272,
    "month": "February",
    "state": "Uttar Pradesh",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "15-Feb",
    "form": "Form XXV",
    "remarks": ""
  },
  {
    "id": 273,
    "month": "February",
    "state": "Uttar Pradesh",
    "act": "Payment of Wages Act, 1936",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "15-Feb",
    "form": "Form IV",
    "remarks": "Applicable to Factories and Industrial Establishments"
  },
  {
    "id": 274,
    "month": "February",
    "state": "West Bengal",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "15-Feb",
    "form": "Form XXV",
    "remarks": ""
  },
  {
    "id": 275,
    "month": "February",
    "state": "Jharkhand",
    "act": "The Jharkhand Tax On Profession, Trades, Callings And Employments Act, 2011",
    "type": "Remittance",
    "category": "Quarterly Return",
    "due_date": "15-Feb",
    "form": "Form - JPT- 03",
    "remarks": "Employee & Employer Tax online (Employer to deduct monthly and remit quarterly)"
  },
  {
    "id": 276,
    "month": "February",
    "state": "Sikkim",
    "act": "Sikkim Tax On Professions, Trades, Callings And Employments, Act 2006",
    "type": "Remittance",
    "category": "Monthly",
    "due_date": "15-Feb",
    "form": "",
    "remarks": ""
  },
  {
    "id": 277,
    "month": "February",
    "state": "Karnataka",
    "act": "Karnataka Tax on Professions, Trades, Callings and Employments Act, 1976",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "20-Feb",
    "form": "Form 9-A or Form 5-A",
    "remarks": ""
  },
  {
    "id": 278,
    "month": "February",
    "state": "West Bengal",
    "act": "West Bengal State Tax on Professions, Trades, Callings and Employments Act, 1979",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "21-Feb",
    "form": "Online",
    "remarks": ""
  },
  {
    "id": 279,
    "month": "February",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "category": "Monthly Return - EDLI Exempted Employer",
    "due_date": "25-Feb",
    "form": "Form - 7(IF)",
    "remarks": ""
  },
  {
    "id": 280,
    "month": "February",
    "state": "Maharashtra",
    "act": "Maharashtra State Tax on Professions, Trade, Callings And Employments Act, 1975",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "28-Feb",
    "form": "Online",
    "remarks": ""
  },
  {
    "id": 281,
    "month": "February",
    "state": "Tripura",
    "act": "Tripura Professions Trades Callings And Employments Taxation Act, 1997",
    "type": "Remittance and Return",
    "category": "Monthly",
    "due_date": "28-Feb",
    "form": "Form VII",
    "remarks": ""
  },
  {
    "id": 282,
    "month": "February",
    "state": "Meghalaya",
    "act": "Meghalaya Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance and Return",
    "category": "Monthly",
    "due_date": "28-Feb",
    "form": "Form III",
    "remarks": ""
  },
  {
    "id": 283,
    "month": "February",
    "state": "Assam",
    "act": "Assam Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "28-Feb",
    "form": "Form III, Form III A, Form VII C",
    "remarks": ""
  },
  {
    "id": 284,
    "month": "February",
    "state": "Odisha",
    "act": "Orissa State Tax On Professions, Trades, Callings And Employments Act, 2000",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "28-Feb",
    "form": "Form 5",
    "remarks": ""
  },
  {
    "id": 285,
    "month": "February",
    "state": "Kerala",
    "act": "Kerala Panchayat Raj Act, 1994 (PT)",
    "type": "Remittance",
    "category": "Half Yearly Remittance",
    "due_date": "28-Feb",
    "form": "Offline",
    "remarks": "Period (Oct to Mar) No Registration Required"
  },

  //march
  {
    "id": 286,
    "month": "March",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Remittance",
    "category": "Monthly Contribution Challan",
    "due_date": "15-Mar",
    "form": "Challan",
    "remarks": ""
  },
  {
    "id": 287,
    "month": "March",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "category": "International Workers Return",
    "due_date": "15-Mar",
    "form": "Challan",
    "remarks": ""
  },
  {
    "id": 288,
    "month": "March",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "category": "Monthly Return - EDLI Exempted Employer",
    "due_date": "15-Mar",
    "form": "IW-1",
    "remarks": ""
  },
  {
    "id": 289,
    "month": "March",
    "state": "All States",
    "act": "Employee State Insurance Act, 1948",
    "type": "Remittance",
    "category": "Monthly Contribution Challan",
    "due_date": "15-Apr",
    "form": "Challan",
    "remarks": ""
  },
  {
    "id": 290,
    "month": "March",
    "state": "Gujarat",
    "act": "Gujarat Panchayats, Municipal Corporations And State Tax on Professions, Traders, Callings and Employments Act, 1976",
    "type": "Return",
    "category": "Monthly Returns",
    "due_date": "25-Mar",
    "form": "Form - 7(IF)",
    "remarks": ""
  },
  {
    "id": 291,
    "month": "March",
    "state": "Karnataka",
    "act": "Karnataka Tax on Professions, Trades, Callings and Employments Act, 1976",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "15-Mar",
    "form": "Form 5",
    "remarks": ""
  },
  {
    "id": 292,
    "month": "March",
    "state": "Kerala",
    "act": "Kerala Shops and Commercial Establishments Workers Welfare Fund Act, 2006",
    "type": "Remittance",
    "category": "Monthly Contribution",
    "due_date": "20-Mar",
    "form": "Form 9-A or Form 5-A",
    "remarks": ""
  },
  {
    "id": 293,
    "month": "March",
    "state": "Maharashtra",
    "act": "Maharashtra State Tax on Professions, Trade, Callings And Employments Act, 1975",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "5-Mar",
    "form": "Form 6",
    "remarks": ""
  },
  {
    "id": 294,
    "month": "March",
    "state": "Maharashtra",
    "act": "Maharashtra State Tax on Professions, Trade, Callings And Employments Act, 1975",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Mar",
    "form": "Online",
    "remarks": ""
  },
  {
    "id": 295,
    "month": "March",
    "state": "West Bengal",
    "act": "West Bengal State Tax on Professions, Trades, Callings and Employments Act, 1979",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "31-Mar",
    "form": "Online",
    "remarks": ""
  },
  {
    "id": 296,
    "month": "March",
    "state": "Odisha",
    "act": "Combined Annual Returns - Various Acts",
    "type": "Return",
    "category": "Combined Annual Returns",
    "due_date": "21-Mar",
    "form": "Online",
    "remarks": ""
  },
  {
    "id": 297,
    "month": "March",
    "state": "Telangana",
    "act": "Telangana Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "31-Mar",
    "form": "Form 13",
    "remarks": ""
  },
  {
    "id": 298,
    "month": "March",
    "state": "Assam",
    "act": "Assam Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "10-Mar",
    "form": "Form V",
    "remarks": ""
  },
  {
    "id": 299,
    "month": "March",
    "state": "Odisha",
    "act": "Orissa State Tax On Professions, Trades, Callings And Employments Act, 2000",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "31-Mar",
    "form": "Form III, Form III A, Form VII C",
    "remarks": ""
  },
  {
    "id": 300,
    "month": "March",
    "state": "Madhya Pradesh",
    "act": "Madhya Pradesh Vritti Kar Adhiniyam, 1995 (PT)",
    "type": "Remittance",
    "category": "Monthly Remittance",
    "due_date": "31-Mar",
    "form": "Form 5",
    "remarks": ""
  },
  {
    "id": 301,
    "month": "March",
    "state": "Tamil Nadu",
    "act": "Tamil Nadu Municipal Laws (Second Amendment) Act, 1998 & Tamil Nadu Professional Tax Rules, 1999",
    "type": "Remittance",
    "category": "Remittance and Return",
    "due_date": "10-Mar",
    "form": "",
    "remarks": ""
  },
  {
    "id": 302,
    "month": "March",
    "state": "Tripura",
    "act": "Tripura Professions Trades Callings And Employments Taxation Act, 1997",
    "type": "Remittance and Return",
    "category": "Monthly",
    "due_date": "31-Mar",
    "form": "Form 2",
    "remarks": ""
  },
  {
    "id": 303,
    "month": "March",
    "state": "Meghalaya",
    "act": "Meghalaya Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance and Return",
    "category": "Monthly",
    "due_date": "31-Mar",
    "form": "Form VII",
    "remarks": ""
  },
  {
    "id": 304,
    "month": "March",
    "state": "Sikkim",
    "act": "Sikkim Tax On Professions, Trades, Callings And Employments, Act 2006",
    "type": "Remittance",
    "category": "Monthly",
    "due_date": "31-Mar",
    "form": "Form III",
    "remarks": ""
  },
  //april
  {
    "id": 305,
    "month": "April",
    "state": "All States",
    "act": "Employment Exchange (Compulsory Notification of Vacancies) Act, 1959",
    "type": "Return",
    "category": "Quarterly Return",
    "due_date": "30-Apr",
    "form": "ER-1",
    "remarks": ""
  },
  {
    "id": 306,
    "month": "April",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "10-Apr",
    "form": "Form V",
    "remarks": ""
  },
  {
    "id": 307,
    "month": "April",
    "state": "All States",
    "act": "Employee State Insurance Act, 1948",
    "type": "Remittance",
    "category": "Monthly Contribution Challan",
    "due_date": "15-Apr",
    "form": "Challan",
    "remarks": ""
  },
  {
    "id": 308,
    "month": "April",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Remittance",
    "category": "Monthly Contribution Challan",
    "due_date": "15-Apr",
    "form": "Challan",
    "remarks": ""
  },
  {
    "id": 309,
    "month": "April",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "category": "International Workers Return",
    "due_date": "15-Apr",
    "form": "IW-1",
    "remarks": ""
  },
  {
    "id": 310,
    "month": "April",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "category": "Monthly Return - EDLI Exempted Employer",
    "due_date": "25-Apr",
    "form": "Form - 7(IF)",
    "remarks": ""
  },
  {
    "id": 311,
    "month": "April",
    "state": "Goa",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Apr",
    "form": "Form XX",
    "remarks": ""
  },
  {
    "id": 312,
    "month": "April",
    "state": "Gujarat",
    "act": "Gujarat Panchayats, Municipal Corporations And State Tax on Professions, Traders, Callings and Employments Act, 1976",
    "type": "Return",
    "category": "Monthly Returns",
    "due_date": "15-Apr",
    "form": "Form 5",
    "remarks": ""
  },
  {
    "id": 313,
    "month": "April",
    "state": "Karnataka",
    "act": "Karnataka Tax on Professions, Trades, Callings and Employments Act, 1976",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "20-Apr",
    "form": "Form 9-A or Form 5-A",
    "remarks": ""
  },
  {
    "id": 314,
    "month": "April",
    "state": "Karnataka",
    "act": "Karnataka Tax on Professions, Trades, Callings and Employments Act, 1976",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "30-Apr",
    "form": "Form 5",
    "remarks": ""
  },
  {
    "id": 315,
    "month": "April",
    "state": "Kerala",
    "act": "Kerala Shops and Commercial Establishments Workers Welfare Fund Act,2006",
    "type": "Remittance",
    "category": "Monthly Contribution",
    "due_date": "5-Apr",
    "form": "Form 6",
    "remarks": ""
  },
  {
    "id": 316,
    "month": "April",
    "state": "Maharashtra",
    "act": "Maharashtra State Tax on Professions, Trade, Callings And Employments Act, 1975",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "30-Apr",
    "form": "Online",
    "remarks": ""
  },
  {
    "id": 317,
    "month": "April",
    "state": "Telangana",
    "act": "Various Acts",
    "type": "Return",
    "category": "Integrated Return",
    "due_date": "30-Apr",
    "form": "Form - I",
    "remarks": ""
  },
  {
    "id": 318,
    "month": "April",
    "state": "West Bengal",
    "act": "West Bengal State Tax on Professions, Trades, Callings and Employments Act, 1979",
    "type": "Remittance",
    "category": "Monthly Remittance",
    "due_date": "21-Apr",
    "form": "Online",
    "remarks": ""
  },
  {
    "id": 319,
    "month": "April",
    "state": "Telangana",
    "act": "Telangana Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "10-Apr",
    "form": "Form V",
    "remarks": ""
  },
  {
    "id": 320,
    "month": "April",
    "state": "Assam",
    "act": "Assam Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "30-Apr",
    "form": "Form III, Form III A, Form VII C",
    "remarks": ""
  },
  {
    "id": 321,
    "month": "April",
    "state": "Odisha",
    "act": "Orissa State Tax On Professions, Trades, Callings And Employments Act, 2000",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "30-Apr",
    "form": "Form 5",
    "remarks": ""
  },
  {
    "id": 322,
    "month": "April",
    "state": "Madhya Pradesh",
    "act": "Madhya Pradesh Vritti Kar Adhiniyam, 1995 (PT)",
    "type": "Remittance",
    "category": "Monthly Remittance",
    "due_date": "10-Apr",
    "form": "",
    "remarks": ""
  },
  {
    "id": 323,
    "month": "April",
    "state": "Chandigarh",
    "act": "Punjab Labour Welfare Fund Act, 1965",
    "type": "Remittance",
    "category": "Half Yearly Remittance & Return",
    "due_date": "15-Apr",
    "form": "",
    "remarks": ""
  },
  {
    "id": 324,
    "month": "April",
    "state": "Madhya Pradesh",
    "act": "Madhya Pradesh Vritti Kar Adhiniyam, 1995 (PT)",
    "type": "Return",
    "category": "Quarterly Return",
    "due_date": "15-Apr",
    "form": "Form 7",
    "remarks": ""
  },
  {
    "id": 325,
    "month": "April",
    "state": "Maharashtra",
    "act": "Various Acts",
    "type": "Return",
    "category": "Consolidated Annual Returns",
    "due_date": "30-Apr",
    "form": "Annexure IV",
    "remarks": ""
  },
  {
    "id": 326,
    "month": "April",
    "state": "Kerala",
    "act": "Kerala Shops and Commercial Establishments Act, 1960",
    "type": "Return",
    "category": "Quarterly Returns",
    "due_date": "10-Apr",
    "form": "Form -H",
    "remarks": ""
  },
  {
    "id": 327,
    "month": "April",
    "state": "Goa",
    "act": "Goa Shops And Establishments Act, 1973",
    "type": "Return",
    "category": "Quarterly Returns",
    "due_date": "10-Apr",
    "form": "Form XXIX",
    "remarks": ""
  },
  {
    "id": 328,
    "month": "April",
    "state": "Bihar",
    "act": "Bihar Shops and Establishments Act, 1953",
    "type": "Return",
    "category": "Quarterly Return",
    "due_date": "30-Apr",
    "form": "Form XIX",
    "remarks": ""
  },
  {
    "id": 329,
    "month": "April",
    "state": "Karnataka",
    "act": "Karnataka Tax on Professions, Trades, Callings and Employments Act, 1976",
    "type": "Return",
    "category": "Employer Enrolment Tax",
    "due_date": "30-Apr",
    "form": "",
    "remarks": ""
  },
  {
    "id": 330,
    "month": "April",
    "state": "Punjab",
    "act": "Punjab Labour Welfare Fund Act, 1965",
    "type": "Remittance",
    "category": "Half Yearly Remittance & Return",
    "due_date": "15-Apr",
    "form": "",
    "remarks": ""
  },
  {
    "id": 331,
    "month": "April",
    "state": "West Bengal",
    "act": "West Bengal State Tax on Professions, Trades, Callings and Employments Act, 1979",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "30-Apr",
    "form": "Form III",
    "remarks": ""
  },
  {
    "id": 332,
    "month": "April",
    "state": "Punjab",
    "act": "Punjab State Development Tax 2018",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "30-Apr",
    "form": "Form PSDT-6",
    "remarks": ""
  },
  {
    "id": 333,
    "month": "April",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Shops And Establishments Act, 1988",
    "type": "Return",
    "category": "Quarterly Return",
    "due_date": "10-Apr",
    "form": "Form XXVII",
    "remarks": ""
  },
  {
    "id": 334,
    "month": "April",
    "state": "West Bengal",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Quarterly Return",
    "due_date": "15-Apr",
    "form": "Form No. 23-A",
    "remarks": ""
  },
  {
    "id": 335,
    "month": "April",
    "state": "Telangana",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Integrated Return",
    "due_date": "30-Apr",
    "form": "Form I",
    "remarks": ""
  },
  {
    "id": 336,
    "month": "April",
    "state": "Tamil Nadu",
    "act": "Tamil Nadu Labour Welfare Fund Act, 1972",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Apr",
    "form": "Statement even 'NIL' Along with Form C",
    "remarks": ""
  },
  {
    "id": 337,
    "month": "April",
    "state": "Karnataka",
    "act": "Karnataka Labour Welfare Fund Act, 1965",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Apr",
    "form": "Statement",
    "remarks": ""
  },
  {
    "id": 338,
    "month": "April",
    "state": "Telangana",
    "act": "Telangana Labour Welfare Fund Act, 1987",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Apr",
    "form": "Statement",
    "remarks": ""
  },
  {
    "id": 339,
    "month": "April",
    "state": "Gujarat",
    "act": "Gujarat Labour Welfare Fund Act, 1953",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Apr",
    "form": "Form B & Form C",
    "remarks": ""
  },
  {
    "id": 340,
    "month": "April",
    "state": "West Bengal",
    "act": "West Bengal Labour Welfare Fund Act, 1974",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Apr",
    "form": "Statement",
    "remarks": ""
  },
  {
    "id": 341,
    "month": "April",
    "state": "Maharashtra",
    "act": "Maharashtra Labour Welfare Fund Act, 1953",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Apr",
    "form": "Statement",
    "remarks": ""
  },
  {
    "id": 342,
    "month": "April",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Labour Welfare Fund Act, 1987",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Apr",
    "form": "Statement",
    "remarks": ""
  },
  {
    "id": 343,
    "month": "April",
    "state": "Manipur",
    "act": "Manipur Professions, Trades, Callings And Employments Taxation Act, 1981",
    "type": "Remittance & Returns",
    "category": "Annual Returns",
    "due_date": "30-Apr",
    "form": "Form V",
    "remarks": ""
  },
  {
    "id": 344,
    "month": "April",
    "state": "Tripura",
    "act": "Tripura Professions Trades Callings And Employments Taxation Act, 1997",
    "type": "Remittance and Return",
    "category": "Monthly",
    "due_date": "30-Apr",
    "form": "Form VII",
    "remarks": ""
  },
  {
    "id": 345,
    "month": "April",
    "state": "Goa",
    "act": "Goa Labour Welfare Fund Act, 1986",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Apr",
    "form": "Statement",
    "remarks": ""
  },
  {
    "id": 346,
    "month": "April",
    "state": "Chhattisgarh",
    "act": "Chhattisgarh Shram Kalyan Nidhi Adhiniyam, 1982 (LWF)",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Apr",
    "form": "Statement",
    "remarks": ""
  },
  {
    "id": 347,
    "month": "April",
    "state": "Madhya Pradesh",
    "act": "The Madhya Pradesh Shram Kalyan Nidhi Adhiniyam, 1982 (LWF)",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Apr",
    "form": "Statement",
    "remarks": ""
  },
  {
    "id": 348,
    "month": "April",
    "state": "Delhi",
    "act": "Bombay Labour Welfare Fund Act, 1953 (Delhi)",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Apr",
    "form": "Statement",
    "remarks": ""
  },
  {
    "id": 349,
    "month": "April",
    "state": "Odisha",
    "act": "Odisha Labour Welfare Fund Act, 1996",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Apr",
    "form": "Statement",
    "remarks": ""
  },
  {
    "id": 350,
    "month": "April",
    "state": "Jharkhand",
    "act": "The Jharkhand Tax On Profession, Trades, Callings And Employments Act, 2011",
    "type": "Remittance",
    "category": "Quarterly Return",
    "due_date": "15-Apr",
    "form": "Form - JPT- 03",
    "remarks": ""
  },
  {
    "id": 351,
    "month": "April",
    "state": "Meghalaya",
    "act": "Meghalaya Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance and Return",
    "category": "Monthly",
    "due_date": "30-Apr",
    "form": "Form III",
    "remarks": ""
  },
  {
    "id": 352,
    "month": "April",
    "state": "Nagaland",
    "act": "Nagaland Professionals, Trades, Calling and Employment Taxation (4th Amendment) Act, 2012",
    "type": "Remittance",
    "category": "Annual Remittance",
    "due_date": "30-Apr",
    "form": "Statement",
    "remarks": ""
  },
  {
    "id": 353,
    "month": "April",
    "state": "Sikkim",
    "act": "Sikkim Tax On Professions, Trades, Callings And Employments, Act 2006",
    "type": "Remittance",
    "category": "Monthly",
    "due_date": "15-Apr",
    "form": "",
    "remarks": ""
  },
  {
    "id": 354,
    "month": "April",
    "state": "Sikkim",
    "act": "Sikkim Tax On Professions, Trades, Callings And Employments, Act 2006",
    "type": "Return",
    "category": "Quarterly Return",
    "due_date": "30-Apr",
    "form": "Form 5",
    "remarks": ""
  },
  {
    "id": 355,
    "month": "April",
    "state": "Jharkhand",
    "act": "Bihar Shops and Establishments Act, 1953",
    "type": "Return",
    "category": "Quarterly Return",
    "due_date": "30-Apr",
    "form": "Form XIX",
    "remarks": ""
  },
  //may
  {
    "id": 356,
    "month": "May",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "10-May",
    "form": "Form V",
    "remarks": ""
  },
  {
    "id": 357,
    "month": "May",
    "state": "All States",
    "act": "Employee State Insurance Act, 1948",
    "type": "Remittance",
    "category": "Monthly Contribution Challan",
    "due_date": "15-May",
    "form": "Challan",
    "remarks": ""
  },
  {
    "id": 358,
    "month": "May",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Remittance",
    "category": "Monthly Contribution Challan",
    "due_date": "15-May",
    "form": "Challan",
    "remarks": ""
  },
  {
    "id": 359,
    "month": "May",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "category": "International Workers Return",
    "due_date": "15-May",
    "form": "IW-1",
    "remarks": ""
  },
  {
    "id": 360,
    "month": "May",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "category": "Monthly Return - EDLI Exempted Employer",
    "due_date": "25-May",
    "form": "Form - 7(IF)",
    "remarks": ""
  },
  {
    "id": 361,
    "month": "May",
    "state": "Gujarat",
    "act": "Gujarat Panchayats, Municipal Corporations And State Tax on Professions, Traders, Callings and Employments Act, 1976",
    "type": "Return",
    "category": "Monthly Returns",
    "due_date": "15-May",
    "form": "Form 5",
    "remarks": ""
  },
  {
    "id": 362,
    "month": "May",
    "state": "Karnataka",
    "act": "Karnataka Tax on Professions, Trades, Callings and Employments Act, 1976",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "20-May",
    "form": "Form 9-A or Form 5-A",
    "remarks": ""
  },
  {
    "id": 363,
    "month": "May",
    "state": "Kerala",
    "act": "Kerala Shops and Commercial Establishments Workers Welfare Fund Act, 2006",
    "type": "Remittance",
    "category": "Monthly Contribution",
    "due_date": "5-May",
    "form": "Form 6",
    "remarks": ""
  },
  {
    "id": 364,
    "month": "May",
    "state": "Maharashtra",
    "act": "Maharashtra State Tax on Professions, Trade, Callings And Employments Act, 1975",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "31-May",
    "form": "Online",
    "remarks": ""
  },
  {
    "id": 365,
    "month": "May",
    "state": "West Bengal",
    "act": "West Bengal State Tax on Professions, Trades, Callings and Employments Act, 1979",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "21-May",
    "form": "Online",
    "remarks": ""
  },
  {
    "id": 366,
    "month": "May",
    "state": "Telangana",
    "act": "Telangana Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "10-May",
    "form": "Form V",
    "remarks": ""
  },
  {
    "id": 367,
    "month": "May",
    "state": "Assam",
    "act": "Assam Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "31-May",
    "form": "Form III, Form III A, Form VII C",
    "remarks": ""
  },
  {
    "id": 368,
    "month": "May",
    "state": "Odisha",
    "act": "Orissa State Tax On Professions, Trades, Callings And Employments Act, 2000",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "31-May",
    "form": "Form 5",
    "remarks": ""
  },
  {
    "id": 369,
    "month": "May",
    "state": "Madhya Pradesh",
    "act": "Madhya Pradesh Vritti Kar Adhiniyam, 1995 (PT)",
    "type": "Remittance",
    "category": "Monthly Remittance",
    "due_date": "10-May",
    "form": "",
    "remarks": ""
  },
  {
    "id": 370,
    "month": "May",
    "state": "All States",
    "act": "Employee State Insurance Act, 1948",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "12-May",
    "form": "Form 5",
    "remarks": "For period Oct to March (after completing the period 42-day time is there to file the return)"
  },
  {
    "id": 371,
    "month": "May",
    "state": "Haryana",
    "act": "Punjab Labour Welfare Fund Act, 1965",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "1-May",
    "form": "Statement",
    "remarks": ""
  },
  {
    "id": 372,
    "month": "May",
    "state": "Tripura",
    "act": "Tripura Professions Trades Callings And Employments Taxation Act, 1997",
    "type": "Remittance and Return",
    "category": "Monthly",
    "due_date": "31-May",
    "form": "Form VII",
    "remarks": ""
  },
  {
    "id": 373,
    "month": "May",
    "state": "Punjab",
    "act": "Punjab Labour Welfare Fund Act, 1965",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "1-May",
    "form": "Statement",
    "remarks": ""
  },
  {
    "id": 374,
    "month": "May",
    "state": "Chandigarh",
    "act": "Punjab Labour Welfare Fund Act, 1965",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "1-May",
    "form": "Statement",
    "remarks": ""
  },
  {
    "id": 375,
    "month": "May",
    "state": "Meghalaya",
    "act": "Meghalaya Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance and Return",
    "category": "Monthly",
    "due_date": "31-May",
    "form": "Form III",
    "remarks": ""
  },
  {
    "id": 376,
    "month": "May",
    "state": "Sikkim",
    "act": "Sikkim Tax On Professions, Trades, Callings And Employments, Act 2006",
    "type": "Remittance",
    "category": "Monthly",
    "due_date": "15-May",
    "form": "",
    "remarks": ""
  },
  //june
  {
    "id": 377,
    "month": "June",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "10-Jun",
    "form": "Form V",
    "remarks": ""
  },
  {
    "id": 378,
    "month": "June",
    "state": "Andhra Pradesh",
    "act": "Various Acts (CLRA, S&E, Factories, MW, POB, ISMW, BOCW, POG)",
    "type": "Return",
    "category": "Combined Annual Returns",
    "due_date": "30-Jun",
    "form": "Form B",
    "remarks": ""
  },
  {
    "id": 379,
    "month": "June",
    "state": "All States",
    "act": "Employee State Insurance Act, 1948",
    "type": "Remittance",
    "category": "Monthly Contribution Challan",
    "due_date": "15-Jun",
    "form": "Challan",
    "remarks": ""
  },
  {
    "id": 380,
    "month": "June",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Remittance",
    "category": "Monthly Contribution Challan",
    "due_date": "15-Jun",
    "form": "Challan",
    "remarks": ""
  },
  {
    "id": 381,
    "month": "June",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "category": "International Workers Return",
    "due_date": "15-Jun",
    "form": "IW-1",
    "remarks": ""
  },
  {
    "id": 382,
    "month": "June",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "category": "Monthly Return - EDLI Exempted Employer",
    "due_date": "25-Jun",
    "form": "Form - 7(IF)",
    "remarks": ""
  },
  {
    "id": 383,
    "month": "June",
    "state": "Gujarat",
    "act": "Gujarat Panchayats, Municipal Corporations And State Tax on Professions, Traders, Callings and Employments Act, 1976",
    "type": "Return",
    "category": "Monthly Returns",
    "due_date": "15-Jun",
    "form": "Form 5",
    "remarks": ""
  },
  {
    "id": 384,
    "month": "June",
    "state": "Karnataka",
    "act": "Karnataka Tax on Professions, Trades, Callings and Employments Act, 1976",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "20-Jun",
    "form": "Form 9-A or Form 5-A",
    "remarks": ""
  },
  {
    "id": 385,
    "month": "June",
    "state": "Kerala",
    "act": "Kerala Shops and Commercial Establishments Workers Welfare Fund Act, 2006",
    "type": "Remittance",
    "category": "Monthly Contribution",
    "due_date": "5-Jun",
    "form": "Form 6",
    "remarks": ""
  },
  {
    "id": 386,
    "month": "June",
    "state": "Maharashtra",
    "act": "Maharashtra State Tax on Professions, Trade, Callings And Employments Act, 1975",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "30-Jun",
    "form": "Online",
    "remarks": ""
  },
  {
    "id": 387,
    "month": "June",
    "state": "West Bengal",
    "act": "West Bengal State Tax on Professions, Trades, Callings and Employments Act, 1979",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "21-Jun",
    "form": "Online",
    "remarks": ""
  },
  {
    "id": 388,
    "month": "June",
    "state": "Telangana",
    "act": "Telangana Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "10-Jun",
    "form": "Form V",
    "remarks": ""
  },
  {
    "id": 389,
    "month": "June",
    "state": "Assam",
    "act": "Assam Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "30-Jun",
    "form": "Form III, Form III A, Form VII C",
    "remarks": ""
  },
  {
    "id": 390,
    "month": "June",
    "state": "Odisha",
    "act": "Orissa State Tax On Professions, Trades, Callings And Employments Act, 2000",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "30-Jun",
    "form": "Form 5",
    "remarks": ""
  },
  {
    "id": 391,
    "month": "June",
    "state": "Madhya Pradesh",
    "act": "Madhya Pradesh Vritti Kar Adhiniyam, 1995 (PT)",
    "type": "Remittance",
    "category": "Monthly Remittance",
    "due_date": "10-Jun",
    "form": "",
    "remarks": ""
  },
  {
    "id": 392,
    "month": "June",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "category": "Enrolment Fees",
    "due_date": "30-Jun",
    "form": "",
    "remarks": ""
  },
  {
    "id": 393,
    "month": "June",
    "state": "Telangana",
    "act": "Telangana Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "category": "Enrolment Fees",
    "due_date": "30-Jun",
    "form": "",
    "remarks": ""
  },
  {
    "id": 394,
    "month": "June",
    "state": "Odisha",
    "act": "Orissa State Tax On Professions, Trades, Callings And Employments Act, 2000",
    "type": "Remittance",
    "category": "Enrolment Fees",
    "due_date": "30-Jun",
    "form": "",
    "remarks": ""
  },
  {
    "id": 395,
    "month": "June",
    "state": "Madhya Pradesh",
    "act": "Madhya Pradesh Vritti Kar Adhiniyam, 1995 (PT)",
    "type": "Remittance",
    "category": "Enrolment Fees",
    "due_date": "30-Jun",
    "form": "",
    "remarks": ""
  },
  {
    "id": 396,
    "month": "June",
    "state": "Bihar",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "30-Jun",
    "form": "Form III",
    "remarks": ""
  },
  {
    "id": 397,
    "month": "June",
    "state": "Tripura",
    "act": "Tripura Professions Trades Callings And Employments Taxation Act, 1997",
    "type": "Remittance and Return",
    "category": "Monthly",
    "due_date": "30-Jun",
    "form": "Form VII",
    "remarks": ""
  },
  {
    "id": 398,
    "month": "June",
    "state": "Meghalaya",
    "act": "Meghalaya Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance and Return",
    "category": "Monthly",
    "due_date": "30-Jun",
    "form": "Form III",
    "remarks": ""
  },
  {
    "id": 399,
    "month": "June",
    "state": "Mizoram",
    "act": "Mizoram Professions, Trades, Callings and Employments Taxation Act, 1995",
    "type": "Remittance and Return",
    "category": "Annual Returns",
    "due_date": "30-Jun",
    "form": "Form I & Form IV",
    "remarks": ""
  },
  {
    "id": 400,
    "month": "June",
    "state": "Sikkim",
    "act": "Sikkim Tax On Professions, Trades, Callings And Employments, Act 2006",
    "type": "Remittance",
    "category": "Monthly",
    "due_date": "15-Jun",
    "form": "",
    "remarks": ""
  },
  //july
  {
    "id": 401,
    "month": "July",
    "state": "All States",
    "act": "Employment Exchange (Compulsory Notification of Vacancies) Act, 1959",
    "type": "Return",
    "category": "Quarterly Return",
    "due_date": "30-Jul",
    "form": "ER-1",
    "remarks": ""
  },
  {
    "id": 402,
    "month": "July",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "10-Jul",
    "form": "Form V",
    "remarks": ""
  },
  {
    "id": 403,
    "month": "July",
    "state": "Arunachal Pradesh",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jul",
    "form": "Form XXIV",
    "remarks": ""
  },
  {
    "id": 404,
    "month": "July",
    "state": "Assam",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jul",
    "form": "Form XXIV",
    "remarks": ""
  },
  {
    "id": 405,
    "month": "July",
    "state": "Bihar",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jul",
    "form": "Form XXIV",
    "remarks": ""
  },
  {
    "id": 406,
    "month": "July",
    "state": "All States",
    "act": "Employee State Insurance Act, 1948",
    "type": "Remittance",
    "category": "Monthly Contribution Challan",
    "due_date": "15-Jul",
    "form": "Challan",
    "remarks": ""
  },
  {
    "id": 407,
    "month": "July",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Remittance",
    "category": "Monthly Contribution Challan",
    "due_date": "15-Jul",
    "form": "Challan",
    "remarks": ""
  },
  {
    "id": 408,
    "month": "July",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "category": "International Workers Return",
    "due_date": "15-Jul",
    "form": "IW-1",
    "remarks": ""
  },
  {
    "id": 409,
    "month": "July",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "category": "Monthly Return - EDLI Exempted Employer",
    "due_date": "25-Jul",
    "form": "Form - 7(IF)",
    "remarks": ""
  },
  {
    "id": 410,
    "month": "July",
    "state": "Chandigarh",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jul",
    "form": "Form XXIV",
    "remarks": ""
  },
  {
    "id": 411,
    "month": "July",
    "state": "Chhattisgarh",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jul",
    "form": "Form XXIV",
    "remarks": ""
  },
  {
    "id": 412,
    "month": "July",
    "state": "Delhi",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "category": "Contractor - Half Yearly Returns",
    "due_date": "30-Jul",
    "form": "Form XXIV",
    "remarks": ""
  },
  {
    "id": 413,
    "month": "July",
    "state": "Delhi",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "15-Jul",
    "form": "Form 22",
    "remarks": ""
  },
  {
    "id": 414,
    "month": "July",
    "state": "Delhi",
    "act": "Bombay Labour Welfare Fund Act, 1953 (Delhi)",
    "type": "Remittance",
    "category": "Half Yearly Remittance & Return",
    "due_date": "15-Jul",
    "form": "Form A",
    "remarks": ""
  },
  {
    "id": 415,
    "month": "July",
    "state": "Goa",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jul",
    "form": "Form XX",
    "remarks": ""
  },
  {
    "id": 416,
    "month": "July",
    "state": "Gujarat",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "category": "Contractor - Half Yearly Returns",
    "due_date": "30-Jul",
    "form": "Form XXIV",
    "remarks": ""
  },
  {
    "id": 417,
    "month": "July",
    "state": "Gujarat",
    "act": "Gujarat Panchayats, Municipal Corporations And State Tax on Professions, Traders, Callings and Employments Act, 1976",
    "type": "Return",
    "category": "Monthly Returns",
    "due_date": "15-Jul",
    "form": "Form 5",
    "remarks": ""
  },
  {
    "id": 418,
    "month": "July",
    "state": "Gujarat",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "15-Jul",
    "form": "Form No. 25",
    "remarks": ""
  },
  {
    "id": 419,
    "month": "July",
    "state": "Gujarat",
    "act": "Gujarat Labour Welfare Fund Act, 1953",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "31-Jul",
    "form": "Form A-1",
    "remarks": ""
  },
  {
    "id": 420,
    "month": "July",
    "state": "Haryana",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jul",
    "form": "Form 20",
    "remarks": ""
  },
  {
    "id": 421,
    "month": "July",
    "state": "Himachal Pradesh",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jul",
    "form": "Form XXIV",
    "remarks": ""
  },
  {
    "id": 422,
    "month": "July",
    "state": "Jammu & Kashmir",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jul",
    "form": "Form XXIV",
    "remarks": ""
  },
  {
    "id": 423,
    "month": "July",
    "state": "Jharkhand",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jul",
    "form": "Form XXIV",
    "remarks": ""
  },
  {
    "id": 424,
    "month": "July",
    "state": "Karnataka",
    "act": "Karnataka Tax on Professions, Trades, Callings and Employments Act, 1976",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "20-Jul",
    "form": "Form 9-A or Form 5-A",
    "remarks": ""
  },
  {
    "id": 425,
    "month": "July",
    "state": "Kerala",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jul",
    "form": "Form XXIV",
    "remarks": ""
  },
  {
    "id": 426,
    "month": "July",
    "state": "Kerala",
    "act": "Kerala Shops and Commercial Establishments Workers Welfare Fund Act,2006",
    "type": "Remittance",
    "category": "Monthly Contribution",
    "due_date": "5-Jul",
    "form": "Form 6",
    "remarks": ""
  },
  {
    "id": 427,
    "month": "July",
    "state": "Maharashtra",
    "act": "Maharashtra State Tax on Professions, Trade, Callings And Employments Act, 1975",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "31-Jul",
    "form": "Online",
    "remarks": ""
  },
  {
    "id": 428,
    "month": "July",
    "state": "Punjab",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jul",
    "form": "Form XXIV",
    "remarks": ""
  },
  {
    "id": 429,
    "month": "July",
    "state": "Punjab",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "15-Jul",
    "form": "Form 22",
    "remarks": ""
  },
  {
    "id": 430,
    "month": "July",
    "state": "Rajasthan",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jul",
    "form": "Form XX",
    "remarks": ""
  },
  {
    "id": 431,
    "month": "July",
    "state": "Tamil Nadu",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jul",
    "form": "Form XXIV",
    "remarks": ""
  },
  {
    "id": 432,
    "month": "July",
    "state": "Tamil Nadu",
    "act": "Tamil Nadu Payment of Subsistence Allowance Act, 1981",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "15-Jul",
    "form": "Form 2",
    "remarks": ""
  },
  {
    "id": 433,
    "month": "July",
    "state": "Uttar Pradesh",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jul",
    "form": "Form XXIV",
    "remarks": ""
  },
  {
    "id": 434,
    "month": "July",
    "state": "Uttarakhand",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jul",
    "form": "Form XXIV",
    "remarks": ""
  },
  {
    "id": 435,
    "month": "July",
    "state": "West Bengal",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jul",
    "form": "Form XXIV",
    "remarks": ""
  },
  {
    "id": 436,
    "month": "July",
    "state": "West Bengal",
    "act": "West Bengal State Tax on Professions, Trades, Callings and Employments Act, 1979",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "21-Jul",
    "form": "Online",
    "remarks": ""
  },
  {
    "id": 437,
    "month": "July",
    "state": "Telangana",
    "act": "Telangana Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "10-Jul",
    "form": "Form V",
    "remarks": ""
  },
  {
    "id": 438,
    "month": "July",
    "state": "Assam",
    "act": "Assam Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "31-Jul",
    "form": "Form III, Form III A, Form VII C",
    "remarks": ""
  },
  {
    "id": 439,
    "month": "July",
    "state": "Odisha",
    "act": "Orissa State Tax On Professions, Trades, Callings And Employments Act, 2000",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "31-Jul",
    "form": "Form 5",
    "remarks": ""
  },
  {
    "id": 440,
    "month": "July",
    "state": "Madhya Pradesh",
    "act": "Madhya Pradesh Vritti Kar Adhiniyam, 1995 (PT)",
    "type": "Remittance",
    "category": "Monthly Remittance",
    "due_date": "10-Jul",
    "form": "",
    "remarks": ""
  },
  {
    "id": 441,
    "month": "July",
    "state": "Assam",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "15-Jul",
    "form": "Form 22",
    "remarks": ""
  },
  {
    "id": 442,
    "month": "July",
    "state": "Maharashtra",
    "act": "Maharashtra Labour Welfare Fund Act, 1953",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "15-Jul",
    "form": "Form A-1",
    "remarks": ""
  },
  {
    "id": 443,
    "month": "July",
    "state": "Tamil Nadu",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "31-Jul",
    "form": "Form 21",
    "remarks": ""
  },
  {
    "id": 444,
    "month": "July",
    "state": "Tamil Nadu",
    "act": "Tamil Nadu Shops And Establishments Act,1947",
    "type": "Return",
    "category": "Certificate - Half Yearly",
    "due_date": "31-Jul",
    "form": "Form O",
    "remarks": ""
  },
  {
    "id": 445,
    "month": "July",
    "state": "Tamil Nadu",
    "act": "Maternity Benefit Act, 1961",
    "type": "Return",
    "category": "Certificate - Half Yearly",
    "due_date": "31-Jul",
    "form": "Form L",
    "remarks": ""
  },
  {
    "id": 446,
    "month": "July",
    "state": "Tamil Nadu",
    "act": "Payment of Gratuity Act, 1972",
    "type": "Return",
    "category": "Certificate - Half Yearly",
    "due_date": "31-Jul",
    "form": "Form 'V'",
    "remarks": ""
  },
  {
    "id": 447,
    "month": "July",
    "state": "Tamil Nadu",
    "act": "Payment of Wages Act, 1936",
    "type": "Return",
    "category": "Certificate - Half Yearly",
    "due_date": "31-Jul",
    "form": "Form VIII",
    "remarks": ""
  },
  {
    "id": 448,
    "month": "July",
    "state": "Madhya Pradesh",
    "act": "Madhya Pradesh Vritti Kar Adhiniyam, 1995 (PT)",
    "type": "Return",
    "category": "Quarterly Return",
    "due_date": "15-Jul",
    "form": "Form 7",
    "remarks": ""
  },
  {
    "id": 449,
    "month": "July",
    "state": "Kerala",
    "act": "Kerala Shops and Commercial Establishments Act, 1960",
    "type": "Return",
    "category": "Quarterly Return",
    "due_date": "10-Jul",
    "form": "Form -H",
    "remarks": ""
  },
  {
    "id": 450,
    "month": "July",
    "state": "Goa",
    "act": "Goa Shops And Establishments Act, 1973",
    "type": "Return",
    "category": "Quarterly Return",
    "due_date": "10-Jul",
    "form": "Form XXIX",
    "remarks": ""
  },
  {
    "id": 451,
    "month": "July",
    "state": "Bihar",
    "act": "Bihar Shops and Establishments Act, 1953",
    "type": "Return",
    "category": "Quarterly Return",
    "due_date": "30-Jul",
    "form": "Form XIX",
    "remarks": ""
  },
  {
    "id": 452,
    "month": "July",
    "state": "Jharkhand",
    "act": "Bihar Shops and Establishments Act, 1953",
    "type": "Return",
    "category": "Quarterly Return",
    "due_date": "30-Jul",
    "form": "Form XIX",
    "remarks": ""
  },
  {
    "id": 453,
    "month": "July",
    "state": "Karnataka",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jul",
    "form": "Form XXIV",
    "remarks": ""
  },
  {
    "id": 454,
    "month": "July",
    "state": "Madhya Pradesh",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jul",
    "form": "Form XXIV",
    "remarks": ""
  },
  {
    "id": 455,
    "month": "July",
    "state": "Meghalaya",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jul",
    "form": "Form XXIV",
    "remarks": ""
  },
  {
    "id": 456,
    "month": "July",
    "state": "Mizoram",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jul",
    "form": "Form XXIV",
    "remarks": ""
  },
  {
    "id": 457,
    "month": "July",
    "state": "Nagaland",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jul",
    "form": "Form XXIV",
    "remarks": ""
  },
  {
    "id": 458,
    "month": "July",
    "state": "Puducherry",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jul",
    "form": "Form XXIV",
    "remarks": ""
  },
  {
    "id": 459,
    "month": "July",
    "state": "Tamil Nadu",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "category": "Certificate - Half Yearly",
    "due_date": "30-Jul",
    "form": "Form XXX",
    "remarks": ""
  },
  {
    "id": 460,
    "month": "July",
    "state": "Odisha",
    "act": "Contract Labour (Regulation and Abolition) Act,1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Jul",
    "form": "Form XX",
    "remarks": ""
  },
  {
    "id": 461,
    "month": "July",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Shops And Establishments Act, 1988",
    "type": "Return",
    "category": "Quarterly Return",
    "due_date": "10-Jul",
    "form": "Form XXVII",
    "remarks": ""
  },
  {
    "id": 462,
    "month": "July",
    "state": "Haryana",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "15-Jul",
    "form": "Form No.22",
    "remarks": ""
  },
  {
    "id": 463,
    "month": "July",
    "state": "Karnataka",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "15-Jul",
    "form": "Form No. 21",
    "remarks": ""
  },
  {
    "id": 464,
    "month": "July",
    "state": "Kerala",
    "act": "Kerala Labour Welfare Fund Act, 1975",
    "type": "Remittance and Return",
    "category": "Half Yearly",
    "due_date": "15-Jul",
    "form": "Form A",
    "remarks": ""
  },
  {
    "id": 465,
    "month": "July",
    "state": "Kerala",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "31-Jul",
    "form": "Form No.22",
    "remarks": ""
  },
  {
    "id": 466,
    "month": "July",
    "state": "Chandigarh",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "15-Jul",
    "form": "Form No. 22",
    "remarks": ""
  },
  {
    "id": 467,
    "month": "July",
    "state": "Tamil Nadu",
    "act": "Minimum Wages Act, 1948",
    "type": "Return",
    "category": "Certificate - Half Yearly",
    "due_date": "31-Jul",
    "form": "Form XII",
    "remarks": ""
  },
  {
    "id": 468,
    "month": "July",
    "state": "Uttar Pradesh",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "15-Jul",
    "form": "Form No. 22",
    "remarks": ""
  },
  {
    "id": 469,
    "month": "July",
    "state": "West Bengal",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "15-Jul",
    "form": "Form No. 23",
    "remarks": ""
  },
  {
    "id": 470,
    "month": "July",
    "state": "West Bengal",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Quarterly Return",
    "due_date": "15-Jul",
    "form": "Form No. 23-A",
    "remarks": ""
  },
  {
    "id": 471,
    "month": "July",
    "state": "West Bengal",
    "act": "West Bengal Labour Welfare Fund Act, 1974",
    "type": "Remittance and Return",
    "category": "Half Yearly",
    "due_date": "15-Jul",
    "form": "Form D",
    "remarks": ""
  },
  {
    "id": 472,
    "month": "July",
    "state": "Chhattisgarh",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "15-Jul",
    "form": "Form No. 28",
    "remarks": ""
  },
  {
    "id": 473,
    "month": "July",
    "state": "Goa",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "15-Jul",
    "form": "Form No. 35",
    "remarks": ""
  },
  {
    "id": 474,
    "month": "July",
    "state": "Madhya Pradesh",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "15-Jul",
    "form": "Form No. 28",
    "remarks": ""
  },
  {
    "id": 475,
    "month": "July",
    "state": "Rajasthan",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "15-Jul",
    "form": "Form No. 24",
    "remarks": ""
  },
  {
    "id": 476,
    "month": "July",
    "state": "Uttarakhand",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "15-Jul",
    "form": "Form No. 22",
    "remarks": ""
  },
  {
    "id": 477,
    "month": "July",
    "state": "Himachal Pradesh",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "15-Jul",
    "form": "Form No. 22",
    "remarks": ""
  },
  {
    "id": 478,
    "month": "July",
    "state": "Manipur",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "15-Jul",
    "form": "Form 30",
    "remarks": ""
  },
  {
    "id": 479,
    "month": "July",
    "state": "Meghalaya",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "15-Jul",
    "form": "Form 35",
    "remarks": ""
  },
  {
    "id": 480,
    "month": "July",
    "state": "Mizoram",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "15-Jul",
    "form": "Form No. 31",
    "remarks": ""
  },
  {
    "id": 481,
    "month": "July",
    "state": "Tripura",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "15-Jul",
    "form": "Form 36",
    "remarks": ""
  },
  {
    "id": 482,
    "month": "July",
    "state": "Dadra and Nagar Haveli",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "15-Jul",
    "form": "Form No. 35",
    "remarks": ""
  },
  {
    "id": 483,
    "month": "July",
    "state": "Daman",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "15-Jul",
    "form": "Form No. 35",
    "remarks": ""
  },
  {
    "id": 484,
    "month": "July",
    "state": "Puducherry",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "31-Jul",
    "form": "Form No. 20",
    "remarks": ""
  },
  {
    "id": 485,
    "month": "July",
    "state": "Tamil Nadu",
    "act": "Tamil Nadu Labour Welfare Fund Act, 1972",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Jul",
    "form": "Statement even 'NIL' Along with Form C",
    "remarks": ""
  },
  {
    "id": 486,
    "month": "July",
    "state": "Kerala",
    "act": "Kerala Labour Welfare Fund Act, 1975",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Jul",
    "form": "Statement",
    "remarks": ""
  },
  {
    "id": 487,
    "month": "July",
    "state": "Karnataka",
    "act": "Karnataka Labour Welfare Fund Act, 1965",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Jul",
    "form": "Statement",
    "remarks": ""
  },
  {
    "id": 488,
    "month": "July",
    "state": "Telangana",
    "act": "Telangana Labour Welfare Fund Act, 1987",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Jul",
    "form": "Statement",
    "remarks": ""
  },
  {
    "id": 489,
    "month": "July",
    "state": "Gujarat",
    "act": "Gujarat Labour Welfare Fund Act, 1953",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Jul",
    "form": "Form B & Form C",
    "remarks": ""
  },
  {
    "id": 490,
    "month": "July",
    "state": "West Bengal",
    "act": "West Bengal Labour Welfare Fund Act, 1974",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Jul",
    "form": "Statement",
    "remarks": ""
  },
  {
    "id": 491,
    "month": "July",
    "state": "Maharashtra",
    "act": "Maharashtra Labour Welfare Fund Act, 1953",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Jul",
    "form": "Statement",
    "remarks": ""
  },
  {
    "id": 492,
    "month": "July",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Labour Welfare Fund Act, 1987",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Jul",
    "form": "Statement",
    "remarks": ""
  },
  {
    "id": 493,
    "month": "July",
    "state": "Madhya Pradesh",
    "act": "The Madhya Pradesh Shram Kalyan Nidhi Adhiniyam, 1982 (LWF)",
    "type": "Remittance and Return",
    "category": "Half Yearly Remittance & Return",
    "due_date": "15-Jul",
    "form": "Form A",
    "remarks": ""
  },
  {
    "id": 494,
    "month": "July",
    "state": "Tripura",
    "act": "Tripura Professions Trades Callings And Employments Taxation Act, 1997",
    "type": "Remittance and Return",
    "category": "Monthly",
    "due_date": "31-Jul",
    "form": "Form VII",
    "remarks": ""
  },
  {
    "id": 495,
    "month": "July",
    "state": "Goa",
    "act": "Goa Labour Welfare Fund Act, 1986",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Jul",
    "form": "Statement",
    "remarks": ""
  },
  {
    "id": 496,
    "month": "July",
    "state": "Goa",
    "act": "Goa Labour Welfare Fund Act, 1986",
    "type": "Remittance & Returns",
    "category": "Half Yearly Returns",
    "due_date": "31-Jul",
    "form": "Form A",
    "remarks": ""
  },
  {
    "id": 497,
    "month": "July",
    "state": "Chhattisgarh",
    "act": "Chhattisgarh Shram Kalyan Nidhi Adhiniyam, 1982 (LWF)",
    "type": "Remittance and Return",
    "category": "Half Yearly Remittance & Return",
    "due_date": "15-Jul",
    "form": "Form A",
    "remarks": ""
  },
  {
    "id": 498,
    "month": "July",
    "state": "Chhattisgarh",
    "act": "Chhattisgarh Shram Kalyan Nidhi Adhiniyam, 1982 (LWF)",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Jul",
    "form": "Statement",
    "remarks": ""
  },
  {
    "id": 499,
    "month": "July",
    "state": "Madhya Pradesh",
    "act": "The Madhya Pradesh Shram Kalyan Nidhi Adhiniyam, 1982 (LWF)",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Jul",
    "form": "Statement",
    "remarks": ""
  },
  {
    "id": 500,
    "month": "July",
    "state": "Delhi",
    "act": "Bombay Labour Welfare Fund Act, 1953 (Delhi)",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Jul",
    "form": "Statement",
    "remarks": ""
  },
  {
    "id": 501,
    "month": "July",
    "state": "Odisha",
    "act": "Odisha Labour Welfare Fund Act, 1996",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Jul",
    "form": "Statement",
    "remarks": ""
  },
  {
    "id": 502,
    "month": "July",
    "state": "Odisha",
    "act": "Odisha Labour Welfare Fund Act, 1996",
    "type": "Remittance & Returns",
    "category": "Half Yearly Remittance & Return",
    "due_date": "15-Jul",
    "form": "Form F",
    "remarks": ""
  },
  {
    "id": 503,
    "month": "July",
    "state": "Jharkhand",
    "act": "The Jharkhand Tax On Profession, Trades, Callings And Employments Act, 2011",
    "type": "Remittance",
    "category": "Quarterly Return",
    "due_date": "15-Jul",
    "form": "Form - JPT- 03",
    "remarks": ""
  },
  {
    "id": 504,
    "month": "July",
    "state": "Meghalaya",
    "act": "Meghalaya Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance and Return",
    "category": "Monthly",
    "due_date": "31-Jul",
    "form": "Form III",
    "remarks": ""
  },
  {
    "id": 505,
    "month": "July",
    "state": "Puducherry",
    "act": "Puducherry Municipalities Act, 1973",
    "type": "Remittance and Return",
    "category": "Half Yearly Remittance & Return",
    "due_date": "15-Jul",
    "form": "",
    "remarks": ""
  },
  {
    "id": 506,
    "month": "July",
    "state": "Sikkim",
    "act": "Sikkim Tax On Professions, Trades, Callings And Employments, Act 2006",
    "type": "Remittance",
    "category": "Monthly",
    "due_date": "15-Jul",
    "form": "",
    "remarks": ""
  },
  {
    "id": 507,
    "month": "July",
    "state": "Sikkim",
    "act": "Sikkim Tax On Professions, Trades, Callings And Employments, Act 2006",
    "type": "Return",
    "category": "Quarterly Return",
    "due_date": "31-Jul",
    "form": "Form 5",
    "remarks": ""
  },
  //august
  {
    "id": 508,
    "month": "August",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "10-Aug",
    "form": "Form V",
    "remarks": ""
  },
  {
    "id": 509,
    "month": "August",
    "state": "All States",
    "act": "Employee State Insurance Act, 1948",
    "type": "Remittance",
    "category": "Monthly Contribution Challan",
    "due_date": "15-Aug",
    "form": "Challan",
    "remarks": ""
  },
  {
    "id": 510,
    "month": "August",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Remittance",
    "category": "Monthly Contribution Challan",
    "due_date": "15-Aug",
    "form": "Challan",
    "remarks": ""
  },
  {
    "id": 511,
    "month": "August",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "category": "International Workers Return",
    "due_date": "15-Aug",
    "form": "IW-1",
    "remarks": ""
  },
  {
    "id": 512,
    "month": "August",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "category": "Monthly Return - EDLI Exempted Employer",
    "due_date": "25-Aug",
    "form": "Form - 7(IF)",
    "remarks": ""
  },
  {
    "id": 513,
    "month": "August",
    "state": "Gujarat",
    "act": "Gujarat Panchayats, Municipal Corporations And State Tax on Professions, Traders, Callings and Employments Act, 1976",
    "type": "Return",
    "category": "Monthly Returns",
    "due_date": "15-Aug",
    "form": "Form 5",
    "remarks": ""
  },
  {
    "id": 514,
    "month": "August",
    "state": "Karnataka",
    "act": "Karnataka Tax on Professions, Trades, Callings and Employments Act, 1976",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "20-Aug",
    "form": "Form 9-A or Form 5-A",
    "remarks": ""
  },
  {
    "id": 515,
    "month": "August",
    "state": "Kerala",
    "act": "Kerala Shops and Commercial Establishments Workers Welfare Fund Act, 2006",
    "type": "Remittance",
    "category": "Monthly Contribution",
    "due_date": "5-Aug",
    "form": "Form 6",
    "remarks": ""
  },
  {
    "id": 516,
    "month": "August",
    "state": "Maharashtra",
    "act": "Maharashtra State Tax on Professions, Trade, Callings And Employments Act, 1975",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "31-Aug",
    "form": "Online",
    "remarks": ""
  },
  {
    "id": 517,
    "month": "August",
    "state": "West Bengal",
    "act": "West Bengal State Tax on Professions, Trades, Callings and Employments Act, 1979",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "21-Aug",
    "form": "Online",
    "remarks": ""
  },
  {
    "id": 518,
    "month": "August",
    "state": "Telangana",
    "act": "Telangana Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "10-Aug",
    "form": "Form V",
    "remarks": ""
  },
  {
    "id": 519,
    "month": "August",
    "state": "Assam",
    "act": "Assam Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "31-Aug",
    "form": "Form III, Form III A, Form VII C",
    "remarks": ""
  },
  {
    "id": 520,
    "month": "August",
    "state": "Odisha",
    "act": "Orissa State Tax On Professions, Trades, Callings And Employments Act, 2000",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "31-Aug",
    "form": "Form 5",
    "remarks": ""
  },
  {
    "id": 521,
    "month": "August",
    "state": "Madhya Pradesh",
    "act": "Madhya Pradesh Vritti Kar Adhiniyam, 1995 (PT)",
    "type": "Remittance",
    "category": "Monthly Remittance",
    "due_date": "10-Aug",
    "form": "",
    "remarks": ""
  },
  {
    "id": 522,
    "month": "August",
    "state": "Kerala",
    "act": "Kerala Panchayat Raj Act, 1994 (PT)",
    "type": "Remittance",
    "category": "Half Yearly Remittance",
    "due_date": "31-Aug",
    "form": "Offline",
    "remarks": ""
  },
  {
    "id": 523,
    "month": "August",
    "state": "Haryana",
    "act": "Punjab Labour Welfare Fund Act, 1965",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "1-Aug",
    "form": "Statement",
    "remarks": ""
  },
  {
    "id": 524,
    "month": "August",
    "state": "Tripura",
    "act": "Tripura Professions Trades Callings And Employments Taxation Act, 1997",
    "type": "Remittance and Return",
    "category": "Monthly",
    "due_date": "31-Aug",
    "form": "Form VII",
    "remarks": ""
  },
  {
    "id": 525,
    "month": "August",
    "state": "Punjab",
    "act": "Punjab Labour Welfare Fund Act, 1965",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "1-Aug",
    "form": "Statement",
    "remarks": ""
  },
  {
    "id": 526,
    "month": "August",
    "state": "Chandigarh",
    "act": "Punjab Labour Welfare Fund Act, 1965",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "1-Aug",
    "form": "Statement",
    "remarks": ""
  },
  {
    "id": 527,
    "month": "August",
    "state": "Meghalaya",
    "act": "Meghalaya Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance and Return",
    "category": "Monthly",
    "due_date": "31-Aug",
    "form": "Form III",
    "remarks": ""
  },
  {
    "id": 528,
    "month": "August",
    "state": "Sikkim",
    "act": "Sikkim Tax On Professions, Trades, Callings And Employments, Act 2006",
    "type": "Remittance",
    "category": "Monthly",
    "due_date": "15-Aug",
    "form": "",
    "remarks": ""
  },
  //september
  {
    "id": 529,
    "month": "September",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "10-Sep",
    "form": "Form V",
    "remarks": ""
  },
  {
    "id": 530,
    "month": "September",
    "state": "All States",
    "act": "Employee State Insurance Act, 1948",
    "type": "Remittance",
    "category": "Monthly Contribution Challan",
    "due_date": "15-Sep",
    "form": "Challan",
    "remarks": ""
  },
  {
    "id": 531,
    "month": "September",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Remittance",
    "category": "Monthly Contribution Challan",
    "due_date": "15-Sep",
    "form": "Challan",
    "remarks": ""
  },
  {
    "id": 532,
    "month": "September",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "category": "International Workers Return",
    "due_date": "15-Sep",
    "form": "IW-1",
    "remarks": ""
  },
  {
    "id": 533,
    "month": "September",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "category": "Monthly Return - EDLI Exempted Employer",
    "due_date": "25-Sep",
    "form": "Form - 7(IF)",
    "remarks": ""
  },
  {
    "id": 534,
    "month": "September",
    "state": "Gujarat",
    "act": "Gujarat Panchayats, Municipal Corporations And State Tax on Professions, Traders, Callings and Employments Act, 1976",
    "type": "Return",
    "category": "Monthly Returns",
    "due_date": "15-Sep",
    "form": "Form 5",
    "remarks": ""
  },
  {
    "id": 535,
    "month": "September",
    "state": "Karnataka",
    "act": "Karnataka Tax on Professions, Trades, Callings and Employments Act, 1976",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "20-Sep",
    "form": "Form 9-A or Form 5-A",
    "remarks": ""
  },
  {
    "id": 536,
    "month": "September",
    "state": "Kerala",
    "act": "Kerala Shops and Commercial Establishments Workers Welfare Fund Act, 2006",
    "type": "Remittance",
    "category": "Monthly Contribution",
    "due_date": "5-Sep",
    "form": "Form 6",
    "remarks": ""
  },
  {
    "id": 537,
    "month": "September",
    "state": "Maharashtra",
    "act": "Maharashtra State Tax on Professions, Trade, Callings And Employments Act, 1975",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "30-Sep",
    "form": "Online",
    "remarks": ""
  },
  {
    "id": 538,
    "month": "September",
    "state": "West Bengal",
    "act": "West Bengal State Tax on Professions, Trades, Callings and Employments Act, 1979",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "21-Sep",
    "form": "Online",
    "remarks": ""
  },
  {
    "id": 539,
    "month": "September",
    "state": "Telangana",
    "act": "Telangana Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "10-Sep",
    "form": "Form V",
    "remarks": ""
  },
  {
    "id": 540,
    "month": "September",
    "state": "Assam",
    "act": "Assam Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "30-Sep",
    "form": "Form III, Form III A, Form VII C",
    "remarks": ""
  },
  {
    "id": 541,
    "month": "September",
    "state": "Odisha",
    "act": "Orissa State Tax On Professions, Trades, Callings And Employments Act, 2000",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "30-Sep",
    "form": "Form 5",
    "remarks": ""
  },
  {
    "id": 542,
    "month": "September",
    "state": "Madhya Pradesh",
    "act": "Madhya Pradesh Vritti Kar Adhiniyam, 1995 (PT)",
    "type": "Remittance",
    "category": "Monthly Remittance",
    "due_date": "10-Sep",
    "form": "",
    "remarks": ""
  },
  {
    "id": 543,
    "month": "September",
    "state": "Tamil Nadu",
    "act": "Tamil Nadu Municipal Laws (Second Amendment) Act, 1998 & Tamil Nadu Professional Tax Rules, 1999",
    "type": "Remittance",
    "category": "Remittance and Return",
    "due_date": "30-Sep",
    "form": "Form 2",
    "remarks": ""
  },
  {
    "id": 544,
    "month": "September",
    "state": "Tripura",
    "act": "Tripura Professions Trades Callings And Employments Taxation Act, 1997",
    "type": "Remittance and Return",
    "category": "Monthly",
    "due_date": "30-Sep",
    "form": "Form VII",
    "remarks": ""
  },
  {
    "id": 545,
    "month": "September",
    "state": "Meghalaya",
    "act": "Meghalaya Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance and Return",
    "category": "Monthly",
    "due_date": "30-Sep",
    "form": "Form III",
    "remarks": ""
  },
  {
    "id": 546,
    "month": "September",
    "state": "Nagaland",
    "act": "Nagaland Professionals, Trades, Calling and Employment Taxation (4th Amendment) Act, 2012",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "30-Sep",
    "form": "Form V & Form VA",
    "remarks": ""
  },
  {
    "id": 547,
    "month": "September",
    "state": "Sikkim",
    "act": "Sikkim Tax On Professions, Trades, Callings And Employments, Act 2006",
    "type": "Remittance",
    "category": "Monthly",
    "due_date": "15-Sep",
    "form": "",
    "remarks": ""
  },
  //october
  {
    "id": 548,
    "month": "October",
    "state": "All States",
    "act": "Employment Exchange (Compulsory Notification of Vacancies) Act, 1959",
    "type": "Return",
    "category": "Quarterly Return",
    "due_date": "30-Oct",
    "form": "ER-1",
    "remarks": ""
  },
  {
    "id": 549,
    "month": "October",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "10-Oct",
    "form": "Form V",
    "remarks": ""
  },
  {
    "id": 550,
    "month": "October",
    "state": "All States",
    "act": "Employee State Insurance Act, 1948",
    "type": "Remittance",
    "category": "Monthly Contribution Challan",
    "due_date": "15-Oct",
    "form": "Challan",
    "remarks": ""
  },
  {
    "id": 551,
    "month": "October",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Remittance",
    "category": "Monthly Contribution Challan",
    "due_date": "15-Oct",
    "form": "Challan",
    "remarks": ""
  },
  {
    "id": 552,
    "month": "October",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "category": "International Workers Return",
    "due_date": "15-Oct",
    "form": "IW-1",
    "remarks": ""
  },
  {
    "id": 553,
    "month": "October",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "category": "Monthly Return - EDLI Exempted Employer",
    "due_date": "25-Oct",
    "form": "Form - 7(IF)",
    "remarks": ""
  },
  {
    "id": 554,
    "month": "October",
    "state": "Goa",
    "act": "Contract Labour (Regulation and Abolition) Act, 1970",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "30-Oct",
    "form": "Form XX",
    "remarks": ""
  },
  {
    "id": 555,
    "month": "October",
    "state": "Gujarat",
    "act": "Gujarat Panchayats, Municipal Corporations And State Tax on Professions, Traders, Callings and Employments Act, 1976",
    "type": "Return",
    "category": "Monthly Returns",
    "due_date": "15-Oct",
    "form": "Form 5",
    "remarks": ""
  },
  {
    "id": 556,
    "month": "October",
    "state": "Karnataka",
    "act": "Karnataka Tax on Professions, Trades, Callings and Employments Act, 1976",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "20-Oct",
    "form": "Form 9-A or Form 5-A",
    "remarks": ""
  },
  {
    "id": 557,
    "month": "October",
    "state": "Kerala",
    "act": "Kerala Shops and Commercial Establishments Workers Welfare Fund Act, 2006",
    "type": "Remittance",
    "category": "Monthly Contribution",
    "due_date": "5-Oct",
    "form": "Form 6",
    "remarks": ""
  },
  {
    "id": 558,
    "month": "October",
    "state": "Maharashtra",
    "act": "Maharashtra State Tax on Professions, Trade, Callings And Employments Act, 1975",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "31-Oct",
    "form": "Online",
    "remarks": ""
  },
  {
    "id": 559,
    "month": "October",
    "state": "West Bengal",
    "act": "West Bengal State Tax on Professions, Trades, Callings and Employments Act, 1979",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "21-Oct",
    "form": "Online",
    "remarks": ""
  },
  {
    "id": 560,
    "month": "October",
    "state": "Telangana",
    "act": "Telangana Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "10-Oct",
    "form": "Form V",
    "remarks": ""
  },
  {
    "id": 561,
    "month": "October",
    "state": "Assam",
    "act": "Assam Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "31-Oct",
    "form": "Form III, Form III A, Form VII C",
    "remarks": ""
  },
  {
    "id": 562,
    "month": "October",
    "state": "Odisha",
    "act": "Orissa State Tax On Professions, Trades, Callings And Employments Act, 2000",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "31-Oct",
    "form": "Form 5",
    "remarks": ""
  },
  {
    "id": 563,
    "month": "October",
    "state": "Madhya Pradesh",
    "act": "Madhya Pradesh Vritti Kar Adhiniyam, 1995 (PT)",
    "type": "Remittance",
    "category": "Monthly Remittance",
    "due_date": "10-Oct",
    "form": "",
    "remarks": ""
  },
  {
    "id": 564,
    "month": "October",
    "state": "Chandigarh",
    "act": "Punjab Labour Welfare Fund Act, 1965",
    "type": "Remittance",
    "category": "Remittance and Return",
    "due_date": "15-Oct",
    "form": "",
    "remarks": ""
  },
  {
    "id": 565,
    "month": "October",
    "state": "Jharkhand",
    "act": "The Jharkhand Tax On Profession, Trades, Callings And Employments Act, 2011",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Oct",
    "form": "Form JPT 203",
    "remarks": ""
  },
  {
    "id": 566,
    "month": "October",
    "state": "Madhya Pradesh",
    "act": "Madhya Pradesh Vritti Kar Adhiniyam, 1995 (PT)",
    "type": "Return",
    "category": "Quarterly Return",
    "due_date": "15-Oct",
    "form": "Form 7",
    "remarks": ""
  },
  {
    "id": 567,
    "month": "October",
    "state": "Kerala",
    "act": "Kerala Shops and Commercial Establishments Act, 1960",
    "type": "Return",
    "category": "Quarterly Returns",
    "due_date": "10-Oct",
    "form": "Form -H",
    "remarks": ""
  },
  {
    "id": 568,
    "month": "October",
    "state": "Goa",
    "act": "Goa Shops And Establishments Act, 1973",
    "type": "Return",
    "category": "Quarterly Returns",
    "due_date": "10-Oct",
    "form": "Form XXIX",
    "remarks": ""
  },
  {
    "id": 569,
    "month": "October",
    "state": "Bihar",
    "act": "Bihar Shops and Establishments Act, 1953",
    "type": "Return",
    "category": "Quarterly Return",
    "due_date": "30-Oct",
    "form": "Form XIX",
    "remarks": ""
  },
  {
    "id": 570,
    "month": "October",
    "state": "Jharkhand",
    "act": "Bihar Shops and Establishments Act, 1953",
    "type": "Return",
    "category": "Quarterly Return",
    "due_date": "30-Oct",
    "form": "Form XIX",
    "remarks": ""
  },
  {
    "id": 571,
    "month": "October",
    "state": "Punjab",
    "act": "Punjab Labour Welfare Fund Act, 1965",
    "type": "Remittance",
    "category": "Half Yearly Remittance & Return",
    "due_date": "15-Oct",
    "form": "",
    "remarks": ""
  },
  {
    "id": 572,
    "month": "October",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Shops And Establishments Act, 1988",
    "type": "Return",
    "category": "Quarterly Return",
    "due_date": "10-Oct",
    "form": "Form XXVII",
    "remarks": ""
  },
  {
    "id": 573,
    "month": "October",
    "state": "West Bengal",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Quarterly Return",
    "due_date": "15-Oct",
    "form": "Form No. 23-A",
    "remarks": ""
  },
  {
    "id": 574,
    "month": "October",
    "state": "Tamil Nadu",
    "act": "Tamil Nadu Labour Welfare Fund Act, 1972",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Oct",
    "form": "Statement even \"NIL\" Along with Form C",
    "remarks": ""
  },
  {
    "id": 575,
    "month": "October",
    "state": "Karnataka",
    "act": "Karnataka Labour Welfare Fund Act, 1965",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Oct",
    "form": "Statement",
    "remarks": ""
  },
  {
    "id": 576,
    "month": "October",
    "state": "Telangana",
    "act": "Telangana Labour Welfare Fund Act, 1987",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Oct",
    "form": "Statement",
    "remarks": ""
  },
  {
    "id": 577,
    "month": "October",
    "state": "Gujarat",
    "act": "Gujarat Labour Welfare Fund Act, 1953",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Oct",
    "form": "Form B & Form C",
    "remarks": ""
  },
  {
    "id": 578,
    "month": "October",
    "state": "West Bengal",
    "act": "West Bengal Labour Welfare Fund Act, 1974",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Oct",
    "form": "Statement",
    "remarks": ""
  },
  {
    "id": 579,
    "month": "October",
    "state": "Maharashtra",
    "act": "Maharashtra Labour Welfare Fund Act, 1953",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Oct",
    "form": "Statement",
    "remarks": ""
  },
  {
    "id": 580,
    "month": "October",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Labour Welfare Fund Act, 1987",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Oct",
    "form": "Statement",
    "remarks": ""
  },
  {
    "id": 581,
    "month": "October",
    "state": "Tripura",
    "act": "Tripura Professions Trades Callings And Employments Taxation Act, 1997",
    "type": "Remittance and Return",
    "category": "Monthly",
    "due_date": "31-Oct",
    "form": "Form VII",
    "remarks": ""
  },
  {
    "id": 582,
    "month": "October",
    "state": "Goa",
    "act": "Goa Labour Welfare Fund Act, 1986",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Oct",
    "form": "Statement",
    "remarks": ""
  },
  {
    "id": 583,
    "month": "October",
    "state": "Chhattisgarh",
    "act": "Chhattisgarh Shram Kalyan Nidhi Adhiniyam, 1982 (LWF)",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Oct",
    "form": "Statement",
    "remarks": ""
  },
  {
    "id": 584,
    "month": "October",
    "state": "Madhya Pradesh",
    "act": "The Madhya Pradesh Shram Kalyan Nidhi Adhiniyam, 1982 (LWF)",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Oct",
    "form": "Statement",
    "remarks": ""
  },
  {
    "id": 585,
    "month": "October",
    "state": "Delhi",
    "act": "Bombay Labour Welfare Fund Act, 1953 (Delhi)",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Oct",
    "form": "Statement",
    "remarks": ""
  },
  {
    "id": 586,
    "month": "October",
    "state": "Odisha",
    "act": "Odisha Labour Welfare Fund Act, 1996",
    "type": "Remittance",
    "category": "Unpaid Accumulation & Fine",
    "due_date": "15-Oct",
    "form": "Statement",
    "remarks": ""
  },
  {
    "id": 587,
    "month": "October",
    "state": "Jharkhand",
    "act": "The Jharkhand Tax On Profession, Trades, Callings And Employments Act, 2011",
    "type": "Remittance",
    "category": "Quarterly Return",
    "due_date": "15-Oct",
    "form": "Form - JPT- 03",
    "remarks": ""
  },
  {
    "id": 588,
    "month": "October",
    "state": "Meghalaya",
    "act": "Meghalaya Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance and Return",
    "category": "Monthly",
    "due_date": "31-Oct",
    "form": "Form III",
    "remarks": ""
  },
  {
    "id": 589,
    "month": "October",
    "state": "Sikkim",
    "act": "Sikkim Tax On Professions, Trades, Callings And Employments, Act 2006",
    "type": "Remittance",
    "category": "Monthly",
    "due_date": "15-Oct",
    "form": "",
    "remarks": ""
  },
  {
    "id": 590,
    "month": "October",
    "state": "Sikkim",
    "act": "Sikkim Tax On Professions, Trades, Callings And Employments, Act 2006",
    "type": "Return",
    "category": "Quarterly Return",
    "due_date": "31-Oct",
    "form": "Form 5",
    "remarks": ""
  },
  //november
  {
    "id": 591,
    "month": "November",
    "state": "Haryana",
    "act": "Punjab Labour Welfare Fund Act,1965",
    "type": "Remittance",
    "category": "Unpaid Accumulation",
    "due_date": "1-Nov",
    "form": "",
    "remarks": "Unpaid Accumulation - After 2 years from becoming payable"
  },
  {
    "id": 592,
    "month": "November",
    "state": "Kerala",
    "act": "Kerala Shops and Commercial Establishments Workers Welfare Fund Act,2006",
    "type": "",
    "category": "",
    "due_date": "",
    "form": "",
    "remarks": ""
  },
  {
    "id": 593,
    "month": "November",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "category": "Monthly Contribution",
    "due_date": "5-Nov",
    "form": "Form 6",
    "remarks": ""
  },
  {
    "id": 594,
    "month": "November",
    "state": "Telangana",
    "act": "Telangana Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "10-Nov",
    "form": "Form V",
    "remarks": ""
  },
  {
    "id": 595,
    "month": "November",
    "state": "Madhya Pradesh",
    "act": "Madhya Pradesh Vritti Kar Adhiniyam, 1995 (PT)",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "10-Nov",
    "form": "Form V",
    "remarks": ""
  },
  {
    "id": 596,
    "month": "November",
    "state": "All States",
    "act": "Employee State Insurance Act, 1948",
    "type": "Remittance",
    "category": "Monthly Remittance",
    "due_date": "10-Nov",
    "form": "",
    "remarks": ""
  },
  {
    "id": 597,
    "month": "November",
    "state": "All States",
    "act": "Employee State Insurance Act, 1948",
    "type": "Return",
    "category": "Half Yearly Returns",
    "due_date": "11-Nov",
    "form": "Form 5",
    "remarks": "For period Apr to Sep (after completing the period, 42-day time is there to file the return)"
  },
  {
    "id": 598,
    "month": "November",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Remittance",
    "category": "Monthly Contribution Challan",
    "due_date": "15-Nov",
    "form": "Challan",
    "remarks": ""
  },
  {
    "id": 599,
    "month": "November",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Remittance",
    "category": "Monthly Contribution Challan",
    "due_date": "15-Nov",
    "form": "Challan",
    "remarks": ""
  },
  {
    "id": 600,
    "month": "November",
    "state": "Gujarat",
    "act": "Gujarat Panchayats, Municipal Corporations And State Tax on Professions, Traders, Callings and Employments Act, 1976",
    "type": "Return",
    "category": "International Workers Return",
    "due_date": "15-Nov",
    "form": "IW-1",
    "remarks": ""
  },
  {
    "id": 601,
    "month": "November",
    "state": "Karnataka",
    "act": "Karnataka Tax on Professions, Trades, Callings and Employments Act, 1976",
    "type": "Return",
    "category": "Monthly Returns",
    "due_date": "15-Nov",
    "form": "Form 5",
    "remarks": ""
  },
  {
    "id": 603,
    "month": "November",
    "state": "West Bengal",
    "act": "West Bengal State Tax on Professions, Trades, Callings and Employments Act, 1979",
    "type": "",
    "category": "",
    "due_date": "",
    "form": "",
    "remarks": ""
  },
  {
    "id": 604,
    "month": "November",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "21-Nov",
    "form": "Online",
    "remarks": ""
  },
  {
    "id": 605,
    "month": "November",
    "state": "Kerala",
    "act": "Kerala Industrial Establishments (National and Festival Holidays) Act, 1958",
    "type": "Return",
    "category": "Monthly Return - EDLI Exempted Employer",
    "due_date": "25-Nov",
    "form": "Form - 7(IF)",
    "remarks": ""
  },
  {
    "id": 606,
    "month": "November",
    "state": "Maharashtra",
    "act": "Maharashtra State Tax on Professions, Trade, Callings And Employments Act, 1975",
    "type": "Return",
    "category": "Holiday Intimation",
    "due_date": "30-Nov",
    "form": "Form I",
    "remarks": ""
  },
  {
    "id": 607,
    "month": "November",
    "state": "Assam",
    "act": "Assam Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "30-Nov",
    "form": "Online",
    "remarks": ""
  },
  {
    "id": 608,
    "month": "November",
    "state": "Odisha",
    "act": "Orissa State Tax On Professions, Trades, Callings And Employments Act, 2000",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "30-Nov",
    "form": "Form III, Form III A, Form VII C",
    "remarks": ""
  },
  {
    "id": 609,
    "month": "November",
    "state": "Bihar",
    "act": "Bihar Tax On Professions, Trades, Callings And Employments Act, 2011",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "30-Nov",
    "form": "Form 5",
    "remarks": ""
  },
  //decemeber
  {
    "id": 611,
    "month": "December",
    "state": "Kerala",
    "act": "Kerala Shops and Commercial Establishments Workers Welfare Fund Act, 2006",
    "type": "Remittance",
    "category": "Monthly Contribution",
    "due_date": "5-Dec",
    "form": "Form 6",
    "remarks": ""
  },
  {
    "id": 612,
    "month": "December",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "10-Dec",
    "form": "Form V",
    "remarks": ""
  },
  {
    "id": 613,
    "month": "December",
    "state": "Telangana",
    "act": "Telangana Tax On Professions, Trades, Callings And Employments Act, 1987",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "10-Dec",
    "form": "Form V",
    "remarks": ""
  },
  {
    "id": 614,
    "month": "December",
    "state": "Madhya Pradesh",
    "act": "Madhya Pradesh Vritti Kar Adhiniyam, 1995 (PT)",
    "type": "Remittance",
    "category": "Monthly Remittance",
    "due_date": "10-Dec",
    "form": "",
    "remarks": ""
  },
  {
    "id": 615,
    "month": "December",
    "state": "All States",
    "act": "Employee State Insurance Act, 1948",
    "type": "Remittance",
    "category": "Monthly Contribution Challan",
    "due_date": "15-Dec",
    "form": "Challan",
    "remarks": ""
  },
  {
    "id": 616,
    "month": "December",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Remittance",
    "category": "Monthly Contribution Challan",
    "due_date": "15-Dec",
    "form": "Challan",
    "remarks": ""
  },
  {
    "id": 617,
    "month": "December",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "category": "International Workers Return",
    "due_date": "15-Dec",
    "form": "IW-1",
    "remarks": ""
  },
  {
    "id": 618,
    "month": "December",
    "state": "Gujarat",
    "act": "Gujarat Panchayats, Municipal Corporations And State Tax on Professions, Traders, Callings and Employments Act, 1976",
    "type": "Return",
    "category": "Monthly Returns",
    "due_date": "15-Dec",
    "form": "Form 5",
    "remarks": ""
  },
  {
    "id": 619,
    "month": "December",
    "state": "Sikkim",
    "act": "Sikkim Tax On Professions, Trades, Callings And Employments, Act 2006",
    "type": "Remittance",
    "category": "Monthly",
    "due_date": "15-Dec",
    "form": "",
    "remarks": ""
  },
  {
    "id": 620,
    "month": "December",
    "state": "Gujarat",
    "act": "Gujarat Panchayats, Municipal Corporations And State Tax on Professions, Traders, Callings and Employments Act, 1976",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "20-Dec",
    "form": "Form 5A, Form 5AA, Form 5C",
    "remarks": ""
  },
  {
    "id": 621,
    "month": "December",
    "state": "Karnataka",
    "act": "Karnataka Tax on Professions, Trades, Callings and Employments Act, 1976",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "20-Dec",
    "form": "Form 9-A or Form 5-A",
    "remarks": ""
  },
  {
    "id": 622,
    "month": "December",
    "state": "West Bengal",
    "act": "West Bengal State Tax on Professions, Trades, Callings and Employments Act, 1979",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "21-Dec",
    "form": "Online",
    "remarks": ""
  },
  {
    "id": 623,
    "month": "December",
    "state": "All States",
    "act": "Employee Provident Fund And Miscellaneous Provisions Act, 1952",
    "type": "Return",
    "category": "Monthly Return - EDLI Exempted Employer",
    "due_date": "25-Dec",
    "form": "Form - 7(IF)",
    "remarks": ""
  },
  {
    "id": 624,
    "month": "December",
    "state": "Andhra Pradesh",
    "act": "Andhra Pradesh Factories And Establishments (National, Festival And Other Holidays) Act, 1974",
    "type": "Return",
    "category": "Holiday Notification",
    "due_date": "31-Dec",
    "form": "Form I",
    "remarks": "List of Holidays"
  },
  {
    "id": 625,
    "month": "December",
    "state": "Maharashtra",
    "act": "Maharashtra State Tax on Professions, Trade, Callings And Employments Act, 1975",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "31-Dec",
    "form": "Online",
    "remarks": ""
  },
  {
    "id": 626,
    "month": "December",
    "state": "Delhi",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Holiday Intimation",
    "due_date": "31-Dec",
    "form": "List of Holidays",
    "remarks": ""
  },
  {
    "id": 627,
    "month": "December",
    "state": "Tamil Nadu",
    "act": "Tamil Nadu Industrial Establishments (National, festival and special holidays) Act, 1958",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Dec",
    "form": "Form V",
    "remarks": ""
  },
  {
    "id": 628,
    "month": "December",
    "state": "Tamil Nadu",
    "act": "Tamil Nadu Industrial Establishments (National, festival and special holidays) Act, 1958",
    "type": "Return",
    "category": "Application on Change of Holidays",
    "due_date": "31-Dec",
    "form": "Form IV",
    "remarks": "If any changes in approved holidays."
  },
  {
    "id": 629,
    "month": "December",
    "state": "Odisha",
    "act": "Self Certificate - UNDER ORISSA SHOPS AND COMMERCIAL ESTABLISHMENTS ACT, 1956",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Dec",
    "form": "Form 15",
    "remarks": ""
  },
  {
    "id": 630,
    "month": "December",
    "state": "Assam",
    "act": "Assam Professions, Trades, Callings And Employments Taxation Act, 1947",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "31-Dec",
    "form": "Form III, Form III A, Form VII C",
    "remarks": ""
  },
  {
    "id": 631,
    "month": "December",
    "state": "Odisha",
    "act": "Orissa State Tax On Professions, Trades, Callings And Employments Act, 2000",
    "type": "Remittance",
    "category": "Monthly Remittance & Filing of Return",
    "due_date": "31-Dec",
    "form": "Form 5",
    "remarks": ""
  },
  {
    "id": 632,
    "month": "December",
    "state": "Maharashtra",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Holiday Intimation",
    "due_date": "31-Dec",
    "form": "List of Holidays",
    "remarks": ""
  },
  {
    "id": 633,
    "month": "December",
    "state": "Delhi",
    "act": "Punjab Industrial Establishments (National and Festival Holidays and Casual and Sick Leave) Rules, 1966",
    "type": "Return",
    "category": "Holiday Intimation",
    "due_date": "31-Dec",
    "form": "Form A",
    "remarks": "List of Holidays"
  },
  {
    "id": 634,
    "month": "December",
    "state": "Gujarat",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Holiday Intimation",
    "due_date": "31-Dec",
    "form": "List of Holidays",
    "remarks": ""
  },
  {
    "id": 635,
    "month": "December",
    "state": "Assam",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "NFH Intimation",
    "due_date": "31-Dec",
    "form": "List of Holidays",
    "remarks": ""
  },
  {
    "id": 636,
    "month": "December",
    "state": "Bihar",
    "act": "Factories Act, 1948",
    "type": "Return",
    "category": "Holiday Intimation",
    "due_date": "31-Dec",
    "form": "List of Holidays",
    "remarks": ""
  },
  {
    "id": 656,
    "month": "December",
    "state": "Karnataka",
    "act": "Payment of Bonus Act, 1965",
    "type": "Return",
    "category": "Annual Returns",
    "due_date": "31-Dec",
    "form": "Form D",
    "remarks": "1. Bonus amount has to be paid within a period of eight months from the close of the Accounting year. 2. Filing Form D on or before 31st of December otherwise after paid the Bonus on or before one month."
  }

]
