import React, { useState, useEffect } from 'react';
import { Card, CardContent, Grid,Typography, TextField, Select, MenuItem, Button, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CorporateFare as CorporateFareIcon, Add,Remove, CardMembership as CardMembershipIcon, LocationOn, Email, Phone, AccountCircle, Lock, DateRange, FormatListNumbered, Person } from '@mui/icons-material';
import axios from 'axios';
import { COLORS } from '../Component/colors';
import api from '../Services/api';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import { Link ,useNavigate} from 'react-router-dom';

const useStyles = makeStyles({
 

  detail: {
    display: 'flex',
    alignItems: 'center',
    justifyContent:"center",
    marginBottom: 15,
  },
  
  textField: {
    width: '80%',
    '& .MuiInputLabel-root': {
      color: 'gray', // Default label color (or set to something else)
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'gray', // Outline color to white when not focused
      },
      '&:hover fieldset': {
        borderColor: 'gray', // Optional: Outline color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black', // Optional: Outline color when focused
      },
    },
    // When the field is focused, change label color to white
    '& .MuiInputLabel-root.Mui-focused': {
      color: 'gray',
    },
    // Text inside input area when focused
    '& .MuiOutlinedInput-input': {
      color: 'black', // Text color inside the input area when focused
    },
    // Ensure the text inside is visible when the field is not focused
    '& .MuiOutlinedInput-input.Mui-focused': {
      color: 'black', // Make text black when focused to keep it visible
    },
  },
 

});

const statesInIndia = ['Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal', 'Andaman and Nicobar Islands', 'Chandigarh', 'Dadra and Nagar Haveli and Daman and Diu', 'Delhi', 'Ladakh', 'Lakshadweep', 'Puducherry'];

export default function AdminAssessor() {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    companyId: '',
    stateId: '',
    placeName: '',
    principleEmployer: '',
    authorisedPersonName: '',
    companyEmailId: '',
    companyPhoneNumber: '',
    companyEmailId1: '',
    companyPhoneNumber1: '',
    companyAddress: '',
    clientName: '',
    clientContactPersonName: '',
    clientContactPersonPhoneNumber: '',
    clientContactPersonEmailId: '',
    clientContactPersonPhoneNumber1: '',
    clientContactPersonEmailId1: '',
    assessorCompanyName: '',
    assessorPlaceName: '',
    assessorStateId: '',
    assessorAddress: '',
    assessorPinCode: '',
    site: '',
    assessorName: '',
    assessorPhoneNumber: '',
    assessorEmail: '',
    assessorPhoneNumber1: '',
    assessorEmail1: '',
    assessorUsername: '',
    assessorPassword: '',
    joiningDate: '',
    exitDate: '',

  });
  const [errors, setErrors] = useState({});
  const [clientOptions, setClientOptions] = useState([]);
  const [showPassword, setShowPassword] = React.useState(false);
  const [extraEmailAssessorVisible, setExtraEmailAssessorVisible] = useState(false);
  const [extraPhoneNumberVisible, setExtraPhoneNumberVisible] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [snackbarErrorMessage, setSnackbarErrorMessage] = useState('');
  const navigate=useNavigate();



  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleAddEmailAssessor = () => {
    setExtraEmailAssessorVisible(prevState=>!prevState);
  }

  const handlePhoneNumberAssessor = () => {
    setExtraPhoneNumberVisible(prevState=>!prevState);
  }

  //  API TO FETCH ALL CLIENTS DETAILS
  useEffect(() => {
    api.client.getClients()
      .then(response => {
        setClientOptions(response.data);
      })
      .catch(error => {
        console.error('Error fetching company names:', error);
      });
  }, []);



  // API TO FETCH SELECTED CLIENT DETAILS 
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'clientName') {

      api.client.getClients().then(response => {
        const selectedClient = response.data.find(client => client.companyId === value);
        if (selectedClient) {
          const { companyAddress, clientName, principleEmployer, companyId, placeName, clientContactPersonName, authorisedPersonName,
            companyEmailId, companyEmailId1, companyPhoneNumber1, companyPhoneNumber, clientContactPersonEmailId, clientContactPersonEmailId1, pinCode, clientContactPersonPhoneNumber1, clientContactPersonPhoneNumber, stateId } = selectedClient;
          setFormData(prevState => ({
            ...prevState,
            clientName: clientName,
            companyId,
            principleEmployer,
            companyAddress,
            pinCode,
            authorisedPersonName,
            companyEmailId,
            companyPhoneNumber,
            companyEmailId1,
            companyPhoneNumber1,
            placeName,
            clientContactPersonName,
            clientContactPersonEmailId,
            clientContactPersonPhoneNumber,
            clientContactPersonEmailId1,
            clientContactPersonPhoneNumber1,
            stateId,

          }));
        }
      })
        .catch(error => {
          console.error('Error fetching vendor details:', error);
        });
    }

    if (name !== 'clientName') {
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };


  //VALIDATION
  const validateForm = () => {
    const newErrors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^[6789]\d{9}$/;
    const pinCodePattern = /^(?!000000$)[0-9]{6}$/;
    if (!formData.clientName) newErrors.clientName = 'Select Client';

    if (!formData.assessorCompanyName) newErrors.assessorCompanyName = 'company Name is required';
    if (!formData.assessorPlaceName) newErrors.assessorPlaceName = 'company Name is required';
    if (!formData.assessorAddress) newErrors.assessorAddress = 'company address is required';
    if (!formData.assessorName) newErrors.assessorName = 'spoc Name is required';
    if (!formData.site) newErrors.site = 'site Name is required';
    if (!emailPattern.test(formData.assessorEmail)) {
      newErrors.assessorEmail = formData.assessorEmail ? 'Invalid email address(ex:text@email.com)' : 'Email address required';
    }
    if (!pinCodePattern.test(formData.assessorPinCode)) {
      newErrors.assessorPinCode = formData.assessorPinCode ? 'Invalid Pin code format(245678)' : 'Pin code required';
    }
    if (!phonePattern.test(formData.assessorPhoneNumber)) {
      newErrors.assessorPhoneNumber = formData.assessorPhoneNumber ? 'Invalid phone number(only numbers and length should be 10)' : 'Phone number required';
    }
    if (formData.assessorEmail1 && !emailPattern.test(formData.assessorEmail1)) {
      newErrors.assessorEmail1 = 'Invalid email address (e.g., text@email.com)';
    }
    
    if (formData.assessorPhoneNumber1 && !phonePattern.test(formData.assessorPhoneNumber1)) {
      newErrors.assessorPhoneNumber1 = 'Invalid phone number. Number should not start with zero)';
    }
    
    const stateIdAssessor = formData.assessorStateId;
    if (!stateIdAssessor) {
      newErrors.stateIdAssessor = 'Please select State';
    }
    if (!formData.assessorUsername) newErrors.assessorUsername = 'Username is required';
    if (!formData.assessorPassword) newErrors.assessorPassword = 'Password is required';
    if (!formData.joiningDate) newErrors.joiningDate = 'Contract Start Date is required';
    if (!formData.exitDate) newErrors.exitDate = 'Contract End Date is required';

    const selectField = formData.clientName;
    if (!selectField) {
      newErrors.selectField = 'Please select client';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  //CREATING ASSESSOR PROFILE
  const handleSave = async () => {
    if (validateForm()) {
      let data = sessionStorage.getItem('user');
      let data1 = JSON.parse(data);
      let type = data1.type;
      try {
        if(type === 'admin'){
        const response = await api.assessor.createAssessor(formData)

        if (response.status === 400) {
          setErrorSnackbarOpen(true);
          setSnackbarErrorMessage("The username you Entered is already in use. Please enter a different one");
          
        }
        else if (response.status === 201) {
         
          setSnackbarOpen(true);
          setSnackbarMessage("Assessor Created Succesfully")
          setFormData({
            companyId: '',
            stateId: '',
            placeName: '',
            principleEmployer: '',
            authorisedPersonName: '',
            companyEmailId: '',
            companyPhoneNumber: '',
            companyEmailId1: '',
            companyPhoneNumber1: '',
            companyAddress: '',
            clientName: '',
            clientContactPersonName: '',
            clientContactPersonPhoneNumber: '',
            clientContactPersonEmailId: '',
            clientContactPersonPhoneNumber1: '',
            clientContactPersonEmailId1: '',
            assessorCompanyName: '',
            assessorPlaceName: '',
            assessorStateId: '',
            assessorAddress: '',
            assessorPinCode: '',
            site: '',
            assessorName: '',
            assessorPhoneNumber: '',
            assessorEmail: '',
            assessorPhoneNumber1: '',
            assessorEmail1: '',
            assessorUsername: '',
            assessorPassword: '',
            joiningDate: '',
            exitDate: '',
          })


        }
      }
      else {
        setErrorSnackbarOpen(true);
        setSnackbarErrorMessage("Only admin can create company");
        navigate('/');
            }

      }
      catch (error) {
        console.error('Error saving vendor profile:', error);
       
      }
    }
  };


  return (
    <>
       <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          
          }}
          severity="success"
          open={snackbarOpen}
          autoHideDuration={4000}
          onClose={() => setSnackbarOpen(false)}
          sx={{
            marginTop: '2.9%', 
            marginRight: '7%'
          }}
        >
          <SnackbarContent style={{ backgroundColor: "green" }} message={snackbarMessage} />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          severity="error"
          open={errorSnackbarOpen}
          autoHideDuration={5000}
          sx={{
            marginTop: '2.9%', 
            marginRight: '7%'
          }}
          onClose={() => {setErrorSnackbarOpen(false)}}
        >
          <SnackbarContent style={{ backgroundColor: "red" }} message={snackbarErrorMessage} />
        </Snackbar>
        <div style={{background:'#eeee'}}>
   <h4 className="p-3  text-center " style={{borderRadius:'0px 0px 0px 0px',background: 'linear-gradient(205deg, rgba(28,28,28,1) 68%, rgba(213,214,215,0) 8%)',color:'#eeee'}}>Create Assessor Profile</h4>
<div className="mt-4">
        
      
          <Grid container >
            <Grid item xs={12} sm={6}>
            <h6 className="text-secondary pb-2" style={{paddingLeft:'60px'}}>Client Details</h6>
          <div className={classes.detail}>
            {/* <CorporateFareIcon className={classes.icon} /> */}
            <Select
              InputLabelProps={{ shrink: true }}
              className={classes.textField}
              value={formData.companyId}
              // sx={{border:'1px solid lightgray'}}
              onChange={handleChange}
              name="clientName"
              displayEmpty
              error={!!errors.clientName}
            >
              <MenuItem value="" disabled>Select Client</MenuItem>
              {Array.isArray(clientOptions) && clientOptions.map((client, index) => (
                <MenuItem key={index} value={client.companyId}>
                  {client.clientName} {client.stateId}
                </MenuItem>
              ))}
            </Select>
          </div>
          {errors.selectField && (
            <div className="pb-2 text-center" style={{ color: 'red', fontSize: '14px' }}>
              {errors.selectField}
            </div>
          )}

          <div className={classes.detail}>
            {/* <LocationOn className={classes.icon} /> */}
            <TextField 
              InputLabelProps={{ shrink: true }}
              className={classes.textField} label="Address" name="companyAddress" value={formData.companyAddress} onChange={handleChange} InputProps={{ readOnly: true }} />
          </div>
          <div className={classes.detail}>
            {/* <MyLocationIcon className={classes.icon} /> */}
            <TextField
              InputLabelProps={{ shrink: true }}
              className={classes.textField}
              label="Place"
              name="placeName"
              value={formData.placeName}


              InputProps={{ readOnly: true }}

            />
          </div>
          <div className={classes.detail}>
            {/* <LocationOn className={classes.icon} /> */}
            <TextField
              InputLabelProps={{ shrink: true }}
               className={classes.textField} label="Pincode" name="pinCode" value={formData.pinCode} onChange={handleChange} InputProps={{ readOnly: true }}/>
          </div>
          <div className={classes.detail}>
            {/* <Person className={classes.icon} /> */}
            <TextField 
              InputLabelProps={{ shrink: true }}
              className={classes.textField} label="Authorised Person Name" name="clientContactPersonName" value={formData.clientContactPersonName} onChange={handleChange} InputProps={{ readOnly: true }}
            />
          </div>
          <div className={classes.detail}>
            {/* <Email className={classes.icon} /> */}
            <TextField 
              InputLabelProps={{ shrink: true }}
              className={classes.textField} label="Email" name="clientContactPersonEmailId" value={formData.clientContactPersonEmailId} onChange={handleChange} InputProps={{ readOnly: true }} />
          </div>
          <div className={classes.detail}>
            {/* <Phone className={classes.icon} /> */}
            <TextField 
              InputLabelProps={{ shrink: true }}
              className={classes.textField} label="Phone Number"  name="clientContactPersonPhoneNumber" value={formData.clientContactPersonPhoneNumber} onChange={handleChange} type='number' InputProps={{ readOnly: true }} />
          </div>
          </Grid>
          <Grid xs={12} md={6}>
          <h6 className="text-secondary pb-2" style={{paddingLeft:'60px'}}>Assessor Details</h6>
          <div className={classes.detail}>
            {/* <Person className={classes.icon} /> */}
            <TextField
              InputLabelProps={{ shrink: true }}
               className={classes.textField} label="Assessor Company Name" name="assessorCompanyName" value={formData.assessorCompanyName} onChange={handleChange} error={!!errors.assessorCompanyName}
              helperText={errors.assessorCompanyName} />
          </div>
          <div className={classes.detail}>
            {/* <LocationOn className={classes.icon} /> */}
            <Select
              className={classes.textField}
              label="State ID"
              InputLabelProps={{ shrink: true }}
              // sx={{border:'1px solid lightgray'}}
              value={formData.assessorStateId}
              onChange={handleChange}
              name="assessorStateId"
              displayEmpty
              error={!!errors.assessorStateId}
            >
              <MenuItem value="" disabled >Select State</MenuItem>
              {statesInIndia.map((state, index) => (

                <MenuItem key={index} value={state}>{state}</MenuItem>
              ))}
            </Select>

          </div>
          {errors.stateIdAssessor && (
            <div className="pb-2 text-center" style={{ color: 'red', fontSize: '14px' }}>
              {errors.stateIdAssessor}
            </div>
          )}
          <div className={classes.detail}>
            {/* <LocationOn className={classes.icon} /> */}
            <TextField 
              InputLabelProps={{ shrink: true }}
              className={classes.textField} label="Assessor Company Address" name="assessorAddress" value={formData.assessorAddress} onChange={handleChange} error={!!errors.assessorAddress}
              helperText={errors.assessorAddress} />
          </div>
          <div className={classes.detail}>
            {/* <MyLocationIcon className={classes.icon} /> */}
            <TextField
              className={classes.textField}
              label="Place"
              InputLabelProps={{ shrink: true }}
              name="assessorPlaceName"
              value={formData.assessorPlaceName}
              onChange={handleChange}
              placeholder="Enter your city"
              error={!!errors.assessorPlaceName}
              helperText={errors.assessorPlaceName}
            />
          </div>
          <div className={classes.detail}>
            {/* <LocationOn className={classes.icon} /> */}
            <TextField className={classes.textField} label="Pincode" name="assessorPinCode" value={formData.assessorPinCode} onChange={handleChange} error={!!errors.assessorPinCode}
              helperText={errors.assessorPinCode} InputLabelProps={{ shrink: true }}/>
          </div>
          <div className={classes.detail}>
            {/* <LocationOn className={classes.icon} /> */}
            <TextField 
              InputLabelProps={{ shrink: true }}
              className={classes.textField} label="Assessor Site" name="site" value={formData.site} onChange={handleChange} error={!!errors.site}
              helperText={errors.site} />
          </div>
          <div className={classes.detail}>
            {/* <Person className={classes.icon} /> */}
            <TextField 
              InputLabelProps={{ shrink: true }}
              className={classes.textField} label="Contact Person" name="assessorName" value={formData.assessorName} onChange={handleChange} error={!!errors.assessorName}
              helperText={errors.assessorName} />
          </div>
          <div className={classes.detail}>
            {/* <Phone className={classes.icon} /> */}
            <TextField 
              InputLabelProps={{ shrink: true }}
              sx={{marginLeft:'42px'}} className={classes.textField} label="Contact Person Phone Number" name="assessorPhoneNumber" value={formData.assessorPhoneNumber} onChange={handleChange} error={!!errors.assessorPhoneNumber}
              helperText={errors.assessorPhoneNumber} />
            <IconButton onClick={handlePhoneNumberAssessor} style={{ marginLeft: '5px' }} color="primary">
            {extraPhoneNumberVisible ? <Remove sx={{ color: "gray" }} /> : <Add sx={{ color: "gray" }} />}
        </IconButton>
          </div>
          {extraPhoneNumberVisible && (
            <div className={classes.detail}>
              {/* <PhoneIphoneIcon className={classes.icon} /> */}
              <TextField className={classes.textField}
                label="Alternative PhoneNumber"
                type="number"
                InputLabelProps={{ shrink: true }}
                name="assessorPhoneNumber1"
                value={formData.assessorPhoneNumber1}
                onChange={handleChange}
                error={!!errors.assessorPhoneNumber1}
                helperText={errors.assessorPhoneNumber1} />

            </div>
          )}
          <div className={classes.detail}>
            {/* <Email className={classes.icon} /> */}
            <TextField sx={{marginLeft:'42px'}} className={classes.textField} label="Contact Person Email" name="assessorEmail" value={formData.assessorEmail} onChange={handleChange} error={!!errors.assessorEmail}
              helperText={errors.assessorEmail}   InputLabelProps={{ shrink: true }}/>
            <IconButton onClick={handleAddEmailAssessor} style={{ marginLeft: '5px' }} color="primary">
            {extraEmailAssessorVisible ? <Remove sx={{ color: "gray" }} /> : <Add sx={{ color: "gray" }} />}
        </IconButton>
          </div>
          {extraEmailAssessorVisible   && (
            <div className={classes.detail}>
              {/* <MailOutlineIcon className={classes.icon} /> */}
              <TextField className={classes.textField}
                label="Alternative Email"
                type="email"
                name="assessorEmail1"
                InputLabelProps={{ shrink: true }}
                value={formData.assessorEmail1}
                onChange={handleChange}
                error={!!errors.assessorEmail1}
                helperText={errors.assessorEmail1} />

            </div>
          )}

          <div className={classes.detail}>
            {/* <DateRange className={classes.icon} /> */}
            <TextField
              className={classes.textField}
              label="Date of joining"
              type="date"
              name="joiningDate"
              InputLabelProps={{ shrink: true }}
              value={formData.joiningDate}
              onChange={handleChange}
             
              error={!!errors.joiningDate}
              helperText={errors.joiningDate}
            />
          </div>
          <div className={classes.detail}>
            {/* <DateRange className={classes.icon} /> */}
            <TextField
              className={classes.textField}
              label="Date of exit"
              type="date"
              name="exitDate"
              value={formData.exitDate}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              error={!!errors.exitDate}
              helperText={errors.exitDate}
            />
          </div>
        

          <div className={classes.detail}>
            {/* <AccountCircle className={classes.icon} /> */}
            <TextField
              className={classes.textField}
              label="Username"
              name="assessorUsername"
              value={formData.assessorUsername}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              error={!!errors.assessorUsername}
              helperText={errors.assessorUsername}
            />
          </div>

          <div className={classes.detail}>
            {/* <Lock className={classes.icon} /> */}
            <FormControl sx={{width: '100%'}} variant="outlined">

              <TextField
              sx={{marginLeft:'10%'}}
              className={classes.textField}
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff sx={{color:'black'}}/> : <Visibility sx={{color:'black'}}/>}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}

                label="Password"
                InputLabelProps={{ shrink: true }}
                name="assessorPassword"
                value={formData.assessorPassword}
                onChange={handleChange}
                error={!!errors.assessorPassword}
                helperText={errors.assessorPassword}
              />
            </FormControl>

          </div>
          </Grid>
            <Grid item xs={12}>
              <button type="button" className="btn  btn-outline-dark float-end   mt-2 mb-2" style={{marginRight:'59px'}} disabled={!validateForm} onClick={handleSave}>
                  Create
                </button>
              </Grid>
          </Grid>
         
       
    </div>
    </div>
    </>
  )
}
