import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import api from '../../../Services/api';
 
const MonthlyComplianceReport = ({month,year}) => {
  // State to store fetched data
  console.log("month",month,year);
  const [data, setData] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);
 
  // Define the headers for the Excel sheet
  const headers = [
    'Sl No',
    'Vendor Code',
    'Name of Contractor/Sub-Contractor',
    'Address',
    'Contact Person',
    'Contact No',
    'Escalation Contact No',
    'Email Id',
    'Escalation Email Id',
    'Nature of Work',
    'combined Muster Roll Cum Register Of Wages',
    'Register Of Workmen',
    'Leave Register / Leave Book',
    'Employment Card',
    'Wage Slip',
    'Service Certificate For Left Employees',
    'In And Out Time Register',
    'Bank Statement For Salary Credit Confirmation',
    'PF Declaration On Letterhead',
    'ESI Declaration On Letterhead',
    'Overtime Declaration On Letterhead',
    'Payment Done As Per Revised Rate Of Minimum wages',
    'PF Monthly ECR',
    'PF Combined Challan',
    'PF TRRN Summary',
    'IW1 International Worker Monthly Returns',
    'ESI Accident Register',
    'ESIC Contribution Challan',
    'ESIC Monthly Statement',
    'Pehchan Card',
    'Professional TaxReturns',
    'PT Challan',
    'Monthly Bonus Paid Or Not',
    'Statutory Bonus Return',
    'Registration Of Establishment',
    'Appointment Order In FormQ',
    'Muster Roll Of Women Workers',
    'Employees Compensation Policy',
    'Equal Remuneration And Register To Be Maintained',
    'Register of Overtime and Payment',
    'Register Of Leave And Leave Book',
    'Register Of Adult Workers Cum MusterRoll',
    'Combined Register For MusterRoll and Wages',
    'Register Of Unclaimed Wages And Payment To The Board',
    'LWF Challan',
    'No Of Employees',
    'Complied Percentage',
    'Not Complied Percentage'
  ];
 


let data1 = sessionStorage.getItem('user');
let clientData = JSON.parse(data1);
let id = clientData.id;


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.client.getComplianceReport(id,month,year) ;
        const result = response.data ;
        console.log("result", result)
        console.log("response", result.data.vendorprofile);
        console.log("master response", result.data.vendorMonthlyData);
 
        // Check if vendorProfile is an array and set the data
        if (Array.isArray(result.data.vendorprofile) ) {
          setData(result.data.vendorprofile);
          setMonthlyData(result.data.vendorMonthlyData)
        } else {
          console.error('vendorProfile is not an array', result.data.vendorprofile);
          setData([]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setData([]);
      }
    };
 
    fetchData();
  }, []);
 
  // Function to generate Excel file
  const handleExport = () => {
    // Mapping API data to Excel sheet format
    const mappedData = data.map((item, index) => {
      const masterItem = monthlyData.find((master) => master.vendorId === item.vendorId);
 
      const getValidationStatus = (field) => {
        if (!masterItem || !masterItem[field]) {
          return 'NA';
        }
        return masterItem[field]?.na && masterItem[field].validation === 2
        ? 'Not Applicable'
        : masterItem[field].validation === 2
          ? 'Complied'
          : masterItem[field].validation === 3
            ? 'Non-Complied'
             :masterItem[field].validation === null
              ? 'Not Avaliable'
               : 'NA'; // or some default value
     
            };
      return {
        'Sl No': index + 1,  // Automatically generate Sl No starting from 1
        'Vendor Code' : item?.vendorCode ? item?.vendorCode : 'NA',
        'Name of Contractor/Sub-Contractor': item?.vendorCompanyName ? item?.vendorCompanyName : 'N/A',
        'Address': (item?.vendorCompanyAddress ? item.vendorCompanyAddress + ', ' : '') + (item?.vendorPlaceName ? item.vendorPlaceName + '-' : '') + (item?.vendorPinCode || 'N/A'),
        'Contact Person': item.vendorName,
        'Contact No': item.vendorPhoneNumber,
        'Escalation Contact No': item.vendorPhoneNumber1 || 'N/A',
        'Email Id': item.vendorEmail,
        'Escalation Email Id': item.vendorEmail1 || 'N/A',
        'Nature of Work': item.natureOfWork,

        'Combined Muster Roll Cum Register Of Wages': masterItem ? getValidationStatus('combinedMusterRollCumRegisterOfWages') : 'N/A',
        'Register of Workmen': masterItem ? getValidationStatus('RegisterOfWorkmen') : 'N/A',
        'Leave Register / Leave Book': masterItem ? getValidationStatus('leaveRegisterLeaveBook') : 'N/A',
        'Employment Card': masterItem ? getValidationStatus('EmploymentCard') : 'N/A',
        'Wage Slip': masterItem ? getValidationStatus('WageSlip') : 'N/A',
        'Service Certificate for Left Employees': masterItem ? getValidationStatus('ServiceCertificateForLeftEmployees') : 'N/A',
        'In and Out Time Register': masterItem ? getValidationStatus('inAndOutTimeRegister') : 'N/A',
        'Bank Statement or Salary Credit Confirmation': masterItem ? getValidationStatus('BankStatementOrSalaryCreditConfirmation') : 'N/A',
        'PF Declaration on Letterhead': masterItem ? getValidationStatus('PFDeclarationOnLetterhead') : 'N/A',
        'ESI Declaration on Letterhead': masterItem ? getValidationStatus('ESIDeclarationOnLetterhead') : 'N/A',
        'Overtime Declaration on Letterhead': masterItem ? getValidationStatus('OvertimeDeclarationOnLetterhead') : 'N/A',
        'Payment Done as per Revised Rate of Minimum Wages': masterItem ? getValidationStatus('PaymentDoneAsPerRevisedRateOfMinimumwages') : 'N/A',
        'PF Monthly ECR': masterItem ? getValidationStatus('PFMonthlyECR') : 'N/A',
        'PF Combined Challan': masterItem ? getValidationStatus('PFCombinedChallan') : 'N/A',
        'PF TRRN Summary': masterItem ? getValidationStatus('PFTRRNSummary') : 'N/A',
        'IW1 International Worker Monthly Returns': masterItem ? getValidationStatus('IW1InternationalWorkerMonthlyReturns') : 'N/A',
        'ESI Accident Register': masterItem ? getValidationStatus('ESIAccidentRegister') : 'N/A',
        'ESIC Contribution Challan': masterItem ? getValidationStatus('ESICContributionChallan') : 'N/A',
        'ESIC Monthly Statement': masterItem ? getValidationStatus('ESICMonthlyStatement') : 'N/A',
        'Pehchan Card': masterItem ? getValidationStatus('PehchanCard') : 'N/A',
        'Professional Tax Returns': masterItem ? getValidationStatus('professionalTaxReturns') : 'N/A',
        'PT Challan': masterItem ? getValidationStatus('PTChallan') : 'N/A',
        'Monthly Bonus Paid or Not': masterItem ? getValidationStatus('MonthlyBonusPaidOrNot') : 'N/A',
        'Statutory Bonus Return': masterItem ? getValidationStatus('statutoryBonusReturn') : 'N/A',
        'Registration of Establishment': masterItem ? getValidationStatus('registrationOfEstablishment') : 'N/A',
        'Appointment Order in Form Q': masterItem ? getValidationStatus('appointmentOrderInFormQ') : 'N/A',
        'Muster Roll of Women Workers': masterItem ? getValidationStatus('musterRollOfWomenWorkers') : 'N/A',
        'Employees Compensation Policy': masterItem ? getValidationStatus('employeesCompensationPolicy') : 'N/A',
        'Equal Remuneration and Register to be Maintained': masterItem ? getValidationStatus('EqualRemunerationAndRegisterToBeMaintained') : 'N/A',
        'Register of Overtime and Payment': masterItem ? getValidationStatus('RegisterofOvertimeandPayment') : 'N/A',
        'Register of Leave and Leave Book': masterItem ? getValidationStatus('RegisterOfLeaveAndLeaveBook') : 'N/A',
        'Register of Adult Workers Cum Muster Roll': masterItem ? getValidationStatus('RegisterOfAdultWorkersCumMusterRoll') : 'N/A',
        'Combined Register for Muster Roll and Wages': masterItem ? getValidationStatus('CombinedregisterforMusterRollandWages') : 'N/A',
        'Register of Unclaimed Wages and Payment to the Board': masterItem ? getValidationStatus('RegisterOfUnclaimedWagesAndPaymentToTheBoard') : 'N/A',
        'LWF Challan': masterItem ? getValidationStatus('LWFChallan') : 'N/A',

        'Number of Employees': masterItem ? masterItem.noOfEmployees : 'N/A',
        'Complied': masterItem ? masterItem.AcceptedPercent+"%" : 'N/A',
        'Non-Complied': masterItem ? masterItem.RejectedPercent+"%" : 'N/A',
      };
    });
 
    // const
 
    // Create worksheet from the data
    const ws = XLSX.utils.json_to_sheet(mappedData);
 
    // Add headers manually to the worksheet
    XLSX.utils.sheet_add_aoa(ws, [headers], { origin: 'A1' });
 
 
    // Style the worksheet (optional)
    const wsCols = [
      { wpx: 50 }, // Sl No
      { wpx: 50 }, // Vendor Code
      { wpx: 250 }, // Name of Contractor/Sub-Contractor
      { wpx: 300 }, // Address
      { wpx: 200 }, // Contact Person
      { wpx: 100 }, // Contact No
      { wpx: 100 }, // Escalation Contact No
      { wpx: 250 }, // Email Id
      { wpx: 250 }, // Escalation Email Id
      { wpx: 150 }, // Nature of Work
      { wpx: 100 }, // CLRA License No
      { wpx: 100 }, // No. of Workers
      { wpx: 100 }, // Valid Date
      { wpx: 100 }, // BOCW RC No
      { wpx: 100 }, // No. of Workers
      { wpx: 100 }, // Valid Date
      { wpx: 100 }, // WC Number
      { wpx: 100 }, // No. of Workers Covered
      { wpx: 100 }, // Valid Date
      { wpx: 100 }, // ESI Number
      { wpx: 100 }, // PF Number
      { wpx: 100 }, // PO Copy Number
      { wpx: 100 }, // Valid Date
      { wpx: 100 }, // HR Policy Undertaking Ack
      { wpx: 100 }, // PSARA Licence
      { wpx: 100 }, // Valid Date
      { wpx: 100 }, // Factory Licence
      { wpx: 100 }, // Valid Date
      { wpx: 100 }, // Valid Date
      { wpx: 100 }, // Valid Date
      { wpx: 100 }, // Valid Date
      { wpx: 100 }, // Valid Date
      { wpx: 100 }, // Valid Date
      { wpx: 100 }, // Valid Date
      { wpx: 100 }, // Valid Date
      { wpx: 100 }, // Valid Date
      { wpx: 100 }, // Valid Date
      { wpx: 100 }, // Valid Date
      { wpx: 100 }, // Valid Date
      { wpx: 100 }, // Valid Date
      { wpx: 100 }, // Valid Date
      { wpx: 100 }, // Valid Date
      { wpx: 100 }, // Valid Date
      { wpx: 100 }, // Valid Date
      { wpx: 100 }, // Valid Date
      { wpx: 100 }, // Valid Date
      { wpx: 100 }, // Valid Date
      { wpx: 100 }, // Valid Date
 
    ];
 
    ws['!cols'] = wsCols; // Apply column widths
 
    // Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Contractor Report');
 
    // Write the workbook to a file
    XLSX.writeFile(wb, 'LCR Report.xlsx');
  };
 
  return (
    <div>
    
      <button className='btn btn-danger btn-sm mb-2 ' onClick={handleExport}>Download Excel</button>
      {/* Optionally display the fetched data in a table */}
      <div style={{ minHeight: "420px",maxHeight: "420px", marginTop:"10px", overflowY: "auto" }}>
      <table style={{ width: '100%' }}>
        <thead  className="position-sticky top-0 " style={{ backgroundColor: 'black', color: 'white', fontWeight: 'bold' }}>
          <tr>
            {headers.map((header, index) => (
              <th key={index} >{header}</th>
            ))}
          </tr>
        </thead>
<tbody>
  {Array.isArray(data) && data.length > 0 ? (
    data.map((item, index) => {
      // Find the corresponding master item for the vendor
      const masterItem = monthlyData.find((master) => master.vendorId === item.vendorId);
 
      // Helper function to check for validation
      const getComplianceStatus = (field) => {
        if (!masterItem || !masterItem[field]) {
          return 'NA'; // If no corresponding master item or field, show 'NA'
        }
        // return masterItem[field].validation === 2 ? 'complied' : masterItem[field].validation === 3 ? 'non-complied' ? masterItem[field].na === true && masterItem[field].validation === 2 ? 'NA' : 'NA'  ;
        return masterItem[field]?.na && masterItem[field].validation === 2
  ? 'Not Applicable'
  : masterItem[field].validation === 2
    ? 'Complied'
    : masterItem[field].validation === 3
      ? 'Non-Complied'
       :masterItem[field].validation === null
        ? 'Not Avaliable'
         : 'NA'; // or some default value
 
      };
 
      return (
        <tr key={index}>
          <td  >{index + 1}</td>
          <td  >{item?.vendorCode ? item?.vendorCode : 'NA'}</td>
          <td  >{item.vendorCompanyName}</td>
          <td  >{item.vendorCompanyAddress}, {item.vendorPlaceName}, {item.vendorStateId}-{item.vendorPinCode}</td>
          <td  >{item.vendorName}</td>
          <td  >{item.vendorPhoneNumber}</td>
          <td  >{item.vendorPhoneNumber1 ? item.vendorPhoneNumber1 : 'NA'}</td>
          <td  >{item.vendorEmail}</td>
          <td  >{item.vendorEmail1 ? item.vendorEmail1 : 'NA'}</td>
          <td  >{item.natureOfWork}</td>

         
          {/* Using the helper function to determine compliance for each field */}
          <td  >{getComplianceStatus('combinedMusterRollCumRegisterOfWages')}</td>
          <td  >{getComplianceStatus('RegisterOfWorkmen')}</td>
          <td  >{getComplianceStatus('leaveRegisterLeaveBook')}</td>
          <td  >{getComplianceStatus('EmploymentCard')}</td>
          <td  >{getComplianceStatus('WageSlip')}</td>
          <td  >{getComplianceStatus('ServiceCertificateForLeftEmployees')}</td>
          <td  >{getComplianceStatus('inAndOutTimeRegister')}</td>
          <td  >{getComplianceStatus('BankStatementOrSalaryCreditConfirmation')}</td>
          <td  >{getComplianceStatus('PFDeclarationOnLetterhead')}</td>
          <td  >{getComplianceStatus('ESIDeclarationOnLetterhead')}</td>
          <td  >{getComplianceStatus('OvertimeDeclarationOnLetterhead')}</td>
          <td  >{getComplianceStatus('PaymentDoneAsPerRevisedRateOfMinimumwages')}</td>
          <td  >{getComplianceStatus('PFMonthlyECR')}</td>
          <td  >{getComplianceStatus('PFCombinedChallan')}</td>
          <td  >{getComplianceStatus('PFTRRNSummary')}</td>
          <td  >{getComplianceStatus('IW1InternationalWorkerMonthlyReturns')}</td>
          <td  >{getComplianceStatus('ESIAccidentRegister')}</td>
          <td  >{getComplianceStatus('ESICContributionChallan')}</td>
          <td  >{getComplianceStatus('ESICMonthlyStatement')}</td>
          <td  >{getComplianceStatus('PehchanCard')}</td>
          <td  >{getComplianceStatus('professionalTaxReturns')}</td>
          <td  >{getComplianceStatus('PTChallan')}</td>
          <td  >{getComplianceStatus('MonthlyBonusPaidOrNot')}</td>
          <td  >{getComplianceStatus('statutoryBonusReturn')}</td>
          <td  >{getComplianceStatus('registrationOfEstablishment')}</td>
          <td  >{getComplianceStatus('appointmentOrderInFormQ')}</td>
          <td  >{getComplianceStatus('musterRollOfWomenWorkers')}</td>
          <td  >{getComplianceStatus('employeesCompensationPolicy')}</td>
          <td  >{getComplianceStatus('EqualRemunerationAndRegisterToBeMaintained')}</td>
          <td  >{getComplianceStatus('RegisterofOvertimeandPayment')}</td>
          <td  >{getComplianceStatus('RegisterOfLeaveAndLeaveBook')}</td>
          <td  >{getComplianceStatus('RegisterOfAdultWorkersCumMusterRoll')}</td>
          <td  >{getComplianceStatus('CombinedregisterforMusterRollandWages')}</td>
          <td  >{getComplianceStatus('RegisterOfUnclaimedWagesAndPaymentToTheBoard')}</td>
          <td  >{getComplianceStatus('LWFChallan')}</td>
          <td  >{masterItem && masterItem.noOfEmployees}</td>
          <td  >{masterItem && masterItem?.AcceptedPercent + "%"}</td>
          <td  >{masterItem && masterItem?.RejectedPercent + "%"}</td>
        </tr>
      );
    })
  ) : (
    <tr>
      <td colSpan={headers.length}>No data available</td>
    </tr>
  )}
</tbody>
 
      </table>
      </div>
    </div>
  );
};
 
export default MonthlyComplianceReport;